import React, { useEffect, useMemo } from 'react'
import withLayout from 'hoc/withLayout'
import Container from 'components/Container/Container'
import Value from '../../components/Value/Value'
import classnames from 'classnames'
import ChartRanking from './components/Chart'
import TopDragons from './components/TopDragons'
import {
  useChartRanking,
  useFetchChartRanking,
  useFetchReportBattles,
  useFetchTopDragons,
  useReportBattles,
  useTopDragons,
} from '../../store/report/hook'
import TopOthers from './components/TopOthers'
import { DEFAULT_TOKEN_USED, TOKEN_USED_MARKETPLACE } from '../../constants'
import TopEvent from './components/TopEvents'
import { useQuery } from '../../utils'
import { useHistory } from 'react-router-dom'

const RankDragons = ({}) => {
  useFetchChartRanking()
  useFetchTopDragons()
  useFetchReportBattles()
  const query = useQuery()
  const { replace } = useHistory()
  const chart = useChartRanking()
  const topDragons = useTopDragons()
  const battles = useReportBattles()
  const dataHeader = chart?.length ? chart?.[chart.length - 1] : undefined
  const total = [
    {
      label: 'Total Dragons',
      value: typeof dataHeader?.dragons === 'number' ? dataHeader.dragons : null,
    },
    {
      label: 'Total Breeding',
      value: typeof dataHeader?.breeding === 'number' ? dataHeader.breeding : null,
    },
    {
      label: 'Total Adventure',
      value: typeof dataHeader?.adventure === 'number' ? dataHeader.adventure : null,
    },
    {
      label: 'Total Boost',
      value: typeof dataHeader?.boots === 'number' ? dataHeader.boots : null,
    },
    {
      label: 'Gen 0 Dragon',
      value: 10000,
    },
  ]

  const battleStatistics = [
    {
      label: 'Number of PvE battles',
      value: typeof battles?.totalAdventure === 'number' ? battles.totalAdventure : null,
    },
    {
      label: 'Revenue PvE',
      value: typeof battles?.revenueAdventure === 'number' ? battles.revenueAdventure : null,
      unit: DEFAULT_TOKEN_USED.symbol,
    },
  ]

  const series1 = useMemo(() => {
    if (chart?.length) {
      const dragons = []
      const breeding = []
      chart.map((item) => {
        dragons.push([item.date, item.dragons])
        breeding.push([item.date, item.breeding])
      })
      return [
        {
          name: 'Total Dracoras',
          data: dragons,
        },
        {
          name: 'Total Breeding',
          data: breeding,
        },
      ]
    }
    return []
  }, [chart])

  const series2 = useMemo(() => {
    if (chart?.length) {
      const adventure = []
      const boost = []
      chart.map((item) => {
        adventure.push([item.date, item.adventure])
        boost.push([item.date, item.boots])
      })
      return [
        {
          name: 'Adventure times',
          data: adventure,
        },
        {
          name: 'Boost times',
          data: boost,
        },
      ]
    }
    return []
  }, [chart])

  const series3 = useMemo(() => {
    if (chart?.length) {
      const dragonsSale = []
      const dragonsSire = []
      chart.map((item) => {
        dragonsSale.push([item.date, item.dragonsSale])
        dragonsSire.push([item.date, item.dragonsSire])
      })
      return [
        {
          name: 'Dracoras sold',
          data: dragonsSale,
        },
        {
          name: 'Dracoras sired',
          data: dragonsSire,
        },
      ]
    }
    return []
  }, [chart])

  const series4 = useMemo(() => {
    if (chart?.length) {
      const sale = []
      const sire = []
      chart.map((item) => {
        sale.push([item.date, Math.round(item.kaiSale)])
        sire.push([item.date, Math.round(item.kaiSire)])
      })
      return [
        {
          name: `${TOKEN_USED_MARKETPLACE.symbol} for sale`,
          data: sale,
        },
        {
          name: `${TOKEN_USED_MARKETPLACE.symbol} for sire`,
          data: sire,
        },
      ]
    }
    return []
  }, [chart])

  const goToDiv = () => {
    if (query?.view) {
      const el = document.getElementById(query?.view)
      if (el) {
        const scrollDiv = el.offsetTop
        window.scrollTo({ top: scrollDiv, behavior: 'smooth' })
        replace('/ranking')
      }
    }
  }

  useEffect(() => {
    goToDiv()
  }, [])

  return (
    <Container className="pt-10 pb-36">
      <h3 className="text-white text-center text-3xl my-5">Ranking</h3>
      <div className="mx-4">
        <div className="py-3">
          <div className="grid grid-cols-2 md:grid-cols-5 rounded-xl border-2 border-blue2 overflow-hidden">
            {total.map((col, index) => (
              <div
                className={classnames(
                  'border-blue2 text-white text-center px-2 py-4',
                  {
                    'border-l-0 col-span-2 md:col-span-1': index === 0,
                  },
                  {
                    'border-l-2': [2, 4].includes(index),
                  },
                  {
                    'border-l-0 md:border-l-2': [1, 3].includes(index),
                  },
                  {
                    'border-b-2 md:border-b-0': [0, 1, 2].includes(index),
                  },
                  {
                    'col-span-2 md:col-span-1': index === 0,
                  },
                )}
                key={index}
              >
                <p>{col.label}</p>
                <div className="text-2xl mt-2">
                  {typeof col.value === 'number' ? <Value value={col.value} decimals={0} /> : '--'}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="py-3">
          <div className="grid grid-cols-2 rounded-xl border-2 border-blue2 overflow-hidden">
            {battleStatistics.map((col, index) => (
              <div
                className={classnames('border-blue2 text-white text-center px-2 py-4 col-span-2 md:col-span-1', {
                  'border-r-0 md:border-r-2 border-b-2 md:border-b-0': index === 0,
                })}
                key={index}
              >
                <p>{col.label}</p>
                <div className="text-2xl mt-2">
                  {typeof col.value === 'number' ? (
                    <Value value={col.value} decimals={0} {...(col.unit ? { unit: ` ${col.unit}` } : null)} />
                  ) : (
                    '--'
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-5">
          <h2 className="text-white text-center text-2xl font-bold">Dragons</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 sm:gap-6">
            <div>
              <ChartRanking titleTable="Dracoras" titleY="Amount" series={series1} />
            </div>
            <div>
              <ChartRanking titleTable="Adventure" titleY="Times" series={series2} />
            </div>
          </div>
        </div>
        <div className="my-5">
          <h2 className="text-white text-center text-2xl">Marketplace</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 sm:gap-6">
            <div>
              <ChartRanking titleTable="Dracoras" titleY="Amount" series={series3} />
            </div>
            <div>
              <ChartRanking titleTable="Volume" titleY={TOKEN_USED_MARKETPLACE.symbol} series={series4} />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 -mx-3">
          <TopDragons title="Top Level" type="level" data={topDragons?.level} />
          <TopDragons title="Top Stats" type="stats" data={topDragons?.stats} />
        </div>
        <TopOthers />
        <TopEvent />
      </div>
    </Container>
  )
}

export default withLayout(RankDragons, null, '#0A1520')
