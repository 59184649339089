import Button from 'components/Button/Button'
import MenuMobile from 'components/Header/MenuMobile'
import WalletModal from 'components/Modal/ModalWallet/ModalWallet'
import Web3Connect from 'components/Web3Connect/Web3Connect'
import Web3Network from 'components/Web3Network/Web3Network'
import { useModalWalletConnect } from 'store/modal/hook'
import { shortenAddress } from 'utils/index'

import { NetworkContextName } from 'constants/index'
import { useWeb3React } from '@web3-react/core'
import ButtonGradient from '../Button/ButtonGradient'

function Web3StatusInner() {
  const { account } = useWeb3React()

  const { onToggleConnectModal } = useModalWalletConnect()

  if (account) {
    return (
      <div className="flex flex-wrap justify-center items-center text-sm sm:text-md ml-1 sm:ml-2">
        <ButtonGradient size="sm" onClick={onToggleConnectModal}>
          {shortenAddress(account)}
        </ButtonGradient>
        {/*<Web3Network />*/}
        {/*<MenuMobile className="hidden sm:block" />*/}
      </div>
    )
  } else {
    return (
      <>
        <Web3Connect />
        {/*<Web3Network />*/}
        {/*<MenuMobile className="" />*/}
      </>
    )
  }
}

export default function Web3Status() {
  const { active } = useWeb3React()
  const contextNetwork = useWeb3React(NetworkContextName)

  if (!contextNetwork.active && !active) {
    return null
  }

  return (
    <>
      <Web3StatusInner />
      <WalletModal />
    </>
  )
}
