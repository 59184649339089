import { DRAGON_CAVE_ADDRESS } from 'config'
import multicall from 'utils/multicall'
import dragonCaveAbi from 'config/abi/DragonCave_ABI.json'
import BigNumber from 'bignumber.js'
import { BIG_TEN } from 'utils/bigNumber'

export const fetchUserDataDragonCave = async (account) => {
  try {
    const calls = [
      {
        address: DRAGON_CAVE_ADDRESS.address,
        name: 'balanceOf',
        params: [account],
      },
    ]
    const [balance] = await multicall(dragonCaveAbi, calls)
    return {
      balance: new BigNumber(balance).dividedBy(BIG_TEN.pow(DRAGON_CAVE_ADDRESS.decimals)).toNumber(),
      userDataLoaded: true,
    }
  } catch (error) {}
}
