import React from 'react'
import PropTypes from 'prop-types'
import Box from '../Box/Box'
import classnames from 'classnames'
import Value from 'components/Value/Value'
import { DRAGON_BODY_PART, DRAGON_STATS, RARITY_ITEM } from 'constants/index'
import Badge from 'components/Badge/Badge'
export default function ItemCard({ item, showBadge = true, className, ...rest }) {
  const bodyPart = item?.part
  const stat = item?.stat

  const bgItem = item?.rarity && RARITY_ITEM?.[item?.rarity]?.image

  return (
    <Box
      {...rest}
      style={{
        backgroundImage: `url(${bgItem})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
      }}
      className={classnames('flex flex-col items-center relative background-skill-card', className)}
    >
      <div
        style={{
          background: RARITY_ITEM?.[item?.rarity]?.color,
        }}
        className="absolute left-0 top-12 rounded-r-full z-20 skill-rarity text-sm"
      >
        <p className="p-1 font-bold text-black">{RARITY_ITEM?.[item?.rarity]?.label}</p>
      </div>
      {showBadge && (
        <>
          <div className="absolute -top-2.5 sm:-left-2 left-1">
            <Badge className="bg-white text-black font-bold py-0.5 px-4 text-sm-md item-badge-container">
              #{item?.nftId}
            </Badge>
          </div>
          <div className="absolute -top-2.5 sm:-right-2 right-2">
            <Badge className="bg-white text-black font-bold py-0.5 px-4 text-sm-md item-badge-container">
              Level: {item?.level + 1}
            </Badge>
          </div>
        </>
      )}
      <div className="w-full h-9 flex items-center justify-center mt-2 item-name-container">
        <h1 className="font-bold capitalize skill-name text-black text-lg">{item?.name}</h1>
      </div>

      <div className="flex items-center justify-center w-full">
        <div className=" w-40 h-40 item-background flex items-center justify-center">
          <img src={item?.image} alt="item" className="max-w-full max-h-full" />
        </div>
      </div>

      <div className="flex items-center my-4 text-white item-point-container">
        <div className="flex items-center px-2 item-icon-container">
          <div className="w-8 item-icon flex items-center justify-center">
            <img src={DRAGON_BODY_PART?.[bodyPart]?.image} className="max-w-full max-h-full" alt="icon" />
          </div>
          <span className="sm:ml-2 ml-1 text-md item-card-text">{DRAGON_BODY_PART[bodyPart]?.display}</span>
        </div>
        <div className="flex items-center px-2 item-icon-container">
          <div className="mr-1 w-5 item-icon flex items-center justify-center">
            <img src={DRAGON_STATS?.[stat]?.icon} alt="icon" className="max-w-full max-h-full" />
          </div>
          +
          {typeof item?.point === 'number' ? (
            <Value className="text-md item-card-text" value={item?.point} decimals={0} />
          ) : (
            '--'
          )}
        </div>
      </div>
    </Box>
  )
}

ItemCard.propTypes = {
  item: PropTypes.object,
}
