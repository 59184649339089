import { useCallback } from 'react'
import useActiveWeb3React from './useActiveWeb3React'
import { useEventMonsterContract } from './useContract'
import { fightMonster } from '../utils/calls/events'

export const useFightMonster = (type, monsterAddress) => {
  const { account } = useActiveWeb3React()
  const contract = useEventMonsterContract(monsterAddress)
  const handleFight = useCallback(
    async (dragonId, monsterId) => {
      const txHash = await fightMonster(contract, dragonId, monsterId, type)
      return txHash
    },
    [account, type],
  )

  return { onFightMonster: handleFight }
}
