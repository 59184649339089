import React from 'react'
import { ChevronLeft } from 'react-feather'
import Container from '../Container/Container'
import { useHistory } from 'react-router-dom'

const NavBack = () => {
  const history = useHistory()
  return (
    <Container className="pt-2 sm:pt-16">
      <div
        className="inline-flex items-center text-white font-bold p-2 cursor-pointer hover:font-extrabold"
        onClick={() => history.goBack()}
      >
        <ChevronLeft size={30} className="pr-2" />
        Back
      </div>
    </Container>
  )
}

export default NavBack
