import React from 'react'
import Chart from 'react-apexcharts'

const ChartRanking = ({ titleTable, titleY, series }) => {
  const options = {
    chart: {
      type: 'line',
      stacked: false,
      height: 350,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: 'zoom',
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    title: {
      text: titleTable,
      align: 'left',
    },
    yaxis: {
      title: {
        text: titleY,
      },
    },
    xaxis: {
      type: 'datetime',
    },
    tooltip: {
      shared: false,
    },
  }
  return (
    <div
      style={{
        background: '#f5f5f5',
      }}
      className="border-2 border-blue2 w-full py-3 sm:px-3 rounded-xl md:mb-8 mt-3 bg-white text-black"
    >
      <div id="chart">
        <Chart options={options} series={series} type="line" height={350} />
      </div>
    </div>
  )
}

export default ChartRanking
