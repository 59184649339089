import PropTypes from 'prop-types'

const ContainerPage = ({ children }) => {
  return <div className="flex flex-col min-h-screen relative overflow-hidden bg-black80 text-white">{children}</div>
}

ContainerPage.propTypes = {
  children: PropTypes.node,
}

export default ContainerPage
