import { useCallback } from 'react'
import { useAuctionContract, useMarketPlaceNFTContract } from './useContract'
import { buyDragonMarket, buyEgg, buyItem } from '../utils/calls/auction'
import useActiveWeb3React from './useActiveWeb3React'

export const useBuyEgg = (poolAddress) => {
  const { account } = useActiveWeb3React()
  const contract = useAuctionContract(poolAddress)
  const handleBuyEgg = useCallback(
    async (idDragon, price, decimals) => {
      const txHash = await buyEgg(contract, idDragon, price, decimals, account)
      return txHash
    },
    [poolAddress, account],
  )
  return { onBuyToken: handleBuyEgg }
}

export const useBuyDragonMarket = (saleAddress) => {
  const { account } = useActiveWeb3React()
  const contract = useAuctionContract(saleAddress)

  const handleBuy = useCallback(
    async (idDragon, price, decimals) => {
      const txHash = await buyDragonMarket(contract, idDragon, price, decimals)
      return txHash
    },
    [saleAddress, account],
  )

  return { onBuyDragon: handleBuy }
}

export const useBuyItem = (saleAddress) => {
  const { account } = useActiveWeb3React()
  const contract = useMarketPlaceNFTContract(saleAddress)
  const handleBuyItem = useCallback(
    async (listingId, price, decimals) => {
      const txHash = await buyItem(contract, listingId, price, decimals)
      return txHash
    },
    [saleAddress, account],
  )

  return { onBuyItem: handleBuyItem }
}
