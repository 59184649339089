import classnames from 'classnames'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const Logo = ({ className }) => {
  return (
    <div className="relative">
      <Link to="/" className={classnames('flex items-center logo-header', className)}>
        <img alt="logo" src="/logo.png" />
      </Link>
    </div>
  )
}

Logo.propTypes = {
  className: PropTypes.string,
}

export default Logo
