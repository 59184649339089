import React, { useEffect, useRef, useState } from 'react'
import ListDragons from './ListDragons'
import ButtonGradient from 'components/Button/ButtonGradient'
import Container from 'components/Container/Container'
import WrapperBackground from 'components/Wrapper/WrapperBackground'
import BuyEgg from './BuyEgg'
import tokens from 'constants/tokens'
import { useHistory } from 'react-router-dom'
import { useDflPrice, useKaiPrice } from '../../../hooks/usePrice'
import dragonsApi from '../../../api/dragonsApi'
import { MARKETPLACE_REPORT_TYPE, ORDER_TYPE, SORT_DRAGON_FIELD } from '../../../constants'
import ListMarketplace from './ListMarketplace'
import classnames from 'classnames'
import useActiveWeb3React from '../../../hooks/useActiveWeb3React'

const OverviewInfo = ({}) => {
  const { push } = useHistory()
  const { account } = useActiveWeb3React()
  const dflPrice = useDflPrice()
  const tokenPrice = useKaiPrice()

  const [dragons, setDragons] = useState(undefined)
  const [tab, setTab] = useState(1)

  const handleFavorites = async (id) => {
    try {
      await dragonsApi.tickFavorites(id, account)
      await fetchListedDragons()
    } catch (error) {
      console.log(error)
    }
  }

  const fetchListedDragons = async () => {
    try {
      const response = await dragonsApi.getDragons({
        sale: MARKETPLACE_REPORT_TYPE.AUCTION,
        order: SORT_DRAGON_FIELD.DATELISTED.field,
        orderBy: ORDER_TYPE.DESC,
        page: 1,
        rowPerPage: 8,
      })
      setDragons(response)
    } catch (error) {}
  }

  const handleChangeTab = (value) => {
    if (tab !== value) {
      setTab(value)
    }
  }

  useEffect(() => {
    fetchListedDragons()
  }, [])

  const dataBannerBottom = {
    img: '/images/home-slider-1.png',
    content:
      'Dracora is a decentralized blockchain game with Play-To-Earn concept. You can earn DRACORA Stones tokens by playing game then use them to swap or continue playing.',
    btnText: 'Get DRACORA Token',
    action: () => window.open(`https://defily.io/#/swap?outputCurrency=${tokens.dragon.address}`, '_blank'),
  }

  return (
    <WrapperBackground colorOverlay="#0a152080">
      <div className="my-8">
        <div className="flex justify-center my-2">
          <p
            className={classnames(
              'text-white sm:text-lg w-32 md:w-36 text-center py-2 sm:py-3 cursor-pointer font-bold',
              {
                'border-b-4 border-white': tab === 1,
              },
            )}
            onClick={() => handleChangeTab(1)}
          >
            Marketplace
          </p>
          <p
            className={classnames(
              'text-white sm:text-lg w-32 md:w-36 text-center py-2 sm:py-3 cursor-pointer font-bold',
              {
                'border-b-4 border-white': tab === 2,
              },
            )}
            onClick={() => handleChangeTab(2)}
          >
            Genesis Sale
          </p>
        </div>
        <div
          className="relative mb-100"
          style={{
            height: tab === 1 ? '200px' : '470px',
            transition: 'height 0.5s ease-in-out',
          }}
        >
          <div className="relative" style={{ height: '100%' }}>
            <div
              className={classnames('absolute inset-0 transition-opacity duration-500 ease-in-out', {
                'opacity-0': tab !== 1,
                'opacity-100': tab === 1,
              })}
              style={{ zIndex: tab === 1 ? 1 : 0 }}
            >
              <ListMarketplace
                dragons={dragons}
                dflPrice={dflPrice}
                tokenPrice={tokenPrice}
                handleFavorites={handleFavorites}
              />
            </div>
            <div
              className={classnames('absolute inset-0 transition-opacity duration-500 ease-in-out', {
                'opacity-0': tab !== 2,
                'opacity-100': tab === 2,
              })}
              style={{ zIndex: tab === 2 ? 1 : 0 }}
            >
              <BuyEgg />
            </div>
          </div>
        </div>
      </div>

      <Container className="mb-5 px-4">
        <div className="bg-blue3 rounded-lg overflow-hidden">
          <div className="hidden sm:grid grid-cols-1 sm:grid-cols-10">
            <div className="col-span-4 px-2 md:px-6 py-4 md:py-8 text-white flex flex-col justify-center">
              <p>{dataBannerBottom?.content}</p>
              {/* <div className="flex justify-center sm:justify-start">
                <ButtonGradient className="mt-3 sm:mt-7" onClick={dataBannerBottom?.action}>
                  {dataBannerBottom?.btnText}
                </ButtonGradient>
              </div> */}
            </div>
            <div className="col-span-6">
              <img src={dataBannerBottom?.img} className="w-full h-full round-lg" />
            </div>
          </div>
          {/*reverse content*/}
          <div className="sm:hidden grid grid-cols-1 sm:grid-cols-10">
            <div className="col-span-6">
              <img src={dataBannerBottom?.img} className="w-full h-full round-lg" />
            </div>
            <div className="col-span-4 px-2 md:px-6 py-4 md:py-8 text-white flex flex-col justify-center">
              <p className="px-2">{dataBannerBottom?.content}</p>
              {/* <div className="flex justify-center sm:justify-start">
                <ButtonGradient className="mt-3 sm:mt-7" onClick={dataBannerBottom?.action}>
                  {dataBannerBottom?.btnText}
                </ButtonGradient>
              </div> */}
            </div>
          </div>
        </div>
      </Container>

      <ListDragons />
      <Container className="mb-20 px-4">
        <div className="rounded-lg overflow-hidden">
          <div
            style={{
              backgroundImage: 'url(/images/slide-home-bottom1.png)',
              backgroundSize: 'cover',
            }}
            className="shadow-md bg-cover bg-center bg-no-repeat h-48 sm:h-60 md:h-72 lg:h-80 xl:h-96"
          >
            <div
              className="flex items-center justify-center w-full h-full p-5"
              style={{
                background: '#00000069',
              }}
            >
              <div className="flex flex-col items-center">
                <h3 className="text-white font-bold text-xl md:text-2xl lg:text-3xl xl:text-4xl max-w-md">Dracora</h3>
                {/** TODO: Uncomment when deploy prod */}
                {/* <div>
                  <ButtonGradient className="mt-5" onClick={() => push('/marketplace?sale=AUCTION')}>
                    Buy Dracora
                  </ButtonGradient>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </WrapperBackground>
  )
}

export default OverviewInfo
