import React from 'react'
import Badge from 'components/Badge/Badge'
import { ITEM_STATUS_BG_COLOR, ITEM_STATUS } from 'constants/index'
import ButtonRefresh from '../ButtonRefresh'
import ItemCard from 'components/Card/ItemCard'
export default function ViewEquipment({ item, updateData }) {
  return (
    <div className="px-2">
      <div className="flex mb-5">
        <div>
          <Badge className="text-white px-4 text-sm-md mr-4 bg-orange50">#{item?.nftId}</Badge>
        </div>
        {item?.status !== 'ACTIVE' ? (
          <div>
            <Badge
              style={{
                background: `linear-gradient(to right, ${ITEM_STATUS_BG_COLOR[item?.status?.toUpperCase()]?.from},${
                  ITEM_STATUS_BG_COLOR[item?.status?.toUpperCase()]?.to
                })`,
              }}
              className="text-white px-4 text-sm-md mr-4"
            >
              {ITEM_STATUS?.[item?.status?.toUpperCase()]}
            </Badge>
          </div>
        ) : null}
      </div>
      <div className="flex items-center">
        <p className="text-2xl font-bold mr-4 uppercase">{item?.equipment?.name}</p>
        <p className="text-lg opacity-50">Level {item?.level + 1}</p>
      </div>
      <p className="flex items-center text-2xl mt-4 mb-2">
        <span className="font-bold mr-2">Equipment</span>
        <span className="font-normal">#{item?.nftId}</span>
      </p>
      <ButtonRefresh item={item} updateData={updateData} />
      <div className="relative w-full md:w-60 lg:w-80 mt-28 flex md:justify-end justify-center">
        <ItemCard item={item?.equipment} showBadge={false} className="z-20" />
        <img
          src="/images/floor-shadow-1.png"
          alt="background"
          className="absolute lg:top-48 lg:-right-16 md:top-56 md:-right-10 top-40 z-0 background-view-skill"
        />
      </div>
    </div>
  )
}
