import classnames from 'classnames'
import { NavLink, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { LIST_MENU } from '../../constants'
import { useDispatch } from 'react-redux'
import { resetSearchData } from '../../store/search'
import Dropdown from '../Dropdown/Dropdown'
import Collapse from 'components/Collapse'
import { useQuery } from 'utils'
const Menu = ({ className, ...rest }) => {
  const dispatch = useDispatch()
  const { location } = useHistory()
  const query = useQuery()

  return (
    <div {...rest} className={classnames('', className)}>
      {LIST_MENU.map((menu, index) => {
        const TagLink = menu?.route ? NavLink : 'a'
        const isActive =
          (menu.activeRoute && menu.activeRoute.includes(`/${location.pathname?.split('/')?.[1]}`)) ||
          location.pathname === menu.route

        // Check if the menu title is "Play now!" for special styling
        const isPlayNow = menu.title === 'Play now!'

        if (menu.child) {
          return (
            <Dropdown
              key={index}
              menu={{ label: menu.title }}
              classNameMenuItem="bg-primary custom-shadow-box w-52 py-2 top-18 left-0"
              classNameMenu={classnames(
                'h-full text-white font-bold text-md xl:text-base lg:text-sm-md whitespace-nowrap font-grobol', // Added font-grobol here
                {
                  'border-b-4': isActive,
                },
              )}
              classNameLabelMenu={classnames(
                'h-full hover:text-gray-100',
                {
                  'text-gray-100': isActive,
                },
                !isActive && 'text-gray-400',
              )}
              onClick={() => {
                if (menu.child.length === 1) {
                  history.push(menu.child[0].route)
                }
              }}
            >
              {menu.child.map((item, indexChild) => {
                const TagLink = item?.route ? NavLink : 'a'
                if (item?.child) {
                  return (
                    <Collapse
                      key={indexChild}
                      className="cursor-pointer mx-2 xl:mx-0 py-2 text-gray-400 hover:text-gray-100 block rounded font-grobol" // Added font-grobol here
                      headerClassName="px-2.5"
                      defaultExtend
                      title={item?.title}
                      widthArrow={15}
                    >
                      {item?.child?.map((child, index) => {
                        const TagLink = child?.route ? NavLink : 'a'
                        return (
                          <TagLink
                            key={index}
                            className={classnames(
                              'px-5 text-md mx-2 xl:mx-0 py-2 text-gray-400 hover:text-gray-100 block rounded bg-dropdown-item-hover font-grobol', // Added font-grobol here
                              {
                                'bg-dropdown-item': child?.route?.split('?')?.[0] === location.pathname,
                              },
                            )}
                            {...(child?.route
                              ? {
                                  to: child.route,
                                }
                              : {
                                  href: child?.url,
                                  target: !child?.inside ? '_blank' : '',
                                })}
                          >
                            <div className="w-full h-full flex items-center">
                              <span className="w-1 h-1 rounded-full bg-white mr-2" />
                              <div className="flex-1">{child.title}</div>
                            </div>
                          </TagLink>
                        )
                      })}
                    </Collapse>
                  )
                }
                return (
                  <TagLink
                    className={classnames(
                      'px-2.5 mx-2 xl:mx-0 py-2 text-gray-400 hover:text-gray-100 block rounded font-grobol', // Added font-grobol here
                      {
                        'bg-dropdown-item': item?.route?.split('?')?.[0] === location.pathname,
                      },
                    )}
                    key={indexChild}
                    {...(item?.route
                      ? {
                          to: item.route,
                        }
                      : {
                          href: item?.url,
                          target: !item?.inside ? '_blank' : '',
                        })}
                  >
                    <div className="group w-full h-full flex flex-row items-center">
                      <div className="flex-1">{item.title}</div>
                    </div>
                  </TagLink>
                )
              })}
            </Dropdown>
          )
        }

        return (
          <TagLink
            className={classnames(
              'font-bold text-md xl:text-base lg:text-sm-md whitespace-nowrap mx-2 xl:mx-0 inline-flex items-center font-grobol',
              {
                'hover:text-gray-100': !isPlayNow,
                'text-gray-100': isActive || isPlayNow,
                'bg-green-500 text-white hover:bg-green-400 rounded-lg self-center': isPlayNow,
                'h-10 px-4': isPlayNow, // Set a specific height and adjust padding
              },
              !isActive && !isPlayNow && 'text-gray-400',
            )}
            key={index}
            {...(menu?.route
              ? {
                  to: menu.route,
                }
              : {
                  href: menu?.url,
                  target: !menu?.inside ? '_blank' : '',
                })}
            onClick={() => dispatch(resetSearchData())}
            isActive={(match, location) => {
              const pathString = location.pathname?.split('/')?.[1]
              if (menu.activeRoute && menu.activeRoute.includes(`/${pathString}`)) return true
              return location.pathname === menu.route
            }}
            activeClassName="border-b-4"
          >
            <span className="w-full">{menu.title}</span>
          </TagLink>
        )
      })}
    </div>
  )
}

Menu.propTypes = {
  className: PropTypes.string,
}

export default Menu
