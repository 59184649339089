import React from 'react'
import { formatNumber } from 'utils/formatBalance'
import Value from 'components/Value/Value'
import {
  DEFAULT_TOKEN_USED,
  EVENT_MONSTER_TYPES,
  MONSTER_NAME,
  MONSTER_TYPES,
  MONSTER_TYPES_QUERY,
  RATIO_KILL_MONSTER,
} from 'constants/index'
import { Link } from 'react-router-dom'
import ButtonFight from '../../Monster/components/ButtonFight'
import ButtonHistory from '../../Monster/components/ButtonHistory'
import { formatNumberToTime } from 'utils/formatDateTime'

const MonsterCard = ({ monster, updateData, openModal, setOpenModal, cooldown }) => {
  const detailXX = [
    {
      label: 'Remaining reward',
      value: typeof monster?.totalReward === 'number' ? formatNumber(monster?.totalReward, 0, 0) : null,
      unit: <span className="text-sm pl-0.5">{DEFAULT_TOKEN_USED.symbol}</span>,
    },
    {
      label: 'Reward',
      value: typeof monster?.rewardAmount === 'number' ? formatNumber(monster?.rewardAmount, 0, 0) : null,
      unit: <span className="text-sm pl-0.5">{DEFAULT_TOKEN_USED.symbol}</span>,
    },
    {
      label: 'Required level',
      value: monster?.requireLevel,
    },
    {
      label: 'Chance increase',
      value:
        typeof monster?.chanceIncrease === 'number'
          ? formatNumber((monster?.chanceIncrease / RATIO_KILL_MONSTER) * 100, 0, 2)
          : null,
      unit: '%',
    },
    {
      label: 'Interval between fights',
      value: typeof cooldown === 'number' ? formatNumberToTime(cooldown) : '--',
    },
    {
      label: 'Unlock reward in',
      value: formatNumberToTime(monster?.lockTime),
    },
  ]

  const detailYY = [
    {
      label: 'Total reward',
      value: typeof monster?.totalReward === 'number' ? formatNumber(monster?.totalReward, 0, 0) : null,
      unit: <span className="text-sm pl-0.5">{DEFAULT_TOKEN_USED.symbol}</span>,
    },
    {
      label: 'Last-hitting reward',
      value: monster?.rewardPercentage,
      unit: '%',
    },
    {
      label: 'Health',
      value:
        typeof monster?.currentHp === 'number' ? (
          <Value
            value={monster?.currentHp || 0}
            decimals={0}
            unit={`/${typeof monster?.totalHp === 'number' ? formatNumber(monster?.totalHp, 0, 0) : '--'}`}
          />
        ) : null,
    },
    {
      label: 'Interval between fights',
      value: typeof cooldown === 'number' ? formatNumberToTime(cooldown) : '--',
    },
  ]

  const detailData = monster?.type === MONSTER_TYPES.XX_MONSTER ? detailXX : detailYY

  return (
    <Link to={`/${EVENT_MONSTER_TYPES[monster?.type].url}/${monster?.id}`}>
      <div className="text-white mt-8">
        <div className="flex justify-center items-end">
          <img src={`/images/${monster?.type}.png`} />
        </div>
        <h2 className="text-center text-xl md:text-2xl mb-3 mt-1">
          {MONSTER_NAME[monster.type]} {monster?.id}
        </h2>
        <div className="rounded-lg border-1 border-blue2 bg-blue1 px-3 sm:px-5 py-4">
          <div className="mb-2">
            {detailData.map((row, index) => (
              <div key={index} className="flex justify-between items-end py-1">
                <p className="text-md">{row.label}</p>
                <div className="text-md sm:text-base  text-blue4">
                  {row.value}
                  {row?.unit}
                </div>
              </div>
            ))}
          </div>
          {monster?.available ? (
            <ButtonFight
              className="text-sm-md w-full"
              monster={monster}
              available={true}
              openModal={openModal}
              setOpenModal={setOpenModal}
              updateData={updateData}
            />
          ) : null}
        </div>
        <ButtonHistory
          monsterId={monster?.id}
          type={monster?.type === MONSTER_TYPES.XX_MONSTER ? MONSTER_TYPES_QUERY.XX : MONSTER_TYPES_QUERY.YY}
          className="w-full mt-3 text-sm-md"
        />
      </div>
    </Link>
  )
}

export default MonsterCard
