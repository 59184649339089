import React, { useState, useEffect } from 'react'
import Modal from 'components/Modal/ModalV2'
import ModalTitle from 'components/Modal/ModalTitleV2'
import { GIFT_TYPE } from 'constants/index'
import ButtonGradient from 'components/Button/ButtonGradient'
import { fetchGiftsAsync } from 'store/gift/index'
import { useDispatch } from 'react-redux'
import giftApi from 'api/giftApi'
import SkillCard from 'components/Card/SkillCard'

import { showToastSuccess } from 'components/CustomToast/CustomToast'

export default function ModalOpenGift({ type, open, toggle }) {
  const dispatch = useDispatch()

  const [reward, setReward] = useState()
  const onOpenGift = async () => {
    try {
      const response = await giftApi.openGift(type)
      setReward(response)
      showToastSuccess('Success', `Open gift successfully!`)
      dispatch(fetchGiftsAsync())
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setReward(null)
  }, [open])

  return (
    <Modal open={open} isDark onClose={toggle} className="w-full">
      <ModalTitle onClose={toggle}>Open Gift</ModalTitle>
      <div className="py-10 flex flex-col items-center">
        {!reward ? (
          <>
            <div className="flex justify-center h-56">
              <img src={GIFT_TYPE?.[type]?.image} alt="gift-card" className="max-h-full" />
            </div>
            <ButtonGradient className="mt-10" onClick={onOpenGift}>
              Open
            </ButtonGradient>
          </>
        ) : (
          <div className="animation-open-gift">
            {reward?.type === 'SKILL_CARD' ? (
              <div className="flex justify-center">
                <SkillCard skill={reward?.skill} />
              </div>
            ) : (
              <div className="flex justify-center">
                {reward?.type === 'EQUIPMENT' ? (
                  <div className="rounded-lg border-2 overflow-hidden h-full border-blue2 bg-blue1 p-3 pb-6 text-white w-60 sm:w-full">
                    <p className="text-lg opacity-50 mt-3">Level: {reward?.level + 1 || 1}</p>
                    <div className="py-3 flex justify-center w-full mt-3">
                      {reward?.type === 'EQUIPMENT' ? (
                        <div className="px-5 flex flex-col justify-center items-center">
                          <div className="sm:w-56 sm:h-56 w-40 h-40 flex flex-col justify-center items-center">
                            <img src={reward?.equipment?.image} alt="item" className="max-w-full max-h-full" />
                          </div>
                          <p className="uppercase text-lg font-medium text-blue4 text-center mt-5">
                            {reward?.equipment?.name}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {reward?.type === 'EXP_CARD' ? (
                  <div className="relative py-3 flex justify-center w-full mt-3">
                    <div className="md:h-56 sm:h-40 h-32">
                      <img src="/images/exp_card.png" className="max-w-full max-h-full" />
                      <p className="absolute md:text-2xl sm:text-xl text-lg text-white50 md:bottom-12 sm:bottom-10 bottom-8 left-1/2 transform -translate-x-1/2">
                        +{reward?.exp} exp
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  )
}
