import { createSlice } from '@reduxjs/toolkit'
import giftApi from 'api/giftApi'

const initialState = {
  gifts: undefined,
}

const giftSlice = createSlice({
  name: 'gift',
  initialState,
  reducers: {
    setGifts: (state, action) => {
      state.gifts = action.payload
    },
  },
})

export const fetchGiftsAsync = () => async (dispatch) => {
  try {
    const response = await giftApi.getGifts()
    dispatch(setGifts(response))
  } catch (error) {
    dispatch(setGifts(undefined))
    console.log(error)
  }
}

export const { setGifts } = giftSlice.actions

export default giftSlice.reducer
