import { createSlice } from '@reduxjs/toolkit'
import {
  fetchAllowanceBuyEggHome,
  fetchAllowanceBuyMarketplace,
  fetchAllowanceDragon,
  fetchAllowanceTraining,
  fetchDragonBirthCost,
  fetchDragonCaveApproved,
  fetchDragonRemain,
  fetchOldStakedDragon,
  fetchStakedDragon,
  fetchAllowancebidOnTokenSiring,
} from './fetchDragons'
import dragonsApi from 'api/dragonsApi'
import { DEFAULT_LIMIT } from 'constants/index'
import { DRAGON_CAVE_ADDRESS } from 'config'

const initialState = {
  myDragons: undefined,
  dragon: undefined,
  userData: undefined,
  userDataHome: undefined,
  userDataBreeding: undefined,
  dragonRemain: undefined,
  dragons: undefined,
  userDataCore: undefined,
  dragonsAdventure: undefined,
}

const dragonsSlice = createSlice({
  name: 'dragons',
  initialState,
  reducers: {
    setMyDragons: (state, action) => {
      state.myDragons = action.payload
    },
    setDragons: (state, action) => {
      state.dragons = action.payload
    },
    updateMyDragons: (state, action) => {
      if (state.myDragons) {
        let arrDragons = [...state.myDragons?.data, ...action.payload?.data]
        arrDragons = arrDragons.filter((item, pos) => arrDragons.findIndex((x) => x.id === item?.id) === pos)
        state.myDragons = {
          ...action.payload,
          data: arrDragons,
        }
      }
    },
    updateUserData: (state, action) => {
      state.userData = {
        ...state.userData,
        ...action.payload,
      }
    },
    updateUserDataHome: (state, action) => {
      state.userDataHome = {
        ...state.userDataHome,
        ...action.payload,
      }
    },

    updateUserDataBreading: (state, action) => {
      state.userDataBreeding = {
        ...state.userDataBreeding,
        ...action.payload,
      }
    },
    setDragon: (state, action) => {
      state.dragon = action.payload
    },
    setDragonRemain: (state, action) => {
      state.dragonRemain = action.payload
    },
    setUserDataDetailDragon: (state, action) => {
      state.userDataCore = action.payload
    },
    setDragonsAdventure: (state, action) => {
      state.dragonsAdventure = action.payload
    },
  },
})

export const fetchUserDataDragonMarketplace = (account) => async (dispatch) => {
  try {
    const promise = [fetchAllowanceBuyMarketplace(account)]
    const res = await Promise.all(promise)
    dispatch(
      updateUserData({
        allowance: res?.[0],
        userDataLoaded: true,
      }),
    )
  } catch (error) {}
}

export const fetchUserDataDragonHome = (account) => async (dispatch) => {
  try {
    // const promise = [fetchStakedDragon(account), fetchAllowanceBuyEggHome(account), fetchOldStakedDragon(account)]
    const promise = [fetchAllowanceBuyEggHome(account)]
    const res = await Promise.all(promise)
    dispatch(
      updateUserDataHome({
        // stake: res?.[0],
        allowance: res?.[0],
        // oldStake: res?.[2],
        userDataLoaded: true,
      }),
    )
  } catch (error) {}
}

export const fetchUserDataBreading = (account) => async (dispatch) => {
  try {
    // const promise = [fetchStakedDragon(account), fetchAllowanceBuyEggHome(account), fetchOldStakedDragon(account)]
    const promise = [fetchAllowancebidOnTokenSiring(account)]
    const res = await Promise.all(promise)
    dispatch(
      updateUserDataBreading({
        // stake: res?.[0],
        allowance: res?.[0],
        // oldStake: res?.[2],
        userDataLoaded: true,
      }),
    )
  } catch (error) {}
}

export const fetchDragonRemainAsync = (account) => async (dispatch) => {
  try {
    const response = await fetchDragonRemain(account)
    dispatch(setDragonRemain(response))
  } catch (error) {}
}

export const fetchMyDragonsAsync =
  (account, params = {}) =>
  async (dispatch) => {
    try {
      const response = await dragonsApi.getMyDragons(account, { rowPerPage: DEFAULT_LIMIT, ...params })
      dispatch(setMyDragons(response))
    } catch (error) {
      dispatch(setMyDragons(undefined))
      console.log(error)
    }
  }

export const fetchDragonsAsync = (params) => async (dispatch) => {
  try {
    const response = await dragonsApi.getDragons(params)
    dispatch(setDragons(response))
  } catch (error) {
    dispatch(setDragons(undefined))
    console.log(error)
  }
}

export const fetchDetailDragon = (account, id) => async (dispatch) => {
  try {
    const promise = [dragonsApi.getDragon(id), fetchDragonCaveApproved(id), dragonsApi.getHistory(id)]
    const data = await Promise.all(promise)
    dispatch(
      setDragon({
        ...data?.[0],
        allowanceWrap: data?.[1]?.toLowerCase() === DRAGON_CAVE_ADDRESS.address.toLowerCase(),
        histories: data?.[2],
      }),
    )
    return data?.[0]
  } catch (error) {
    dispatch(setDragon(null))
  }
}

export const fetchUserDataDetailDragon = (account, id) => async (dispatch) => {
  try {
    const allowance = await fetchAllowanceDragon(account)
    const birthCost = await fetchDragonBirthCost(id)
    dispatch(
      setUserDataDetailDragon({
        allowance,
        birthCost,
        userDataLoaded: true,
      }),
    )
  } catch (error) {}
}

export const fetchUserDataTraining = (account) => async (dispatch) => {
  try {
    const allowance = await fetchAllowanceTraining(account)
    dispatch(
      setUserDataDetailDragon({
        allowance,
        userDataLoaded: true,
      }),
    )
  } catch (error) {}
}

export const fetchDragonsAdventureAsync = (account) => async (dispatch) => {
  try {
    const response = await dragonsApi.getDragonsAdventure(account)
    const listDragons = response?.sort((a, b) => (a < b ? -1 : 1))
    dispatch(setDragonsAdventure(listDragons))
  } catch (error) {}
}

export const {
  setMyDragons,
  updateMyDragons,
  updateUserData,
  setDragon,
  setDragonRemain,
  setDragons,
  setUserDataDetailDragon,
  setDragonsAdventure,
  updateUserDataHome,
  updateUserDataBreading,
} = dragonsSlice.actions
export default dragonsSlice.reducer
