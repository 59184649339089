import classnames from 'classnames'
import React, { Fragment, useEffect, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

const Popover = ({
  children,
  className,
  classNameMenuItem,
  classNameButtonToggle,
  buttonChild,
  forceClose,
  ...rest
}) => {
  const history = useHistory()
  const { pathname } = history.location
  const [isOpen, setIsOpen] = useState(false)
  const openMenu = () => setIsOpen(true)
  const closeMenu = () => setIsOpen(false)
  const toggleMenu = () => setIsOpen(!isOpen)

  useEffect(() => {
    closeMenu()
  }, [pathname, forceClose])

  return (
    <Menu {...rest} as="div" className={classnames('relative inline-block', className)}>
      <Menu.Button
        className={classnames('h-10 w-full shadow-sm focus:outline-none focus:boxShadow:none', classNameButtonToggle)}
      >
        <div onClick={toggleMenu} onMouseLeave={closeMenu}>
          {buttonChild}
        </div>
      </Menu.Button>
      <Transition
        onMouseEnter={openMenu}
        onMouseLeave={closeMenu}
        show={isOpen}
        as={Fragment}
        enter="transition ease-out"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          static
          className={classnames(
            'custom-position-popover origin-top-left absolute w-56 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50',
            classNameMenuItem,
          )}
        >
          <div className="py-1">{children}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

Popover.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classNameMenu: PropTypes.string,
  classNameMenuItem: PropTypes.string,
}

export default Popover
