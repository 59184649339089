import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const ModalFooter = ({ children, className, align = 'center' }) => {
  return (
    <div
      className={classnames(
        'flex mt-3',
        {
          'justify-center': align === 'center',
        },
        {
          'justify-end': align === 'right',
        },
        className,
      )}
    >
      {children}
    </div>
  )
}

ModalFooter.propTypes = {
  children: PropTypes.node,
}

export default ModalFooter
