import axiosClient from './axiosClient'

const giftApi = {
  getGifts: () => {
    const url = '/gifts/list'
    return axiosClient.get(url)
  },
  openGift: (type) => {
    const url = `/gifts/open-gift/${type}`
    return axiosClient.post(url, {})
  },
}

export default giftApi
