import React, { useEffect } from 'react'
import Modal from './ModalV2'
import ModalTitle from './ModalTitleV2'
import FilterAction from 'views/DragonCave/components/FilterAction'
import { useQuerySearch, useUpdateQueryWithoutURL } from 'store/search/hook'
import { formatQueryDragons, useFetchDragonsBreed } from 'store/dragons/hook'
import ListView from '../ListView'
import { AUCTION_TYPE, DEFAULT_LIMIT, DRAGON_TYPE, FILTER_DRAGON_FOR_SALE, SIRING_TYPE } from 'constants/index'
import { fetchMyDragonsAsync, setMyDragons } from 'store/dragons'
import { showToastError } from '../CustomToast/CustomToast'
import { useDispatch } from 'react-redux'
import LoadingPage from '../Loader/LoadingPage'
import dragonsApi from 'api/dragonsApi'
import { useDflPrice, useKaiPrice } from 'hooks/usePrice'
import { NavItem, Tabs } from '../Tabs/Tabs'
import { useListDragonsBreed, useTabSelect } from 'store/breed/hook'
import { fetchListDragonsBreedAsync, setTabSelect } from 'store/breed'
import { resetSearchData } from 'store/search'
import DragonCard from '../Card/DragonCardV4'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'

const ModalSelectDragon = ({ open, toggle, matron, sire, type, onSelect }) => {
  useUpdateQueryWithoutURL()
  useFetchDragonsBreed()
  const dflPrice = useDflPrice()
  const { account } = useActiveWeb3React()
  const dispatch = useDispatch()
  const dragons = useListDragonsBreed()
  const query = useQuerySearch()
  const tab = useTabSelect()
  const tokenPrice = useKaiPrice()

  const onChangeTab = (value) => {
    if (value !== tab) {
      dispatch(setTabSelect(value))
      dispatch(resetSearchData())
    }
  }

  const onChangePage = async (page) => {
    try {
      const params = {
        ...query,
        type: DRAGON_TYPE.DRAGON,
        sale: tab === 1 ? FILTER_DRAGON_FOR_SALE.NOT_FOR_SALE.value : FILTER_DRAGON_FOR_SALE.SIRING.value,
        page: page + 1,
      }
      await dispatch(fetchListDragonsBreedAsync(account, formatQueryDragons(params), tab))
    } catch (error) {
      showToastError('Error', 'Some error occurred, please try again!')
    }
  }

  const handleFavorites = async (id) => {
    try {
      await dragonsApi.tickFavorites(id, account)
      const page = dragons?.currentPage || 1
      dispatch(
        fetchMyDragonsAsync(
          account,
          formatQueryDragons({ page, rowPerPage: DEFAULT_LIMIT, ...query, type: DRAGON_TYPE.DRAGON }),
        ),
      )
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (sire?.sale === AUCTION_TYPE.SIRING && type === SIRING_TYPE.SIRE) {
      dispatch(setTabSelect(2))
    } else {
      dispatch(setTabSelect(1))
    }
    return () => dispatch(setMyDragons(undefined))
  }, [])

  return (
    <Modal open={open} isDark size="xl" onClose={toggle}>
      <ModalTitle onClose={toggle}>
        Select dragon
        {SIRING_TYPE[type] ? <span>({type === SIRING_TYPE.MATRON ? 'Mother' : 'Father'})</span> : null}
      </ModalTitle>
      <div className="pb-8 px-2 sm:px-4">
        {type === SIRING_TYPE.SIRE ? (
          <div className="flex justify-center">
            <Tabs className="justify-center">
              <NavItem active={tab === 1} onClick={() => onChangeTab(1)} className="w-32 h-10">
                My Dragons
              </NavItem>
              <NavItem active={tab === 2} onClick={() => onChangeTab(2)} className="w-32 h-10">
                Siring
              </NavItem>
            </Tabs>
          </div>
        ) : null}
        <FilterAction showType={false} showFilter={false} />
        {dragons === undefined ? (
          <div>
            <LoadingPage />
          </div>
        ) : (
          <ListView
            className="min-height-list-save-modal max-w-screen-lg m-auto px-2.5 sm:px-5 xl:px-0 mt-8"
            pageCount={dragons?.totalPage}
            totalItems={dragons?.totalItems}
            currentPage={dragons?.currentPage}
            fetchApi={onChangePage}
          >
            <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 -mx-2 gap-y-4">
              {dragons?.data?.map((item, index) => {
                const disabled = !item?.isReady || item?.isGestating || item?.id === sire?.id || item?.id === matron?.id
                return (
                  <DragonCard
                    key={index}
                    dragon={item}
                    handleFavorites={handleFavorites}
                    tokenPrice={tokenPrice}
                    dflPrice={dflPrice}
                    onClick={() => (!disabled ? onSelect(item?.id) : null)}
                    disabled={disabled}
                    className="cursor-pointer"
                  />
                )
              })}
            </div>
          </ListView>
        )}
      </div>
    </Modal>
  )
}

export default ModalSelectDragon
