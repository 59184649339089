import PropTypes from 'prop-types'
import classNames from 'classnames'
import Loader from 'components/Loader/Loader'
import classnames from 'classnames'
import { AlertTriangle } from 'react-feather'

const Button = ({
  children,
  onClick,
  color = 'primary',
  outline = false,
  className,
  disabled,
  isLoading,
  size = 'md',
  msgDisable,
  msgHover,
  classNameToolTip,
  styleToolTip,
  ...props
}) => {
  return (
    <button
      {...props}
      disabled={disabled}
      className={classNames(
        'flex items-center rounded-xl justify-center',
        size === 'md' && 'py-2 px-4',
        size === 'sm' && 'p-2 h-10',
        size === 'xs' && 'p-2 h-7',
        color === 'primary' && 'bg-primary hover:opacity-80',
        color === 'secondary' && 'bg-white1 text-white hover:opacity-80',
        color === 'blue' && 'bg-blue1 text-white hover:opacity-80',
        color === 'danger' && 'bg-red-600 text-white hover:opacity-80',
        outline && 'text-blue4 bg-transparent-important border-1 border-blue2',
        disabled && 'opacity-50 cursor-default !hover:opacity-100 hover:opacity-100',
        {
          'tooltip-wrapper': (disabled && msgDisable) || msgHover,
        },
        className,
      )}
      onClick={onClick}
    >
      {children}{' '}
      {isLoading && (
        <Loader
          className="ml-2"
          size="sm"
          color="white"
          width={size === 'xs' ? 2 : ''}
          height={size === 'xs' ? 2 : ''}
        />
      )}
      {disabled && msgDisable ? <AlertTriangle color="orange" size={12} className="ml-1" /> : null}
      {(disabled && msgDisable) || msgHover ? (
        <div
          style={styleToolTip}
          className={classnames(
            'rounded-md text-white p-2 tooltip bg-black text-sm-md border-2 border-blue2 opacity-100',
            classNameToolTip,
          )}
        >
          {msgHover || msgDisable}
        </div>
      ) : null}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  color: PropTypes.string,
  outline: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
}

export default Button
