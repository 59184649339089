import React from 'react'
import Box from '../../../components/Box/Box'
import classnames from 'classnames'

const ListDragons = ({}) => {
  const listDragons = [
    {
      img: '/images/dragon-legend-1.png',
      title: 'Army',
      desc: 'Own your dracoras with many different skills based on their genes',
    },
    {
      img: '/images/dragon-legend-2.png',
      title: 'Tournament',
      desc: (
        <p>
          PvP battle to earn real <br /> money rewards
        </p>
      ),
    },
  ]
  return (
    <div className="mx-4 md:mx-10 my-12 mt-0">
      <div className="container max-w-screen-lg m-auto">
        <div className="grid gird-cols-1 md:grid-cols-4 mb-12 md:mb-24 ">
          {listDragons.map((item, index) => (
            <div key={index} className="m-auto h-full col-span-2 pt-4 w-full">
              <div className="mx-4 md:mx-10">
                <Box
                  style={{
                    backgroundImage: `url(${item.img})`,
                  }}
                  className={classnames(
                    'border-4 lg:border-8 overflow-hidden h-full border-blue2 p-0',
                    'mt-12 md:mt-16 flex justify-center items-end home-card-dragon bg-center bg-no-repeat bg-cover',
                  )}
                >
                  <div
                    style={{
                      background: 'linear-gradient(to top, rgb(17 17 17 / 84%) 0%, rgba(69, 25, 0, 0) 100%)',
                    }}
                    className="text-white text-center py-6 md:py-8 w-full px-4"
                  >
                    <h3 className="text-xl md:text-3xl font-bold">{item.title}</h3>
                    <div
                      className="mt-1.5"
                      style={{
                        minHeight: 48,
                      }}
                    >
                      {item.desc}
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ListDragons
