import BigNumber from 'bignumber.js'
import { BIG_TEN } from '../bigNumber'
import { MerkleTree } from 'merkletreejs'
import { ethers } from 'ethers'

import whitelist from 'constants/whitelist'

const hashAddress = (addr) => {
  return ethers.utils.keccak256(ethers.utils.defaultAbiCoder.encode(['address'], [addr]))
}

const leafNodes = whitelist.map((addr) => hashAddress(addr))
const merkleTree = new MerkleTree(leafNodes, ethers.utils.keccak256, { sortPairs: true })
const merkleRoot = merkleTree.getRoot().toString('hex')
console.log('Merkle Root:', merkleRoot)

const getMerkleProof = (address) => {
  const hashedAddress = hashAddress(address)
  return merkleTree.getHexProof(hashedAddress)
}

export const buyEgg = async (contract, idDragon, price, decimals, userAddress) => {
  const merkleProof = getMerkleProof(userAddress)
  const tx = await contract.bidWithDragonToken(
    idDragon,
    new BigNumber(price).times(BIG_TEN.pow(decimals)).toFixed(),
    merkleProof,
  )
  await tx.wait()
  return tx.transactionHash
}

export const buyDragonMarket = async (contract, idDragon, price, decimals) => {
  const emptyMerkleProof = []
  const formattedPrice = new BigNumber(price).times(BIG_TEN.pow(decimals)).toFixed()

  const tx = await contract.bidWithDragonToken(idDragon, formattedPrice, emptyMerkleProof)

  await tx.wait()
  return tx.transactionHash
}

export const sellDragon = async (contract, idToken, price, decimals) => {
  const tx = await contract.createTokenSaleAuction(idToken, new BigNumber(price).times(BIG_TEN.pow(decimals)).toFixed())
  await tx.wait()
  return tx.transactionHash
}
export const cancelSellDragon = async (contract, idToken) => {
  const tx = await contract.cancelAuction(idToken)
  await tx.wait()
  return tx.transactionHash
}
export const sireDragon = async (contract, idToken, price, decimals) => {
  const tx = await contract.createEthSiringAuction(idToken, new BigNumber(price).times(BIG_TEN.pow(decimals)).toFixed())
  await tx.wait()
  return tx.transactionHash
}
export const cancelSiring = async (contract, idToken) => {
  const tx = await contract.cancelAuction(idToken)
  await tx.wait()
  return tx.transactionHash
}
export const breedDragon = async (contract, matron, sire) => {
  const tx = await contract.mateWithAuto(matron, sire, {
    value: ethers.utils.parseUnits('2', 'finney'),
  })
  await tx.wait()
  return tx.transactionHash
}
export const breedDragonWithSiring = async (contract, sire, matron, price, decimals) => {
  const priceInWei = new BigNumber(price).times(BIG_TEN.pow(decimals))
  const twoFinneyInWei = ethers.utils.parseUnits('2', 'finney')

  const totalValue = priceInWei.plus(twoFinneyInWei.toString())

  const tx = await contract.bidOnEthSiringAuction(sire, matron, {
    value: totalValue.toString(),
  })
  await tx.wait()
  return tx.transactionHash
}
export const claimDragonCave = async (contract, idToken, account) => {
  const tx = await contract.burnTokensAndWithdrawDragons([idToken], [account])
  await tx.wait()
  return tx.transactionHash
}

export const sellItem = async (contract, addressToken, idToken, price, decimals) => {
  const tx = await contract.listToken(
    addressToken,
    idToken,
    new BigNumber(price).times(BIG_TEN.pow(decimals)).toFixed(),
  )
  await tx.wait()
  return tx.transactionHash
}

export const cancelSellItem = async (contract, listingId) => {
  const tx = await contract.cancel(listingId)
  await tx.wait()
  return tx.transactionHash
}

export const buyItem = async (contract, listingId, price, decimals) => {
  const tx = await contract.buyToken(listingId, { value: new BigNumber(price).times(BIG_TEN.pow(decimals)).toFixed() })
  await tx.wait()
  return tx.transactionHash
}
