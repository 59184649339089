import classnames from 'classnames'
import useCopyClipboard from 'hooks/useCopyClipboard'
import { Clipboard, Copy } from 'react-feather'

const CopyHelper = ({ className, toCopy, children }) => {
  const [isCopied, setCopied] = useCopyClipboard()

  return (
    <div
      className={classnames(
        'flex items-center flex-shrink-0 space-x-1 no-underline cursor-pointer whitespace-nowrap hover:no-underline focus:no-underline active:no-underline text-gray-500 hover:text-primary opacity-80 hover:opacity-100 focus:opacity-100',
        className,
      )}
      onClick={() => setCopied(toCopy)}
    >
      {isCopied && (
        <div className="flex items-center space-x-1 whitespace-nowrap">
          <p>Copied</p>
          <Copy size={16} />
        </div>
      )}

      {!isCopied && (
        <>
          {children}
          <Clipboard size={16} />
        </>
      )}
    </div>
  )
}

export default CopyHelper
