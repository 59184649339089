import React from 'react'
import BoxTab from './BoxTab'
import { useDispatch } from 'react-redux'
import { setTabSold } from 'store/report'
import { useTabSold, useFetchItemsSold, useEquipmentSold, useSkillSold, useExpSold } from 'store/report/hook'
import { useKaiPrice } from 'hooks/usePrice'
import SoldItem from './SoldItem'
export default function RecentSold() {
  useFetchItemsSold()
  const dispatch = useDispatch()
  const equipmentSold = useEquipmentSold()
  const skillSold = useSkillSold()
  const expSold = useExpSold()
  const tokenPrice = useKaiPrice()
  const tab = useTabSold()

  const itemsSold = tab === 0 ? equipmentSold : tab === 1 ? skillSold : tab === 2 ? expSold : []

  const onChangeTab = (value) => {
    if (value !== tab) {
      dispatch(setTabSold(value))
    }
  }

  return (
    <BoxTab
      title="Recently sold"
      tabActive={tab}
      tabs={['Equipment sold', 'Skill card sold', 'Exp card sold']}
      onChangeTab={onChangeTab}
      column={3}
    >
      {itemsSold === undefined ? (
        <div className="flex justify-center py-10">
          <img src="/icon/loading.gif" width={45} />
        </div>
      ) : itemsSold?.length ? (
        itemsSold.map((item, index) => <SoldItem key={index} item={item} tokenPrice={tokenPrice} />)
      ) : (
        <p className="text-xl text-white py-10 text-center">No data</p>
      )}
    </BoxTab>
  )
}
