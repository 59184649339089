import kardiaClient from 'plugin/kardia-dx'
import { showToastError } from 'components/CustomToast/CustomToast'
import { getAddress } from '@ethersproject/address'
import { useLocation } from 'react-router-dom'
import { IMAGE_SIZE } from '../constants'

export function isAddress(value) {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

export const useQuery = () => {
  return Object.fromEntries(new URLSearchParams(useLocation().search))
}

export const getImageURL = (object, size = 'root') => {
  return object?.[size] || object?.[IMAGE_SIZE.root]
}

export const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

export const formatQuery = (query) =>
  Object.keys(query)
    .map((key) => `${key}=${query[key]}`)
    .join('&')

export const getUrlQuery = (pathname, query) => {
  let _query = Object.assign({}, query)
  if (_query?.page === 1) {
    delete _query.page
  }
  const queryString = formatQuery(_query)
  return `${pathname}${queryString ? `?${queryString}` : ''}`
}

export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function getParameterCaseInsensitive(object, key) {
  if (object instanceof Object) {
    return object[Object.keys(object).find((k) => k.toLowerCase() === key.toLowerCase())]
  }
  return undefined
}

export const getLink = (link) => {
  if (link) {
    if (link?.match(/^http[s]?:\/\//)) {
      return link
    }
    return 'http://' + link
  }
  return ''
}

export const getBlockNumber = async () => {
  const BLOCK_NUMBER = 'latest'
  const blockResponse = await kardiaClient.kaiChain.getBlockByBlockNumber(BLOCK_NUMBER)
  return blockResponse.height
}

export const formatNumberMinifiedCharacters = (number, decimals = 2) => {
  if (isNaN(number)) {
    return {
      value: '???',
      unit: '',
    }
  }
  if (number === Infinity) {
    return {
      value: number,
      unit: '',
    }
  }
  // billion
  if (number > 1000 * 1000 * 1000000000) {
    return {
      value: (number / 1000000000)?.toExponential(decimals),
      unit: 'B',
    }
  }
  if (number > 100 * 1000000000) {
    return {
      value: (number / 1000000000)?.toFixed(decimals),
      unit: 'B',
    }
  }
  // million
  if (number > 100 * 1000000) {
    return {
      value: (number / 1000000)?.toFixed(decimals),
      unit: 'M',
    }
  }
  return {
    value: number?.toFixed(decimals),
    unit: '',
  }
}

export const handleToastError = (error) => {
  console.log(error)
  if (error?.response?.data?.errors?.[0]?.msg) {
    showToastError(error.response.data.errors[0].msg)
  } else {
    showToastError('Some error occurred, please try again!')
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address, chars = 4) {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

export const checkChainId = (chainId) => {
  return !!chainId || typeof chainId === 'number'
}
