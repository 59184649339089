import React, { useMemo, useState } from 'react'
import classnames from 'classnames'
import Box from '../Box/Box'
import Badge from '../Badge/Badge'
import PropTypes from 'prop-types'
import {
  AUCTION_TYPE,
  cooldownLabel,
  DEFAULT_EGG_CLASS,
  DEFAULT_TOKEN_USED,
  DRAGON_STATUS,
  DRAGON_TYPE,
  IMAGE_SIZE,
  MAX_DEFINE_COOLDOWN,
} from 'constants/index'
import Countdown, { zeroPad } from 'react-countdown'
import ButtonGradient from '../Button/ButtonGradient'
import { DRAGON_CAVE_ADDRESS } from '../../config'
import { useClaimDragonCave } from '../../hooks/useClaim'
import { showToastError, showToastSuccess } from '../CustomToast/CustomToast'
import ModalDragonCave from '../Modal/ModalClaimDragonCave'
import { useHistory } from 'react-router-dom'
import { getImageURL } from '../../utils'
import useInterval from '../../hooks/useInterval'
import Value from '../Value/Value'
import { getBalanceAmount } from '../../utils/formatBalance'
import BigNumber from 'bignumber.js'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'

const DragonCard = ({
  dragon,
  isClaim,
  isShop,
  wDragonBalance,
  updateData,
  className,
  dflPrice,
  disabled,
  showPrice = true,
  handleFavorites,
  ...rest
}) => {
  const { id, image, level, favorite } = dragon
  const { account } = useActiveWeb3React()
  const history = useHistory()
  const isEgg = dragon?.type === 'EGG'
  const [openClaim, setOpenClaim] = useState(false)
  const [canBirth, setCanBirth] = useState(false)
  const price = getBalanceAmount(dragon?.price, 0)
  const cooldownIndex = dragon?.cooldownIndex < MAX_DEFINE_COOLDOWN ? dragon?.cooldownIndex : MAX_DEFINE_COOLDOWN

  const priceUsd = price ? new BigNumber(price).times(dflPrice).toNumber() : 0
  const { onClaim } = useClaimDragonCave(DRAGON_CAVE_ADDRESS.address)

  const dragonStatus = useMemo(() => {
    if (dragon?.type === DRAGON_TYPE.DRAGON) {
      if (dragon?.isGestating) return DRAGON_STATUS.PREGNANT
      if (!dragon?.isReady) {
        return DRAGON_STATUS.RESTING
      }
    }
    return null
  }, [dragon])

  const handleClaim = async () => {
    try {
      if (wDragonBalance && account) {
        await onClaim(dragon?.id)
        await updateData()
        showToastSuccess('Success', `Claim ${dragon?.type === 'EGG' ? 'Egg' : 'Dragon'} successfully!`)
      }
    } catch (error) {
      throw error
    }
  }

  const onFavorites = (e) => {
    handleFavorites(id)
    e.stopPropagation()
    e.preventDefault()
  }

  const handleOpenModal = () => {
    if (wDragonBalance) {
      setOpenClaim(true)
    } else {
      const btn = (
        <div className="flex justify-end mt-2">
          <ButtonGradient
            size="sm"
            onClick={() => history.push('/my-dragons')}
            className="text-sm sm:pl-3 sm:pr-3 sm:pt-1.5 sm:pb-1.5"
          >
            Wrap Egg
          </ButtonGradient>
        </div>
      )
      showToastError('', 'You do not have any WDRACORA token!', btn)
    }
  }

  useInterval(() => {
    if (dragon?.isGestating && dragon?.timeLock < Date.now() && !canBirth) {
      setCanBirth(true)
    }
  }, 1000)

  const rendererIncubation = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) return null
    return (
      <div className="text-white">
        <p className="font-bold text-xs sm:text-sm">
          {zeroPad(days)}d {zeroPad(hours)}h {zeroPad(minutes)}m {zeroPad(seconds)}s
        </p>
      </div>
    )
  }
  return (
    <Box
      {...rest}
      className={classnames(
        'border-2 overflow-hidden h-full border-blue2 bg-blue1 p-3 px-2.5 mx-4 text-white mt-6 relative',
        {
          'pb-6': !showPrice,
        },
        className,
      )}
    >
      <div className="flex justify-between">
        <div className="flex justify-between">
          <div className="flex items-center">
            <Badge
              className={classnames(
                `bg-${dragon?.class?.toLowerCase()}`,
                'text-white px-2 sm:px-3 text-xs sm:text-sm pt-0.5 pb-0.5',
              )}
            >
              #{id}
            </Badge>
            <Badge className={classnames('text-white px-2 sm:px-3 text-xs sm:text-sm pt-0.5 pb-0.5 bg-blue2 ml-1')}>
              Gen {dragon?.generation}
            </Badge>
          </div>
        </div>
        {account ? (
          <img
            className="w-4 sm:w-6 h-4 sm:h-6"
            src={favorite ? '/icon/heart-red.png' : '/icon/heart-white.png'}
            onClick={onFavorites}
          />
        ) : null}
      </div>
      <div className="flex justify-between mt-2">
        <div>
          <div className="flex items-center">
            <Badge className={classnames(`bg-${dragon?.class?.toLowerCase()}`, 'px-1 py-1')} />
            <span className="pl-2 capitalize text-xs sm:text-sm-md">{dragon?.class?.toLowerCase()}</span>
          </div>
          <p className="opacity-50 text-xs sm:text-sm-md">Level: {level || 1}</p>
        </div>
        <div className="flex flex-col items-end h-12">
          {dragonStatus ? (
            <Badge
              className={classnames(
                dragonStatus === DRAGON_STATUS.PREGNANT && canBirth ? 'bg-green1' : 'bg-skin',
                'text-white px-2.5 text-xs sm:text-sm pt-0.5 pb-0.5 ml-1',
              )}
            >
              {dragonStatus === DRAGON_STATUS.PREGNANT && canBirth ? 'Give birth' : dragonStatus}
            </Badge>
          ) : null}
          {!dragonStatus && dragon?.type === DRAGON_TYPE.DRAGON ? (
            <Badge className="bg-blue2 text-white px-2 text-xs sm:text-sm pt-0.5 pb-0.5 ml-1">
              {typeof dragon?.cooldownIndex === 'number'
                ? `${cooldownLabel[cooldownIndex]?.label} (${cooldownLabel[cooldownIndex]?.duration})`
                : '???'}
            </Badge>
          ) : null}
          {dragon?.timeLock ? (
            <Countdown zeroPadTime={2} date={dragon?.timeLock} renderer={rendererIncubation} />
          ) : null}
          {dragon?.hatched ? <Countdown zeroPadTime={2} date={dragon?.hatched} renderer={rendererIncubation} /> : null}
          {[AUCTION_TYPE.AUCTION, AUCTION_TYPE.SIRING].includes(dragon?.sale) ? (
            <p
              style={{
                backgroundImage: `linear-gradient(to right, #48CAE4,#0068B4)`,
              }}
              className="rounded-l-xl text-xs sm:text-sm-md px-6 pl-3 text-white mt-1.5 transform translate-x-2.5"
            >
              {dragon?.sale === AUCTION_TYPE.AUCTION ? 'For sale' : 'Siring'}
            </p>
          ) : null}
        </div>
      </div>
      <div
        className={classnames('flex justify-center h-36 py-3 relative', {
          'py-6': isEgg,
        })}
      >
        <img
          src={isEgg ? DEFAULT_EGG_CLASS[dragon?.class] : getImageURL(image, IMAGE_SIZE['250'])}
          className="h-full"
        />
      </div>
      {isClaim ? (
        <div className="flex justify-center">
          <ButtonGradient
            size="sm"
            className="text-sm-md sm:pl-4 sm:pr-4 sm:pt-1.5 sm:pb-1.5"
            onClick={(e) => {
              handleOpenModal()
              e.preventDefault()
            }}
          >
            Claim
          </ButtonGradient>
        </div>
      ) : null}

      {showPrice ? (
        <div className="h-9">
          {dragon?.price ? (
            <>
              <Value
                value={dragon?.price}
                decimals={2}
                unit={` ${DEFAULT_TOKEN_USED.symbol}`}
                className="text-white text-center font-bold text-md"
              />
              <Value
                value={priceUsd}
                decimals={2}
                prefix="~"
                unit="$"
                className="text-white text-center text-xs opacity-50"
              />
            </>
          ) : null}
        </div>
      ) : null}

      {openClaim ? (
        <ModalDragonCave
          open={openClaim}
          toggle={() => setOpenClaim(!openClaim)}
          onSubmit={handleClaim}
          dragon={dragon}
        />
      ) : null}

      {disabled ? <div className="absolute bg-disabled top-0 right-0 w-full h-full" /> : null}
    </Box>
  )
}

DragonCard.propTypes = {
  dragon: PropTypes.object.isRequired,
}

export default DragonCard
