import React, { useEffect } from 'react'
import withLayout from 'hoc/withLayout'
import { useFetchAllEquipments, useAllEquipments } from 'store/items/hook'
import LoadingPage from 'components/Loader/LoadingPage'
import Container from 'components/Container/Container'
import Value from 'components/Value/Value'
import ListView from 'components/ListView'
import ItemCard from 'components/Card/ItemCard'
import ItemsFilterAction from 'views/Items/components/ItemsFilterAction'
import { useUpdateQueryItems, usePageQuery } from 'store/search/hook'
import { useDispatch } from 'react-redux'
import { setPageQuery } from 'store/search'
import { setAllEquipments } from 'store/items'
function DocEquipment() {
  useUpdateQueryItems()
  useFetchAllEquipments()
  const allEquipments = useAllEquipments()

  const pageQuery = usePageQuery()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setPageQuery(1))
    return () => {
      dispatch(setAllEquipments(undefined))
    }
  }, [dispatch])

  const onChangePage = async (page) => {
    try {
      dispatch(setPageQuery(page + 1))
    } catch (error) {
      showToastError('Error', 'Some error occurred, please try again!')
    }
  }

  if (allEquipments === undefined) {
    return <LoadingPage />
  }

  return (
    <Container className="py-10">
      <div className="text-center text-white">
        <h3 className="text-xl xl:text-2xl">Total Equipment</h3>
        {typeof allEquipments?.totalItems === 'number' ? (
          <Value className="text-5xl xl:text-6xl" value={allEquipments?.totalItems} decimals={0} />
        ) : (
          '--'
        )}
      </div>
      <ItemsFilterAction
        className="mx-2.5 sm:mx-10"
        ignoreBurn={true}
        showEquipmentRarity={true}
        ignoreFilterStatus={true}
        ignoreSort={true}
        ignoreLevel={true}
        ignoreRangePrice={true}
      />
      <ListView
        className="min-height-list-save max-w-screen-lg m-auto xl:px-0 mt-8 mb-5"
        pageCount={allEquipments?.totalPages}
        totalItems={allEquipments?.totalItems}
        currentPage={pageQuery}
        fetchApi={onChangePage}
      >
        <div className="grid sm:grid-cols-3 md:grid-cols-4 grid-cols-2 gap-y-5 sm:gap-x-5 gap-x-2 px-2.5 sm:px-5">
          {allEquipments?.data?.map((item, index) => {
            return (
              <div className="flex justify-center">
                <ItemCard key={index} item={item} ignoreLevel={true} showBadge={false} />
              </div>
            )
          })}
        </div>
      </ListView>
    </Container>
  )
}

export default withLayout(DocEquipment)
