import axiosClient from './axiosClient'

const dragonsApi = {
  getEgg: () => {
    const url = '/dragons/dragon-sale'
    return axiosClient.get(url)
  },
  getMyDragons: (account, params) => {
    const url = '/dragons/my-dragon'
    return axiosClient.get(url, { wallet: account, params })
  },
  getDragon: (id) => {
    const url = `/dragons/${id}`
    return axiosClient.get(url)
  },
  getDragons: (params) => {
    const url = '/dragons'
    return axiosClient.get(url, { params })
  },
  hatchDragon: (account, id) => {
    const url = `/dragons/${id}/hatch`
    return axiosClient.get(url, { wallet: account })
  },
  tickFavorites: (id, wallet) => {
    const url = `/dragons/${id}/favorites`
    return axiosClient.post(url, {}, { wallet })
  },
  getCooldown: () => {
    const url = '/dragons/get-cooldowns'
    return axiosClient.get(url)
  },
  getBirthCost: () => {
    const url = '/dragons/get-birthCost'
    return axiosClient.get(url)
  },
  migrateData: (id) => {
    const url = `/dragons/${id}/migrate-data`
    return axiosClient.get(url)
  },
  getDragonsAdventure: (wallet) => {
    const url = '/dragons/get-dragon-adventure'
    return axiosClient.get(url, { wallet })
  },
  getHistory: (id) => {
    const url = `/dragons/${id}/histories`
    return axiosClient.get(url)
  },
  getBodyParts: () => {
    const url = '/dragons/get-parts'
    return axiosClient.get(url)
  },
  useDefaultSkill: (body) => {
    const url = 'dragons/use-default-skill'
    return axiosClient.put(url, { ...body })
  },
}

export default dragonsApi
