// import ModalWallet from 'components/Modal/ModalWallet'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { ToastContainer } from 'react-toastify'

import Home from 'views/Home/Home'
import { usePollBlockNumber } from 'store/block/hook'
import NotFound from './views/NotFound'
import MyDragons from './views/MyDragons/MyDragons'
import Gameplay from './views/Gameplay/Gameplay'
import ItemTabPage from './views/Items/ItemTabPage'
import ItemDetailPage from './views/Items/ItemDetailPage'
import ModalUpcoming from './components/Modal/ModalUpcoming'
import MyDragon from './views/Dragon/Dragon'
import DragonCave from './views/DragonCave/DragonCave'
import Shops from './views/Shops/Shops'
import SireDragon from './views/Sire/Sire'
import TrainDragons from './views/Train/Train'
import RankDragons from './views/Rank/Rank'
import Dashboard from './views/Dashboard/Dashboard'
import Events from './views/Events/Events'
import Monster from './views/Monster/Monster'
import Items from './views/MarketPlaceItems/Items'
import DashboardItems from './views/DashboardItems/DashboardItems'
import { EVENT_MONSTER_TYPES, MONSTER_TYPES } from './constants'
import Gift from './views/Gift/Gift'
import useStorageActiveAccount from './hooks/useStorageActiveAccount'
import DocSkill from './views/Doc/Skill'
import DocEffect from './views/Doc/Effect'
import DocEquipment from './views/Doc/Equipment'
// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

function App() {
  usePollBlockNumber()
  useStorageActiveAccount()
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/my-dracoras" exact>
          <MyDragons />
        </Route>
        <Route path="/game" exact>
          <Gameplay />
        </Route>
        <Route path="/my-items/:type" exact>
          <ItemTabPage />
        </Route>
        <Route path="/dragon-cave" exact>
          <DragonCave />
        </Route>
        <Route path="/dragon/:id">
          <MyDragon />
        </Route>
        <Route path="/items/:type/:id">
          <ItemDetailPage />
        </Route>
        {/**
         * TODO
         * Uncomment later
         */}
        <Route path="/marketplace">
          <Shops />
        </Route>
        <Route path="/dashboard">
          <Dashboard />
        </Route>
        <Route path="/dashboard-items">
          <DashboardItems />
        </Route>
        <Route path="/marketplace-items/:type" exact>
          <Items />
        </Route>
        <Route path="/breed">
          <SireDragon />
        </Route>
        <Route path="/train">
          <TrainDragons />
        </Route>
        <Route path="/ranking">
          <RankDragons />
        </Route>
        <Route path="/gift">
          <Gift />
        </Route>
        <Route path="/book/skill">
          <DocSkill />
        </Route>
        <Route path="/book/effect">
          <DocEffect />
        </Route>
        <Route path="/book/equipment">
          <DocEquipment />
        </Route>
        <Route path={`/${EVENT_MONSTER_TYPES[MONSTER_TYPES.XX_MONSTER].urls}`}>
          <Events />
        </Route>
        <Route path={`/${EVENT_MONSTER_TYPES[MONSTER_TYPES.YY_MONSTER].urls}`}>
          <Events />
        </Route>
        <Route path={`/${EVENT_MONSTER_TYPES[MONSTER_TYPES.XX_MONSTER].url}/:id`}>
          <Monster />
        </Route>
        <Route path={`/${EVENT_MONSTER_TYPES[MONSTER_TYPES.YY_MONSTER].url}/:id`}>
          <Monster />
        </Route>
        <Route component={NotFound} />
      </Switch>
      {/*<ModalWallet />*/}
      <ModalUpcoming />
      <ToastContainer newestOnTop />
    </Router>
  )
}

export default App
