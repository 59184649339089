import React from 'react'
import { useParams } from 'react-router-dom'
import EXPDetail from './components/Exp/EXPDetail'
import EquipmentDetail from './components/Equipment/EquipmentDetail'
import SkillDetail from './components/Skill/SKillDetail'
import { ITEM_TYPES } from '../../constants'
export default function ItemDetailPage() {
  const { type } = useParams()

  if (type == 'skill-card') {
    return <SkillDetail />
  } else if (type == 'equipment') {
    return <EquipmentDetail />
  } else return <EXPDetail />
}
