import React, { useEffect } from 'react'
import withLayout from 'hoc/withLayout'
import Container from 'components/Container/Container'
import Value from 'components/Value/Value'
import DragonCard from 'components/Card/DragonCardV3'
import { formatQueryDragons, useFetchMyDragon, useMyDragons } from 'store/dragons/hook'
import LoadingPage from 'components/Loader/LoadingPage'
import ListView from 'components/ListView'
import { useDispatch } from 'react-redux'
import { fetchMyDragonsAsync, setMyDragons } from 'store/dragons'
import { showToastError } from 'components/CustomToast/CustomToast'
import FilterAction from '../DragonCave/components/FilterAction'
import { useUpdateQuery } from 'store/search/hook'
import { setPageQuery, resetSearchData } from 'store/search'
import dragonsApi from 'api/dragonsApi'
import { useQuery } from 'utils'
import { useKaiPrice } from 'hooks/usePrice'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'
import Web3Status from '../../components/Web3Status/Web3Status'
import Web3Connect from '../../components/Web3Connect/Web3Connect'

const MyDragons = ({}) => {
  useUpdateQuery()
  useFetchMyDragon()
  const { account } = useActiveWeb3React()
  const dispatch = useDispatch()
  const dragons = useMyDragons()
  const queryUrl = useQuery()
  const tokenPrice = useKaiPrice()

  const onChangePage = async (page) => {
    try {
      dispatch(setPageQuery(page + 1))
    } catch (error) {
      showToastError('Error', 'Some error occurred, please try again!')
    }
  }

  const handleFavorites = async (id) => {
    try {
      await dragonsApi.tickFavorites(id, account)
      const page = dragons?.currentPage || 1
      dispatch(fetchMyDragonsAsync(account, formatQueryDragons({ page, ...queryUrl })))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    return () => dispatch(setMyDragons(undefined))
  }, [])

  if (!account) {
    return (
      <div className="mt-12 flex justify-center">
        <Web3Connect />
      </div>
    )
  }

  if (dragons === undefined) {
    return <LoadingPage />
  }
  return (
    <Container className="pt-10 pb-36">
      <div className="text-center text-white">
        <h3 className="text-xl xl:text-2xl">Total dracoras</h3>
        {typeof dragons?.totalItems === 'number' ? (
          <Value className="text-5xl xl:text-6xl" value={dragons?.totalItems} decimals={0} />
        ) : (
          '--'
        )}
      </div>
      <FilterAction />
      <ListView
        className="min-height-list-save max-w-screen-lg m-auto px-2.5 sm:px-5 xl:px-0 mt-8"
        pageCount={dragons?.totalPage}
        totalItems={dragons?.totalItems}
        currentPage={dragons?.currentPage}
        fetchApi={onChangePage}
      >
        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 -mx-2 gap-y-4">
          {dragons?.data?.map((item, index) => (
            <DragonCard
              key={index}
              dragon={item}
              tokenPrice={tokenPrice}
              handleFavorites={handleFavorites}
              isLink
              to={`/dragon/${item.id}`}
            />
          ))}
        </div>
      </ListView>
    </Container>
  )
}

export default withLayout(MyDragons)
