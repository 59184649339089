import React from 'react'
import { DEFAULT_EGG_CLASS, DRAGON_TYPE, IMAGE_SIZE, KROMA_CHAIN_ID, TOKEN_USED_MARKETPLACE } from '../../../constants'
import { getImageURL } from '../../../utils'
import Badge from '../../../components/Badge/Badge'
import classnames from 'classnames'
import { formatNumber } from '../../../utils/formatBalance'
import BigNumber from 'bignumber.js'
import { formatDDMMYYYYHHmm } from '../../../utils/formatDateTime'
import { Link } from 'react-router-dom'
import { getExplorerLink } from '../../../utils/explorer'
import Web3 from 'web3'
import { formatAddress } from '../../../utils/addressHelpers'
import { ChainId } from 'defily-v2-sdk'

const SoldDragon = ({ dragon, tokenPrice, isSired }) => {
  const isEgg = dragon?.type === DRAGON_TYPE.EGG
  const priceUsd = dragon?.price ? new BigNumber(dragon.price).times(tokenPrice).toNumber() : 0
  const buyer = isSired ? dragon?.from : dragon?.owner
  const seller = isSired ? dragon?.owner : dragon?.from
  return (
    <Link to={`/dragon/${dragon?.id}`}>
      <div className="border-b-1 border-blue4 cursor-pointer pt-4 px-2.5 relative">
        <div className="flex justify-center">
          <div className="flex flex-1 items-center">
            <div className="h-full relative flex items-center justify-center">
              {dragon?.mutant ? <Badge className="bg-mutation px-1.5 py-1.5 mr-1 absolute top-0 left-0" /> : null}
              <div className="h-14 sm:h-20 w-20 sm:w-24 lg:w-28 xl:w-32 flex items-center justify-center">
                <img
                  src={isEgg ? DEFAULT_EGG_CLASS[dragon?.class] : getImageURL(dragon?.image, IMAGE_SIZE['250'])}
                  className="h-full"
                />
              </div>
            </div>

            <div className="flex">
              <div className="ml-1 xl:ml-2.5 flex items-center">
                <div>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <Badge
                        className={classnames(
                          `bg-${dragon?.class?.toLowerCase()}`,
                          'text-white px-2 sm:px-2.5 text-xs sm:text-sm pt-0.5 pb-0.5',
                        )}
                      >
                        #{dragon?.id}
                      </Badge>
                    </div>
                    <Badge
                      className={classnames('text-white px-2 sm:px-2.5 text-xs sm:text-sm pt-0.5 pb-0.5 bg-blue2 ml-1')}
                    >
                      Gen {dragon?.generation}
                    </Badge>
                  </div>
                  <div className="text-white mt-3">
                    <div className="flex justify-between items-center">
                      <p className="opacity-50 text-xs sm:text-sm-md">Level: {dragon?.level || 1}</p>
                      <Badge className="text-white px-2 sm:px-3 text-xs sm:text-sm pt-0.5 pb-0.5 bg-blue2">
                        <span>Pot</span> {dragon?.potential + 1}
                      </Badge>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ml-2.5 text-white flex items-center">
                <div>
                  <p>
                    <span className="text-white pr-1 text-sm sm:text-sm-md block sm:inline">Seller:</span>
                    <a
                      href={getExplorerLink(KROMA_CHAIN_ID, Web3.utils.toChecksumAddress(seller), 'address')}
                      target="_blank"
                      className="text-white50 text-sm-md sm:text-base"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {formatAddress(seller)}
                    </a>
                  </p>
                  <p>
                    <span className="text-blue4 pr-1 text-sm sm:text-sm-md block sm:inline">Buyer:</span>
                    <a
                      href={getExplorerLink(KROMA_CHAIN_ID, Web3.utils.toChecksumAddress(buyer), 'address')}
                      target="_blank"
                      className="text-white50 text-sm-md sm:text-base"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {formatAddress(buyer)}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden sm:flex items-center justify-end text-white mr-1">
            <div>
              <p
                style={{
                  maxWidth: 105,
                }}
                className="xl:text-lg truncate text-right"
              >
                {formatNumber(dragon?.price || 0, 0, 3)}
                <span className="text-sm-md pl-0.5">{TOKEN_USED_MARKETPLACE.symbol}</span>
              </p>
              <p className="text-xs opacity-50 text-right">~{formatNumber(priceUsd, 0, 3)}$</p>
            </div>
          </div>
        </div>
        <div className="flex justify-between sm:justify-end items-end my-1 px-2.5">
          <div className="flex sm:hidden justify-center text-white">
            <p>
              <span className="pr-1">{formatNumber(dragon?.price || 0, 0, 5)}</span>
              <span>{TOKEN_USED_MARKETPLACE.symbol}</span>
              <span className="text-xs opacity-50 pl-2">~{formatNumber(priceUsd, 0, 3)}$</span>
            </p>
          </div>
          <p className="text-xs text-white50 text-right">{formatDDMMYYYYHHmm(dragon?.createdAt)}</p>
        </div>
      </div>
    </Link>
  )
}

export default SoldDragon
