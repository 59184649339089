import React, { useEffect, useState } from 'react'
import InputNumber from '../Input/InputNumber'
import classnames from 'classnames'
import { X } from 'react-feather'

const FilterFromTo = ({ value, onChange, className, label, max }) => {
  const [from, setFrom] = useState(value?.from)
  const [to, setTo] = useState(value?.to)

  const handleClear = () => {
    setTo(undefined)
    setFrom(undefined)
  }

  useEffect(() => {
    onChange({
      from,
      to,
    })
  }, [from, to])

  useEffect(() => {
    if (value === undefined || value.from === undefined) {
      setFrom('')
    }
    if (value === undefined || value.to === undefined) {
      setTo('')
    }
    if (value?.from && from !== value?.from) {
      setFrom(Number(value?.from))
    }
    if (value?.to && to !== value?.to) {
      setTo(Number(value?.to))
    }
  }, [value])

  return (
    <div className={classnames('my-2', className)}>
      <p className="text-blue4 mb-1 text-sm-md flex items-center justify-between">{label}</p>
      <div className="grid grid-cols-2">
        <div className="flex items-center">
          <p className="mr-1.5 text-sm-md">From</p>
          <InputNumber value={from} onChange={setFrom} disabledIncrease={to && from === to} max={max} />
        </div>
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <p className="mr-1.5 text-sm-md">To</p>
            <InputNumber value={to} onChange={setTo} disabledDecrease={from === to} max={max} />
          </div>
          {from || to ? (
            <X size={20} className="ml-1 text-white hover:text-blue4 cursor-pointer" onClick={handleClear} />
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default FilterFromTo
