import React, { useState } from 'react'
import Modal from './ModalV2'
import ModalTitle from './ModalTitleV2'
import ModalFooter from './ModalFooter'
import ButtonGradient from '../Button/ButtonGradient'
import Input from '../Input/Input'
import Web3 from 'web3'
import { showToastError } from '../CustomToast/CustomToast'
import { DRAGON_TYPE } from '../../constants'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'

const ModalSendEgg = ({ open, toggle, dragon, onConfirm }) => {
  const { account } = useActiveWeb3React()
  const [address, setAddress] = useState('')
  const [pendingTx, setPendingTx] = useState(false)
  const isValid = address ? Web3.utils.isAddress(address) : false
  const isOwner =
    account && dragon?.owner && Web3.utils.toChecksumAddress(account) === Web3.utils.toChecksumAddress(dragon?.owner)

  const handleChange = (evt) => {
    setAddress(evt.target.value)
  }

  const handleSubmit = async () => {
    try {
      if (isOwner && address && isValid) {
        setPendingTx(true)
        await onConfirm(address)
        setPendingTx(false)
        toggle()
      }
    } catch (error) {
      setPendingTx(false)
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
    }
  }

  return (
    <Modal isDark open={open} onClose={toggle}>
      <ModalTitle onClose={toggle}>Send {dragon?.type === DRAGON_TYPE.DRAGON ? 'Dragon' : 'Egg'}</ModalTitle>
      <div className="p-4">
        <div className="mt-2 mx-2">
          <p className="my-1 text-white">Type or paste wallet address</p>
          <Input
            className={address && !isValid ? 'border-red-600' : 'border-blue4'}
            value={address}
            onChange={handleChange}
          />
          {address && !isValid ? <p className="text-red-600 text-sm-md">Invalid address</p> : null}
        </div>

        <p className="text-white m-5 mt-4 p-2 text-sm-md">
          <strong>Note:</strong> Please ensure that you have an exact wallet address. If not, we are not responsible for
          any mistakes!
        </p>
        <ModalFooter className="mt-4">
          <ButtonGradient
            disabled={!isOwner || !address || !isValid || pendingTx}
            isLoading={pendingTx}
            onClick={handleSubmit}
          >
            Confirm
          </ButtonGradient>
        </ModalFooter>
      </div>
    </Modal>
  )
}

export default ModalSendEgg
