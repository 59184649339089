import React from 'react'
import Container from '../Container/Container'
import classnames from 'classnames'

const LoadingPage = ({ className }) => {
  return (
    <Container className={classnames('flex justify-center mt-24', className)}>
      <img src="/icon/loading.gif" width={65} />
    </Container>
  )
}

export default LoadingPage
