export const trainingDragon = async (contract, idToken) => {
  const tx = await contract.trainDragon(idToken)
  await tx.wait()
  return tx.transactionHash
}

export const bootingDragon = async (contract, idToken) => {
  const tx = await contract.boostDragonLevel(idToken)
  await tx.wait()
  return tx.transactionHash
}

export const buffExpDragon = async (contract, dragonId, itemId) => {
  const tx = await contract.useExpNFT(dragonId, itemId)
  await tx.wait()
  return tx.transactionHash
}

export const dragonUseItem = async (contract, itemId, dragonId) => {
  const tx = await contract.wearItem(itemId, dragonId)
  await tx.wait()
  return tx.transactionHash
}

export const dragonCancelEquipment = async (contract, itemPosition, dragonId) => {
  const tx = await contract.unwearItem(itemPosition, dragonId)
  await tx.wait()
  return tx.transactionHash
}
