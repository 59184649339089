import { InjectedConnector } from '@web3-react/injected-connector'
import { ChainId } from 'defily-v2-sdk'
import { NetworkConnector } from './NetworkConnector'
import { BscConnector } from '@binance-chain/bsc-connector'
import { KaiConnector } from '@kardiachain-chain/kai-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

const KROMA_CHAIN_ID = 0xff
const KROMA_SEPOLIA_CHAIN_ID = 0x936

export const RPC = {
  [ChainId.BSC]: 'https://bsc-dataseed.binance.org/',
  [KROMA_CHAIN_ID]: 'https://api.kroma.network',
  [KROMA_SEPOLIA_CHAIN_ID]: 'https://api.sepolia.kroma.network',
  [ChainId.BSC_TESTNET]: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
  [ChainId.HARMONY]: 'https://api.s0.t.hmny.io',
  [ChainId.HARMONY_TESTNET]: 'https://api.s0.b.hmny.io',
}

export const DEFAULT_NETWORK = !process.env.REACT_APP_DEVELOP_ENVIRONMENT ? KROMA_SEPOLIA_CHAIN_ID : KROMA_CHAIN_ID

export const network = new NetworkConnector({
  defaultChainId: DEFAULT_NETWORK,
  urls: RPC,
})

export const NETWORK_SUPPORTED_CHAINIDS = [ChainId.KAI]

export const injected = new InjectedConnector({
  supportedChainIds: [
    !process.env.REACT_APP_DEVELOP_ENVIRONMENT ? KROMA_SEPOLIA_CHAIN_ID : KROMA_CHAIN_ID,
    // 56, // binance smart chain
    // 97, // binance smart chain testnet
    // 1666600000, // HARMONY
    // 1666700000, // HARMONY TESTNET
  ],
})

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: RPC,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: 15000,
})

// binance only
export const binance = new BscConnector({ supportedChainIds: [56] })
// export const binance = new BscConnector({ supportedChainIds: [97] }) // testnet

export const kai = new KaiConnector({ supportedChainIds: [24] })

export const SUPPORTED_NETWORKS = {
  [ChainId.MAINNET]: {
    chainId: '0x1',
    chainName: 'Ethereum',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://mainnet.infura.io/v3'],
    blockExplorerUrls: ['https://etherscan.com'],
  },
  [ChainId.TOMO]: {
    chainId: '0x58',
    chainName: 'TomoChain',
    nativeCurrency: {
      name: 'TomoChain',
      symbol: 'TOMO',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.tomochain.com'],
    blockExplorerUrls: ['https://scan.tomochain.com'],
  },
  [ChainId.BSC]: {
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed.binance.org'],
    blockExplorerUrls: ['https://bscscan.com'],
  },
  [KROMA_CHAIN_ID]: {
    chainId: '0xff',
    chainName: 'Kroma',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://api.kroma.network'],
    blockExplorerUrls: ['https://kromascan.com/'],
  },
  [KROMA_SEPOLIA_CHAIN_ID]: {
    chainId: '0x936',
    chainName: 'Kroma Sepolia',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://api.sepolia.kroma.network'],
    blockExplorerUrls: ['https://sepolia.kromascan.com/'],
  },
  [ChainId.MATIC]: {
    chainId: '0x89',
    chainName: 'Matic',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://rpc-mainnet.maticvigil.com'], // ['https://matic-mainnet.chainstacklabs.com/'],
    blockExplorerUrls: ['https://explorer-mainnet.maticvigil.com'],
  },
  [ChainId.FANTOM]: {
    chainId: '0xfa',
    chainName: 'Fantom',
    nativeCurrency: {
      name: 'Fantom',
      symbol: 'FTM',
      decimals: 18,
    },
    rpcUrls: ['https://rpcapi.fantom.network'],
    blockExplorerUrls: ['https://ftmscan.com'],
  },
  [ChainId.HECO]: {
    chainId: '0x80',
    chainName: 'Heco',
    nativeCurrency: {
      name: 'Heco Token',
      symbol: 'HT',
      decimals: 18,
    },
    rpcUrls: ['https://http-mainnet.hecochain.com'],
    blockExplorerUrls: ['https://hecoinfo.com'],
  },
  [ChainId.XDAI]: {
    chainId: '0x64',
    chainName: 'xDai',
    nativeCurrency: {
      name: 'xDai Token',
      symbol: 'xDai',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.xdaichain.com'],
    blockExplorerUrls: ['https://blockscout.com/poa/xdai'],
  },
  [ChainId.HARMONY]: {
    chainId: '0x63564C40',
    chainName: 'Harmony',
    nativeCurrency: {
      name: 'One Token',
      symbol: 'ONE',
      decimals: 18,
    },
    rpcUrls: ['https://api.s0.t.hmny.io'],
    blockExplorerUrls: ['https://explorer.harmony.one/'],
  },
  [ChainId.HARMONY_TESTNET]: {
    chainId: '0x6357D2E0',
    chainName: 'Harmony Testnet',
    nativeCurrency: {
      name: 'One Token',
      symbol: 'ONE',
      decimals: 18,
    },
    rpcUrls: [
      'https://api.s0.b.hmny.io',
      'https://api.s1.b.hmny.io',
      'https://api.s2.b.hmny.io',
      'https://api.s3.b.hmny.io',
    ],
    blockExplorerUrls: ['https://explorer.pops.one/'],
  },
  [ChainId.AVALANCHE]: {
    chainId: '0xA86A',
    chainName: 'Avalanche',
    nativeCurrency: {
      name: 'Avalanche Token',
      symbol: 'AVAX',
      decimals: 18,
    },
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://cchain.explorer.avax.network'],
  },
  [ChainId.OKEX]: {
    chainId: '0x42',
    chainName: 'OKEx',
    nativeCurrency: {
      name: 'OKEx Token',
      symbol: 'OKT',
      decimals: 18,
    },
    rpcUrls: ['https://exchainrpc.okex.org'],
    blockExplorerUrls: ['https://www.oklink.com/okexchain'],
  },
  [ChainId.ARBITRUM]: {
    chainId: '0xA4B1',
    chainName: 'Arbitrum',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://mainnet-arb-explorer.netlify.app'],
  },
  [ChainId.CELO]: {
    chainId: '0xA4EC',
    chainName: 'Celo',
    nativeCurrency: {
      name: 'Celo',
      symbol: 'CELO',
      decimals: 18,
    },
    rpcUrls: ['https://forno.celo.org'],
    blockExplorerUrls: ['https://explorer.celo.org'],
  },
  [ChainId.PALM]: {
    chainId: '0x2A15C308D',
    chainName: 'Palm',
    nativeCurrency: {
      name: 'Palm',
      symbol: 'PALM',
      decimals: 18,
    },
    rpcUrls: ['https://palm-mainnet.infura.io/v3/da5fbfafcca14b109e2665290681e267'],
    blockExplorerUrls: ['https://explorer.palm.io'],
  },
}
