import React, { useEffect, useState } from 'react'
import Modal from 'components/Modal/ModalV2'
import ModalTitle from 'components/Modal/ModalTitleV2'
import Pagination from 'components/Pagination'
import eventApi from 'api/eventApi'
import { DEFAULT_TOKEN_USED, HISTORY_MONSTER_TYPE, KROMA_CHAIN_ID } from 'constants/index'
import { formatNumber } from 'utils/formatBalance'
import { getExplorerLink } from 'utils/explorer'
import { formatAddress } from 'utils/addressHelpers'
import { formatDDMMYYYYHHmm } from 'utils/formatDateTime'
import useActiveWeb3React from '../../../../hooks/useActiveWeb3React'
import { ChainId } from 'defily-v2-sdk'

const ModalClaimHistory = ({ open, toggle, type }) => {
  const { account } = useActiveWeb3React()
  const [history, setHistory] = useState(undefined)

  const getHistory = async (page = 1) => {
    try {
      const params = {
        page,
        type: HISTORY_MONSTER_TYPE.Claim,
        pageLimit: 15,
      }
      if (type) {
        params.monster = type
      }
      const response = await eventApi.getEventHistoryOwner(params)

      setHistory(response)
    } catch (error) {}
  }

  const handleChangePage = (page) => {
    getHistory(page + 1)
  }

  useEffect(() => {
    getHistory()
  }, [account])

  return (
    <Modal isDark size="xl" open={open} onClose={toggle}>
      <ModalTitle onClose={toggle} className="mb-0-important">
        Claim reward history
      </ModalTitle>
      <div>
        <div className="overflow-auto custom-scrollbar-scroll">
          <table
            className="custom-table-training w-full text-white"
            style={{
              minWidth: 780,
            }}
          >
            <thead>
              <tr>
                <th>Reward</th>
                <th>TX hash</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody className="text-white">
              {Array.isArray(history?.data) ? (
                history?.data?.length ? (
                  history?.data?.map((row) => (
                    <tr key={row?.trxHash}>
                      <td>
                        {typeof row?.data?.reward ? (
                          <span>
                            {formatNumber(row?.data?.reward, 0, 2)} {DEFAULT_TOKEN_USED.symbol}
                          </span>
                        ) : null}
                      </td>
                      <td>
                        {row?.trxHash ? (
                          <a href={getExplorerLink(KROMA_CHAIN_ID, row?.trxHash, 'transaction')} target="_blank">
                            {row?.trxHash ? formatAddress(row?.trxHash) : null}
                          </a>
                        ) : null}
                      </td>
                      <td>{formatDDMMYYYYHHmm(row?.createdAt)}</td>
                    </tr>
                  ))
                ) : (
                  <tr style={{ borderBottomWidth: 0 }}>
                    <td colSpan={999} className="text-center text-lg my-5">
                      No data
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan={999}>
                    <div className="flex justify-center py-5">
                      <img src="/icon/loading.gif" width={35} />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="my-5">
          {history?.totalItems ? (
            <Pagination
              pageCount={history?.totalPage || 0}
              onChangePage={handleChangePage}
              currentPage={history?.currentPage || 1}
            />
          ) : null}
        </div>
      </div>
    </Modal>
  )
}

export default ModalClaimHistory
