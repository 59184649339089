import { Web3ReactProvider } from '@web3-react/core'
import { Provider } from 'react-redux'
import { getLibrary } from 'utils/web3React'
import PropTypes from 'prop-types'

import { RefreshContextProvider } from 'contexts/RefreshContext'
import store from 'store'
import Web3ReactManager from './components/Web3ReactManager/Web3ReactManager'
import Web3ProviderNetwork from './components/Web3ProviderNetwork/Web3ProviderNetwork'

const Providers = ({ children }) => {
  return (
    <Provider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
          <Web3ReactManager>
            <RefreshContextProvider>{children}</RefreshContextProvider>
          </Web3ReactManager>
        </Web3ProviderNetwork>
      </Web3ReactProvider>
    </Provider>
  )
}

Providers.propTypes = {
  children: PropTypes.node,
}

export default Providers
