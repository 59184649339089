import React, { useEffect } from 'react'
import Modal from './Modal'
import { useModal } from '../../store/modal/hook'
import { MODAL_TYPE } from '../../constants'
import { useDispatch } from 'react-redux'
import { toggleModal } from '../../store/modal'

const ModalUpcoming = () => {
  const modal = useModal()
  const dispatch = useDispatch()
  useEffect(() => {
    setTimeout(() => {
      if (modal?.type === MODAL_TYPE.UPCOMING_ALERT) {
        dispatch(toggleModal())
      }
    }, 2000)
  }, [modal?.type])

  return (
    <Modal
      size="lg"
      open={modal?.type === MODAL_TYPE.UPCOMING_ALERT}
      onClose={() => dispatch(toggleModal())}
      className="bg-black80 p-4 md:p-6 lg:p-8 xl:p-10"
    >
      <div className="text-white text-bold text-center text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl">
        Please stay tuned for more updates!
      </div>
    </Modal>
  )
}

export default ModalUpcoming
