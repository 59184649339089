import Logo from './Logo'
import Menu from './Menu'
import ButtonGradient from '../Button/ButtonGradient'
import MenuMobile from './MenuMobile'
import Notification from '../Notification'
import Web3Status from '../Web3Status/Web3Status'

const Header = ({}) => {
  return (
    <header
      id="header-pages"
      className="bg-primary py-4 xl:py-0 px-2 relative z-50 flex justify-between items-center shadow-lg header-page"
    >
      <div className="max-w-screen-xl mx-auto flex justify-between items-center flex-1">
        <Logo className="sm:flex" />
        <Menu style={{ height: 80, gap: 10 }} className="hidden lg:flex flex-nowrap justify-center md:h-full" />
        <div className="flex items-center">
          {/**
           * TODO
           * Uncomment later
           */}
          {/* <ButtonGradient
            size="custom"
            className="text-sm sm:text-md py-1 px-3 sm:py-2 sm:px-6 whitespace-nowrap"
            onClick={() => window.open('https://go.dragonwars.game', '_self')}
          >
            Start Battle
          </ButtonGradient> */}
          <Web3Status />
          <Notification />
          <MenuMobile />
        </div>
      </div>
    </header>
  )
}
export default Header
