import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'

const Input = ({ className, ...rest }) => {
  const inputElement = useRef(null)

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus()
    }
  }, [])
  return (
    <input
      {...rest}
      className={classnames('w-full p-2 border-primary border-2 rounded-2xl', className)}
      ref={inputElement}
    />
  )
}

Input.propTypes = {
  className: PropTypes.string,
}

export const InputCustom = ({ className, register, label, rules, ...rest }) => {
  return (
    <input
      {...rest}
      {...register(label, rules)}
      className={classnames('w-full p-2 border-primary border-2 rounded-2xl', className)}
    />
  )
}

InputCustom.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  rules: PropTypes.object,
  register: PropTypes.any,
}

export default Input
