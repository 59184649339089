import useRefresh from 'hooks/useRefresh'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { fetchNotificationAsync, fetchNumberNotifyAsync, setNotifications } from './index'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'

export const useNotifications = () => useSelector((state) => state.notification.notifications)

export const useNumberNotify = () => useSelector((state) => state.notification.numberNotify)

export const useFetchNotifications = () => {
  const { account } = useActiveWeb3React()
  const { fastRefresh } = useRefresh()
  const dispatch = useDispatch()

  useEffect(() => {
    if (account) {
      dispatch(fetchNotificationAsync({}))
      dispatch(fetchNumberNotifyAsync())
    }
  }, [fastRefresh, account])

  useEffect(() => {
    dispatch(setNotifications(undefined))
  }, [account])
}
