import React from 'react'
import Modal from './Modal'
import ModalTitle from './ModalTitle'
import ModalFooter from './ModalFooter'
import ButtonGradient from '../Button/ButtonGradient'
import Button from '../Button/Button'

const ModalWarningBuyEgg = ({ open, toggle, onSubmit }) => {
  return (
    <Modal open={open} onClose={toggle}>
      <ModalTitle onClose={toggle}>Warming</ModalTitle>
      <div className="py-2">
        It's not time to buy yet, transaction may be failed. Are you sure you want to continue?
      </div>
      <ModalFooter align={'right'}>
        <Button color="secondary" className="mr-2" onClick={toggle}>
          Cancel
        </Button>
        <ButtonGradient noneBorder rounded={false} className="text-white" onClick={onSubmit}>
          Confirm
        </ButtonGradient>
      </ModalFooter>
    </Modal>
  )
}

export default ModalWarningBuyEgg
