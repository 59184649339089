import React from 'react'
import Badge from '../Badge/Badge'
import Card from './Card'
import PropTypes from 'prop-types'
import Box from '../Box/Box'
import classnames from 'classnames'
import Value from 'components/Value/Value'
import { DRAGON_BODY_PART, DRAGON_STATS, RARITY_ITEM } from 'constants/index'
import { TOKEN_USED_MARKETPLACE, ITEM_STATUS_BG_COLOR, ITEM_STATUS } from 'constants/index'
import { formatNumber, getBalanceAmount } from 'utils/formatBalance'
import { useKaiPrice } from 'hooks/usePrice'
import BigNumber from 'bignumber.js'
export default function ItemCardV2({ item, className, showPrice = true, ...rest }) {
  const bodyPart = item?.equipment?.part
  const stat = item?.equipment?.stat
  const price = getBalanceAmount(item?.price, 0)
  const tokenPrice = useKaiPrice()
  const priceUsd = price ? new BigNumber(price).times(tokenPrice).toNumber() : 0
  const bgItem = item?.equipment?.rarity && RARITY_ITEM?.[item?.equipment?.rarity]?.image
  return (
    <Box {...rest} className={classnames('rounded-xl border-2 border-blue2 bg-blue1 shadow-lg py-4', className)}>
      <div className="flex flex-col items-center py-1 relative">
        <div className="flex items-center justify-between mb-2 w-full sm:px-5 px-1">
          <Badge className="bg-white py-0.5 px-4 text-sm-md text-black font-bold">#{item?.nftId}</Badge>
          <p className="text-sm-md text-white50">Level: {item?.level + 1}</p>
        </div>
        {item?.status === 'USED' ? (
          <div className="absolute sm:left-5 left-1 top-8 flex items-center mt-1">
            <img src="/icon/dragon_icon.png" className="w-6 h-6" alt="icon" />
            <span className="ml-1 sm:text-md text-sm">{item?.dragon}</span>
          </div>
        ) : null}
        <div className="absolute right-0 top-8">
          {showPrice && item?.status !== 'ACTIVE' ? (
            <p
              style={{
                backgroundImage: `linear-gradient(to right, ${
                  ITEM_STATUS_BG_COLOR[item?.status?.toUpperCase()]?.from
                },${ITEM_STATUS_BG_COLOR[item?.status?.toUpperCase()]?.to})`,
              }}
              className="whitespace-nowrap flex items-center rounded-l-xl text-sm sm:text-sm-md pr-6 pl-3 text-white"
            >
              {ITEM_STATUS?.[item?.status?.toUpperCase()]}
            </p>
          ) : null}
        </div>

        {/* <div className="px-5 mt-6 sm:w-40 sm:h-32 w-32 h-32 flex justify-center items-center">
          <img src={item?.equipment?.image} alt="item" className="max-w-full max-h-full" />
        </div>
        <div className="flex items-center mb-5 mt-4">
          <div className="flex items-center px-2.5 text-white">
            <img src={DRAGON_BODY_PART[bodyPart]?.image} className="sm:w-8 w-6" alt="icon" />
            <span className="sm:ml-2 ml-1 sm:text-md text-sm-md font-bold">{DRAGON_BODY_PART[bodyPart]?.display}</span>
          </div>
          <div className="flex items-center px-2.5 text-white text-md">
            <img src={DRAGON_STATS[stat]?.icon} alt="icon" className="mr-1 sm:w-5 w-5" />+
            {typeof item?.equipment?.point === 'number' ? (
              <Value className="" value={item?.equipment?.point} decimals={0} />
            ) : (
              '--'
            )}
          </div>
        </div>
        <p className="uppercase text-lg font-medium text-blue4 mb-2 text-center">{item?.equipment?.name}</p> */}
        <div
          {...rest}
          style={{
            backgroundImage: `url(${bgItem})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
          }}
          className={classnames('mt-8 flex flex-col items-center relative background-skill-card', className)}
        >
          <div
            style={{
              background: RARITY_ITEM?.[item?.equipment?.rarity]?.color,
            }}
            className="absolute left-0 top-12 rounded-r-full z-20 skill-rarity text-sm"
          >
            <p className="p-1 font-bold text-black">{RARITY_ITEM?.[item?.equipment?.rarity]?.label}</p>
          </div>
          <div className="w-full h-9 flex items-center justify-center mt-2 item-name-container">
            <h1 className="font-bold capitalize skill-name text-black text-lg">{item?.equipment?.name}</h1>
          </div>

          <div className="flex items-center justify-center w-full">
            <div className=" w-40 h-40 item-background flex items-center justify-center">
              <img src={item?.equipment?.image} alt="item" className="max-w-full max-h-full" />
            </div>
          </div>

          <div className="flex items-center my-4 text-white item-point-container">
            <div className="flex items-center px-2 item-icon-container">
              <div className="w-8 item-icon flex items-center justify-center">
                <img src={DRAGON_BODY_PART?.[bodyPart]?.image} className="max-w-full max-h-full" alt="icon" />
              </div>
              <span className="sm:ml-2 ml-1 text-md item-card-text">{DRAGON_BODY_PART[bodyPart]?.display}</span>
            </div>
            <div className="flex items-center px-2 item-icon-container">
              <div className="mr-1 w-5 item-icon flex items-center justify-center">
                <img src={DRAGON_STATS?.[stat]?.icon} alt="icon" className="max-w-full max-h-full" />
              </div>
              +
              {typeof item?.equipment?.point === 'number' ? (
                <Value className="text-md item-card-text" value={item?.equipment?.point} decimals={0} />
              ) : (
                '--'
              )}
            </div>
          </div>
        </div>

        <div className="sm:h-4 h-6 mt-5">
          {showPrice && item?.status === 'SELLING' ? (
            <>
              <div className="flex sm:flex-row flex-col text-center items-center text-sm-md">
                <div className="uppercase mr-1 font-medium flex items-center">
                  {typeof item?.price === 'number' ? <Value className="" value={item?.price} decimals={0} /> : '--'}
                  <span className="ml-1">{TOKEN_USED_MARKETPLACE.symbol}</span>
                </div>
                <p className="text-white50">~{formatNumber(priceUsd, 2, 3)}$</p>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </Box>
  )
}

ItemCardV2.propTypes = {
  item: PropTypes.object,
}
