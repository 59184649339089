import React, { useEffect, useState } from 'react'
import InputSearch from 'components/Input/InputSearch'
import FilterDragonAttribute from 'components/Filter/FilterDragonAttribute'
import Dropdown from 'components/Dropdown/Dropdown'
import { AUCTION_TYPE, FILTER_DRAGON_FOR_SALE, SORT_DRAGON_FIELD } from 'constants/index'
import classnames from 'classnames'
import { useFetchBodyPartsData, useSearchData, useUpdateSearch } from 'store/search/hook'
import { useDispatch } from 'react-redux'
import { setPageQuery, updateSearchData, updateSearchDragons } from 'store/search'
import useDebounce from 'hooks/useDebounce'

const FilterAction = ({ handleFilter, filterLeft, showFilter = true, showSort = true, showDropdownBox = true }) => {
  useUpdateSearch()
  useFetchBodyPartsData()
  const searchData = useSearchData()
  const dispatch = useDispatch()
  const [textSearch, setTextSearch] = useState(searchData?.textSearch)
  const textSearchDebounce = useDebounce(textSearch, 0)

  const updateFieldSearch = (field, value) => {
    dispatch(updateSearchData({ [field]: value }))
  }

  const updateSelect = (field, value) => {
    updateFieldSearch(field, value)
    dispatch(setPageQuery(1))
  }

  const selectTypeDragon = (field, value) => {
    updateSelect(field, value)
    if (value?.value === AUCTION_TYPE.NOT_FOR_SALE) {
      dispatch(updateSearchDragons({ price: undefined }))
      dispatch(updateSearchDragons({ price2: undefined }))
    }
  }

  const onChangeText = (e) => {
    setTextSearch(e.target.value)
    dispatch(setPageQuery(1))
  }

  useEffect(() => {
    if (textSearchDebounce !== undefined) {
      updateFieldSearch('textSearch', textSearchDebounce)
    }
  }, [textSearchDebounce])

  useEffect(() => {
    setTextSearch(searchData?.textSearch)
  }, [searchData?.textSearch])

  return (
    <div className="m-auto pt-2 sm:pt-4 max-w-screen-lg">
      <div
        className={classnames('sm:flex justify-between items-center mx-2.5 sm:mx-5 xl:mx-0', {
          'lg:mx-5 xl:mx-5 2xl:mx-0': filterLeft,
        })}
      >
        <InputSearch
          className="flex-1 sm:max-w-sm h-10 mt-2"
          placeholder="Search #..."
          classNameInput="w-full"
          value={searchData?.textSearch || ''}
          onChange={onChangeText}
        />
        <div className="block sm:flex items-center">
          <div className="flex mt-2">
            {showDropdownBox ? (
              <FilterDragonAttribute
                className={classnames('flex-1 sm:flex-none mr-2 sm:mr-0 sm:ml-2.5', {
                  'block lg:hidden': filterLeft,
                })}
                classNameWrapperButton="w-full sm:w-auto"
                classNameMenuItem="popover-filter-dragons"
                handleFilter={handleFilter}
              />
            ) : null}
            {showFilter ? (
              <Dropdown
                bsprefixmenu="p-0 text-sm-md sm:text-base mr-1 sm:mr-4"
                menu={searchData?.filterSale || FILTER_DRAGON_FOR_SALE.ALL}
                className="flex-1 sm:flex-none mr-0 ml-2 sm:ml-2.5"
                classNameMenu="border-blue2 border-2 rounded-xl h-10"
                classNameLabel="mr-8 sm:mr-4"
                classNameMenuItem={classnames('bg-primary text-white right-0 w-36')}
                classNameButtonToggle={'inline text-blue4 px-4 sm:px-2.5 mr-0 cursor-pointer'}
              >
                {Object.values(FILTER_DRAGON_FOR_SALE).map((item, index) => (
                  <p
                    key={index}
                    className="p-3 text-white cursor-pointer hover:bg-blue2"
                    onClick={() => selectTypeDragon('filterSale', item)}
                  >
                    {item.label}
                  </p>
                ))}
              </Dropdown>
            ) : showSort ? (
              <Dropdown
                bsprefixmenu="p-0 text-sm sm:text-base mr-1 sm:mr-4"
                menu={searchData?.sort || SORT_DRAGON_FIELD.LOWEST_ID}
                className="flex-1 sm:flex-none sm:ml-2.5"
                classNameMenu="border-blue2 border-2 rounded-xl h-10"
                classNameLabel="text-sm-md2 sm:text-sm-md sm:mr-4"
                classNameMenuItem={classnames('bg-primary text-white right-0 w-44')}
                classNameButtonToggle={'inline text-blue4 px-4 sm:px-2.5 mr-0 cursor-pointer'}
              >
                {Object.values(SORT_DRAGON_FIELD).map((item, index) => (
                  <p
                    key={index}
                    className="p-3 text-white cursor-pointer hover:bg-blue2"
                    onClick={() => updateSelect('sort', item)}
                  >
                    {item.label}
                  </p>
                ))}
              </Dropdown>
            ) : null}
          </div>
          <div className="flex mt-2">
            {showFilter && showSort ? (
              <Dropdown
                bsprefixmenu="p-0 text-sm-md2 sm:text-base mr-1 sm:mr-4"
                menu={searchData?.sort || SORT_DRAGON_FIELD.LOWEST_ID}
                className="flex-1 sm:flex-none mr-2 sm:mr-0 sm:ml-2.5"
                classNameMenu="border-blue2 border-2 rounded-xl h-10"
                classNameLabel="text-sm-md2 sm:text-sm-md sm:mr-4"
                classNameMenuItem={classnames('bg-primary text-white right-0 w-44')}
                classNameButtonToggle={'inline text-blue4 px-4 sm:px-2.5 mr-0 cursor-pointer'}
              >
                {Object.values(SORT_DRAGON_FIELD).map((item, index) => (
                  <p
                    key={index}
                    className="p-3 text-white cursor-pointer hover:bg-blue2"
                    onClick={() => updateSelect('sort', item)}
                  >
                    {item.label}
                  </p>
                ))}
              </Dropdown>
            ) : null}
            <div className="flex-1 sm:flex-none ml-2 sm:ml-0" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FilterAction
