import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Box from 'components/Box/Box'
import { getImageURL, sleep } from 'utils'
import { AUCTION_TYPE, DEFAULT_TOKEN_USED, IMAGE_SIZE } from 'constants/index'
import Badge from 'components/Badge/Badge'
import Progress from 'components/Progress'
import Value from 'components/Value/Value'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import Button from 'components/Button/Button'
import Countdown, { zeroPad } from 'react-countdown'
import useInterval from 'hooks/useInterval'
import ModalEstimateBoosting from 'components/Modal/ModalEstimateBoosting'
import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast'
import { fetchUserDataTraining } from 'store/dragons'
import dragonsApi from 'api/dragonsApi'
import { useApprove } from 'hooks/useApprove'
import { TRAINING_ADDRESS } from 'config'
import { useBootDragon, useTrain } from 'hooks/useTrain'
import { ArrowRight } from 'react-feather'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { TRAINING_DESCRIPTION } from '../../../constants/training'
import useActiveWeb3React from '../../../hooks/useActiveWeb3React'

const DragonCard = ({ dragon, userData, dragonBalance, ...rest }) => {
  const { id, image, level } = dragon
  const { account } = useActiveWeb3React()
  const dispatch = useDispatch()
  const { push } = useHistory()
  const { onApprove } = useApprove(TRAINING_ADDRESS, DEFAULT_TOKEN_USED.address)
  const { onTrain } = useTrain(TRAINING_ADDRESS)
  const { onBoot } = useBootDragon(TRAINING_ADDRESS)

  const trainingAvailable = ![AUCTION_TYPE.SIRING, AUCTION_TYPE.AUCTION].includes(dragon?.sale)
  const [pendingApproveTraining, setPendingApproveTraining] = useState(false)
  const [pendingTraining, setPendingTraining] = useState(false)
  const [pendingBooting, setPendingBooting] = useState(false)
  const [canAdventure, setCanAdventure] = useState(false)
  const [showCountDown, setShowCountDown] = useState(false)
  const [openModalBoosting, setOpenModalBoosting] = useState(false)

  const allowance = new BigNumber(userData?.allowance?.training) || BIG_ZERO
  const isApproved = account && allowance && allowance?.isGreaterThan(0)

  const dataStats = [
    {
      icon: '/icon/health.svg',
      label: 'Mana',
      value: typeof dragon?.currentStats?.mana === 'number' ? dragon?.currentStats?.mana : '???',
    },
    {
      icon: '/icon/generation.svg',
      label: 'Health',
      value: typeof dragon?.currentStats?.health === 'number' ? dragon?.currentStats?.health : '???',
    },
    {
      icon: '/icon/attack.svg',
      label: 'Attack',
      value: typeof dragon?.currentStats?.attack === 'number' ? dragon?.currentStats?.attack : '???',
    },
    {
      icon: '/icon/defend.svg',
      label: 'Defend',
      value: typeof dragon?.currentStats?.defend === 'number' ? dragon?.currentStats?.defend : '???',
    },
    {
      icon: '/icon/speed.svg',
      label: 'Speed',
      value: typeof dragon?.currentStats?.speed === 'number' ? dragon?.currentStats?.speed : '???',
    },
    {
      icon: '/icon/morale.svg',
      label: 'Morale',
      value: typeof dragon?.currentStats?.morale === 'number' ? dragon?.currentStats?.morale : '???',
    },
  ]

  const requiredLevel = TRAINING_DESCRIPTION[dragon?.level ? dragon?.level - 1 : 0]
  const requiredNextLevel = TRAINING_DESCRIPTION[dragon?.level ? dragon?.level : 1]
  const percent =
    (((dragon?.xp ? dragon?.xp : 0) - (requiredLevel.expRequired || 0)) /
      (requiredNextLevel.expRequired - (requiredLevel.expRequired || 0))) *
    100

  useInterval(() => {
    if (!canAdventure && dragon && (dragon?.startLock < Date.now() || !dragon?.startLock)) {
      setCanAdventure(true)
    }
  }, 1000)

  const handleApproveTraining = async () => {
    try {
      setPendingApproveTraining(true)
      await onApprove()
      await dispatch(fetchUserDataTraining(account))
      setPendingApproveTraining(false)
      showToastSuccess('Contract Enabled', 'Approve contract successfully!')
    } catch (error) {
      console.log(error)
      setPendingApproveTraining(false)
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
    }
  }

  const handleRefresh = async () => {
    try {
      await dragonsApi.migrateData(dragon?.id)
      return true
    } catch (error) {
      console.log(error)
    }
  }

  const handleTraining = async () => {
    try {
      setPendingTraining(true)
      await onTrain(dragon?.id)
      await handleRefresh()
      showToastSuccess('Success', 'Congratulations! Your dracora has successfully defeated the enemy in the adventure!')
      setPendingTraining(false)
    } catch (error) {
      setPendingTraining(false)
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
    }
  }

  const handleBooting = async () => {
    try {
      setOpenModalBoosting(false)
      setPendingBooting(true)
      await onBoot(dragon?.id)
      await handleRefresh()
      showToastSuccess('Success', 'Upgrade dracora successfully!')
      setPendingBooting(false)
    } catch (error) {
      setPendingBooting(false)
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
    }
  }

  const handleShowCountdown = async () => {
    await setShowCountDown(false)
    await sleep(1500)
    await setShowCountDown(true)
  }

  const renderCooldown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed || !showCountDown) return null
    return (
      <span className="pl-1 text-sm">
        {hours > 0 ? `${zeroPad(hours)}h` : ''}
        {zeroPad(minutes)}m{zeroPad(seconds)}s
      </span>
    )
  }

  useEffect(() => {
    setCanAdventure(false)
    handleShowCountdown()
  }, [dragon?.startLock])

  return (
    <Box
      {...rest}
      className={classnames('border-2 overflow-hidden h-full border-blue2 bg-blue1 p-3 px-2.5 text-white relative', {
        'opacity-60': !trainingAvailable,
      })}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center mb-2">
          <Badge className={classnames(`bg-${dragon?.class?.toLowerCase()}`, 'text-white px-3 text-sm pt-0.5 pb-0.5')}>
            #{id}
          </Badge>
          <Badge className={classnames('text-white px-3 text-sm pt-0.5 pb-0.5 bg-blue2 ml-1')}>
            Gen {dragon?.generation}
          </Badge>
        </div>

        <Badge className={classnames(`bg-blue2`, 'text-white px-3 text-sm pt-0.5 pb-0.5')}>
          Potential {dragon?.potential + 1 || 0}
        </Badge>
      </div>
      <div className="grid grid-cols-12">
        <div className="col-span-4 flex justify-center items-end h-full">
          <div className="h-20">
            <img src={getImageURL(image, IMAGE_SIZE['250'])} className="h-full" />
          </div>
        </div>
        <div className="col-span-8 pl-2">
          <div className="flex justify-between items-end mt-1">
            <div className="flex items-center">
              <Badge className={classnames(`bg-${dragon?.class?.toLowerCase()}`, 'px-1 py-1')} />
              <span className="pl-2 capitalize text-sm-md">{dragon?.class?.toLowerCase()}</span>
            </div>
            <p className="opacity-50 text-sm">
              Level: {level || 1}
              <span className="text-sm pl-1">
                ({dragon?.xp ? dragon?.xp : 0}/{requiredNextLevel.expRequired})
              </span>
            </p>
          </div>

          <Progress percent={percent} />
          <div className="grid grid-cols-3 mt-2">
            {dataStats.map((item, index) => (
              <div className="flex items-center pb-1" key={index}>
                <img
                  src={item.icon}
                  style={{
                    maxHeight: 12,
                    maxWidth: 12,
                    width: 12,
                  }}
                />
                <div className="text-white ml-2">
                  {typeof item?.value === 'number' ? (
                    <Value className="text-sm" value={item?.value} decimals={0} />
                  ) : (
                    item?.value
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-2">
        <div className="flex justify-center">
          {isApproved ? (
            <>
              <Button
                outline
                size="xs"
                className="mr-1 text-xs"
                disabled={pendingBooting || pendingTraining || !trainingAvailable}
                isLoading={pendingBooting}
                styleToolTip={{ top: '-4rem' }}
                classNameToolTip="w-44 -right-20"
                msgDisable={
                  !trainingAvailable
                    ? `Dracora for ${dragon?.sale === AUCTION_TYPE.AUCTION ? 'sell' : 'sire'} cannot boot level`
                    : null
                }
                onClick={() => setOpenModalBoosting(true)}
              >
                Boost Level
              </Button>
              {!showCountDown ? (
                <Button
                  outline
                  size="xs"
                  disabled={pendingBooting || pendingTraining || !canAdventure || !trainingAvailable}
                  isLoading={pendingTraining}
                  className="z-10 mr-1 text-xs"
                  styleToolTip={{ top: '-4rem' }}
                  classNameToolTip="w-48"
                  msgDisable={
                    !trainingAvailable
                      ? `Dracora for ${dragon?.sale === AUCTION_TYPE.AUCTION ? 'sell' : 'sire'} cannot adventure`
                      : null
                  }
                  onClick={handleTraining}
                >
                  Adventure
                  {trainingAvailable ? (
                    <>
                      {dragon?.startLock && showCountDown ? (
                        <Countdown zeroPadTime={2} date={dragon?.startLock} renderer={renderCooldown} />
                      ) : null}
                    </>
                  ) : null}
                </Button>
              ) : (
                <Button
                  outline
                  size="xs"
                  disabled={pendingBooting || pendingTraining || !canAdventure || !trainingAvailable}
                  isLoading={pendingTraining}
                  className="z-10 mr-1 text-xs"
                  styleToolTip={{ top: '-4rem' }}
                  classNameToolTip="w-48"
                  msgDisable={
                    !trainingAvailable
                      ? `Dracora for ${dragon?.sale === AUCTION_TYPE.AUCTION ? 'sell' : 'sire'} cannot adventure`
                      : null
                  }
                  onClick={handleTraining}
                >
                  Adventure
                  {trainingAvailable ? (
                    <>
                      {dragon?.startLock && showCountDown ? (
                        <Countdown zeroPadTime={2} date={dragon?.startLock} renderer={renderCooldown} />
                      ) : null}
                    </>
                  ) : null}
                </Button>
              )}
            </>
          ) : (
            <div>
              <Button
                outline
                size="xs"
                className="z-10 text-xs"
                disabled={pendingApproveTraining || !userData?.userDataLoaded}
                isLoading={pendingApproveTraining || !userData?.userDataLoaded}
                onClick={handleApproveTraining}
              >
                Approve Contract
              </Button>
            </div>
          )}
        </div>
        <div
          className="flex items-center text-sm cursor-pointer text-blue4 hover:opacity-80"
          onClick={() => push(`/dragon/${dragon?.id}`)}
        >
          Detail
          <ArrowRight size={14} />
        </div>
      </div>
      {openModalBoosting ? (
        <ModalEstimateBoosting
          open={openModalBoosting}
          dragon={dragon}
          dragonBalance={dragonBalance}
          requiredNextLevel={requiredNextLevel}
          toggle={() => setOpenModalBoosting(false)}
          onSubmit={handleBooting}
        />
      ) : null}
    </Box>
  )
}

DragonCard.propTypes = {
  dragon: PropTypes.object.isRequired,
}

export default DragonCard
