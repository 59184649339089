import React from 'react'
import BoxTab from './BoxTab'
import { useDispatch } from 'react-redux'
import { setTabListed } from 'store/report'
import {
  useTabListed,
  useFetchItemsListed,
  useEquipmentSelling,
  useSkillSelling,
  useExpSelling,
} from 'store/report/hook'
import { useKaiPrice } from 'hooks/usePrice'
import ListedItem from './ListedItem'

export default function RecentListed() {
  useFetchItemsListed()
  const dispatch = useDispatch()
  const equipmentSelling = useEquipmentSelling()
  const skillSelling = useSkillSelling()
  const expSelling = useExpSelling()
  const tab = useTabListed()
  const tokenPrice = useKaiPrice()

  const itemsSelling = tab === 0 ? equipmentSelling : tab === 1 ? skillSelling : tab === 2 ? expSelling : []

  const onChangeTab = (value) => {
    if (value !== tab) {
      dispatch(setTabListed(value))
    }
  }
  return (
    <BoxTab
      title="Recently listed"
      tabActive={tab}
      tabs={['Equipment selling', 'Skill card selling', 'Exp card selling']}
      onChangeTab={onChangeTab}
      column={3}
    >
      {itemsSelling === undefined ? (
        <div className="flex justify-center py-10">
          <img src="/icon/loading.gif" width={45} />
        </div>
      ) : itemsSelling?.length ? (
        itemsSelling.map((item, index) => <ListedItem key={index} item={item} tokenPrice={tokenPrice} />)
      ) : (
        <p className="text-xl text-white py-10 text-center">No data</p>
      )}
    </BoxTab>
  )
}
