import React, { useState } from 'react'
import Card from 'components/Card/Card'
import { formatAddress } from 'utils/addressHelpers'
import { formatDDMMYYYYHHmm } from 'utils/formatDateTime'
import { formatNumber } from 'utils/formatBalance'
import { HISTORY_TYPE_BG_COLOR, KROMA_CHAIN_ID, TOKEN_USED_MARKETPLACE } from 'constants/index'
import Button from 'components/Button/Button'
import { ChevronDown } from 'react-feather'
import { getExplorerLink } from 'utils/explorer'
import Web3 from 'web3'
import { ChainId } from 'defily-v2-sdk'

const AMOUNT_ROWS = 5

const DragonHistories = ({ histories }) => {
  const [rows, setRows] = useState(AMOUNT_ROWS)

  const showMore = () => {
    setRows((prevState) => prevState + AMOUNT_ROWS)
  }
  if (!histories?.length) return null

  return (
    <div>
      <h2 className="text-white text-2xl font-bold py-2 pt-8">Histories</h2>
      <Card className="px-3 py-4 md:py-6 text-white sm:max-w-lg">
        <table className="w-full">
          <thead>
            <tr className="text-left">
              <th className="px-1">Address</th>
              <th className="px-1">Type</th>
              <th className="px-1">Detail</th>
            </tr>
          </thead>
          <tbody className="text-sm-md md:text-md text-white50">
            {histories?.map((item, index) => {
              if (index >= rows) return null
              return (
                <tr key={index}>
                  <td className="py-2 px-1">
                    <p>
                      <span className="text-sm block lg:inline text-white">From:</span>{' '}
                      {item?.from ? (
                        <a
                          href={getExplorerLink(KROMA_CHAIN_ID, Web3.utils.toChecksumAddress(item?.from), 'address')}
                          target="_blank"
                        >
                          {formatAddress(item?.from)}
                        </a>
                      ) : (
                        '--'
                      )}
                    </p>
                    {item?.to ? (
                      <p>
                        <span className="text-sm block lg:inline text-blue4">To:</span>{' '}
                        <a
                          href={getExplorerLink(KROMA_CHAIN_ID, Web3.utils.toChecksumAddress(item?.to), 'address')}
                          target="_blank"
                        >
                          {formatAddress(item?.to)}
                        </a>
                      </p>
                    ) : null}
                  </td>
                  <td className="py-2 px-1">
                    <div className="flex items-center w-full h-full">
                      <span
                        style={{
                          background: HISTORY_TYPE_BG_COLOR[item?.type],
                        }}
                        className="text-xs px-2.5 py-1 rounded-xl text-white"
                      >
                        {item?.type}
                      </span>
                    </div>
                  </td>
                  <td className="py-2 px-1">
                    {item?.price ? (
                      <p>
                        <span className="text-sm block lg:inline text-white">Price:</span>{' '}
                        <span className="text-orange85">
                          {formatNumber(Number(item.price), 0, 8)}{' '}
                          <span className="text-sm">{TOKEN_USED_MARKETPLACE.symbol}</span>
                        </span>
                      </p>
                    ) : null}
                    <p>{formatDDMMYYYYHHmm(item?.createdAt)}</p>
                    {item?.trxHash ? (
                      <a
                        className="underline text-sm text-blue4"
                        href={getExplorerLink(KROMA_CHAIN_ID, item?.trxHash, 'transaction')}
                        target="_blank"
                      >
                        View Tx hash
                      </a>
                    ) : null}
                  </td>
                </tr>
              )
            })}
            {histories?.length > rows ? (
              <tr>
                <td colSpan={999}>
                  <div className="flex justify-center pt-5">
                    <Button outline size="xs" className="px-8 text-sm-md h-8" onClick={showMore}>
                      View more ({histories?.length - rows})
                      <ChevronDown className="ml-2" />
                    </Button>
                  </div>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </Card>
    </div>
  )
}

export default DragonHistories
