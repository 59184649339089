import { useCallback } from 'react'
import useActiveWeb3React from './useActiveWeb3React'
import { useItemContract } from './useContract'
import { upgradeItem } from '../utils/calls/upgrade'

export const useUpgradeItem = (type, address) => {
  const { account } = useActiveWeb3React()
  const contract = useItemContract(type, address)

  const handleUpgradeItem = useCallback(
    async (upItem, usedItem) => {
      const txHash = await upgradeItem(contract, upItem, usedItem)
      return txHash
    },
    [account, type, address],
  )

  return { onUpgradeItem: handleUpgradeItem }
}
