import PropTypes from 'prop-types'
import classNames from 'classnames'
import Loader from 'components/Loader/Loader'

const ButtonGradient = ({
  children,
  onClick,
  className,
  disabled,
  isLoading,
  rounded = true,
  noneBorder,
  size = 'md',
  angle = 'to right',
  colors = ['#48CAE4', '#0068B4'],
  hoverColors = ['#6EE7B7', '#3B82F6'],
  ...props
}) => {
  return (
    <button
      {...props}
      disabled={disabled}
      className={classNames(
        'flex items-center justify-center border-white text-white font-bold transition-all',
        !noneBorder && 'border-2',
        rounded ? 'rounded-3xl' : 'rounded-xl',
        size === 'md' && 'py-1.5 px-6 sm:py-2 sm:px-7',
        size === 'sm' && 'py-1 px-3 sm:py-2 sm:px-7',
        disabled && 'opacity-50 cursor-default',
        !disabled && 'hover:opacity-80',
        className,
      )}
      style={{
        backgroundImage: `linear-gradient(${angle}, ${colors.join(',')})`,
        transition: 'background-image 0.3s ease',
      }}
      onClick={onClick}
    >
      {children} {isLoading && <Loader className="ml-2" size="sm" color="white" />}
    </button>
  )
}

ButtonGradient.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  outline: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  hoverColors: PropTypes.arrayOf(PropTypes.string),
}

export default ButtonGradient
