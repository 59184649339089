import React, { useMemo, useState } from 'react'
import { NavItem, TabContent, TabPane, Tabs } from '../Tabs/Tabs'
import {
  AUCTION_TYPE,
  BODY_PART_TYPE,
  cooldownSlelect,
  DRAGON_CLASS,
  DRAGON_STATS_BASE,
  DRAGON_TYPE,
} from '../../constants'
import CheckBox from '../Input/CheckBox'
import FilterFromTo from './FilterFromTo'
import { useDispatch } from 'react-redux'
import { useBodyParts, useBodyPartsData, useSearchData, useSearchDragon } from 'store/search/hook'
import { resetSearchDragons, setPageQuery, updateSearchDragons } from 'store/search'
import Dropdown from '../Dropdown/Dropdown'
import classnames from 'classnames'
import { useHistory } from 'react-router-dom'
import BodyParts from './BodyParts'
import FilterPrice from './FilterPrice'

const DragonAttribute = ({ className }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const searchData = useSearchData()
  const searchDragons = useSearchDragon()
  const bodyPartsData = useBodyPartsData()
  const bodyParts = useBodyParts()
  const [tab, setTab] = useState(1)
  const showType = !['/breed', '/train'].includes(history.location.pathname)
  const onChangeTab = (value) => {
    if (value !== tab) {
      setTab(value)
    }
  }

  const handleClear = () => {
    dispatch(resetSearchDragons())
  }

  const handleChangeCheckbox = (key, value, checked) => {
    const _currentValue = searchDragons?.[key] ? [...searchDragons?.[key]] : []
    if (checked) {
      // push value to array
      _currentValue.push(value)
    } else {
      // remove value to array
      const index = _currentValue.indexOf(value)
      if (index !== -1) {
        _currentValue.splice(index, 1)
      }
    }
    dispatch(updateSearchDragons({ [key]: _currentValue }))
    dispatch(setPageQuery(1))
  }

  const handleChangeFilterNumber = (key, value) => {
    if (searchDragons?.[key]?.from !== value?.from || searchDragons?.[key]?.to !== value?.to) {
      dispatch(updateSearchDragons({ [key]: value }))
      dispatch(setPageQuery(1))
    }
  }

  const countFilter = useMemo(() => {
    let count = 0
    count += searchDragons?.class?.length || 0
    count += searchDragons?.type?.length || 0
    if (searchDragons?.breadCount?.from || searchDragons?.breadCount?.to) {
      count += 1
    }
    if (searchDragons?.general?.from || searchDragons?.general?.to) {
      count += 1
    }
    if (searchDragons?.level?.from || searchDragons?.level?.to) {
      count += 1
    }

    if (
      (searchDragons?.price && !(searchDragons?.price?.from === 0 && searchDragons?.price?.to >= 100)) ||
      searchDragons?.price2?.from ||
      searchDragons?.price2?.to
    ) {
      count += 1
    }

    Object.values(DRAGON_STATS_BASE).map((item) => {
      if (searchDragons?.[item?.toLowerCase()]?.from || searchDragons?.[item?.toLowerCase()]?.to) {
        count += 1
      }
    })
    Object.keys(BODY_PART_TYPE).forEach((key) => {
      if (bodyParts?.[key]) {
        count += 1
      }
    })
    return count
  }, [searchDragons])

  return (
    <div className={className}>
      <div className="mb-4">
        <div className="flex justify-between items-center px-6 pt-4 mb-2">
          <h3 className="text-lg text-blue4">Filter ({countFilter})</h3>
          <p className="text-white underline text-sm cursor-pointer" onClick={handleClear}>
            Clear filter
          </p>
        </div>
        <div className="flex justify-center">
          <Tabs className="justify-center">
            <NavItem active={tab === 1} onClick={() => onChangeTab(1)} className="w-20 h-10">
              General
            </NavItem>
            <NavItem active={tab === 2} onClick={() => onChangeTab(2)} className="w-20 h-10">
              Stats
            </NavItem>
            <NavItem active={tab === 3} onClick={() => onChangeTab(3)} className="w-20 h-10">
              Body
            </NavItem>
          </Tabs>
        </div>
        <TabContent className="mt-4" activeTab={tab}>
          <TabPane tabId={1}>
            <div className="px-6 mb-2">
              <p className="text-blue4 mt-1.5">Class</p>
              <div className="grid grid-cols-2">
                {Object.values(DRAGON_CLASS).map((item, index) => (
                  <CheckBox
                    key={index}
                    checked={searchDragons?.class !== undefined && searchDragons?.class?.includes(item)}
                    id={`dragon-class-${item}`}
                    label={item.toLowerCase()}
                    className="capitalize"
                    onChange={(checked) => handleChangeCheckbox('class', item, checked)}
                  />
                ))}
              </div>
              {showType ? (
                <>
                  <p className="text-blue4 mt-1.5">Type</p>
                  <div className="grid grid-cols-2">
                    {Object.values(DRAGON_TYPE).map((item, index) => (
                      <CheckBox
                        key={index}
                        checked={searchDragons?.type !== undefined && searchDragons?.type?.includes(item)}
                        id={`dragon-type-${item}`}
                        label={item.toLowerCase()}
                        className="capitalize"
                        onChange={(checked) => handleChangeCheckbox('type', item, checked)}
                      />
                    ))}
                  </div>
                </>
              ) : null}
            </div>
            <hr className="border-blue2" />
            <div className="px-6 mb-2">
              <p className="text-blue4 text-sm-md mt-1.5 mb-1">Breed count</p>
              <Dropdown
                bsprefixmenu="p-0 text-sm-md sm:text-base mr-1 sm:mr-4"
                menu={searchDragons?.cooldown || cooldownSlelect[0]}
                className="flex-1 sm:flex-none mr-0"
                classNameMenu="border-blue2 border-2 rounded-xl h-10 min-w-8"
                classNameLabel="mr-8 sm:mr-4"
                classNameMenuItem={classnames('bg-primary text-white right-0 w-44 bottom-0 -right-10')}
                classNameLabelMenu="justify-between px-2"
                classNameButtonToggle={'inline text-blue4 px-4 sm:px-2.5 mr-0 cursor-pointer'}
              >
                {cooldownSlelect.map((item, index) => (
                  <p
                    key={index}
                    className="p-3 text-white cursor-pointer hover:bg-blue2"
                    onClick={() => handleChangeFilterNumber('cooldown', item)}
                  >
                    {item.label}
                  </p>
                ))}
              </Dropdown>
            </div>
            <hr className="border-blue2" />
            <FilterFromTo
              className="px-6"
              label="Generation"
              value={searchDragons?.generation}
              onChange={(value) => handleChangeFilterNumber('generation', value)}
            />
            <hr className="border-blue2" />
            <FilterFromTo
              className="px-6"
              label="Potential"
              value={searchDragons?.potential}
              onChange={(value) => handleChangeFilterNumber('potential', value)}
            />
            {searchData?.filterSale?.value !== AUCTION_TYPE.NOT_FOR_SALE ? (
              <>
                <hr className="border-blue2" />
                <FilterPrice searchDragons={searchDragons} handleChangeFilterNumber={handleChangeFilterNumber} />
              </>
            ) : null}
          </TabPane>
          <TabPane tabId={2}>
            <div>
              {Object.values(DRAGON_STATS_BASE).map((item, index) => (
                <div key={index}>
                  <FilterFromTo
                    className="px-6 capitalize"
                    label={item.toLowerCase()}
                    value={searchDragons?.[item.toLowerCase()]}
                    onChange={(value) => handleChangeFilterNumber(item.toLowerCase(), value)}
                  />
                  {index !== Object.values(DRAGON_STATS_BASE).length - 1 ? <hr className="border-blue2" /> : null}
                </div>
              ))}
            </div>
          </TabPane>
          <TabPane tabId={3}>
            <BodyParts bodyParts={bodyParts} bodyPartsData={bodyPartsData} />
          </TabPane>
        </TabContent>
      </div>
    </div>
  )
}

export default DragonAttribute
