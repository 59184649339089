import React from 'react'
import classnames from 'classnames'

const WrapperBackground = ({ className, bgImg, colorOverlay, children }) => {
  return (
    <div
      style={{
        backgroundImage: bgImg !== undefined ? `url(${bgImg})` : 'url(/images/bg-home-main.png)',
      }}
      className={classnames('bg-top bg-no-repeat bg-cover flex flex-col', className)}
    >
      <div
        style={{
          background: colorOverlay,
        }}
        className="w-full h-full flex-1"
      >
        {children}
      </div>
    </div>
  )
}

export default WrapperBackground
