import React from 'react'
import { Link } from 'react-router-dom'
import { cooldownLabel, MAX_DEFINE_COOLDOWN, TYPE_FILTER_RANKING } from 'constants/index'
import classnames from 'classnames'
import Dropdown from 'components/Dropdown/Dropdown'
import { useFetchTopOthers, useTopOthers, useTypeFilter } from 'store/report/hook'
import { useDispatch } from 'react-redux'
import { setTypeFilter } from 'store/report'

const TopOthers = () => {
  useFetchTopOthers()
  const data = useTopOthers()
  const typeFilter = useTypeFilter()
  const dispatch = useDispatch()

  return (
    <div>
      <h2 className="text-white text-2xl text-center mt-10 mb-2">
        Top {typeFilter?.label || TYPE_FILTER_RANKING[0].label}
      </h2>
      <div className="flex justify-end">
        <Dropdown
          bsprefixmenu="p-0 text-sm-md sm:text-base mr-1 sm:mr-4"
          menu={typeFilter || TYPE_FILTER_RANKING[0]}
          className="sm:ml-2.5"
          classNameMenu="border-blue2 border-2 rounded-xl h-10"
          classNameLabel="mr-8 sm:mr-4"
          classNameMenuItem={classnames('bg-primary text-white right-0 w-44')}
          classNameButtonToggle={'inline text-blue4 px-4 sm:px-2.5 mr-0 cursor-pointer'}
        >
          {TYPE_FILTER_RANKING.map((item, index) => (
            <p
              key={index}
              className="p-3 text-white cursor-pointer hover:bg-blue2"
              onClick={() => dispatch(setTypeFilter(item))}
            >
              {item.label}
            </p>
          ))}
        </Dropdown>
      </div>
      <div className="my-3 rounded-xl border-1 border-blue2 overflow-hidden">
        <div className="overflow-auto custom-scrollbar-scroll w-full">
          <table
            className="custom-table-base custom-table-base__non-border w-full border-0-important bg-blue1"
            style={{
              minHeight: 200,
            }}
          >
            <thead>
              <tr className="text-white bg-blue3">
                <th>No.</th>
                <th>Dracora</th>
                <th>Level</th>
                <th>Total stats</th>
                <th>{typeFilter?.label || TYPE_FILTER_RANKING[0].label}</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="text-white text-center text-md sm:text-base">
              {data?.length ? (
                <>
                  {data.map((row, index) => {
                    if (index >= 30) return null
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>Dracora #{row?.id}</td>
                        <td>{row?.level || 1}</td>
                        <td>{row?.totalStats?.total}</td>
                        <td>
                          {typeFilter?.isStats
                            ? row?.totalStats?.[typeFilter?.key]
                            : typeFilter?.value === 'cooldownIndex'
                            ? `${
                                cooldownLabel[
                                  row.cooldownIndex > MAX_DEFINE_COOLDOWN ? MAX_DEFINE_COOLDOWN : row.cooldownIndex
                                ]?.label
                              } (${
                                cooldownLabel[
                                  row.cooldownIndex > MAX_DEFINE_COOLDOWN ? MAX_DEFINE_COOLDOWN : row.cooldownIndex
                                ]?.duration
                              })`
                            : !typeFilter?.value || typeFilter?.value === TYPE_FILTER_RANKING[0].value
                            ? row?.potential + 1
                            : row?.[typeFilter?.value]}
                        </td>
                        <td>
                          <Link to={`/dragon/${row?.id}`}>
                            <span className="text-md text-blue4 hover:opacity-80">Detail</span>
                          </Link>
                        </td>
                      </tr>
                    )
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan={9999} className="text-white text-center">
                    Loading ...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default TopOthers
