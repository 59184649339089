import React, { useState, useEffect } from 'react'
import Modal from './ModalV2'
import ModalTitle from './ModalTitleV2'
import LoadingPage from '../Loader/LoadingPage'
import ListView from '../ListView'
import ItemCardV2 from '../Card/ItemCardV2'
import { setPageQuery } from 'store/search'
import { showToastError } from 'components/CustomToast/CustomToast'
import ItemsFilterAction from '../../views/Items/components/ItemsFilterAction'
import { useUpdateQueryFilterItemsWithoutURL } from 'store/search/hook'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useFetchItemsSameType, useMyItems } from 'store/items/hook'
import SkillCardV2 from 'components/Card/SkillCardV2'
import { useDispatch } from 'react-redux'
export default function ModalSelectItem({ open, toggle, onSelect, item }) {
  const { account } = useActiveWeb3React()
  const dispatch = useDispatch()
  useUpdateQueryFilterItemsWithoutURL()
  useFetchItemsSameType({
    type: item?.type,
    nftType: item?.equipment?.id || item?.skill?.id,
    level: `${item?.level}, ${item?.level}`,
    owner: account,
    exceptId: item?.nftId,
    status: 'ACTIVE',
  })
  const items = useMyItems()
  const onChangePage = async (page) => {
    try {
      dispatch(setPageQuery(page + 1))
    } catch (error) {
      showToastError('Error', 'Some error occurred, please try again!')
    }
  }

  return (
    <Modal open={open} onClose={toggle} isDark size="xl" className="w-full">
      <ModalTitle onClose={toggle}>Select {`${item?.type === 'SKILL_CARD' ? 'skill card' : 'equipment'}`}</ModalTitle>
      <ItemsFilterAction ignorePrice={true} ignoreFilterStatus={true} showFilterItemAttribute={false} />
      {items === undefined ? (
        <div>
          <LoadingPage />
        </div>
      ) : (
        <ListView
          className="min-height-list-save-modal max-w-screen-lg m-auto px-2.5 sm:px-5 mt-8 mb-5"
          pageCount={items?.totalPages}
          totalItems={items?.totalItems}
          currentPage={items?.currentPage}
          fetchApi={onChangePage}
        >
          <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-y-4 gap-x-5">
            {items?.data?.map((item) => {
              if (item?.type === 'EQUIPMENT') {
                return (
                  <ItemCardV2
                    key={item?.nftId}
                    item={item}
                    className="cursor-pointer"
                    onClick={() => onSelect(item?.nftId)}
                  />
                )
              } else {
                return (
                  <SkillCardV2
                    key={item?.nftId}
                    item={item}
                    className="cursor-pointer"
                    onClick={() => onSelect(item?.nftId)}
                  />
                )
              }
            })}
          </div>
        </ListView>
      )}
    </Modal>
  )
}
