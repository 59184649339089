/**
 * Handle get/set user token
 * @type {{get: (function()), set: (function(*=))}}
 */
export const USER_BOUGHT_EGG = {
  get: () => localStorage?.getItem('userBuyEgg'),
  set: (newValue) => {
    localStorage?.setItem('userBuyEgg', newValue)
  },
  delete: () => localStorage?.removeItem('userBuyEgg'),
}

export const ACTIVE_WALLET = {
  get: () => localStorage?.getItem('active'),
  set: (newValue) => {
    localStorage?.setItem('active', newValue)
  },
  delete: () => localStorage?.removeItem('active'),
}
