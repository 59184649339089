import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { getExplorerLink } from 'utils/explorer'
import { ArrowUpRight, Copy } from 'react-feather'
import { formatDDMMYYYYHHmm } from 'utils/formatDateTime'
import Loader from '../Loader/Loader'
import Tooltip from '../Tooltip/Tooltip'
import { ChainId } from 'defily-v2-sdk'
import { KROMA_CHAIN_ID, KROMA_SEPOLIA_CHAIN_ID } from 'constants/index'

const ItemNotify = ({ notify, ...rest }) => {
  return (
    <div
      {...rest}
      className="flex justify-between items-center m-1 pl-4 pr-3 py-2.5 rounded hover:bg-blue2 cursor-pointer"
    >
      <div className="flex-1">
        <p className="text-sm sm:text-sm-md">{notify?.message}</p>
        {notify?.date ? (
          <p className="text-white50 text-right text-xs sm:text-sm">{formatDDMMYYYYHHmm(notify?.date)}</p>
        ) : null}
      </div>
      {notify?.trxHash ? (
        <div className="">
          <Tooltip
            className="flex items-center cursor-pointer text-sm-md text-white50 hover:text-white"
            classNameToolTip="w-28 bg-black text-sm-md border-2 border-blue2 right-0"
            tooltip={<span className="text-sm">View Tx hash</span>}
          >
            <a
              className="ml-3"
              href={getExplorerLink(
                !process.env.REACT_APP_DEVELOP_ENVIRONMENT ? KROMA_SEPOLIA_CHAIN_ID : KROMA_CHAIN_ID,
                notify?.trxHash,
                'transaction',
              )}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
            >
              <span className="hover:bg-blue4">
                <ArrowUpRight />
              </span>
            </a>
          </Tooltip>
        </div>
      ) : null}
    </div>
  )
}

const ListNotification = ({ data, handleLoadMore, isBusy, onClickNotify }) => {
  return (
    <div className="pt-3">
      <h2 className="px-3 text-white pb-2">Notification</h2>
      <hr />
      {data === undefined ? (
        <div className="flex justify-center py-10">
          <img src="/icon/loading.gif" width={30} />
        </div>
      ) : (
        <>
          {data?.length ? (
            <PerfectScrollbar
              className="min-h-80 max-h-96"
              options={{ wheelPropagation: false }}
              onScrollDown={handleLoadMore}
            >
              {data.map((item, index) => (
                <ItemNotify key={index} notify={item} onClick={() => onClickNotify(item)} />
              ))}
              {isBusy ? (
                <div className="flex justify-center h-5">
                  <Loader className="ml-2" size="sm" color="white" />
                </div>
              ) : null}
            </PerfectScrollbar>
          ) : (
            <div className="py-5">
              <p className="text-white text-center text-sm">There are currently no notifications</p>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default ListNotification
