import React from 'react'
import Modal from 'components/Modal/ModalV2'
import Dots from 'components/Loader/Dots'

const ModalFighting = ({ open, toggle }) => {
  return (
    <Modal isDark open={open} onClose={toggle} className="bg-blue1 border-1 border-blue2">
      <div className="p-5 custom-effect-show-out">
        <div className="flex justify-center py-5">
          <img src="/icon/icon-fighting.png" className="px-5" />
        </div>
        <div>
          <h2 className="text-xl sm:text-3xl text-center text-white text-bold">Dracora is fighting Monster!</h2>
          <Dots className="text-center text-white text-sm sm:text-base">
            Confirm transaction and wait for the result
          </Dots>
        </div>
      </div>
    </Modal>
  )
}

export default ModalFighting
