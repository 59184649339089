import useActiveWeb3React from './useActiveWeb3React'
import { useMemo } from 'react'
import {
  getAuctionContract,
  getBirthContract,
  getCoreContract,
  getDragonCaveContract,
  getERC20Contract,
  getEventMonsterContract,
  getItemContract,
  getSaleClockAuctionContract,
  getSireContract,
  getTrainingContract,
  getMarketPlaceNFTContract,
  getTrainingExtendContract,
  getWearableItemContract,
} from '../utils/ethers/contractHelpers'

export function useTokenContract(tokenAddress) {
  const { library, chainId } = useActiveWeb3React()

  return useMemo(() => getERC20Contract(tokenAddress, chainId, library.getSigner()), [tokenAddress, chainId, library])
}

export function useAuctionContract(auctionContract) {
  const { library, chainId } = useActiveWeb3React()

  return useMemo(
    () => getAuctionContract(auctionContract, chainId, library.getSigner()),
    [auctionContract, chainId, library],
  )
}

export function useTrainingContract(trainingContract) {
  const { library, chainId } = useActiveWeb3React()

  return useMemo(
    () => getTrainingContract(trainingContract, chainId, library.getSigner()),
    [trainingContract, chainId, library],
  )
}

export function useBirthContract(birthContract) {
  const { library, chainId } = useActiveWeb3React()

  return useMemo(() => getBirthContract(birthContract, chainId, library.getSigner()), [birthContract, chainId, library])
}

export function useCoreContract(coreContract) {
  const { library, chainId } = useActiveWeb3React()

  return useMemo(() => getCoreContract(coreContract, chainId, library.getSigner()), [coreContract, chainId, library])
}

export function useDragonCaveContract(dragonCaveContract) {
  const { library, chainId } = useActiveWeb3React()

  return useMemo(
    () => getDragonCaveContract(dragonCaveContract, chainId, library.getSigner()),
    [dragonCaveContract, chainId, library],
  )
}

export function useSaleClockAuctionContract(contract) {
  const { library, chainId } = useActiveWeb3React()

  return useMemo(
    () => getSaleClockAuctionContract(contract, chainId, library.getSigner()),
    [contract, chainId, library],
  )
}

export function useSireContract(contract) {
  const { library, chainId } = useActiveWeb3React()

  return useMemo(() => getSireContract(contract, chainId, library.getSigner()), [contract, chainId, library])
}

export function useEventMonsterContract(contract) {
  const { library, chainId } = useActiveWeb3React()

  return useMemo(() => getEventMonsterContract(contract, chainId, library.getSigner()), [contract, chainId, library])
}

export function useItemContract(type, contract) {
  const { library, chainId } = useActiveWeb3React()
  return useMemo(
    () => getItemContract(contract, type, chainId, library.getSigner()),
    [contract, type, chainId, library],
  )
}

export function useMarketPlaceNFTContract(contract) {
  const { library, chainId } = useActiveWeb3React()
  return useMemo(() => getMarketPlaceNFTContract(contract, chainId, library.getSigner()), [contract, chainId, library])
}

export function useTrainingExtendContract(contract) {
  const { library, chainId } = useActiveWeb3React()
  return useMemo(() => getTrainingExtendContract(contract, chainId, library.getSigner()), [contract, chainId, library])
}

export function useWearableItemContract(contract) {
  const { library, chainId } = useActiveWeb3React()
  return useMemo(() => getWearableItemContract(contract, chainId, library.getSigner()), [contract, chainId, library])
}
