import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import classnames from 'classnames'
import { useModalWalletConnect } from 'store/modal/hook'
import ButtonGradient from '../Button/ButtonGradient'

export default function Web3Connect() {
  const { onToggleConnectModal } = useModalWalletConnect()
  const { error } = useWeb3React()

  return error ? (
    <div
      className={classnames(
        'flex items-center justify-center px-4 py-1.5 sm:py-2 font-semibold text-white border rounded-lg bg-opacity-80 border-red-500 bg-red-500 hover:bg-opacity-100',
        error instanceof UnsupportedChainIdError && 'cursor-pointer text-sm sm:text-md ml-1 sm:ml-2 rounded-3xl',
      )}
      onClick={onToggleConnectModal}
    >
      {error instanceof UnsupportedChainIdError ? 'Wrong network' : 'Error'}
    </div>
  ) : (
    <div className="flex flex-wrap justify-center items-center text-sm sm:text-md ml-1 sm:ml-2">
      <ButtonGradient size="sm" onClick={onToggleConnectModal} className="whitespace-nowrap">
        Connect wallet
      </ButtonGradient>
    </div>
  )
}
