import { fetchUserDataDragonCave } from './fetchDragonCave'
import dragonsApi from 'api/dragonsApi'
import { DEFAULT_LIMIT } from 'constants/index'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  dragons: undefined,
  userData: undefined,
}

const dragonsSlice = createSlice({
  name: 'dragons',
  initialState,
  reducers: {
    setDragons: (state, action) => {
      state.dragons = action.payload
    },
    setUserData: (state, action) => {
      state.userData = action.payload
    },
  },
})

export const fetchDragonsCaveAsync =
  (account, params = { rowPerPage: DEFAULT_LIMIT }) =>
  async (dispatch) => {
    try {
      const response = await dragonsApi.getMyDragons(account, params)
      dispatch(setDragons(response))
    } catch (error) {
      dispatch(setDragons(undefined))
    }
  }

export const fetchUserDataDragonCaveAsync = (account) => async (dispatch) => {
  try {
    const userData = await fetchUserDataDragonCave(account)
    dispatch(setUserData(userData))
  } catch (error) {}
}

export const { setDragons, setUserData } = dragonsSlice.actions
export default dragonsSlice.reducer
