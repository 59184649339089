import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import { NetworkContextName } from 'constants/index'
import { useEffect } from 'react'
import { ACTIVE_WALLET } from '../utils/storage'

export function useStorageActiveAccount() {
  // replace with address to impersonate
  const context = useWeb3ReactCore()
  const contextNetwork = useWeb3ReactCore(NetworkContextName)
  const account = context?.active ? context?.account : contextNetwork?.account

  useEffect(() => {
    ACTIVE_WALLET.set(account)
  }, [account])
}

export default useStorageActiveAccount
