import { useDispatch, useSelector } from 'react-redux'
import useRefresh from '../../hooks/useRefresh'
import { useEffect } from 'react'
import { fetchUserDataDragonCaveAsync } from './index'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'

export const useDragonCave = () => useSelector((state) => state.dragonCave.dragons)
export const useUserDataDragonCave = () => useSelector((state) => state.dragonCave.userData)

// export const useFetchDragonCave = () => {
//   const dispatch = useDispatch()
//   const { slowRefresh } = useRefresh()
//
//   useEffect(() => {
//     dispatch(fetchDragonsCaveAsync(DRAGON_CAVE_ADDRESS.address))
//   }, [dispatch, slowRefresh])
// }

export const useFetchUserDataDragonCave = () => {
  const { account } = useActiveWeb3React()
  const dispatch = useDispatch()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    if (account) {
      dispatch(fetchUserDataDragonCaveAsync(account))
    }
  }, [account, dispatch, fastRefresh])
}
