import React from 'react'
import DragonCard from 'components/Card/DragonCardV3'
import classnames from 'classnames'
import Button from 'components/Button/Button'
import { ChevronRight } from 'react-feather'
import { useHistory } from 'react-router-dom'

const ListMarketplace = ({ dragons, handleFavorites, tokenPrice, dflPrice }) => {
  const { push } = useHistory()

  const isLoading = !dragons?.data
  const hasData = Array.isArray(dragons?.data) && dragons.data.length > 0

  return (
    <div className="max-w-screen-lg m-auto px-2.5 sm:px-5 xl:px-0 mt-8 mb-16">
      {isLoading ? (
        <div className="flex justify-center py-10">
          <img src="/icon/loading.gif" width={45} />
        </div>
      ) : hasData ? (
        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 -mx-2 gap-y-4">
          {dragons.data.map((item, index) => {
            if (index > 7) return null
            return (
              <DragonCard
                key={index}
                dragon={item}
                handleFavorites={handleFavorites}
                tokenPrice={tokenPrice}
                isShop
                isLink
                className={classnames({
                  'hidden xl:block': index > 5,
                })}
                dflPrice={dflPrice}
                to={`/dragon/${item.id}`}
              />
            )
          })}
        </div>
      ) : (
        <div className="flex justify-center py-10">
          <p className="text-gray-500 text-lg">No data</p>
        </div>
      )}
      <div className="flex justify-center mt-5">
        <Button outline size="xs" className="px-8 text-sm-md h-8" onClick={() => push('/marketplace?sale=AUCTION')}>
          View more
          <ChevronRight className="ml-2" />
        </Button>
      </div>
    </div>
  )
}

export default ListMarketplace
