import Button from 'components/Button/Button'
import Modal from 'components/Modal/ModalV2'
import ModalTitle from 'components/Modal/ModalTitleV2'
import PropTypes from 'prop-types'
import ButtonGradient from '../Button/ButtonGradient'
import React, { useState } from 'react'
import { showToastError } from '../CustomToast/CustomToast'

const ModalConfirm = ({ open, toggle, title, content, onConfirm }) => {
  const [pendingTx, setPendingTx] = useState(false)
  const handleSubmit = async () => {
    try {
      setPendingTx(true)
      await onConfirm()
      setPendingTx(false)
    } catch (error) {
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
      setPendingTx(false)
    }
  }

  return (
    <Modal isDark open={open} onClose={toggle}>
      <ModalTitle onClose={toggle}>{title}</ModalTitle>
      <div className="p-4">
        <div className="text-center">
          <p className="text-white text-lg">{content}</p>
        </div>
        <div className="flex justify-center mt-4">
          <ButtonGradient
            noneBorder
            rounded={false}
            disabled={pendingTx}
            isLoading={pendingTx}
            className="mx-auto mx-1.5"
            onClick={handleSubmit}
          >
            Confirm
          </ButtonGradient>
          <Button color="secondary" className="mx-auto mx-1.5" onClick={toggle}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}

ModalConfirm.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  content: PropTypes.any,
}

export default ModalConfirm
