import React, { useState, useEffect, useMemo } from 'react'
import { NavItem, TabContent, TabPane, Tabs } from '../Tabs/Tabs'
import Dropdown from '../Dropdown/Dropdown'
import classnames from 'classnames'
import CheckBox from '../Input/CheckBox'
import FilterFromTo from './FilterFromTo'
import { useDispatch } from 'react-redux'
import FilterPrice from './FilterPrice'
import { useSearchData, useSearchItem } from 'store/search/hook'
import { resetSearchItems, setPageQuery, updateSearchItems } from 'store/search'
import {
  DRAGON_BODY_PART,
  DRAGON_STATS_BASE,
  EXPERIENCE_TYPES,
  RARITY_SKILL,
  RARITY_ITEM,
  DRAGON_CLASS,
} from '../../constants'
import { useParams } from 'react-router-dom'
export default function ItemAttribute({
  className,
  isResetWhenChangeTab = true,
  showSkillRarity, // show rarity of skill or not
  showEquipmentRarity, // show rarity of equipment or not
  ignoreLevel, // level show or not ( if tab === exp dont show level filter)
  ignoreRangePrice, // range filter price show or not
  ignoreBasicSkill = false, //basic check box skill show or not
  showDragonClass, //dragon class show or not
}) {
  const { type } = useParams()
  const dispatch = useDispatch()
  const searchData = useSearchData()
  const searchItems = useSearchItem()

  const [tab, setTab] = useState(1)

  useEffect(() => {
    setTab(1)
    if (isResetWhenChangeTab) {
      let timeOutID = setTimeout(() => dispatch(resetSearchItems(), 100))

      return () => clearTimeout(timeOutID)
    }
  }, [type])

  const onChangeTab = (value) => {
    if (value !== tab) {
      setTab(value)
    }
  }
  const handleClear = () => {
    dispatch(resetSearchItems())
  }

  const handleChangeFilterNumber = (key, value) => {
    if (searchItems?.[key]?.from !== value?.from || searchItems?.[key]?.to !== value?.to) {
      dispatch(updateSearchItems({ [key]: value }))
      dispatch(setPageQuery(1))
    }
  }

  const handleChangeCheckbox = (key, value, checked) => {
    const _currentValue = searchItems?.[key] ? [...searchItems?.[key]] : []
    if (checked) {
      // push value to array
      _currentValue.push(value)
    } else {
      // remove value to array
      const index = _currentValue.indexOf(value)
      if (index !== -1) {
        _currentValue.splice(index, 1)
      }
    }
    dispatch(updateSearchItems({ [key]: _currentValue }))
    dispatch(setPageQuery(1))
  }

  const countFilter = useMemo(() => {
    let count = 0
    count += searchItems?.stats?.length || 0
    count += searchItems?.exp?.length || 0
    count += searchItems?.part?.length || 0
    count += searchItems?.rarity?.length || 0
    count += searchItems?.class?.length || 0

    if (
      (searchItems?.price && !(searchItems?.price?.from === 0 && searchItems?.price?.to >= 100)) ||
      searchItems?.price2?.from ||
      searchItems?.price2?.to
    ) {
      count += 1
    }

    if (searchItems?.level?.from || searchItems?.level?.to) {
      count += 1
    }

    Object.values(DRAGON_STATS_BASE).map((item) => {
      if (searchItems?.[item?.toLowerCase()]?.from || searchItems?.[item?.toLowerCase()]?.to) {
        count += 1
      }
    })

    return count
  }, [searchItems])

  return (
    <div className={className}>
      <div className="mb-4">
        <div className="flex justify-between items-center px-6 pt-4 mb-2">
          <h3 className="text-lg text-blue4">Filter ({countFilter})</h3>
          <p className="text-white underline text-sm cursor-pointer" onClick={handleClear}>
            Clear filter
          </p>
        </div>

        {/* exp thi khong hien tab */}
        {type !== 'exp-card' ? (
          <div className="flex justify-center">
            <Tabs className="justify-center">
              <NavItem active={tab === 1} onClick={() => onChangeTab(1)} className="w-20 h-10">
                General
              </NavItem>

              <NavItem active={tab === 2} onClick={() => onChangeTab(2)} className="w-20 h-10">
                Body
              </NavItem>
            </Tabs>
          </div>
        ) : null}
        <TabContent className="mt-4" activeTab={tab}>
          <TabPane tabId={1}>
            {showDragonClass ? (
              <div className="px-6 mb-2">
                <p className="text-blue4 mt-1.5">Class</p>
                <div className="grid grid-cols-2 gap-y-3 gap-x-4 mt-2">
                  {Object.values(DRAGON_CLASS).map((item, index) => (
                    <CheckBox
                      key={index}
                      checked={searchItems?.class !== undefined && searchItems?.class?.includes(item)}
                      id={`skill-dragon-class-${item}`}
                      label={item.toLowerCase()}
                      className="capitalize"
                      onChange={(checked) => handleChangeCheckbox('class', item, checked)}
                    />
                  ))}
                </div>
              </div>
            ) : null}
            {type === 'skill-card' || showSkillRarity ? ( // show Ratiry when type in params is Skill card or prop is true
              <div className="px-6 mb-2">
                <p className="text-blue4 mt-1.5">Rarity</p>
                <div className="grid grid-cols-2 gap-y-3 mt-2">
                  {Object.values(RARITY_SKILL)
                    .filter((item) => (ignoreBasicSkill ? item?.label !== 'Basic' : true))
                    .map((item, index) => (
                      <CheckBox
                        key={index}
                        checked={searchItems?.rarity !== undefined && searchItems?.rarity?.includes(item?.value)}
                        id={`skill-rarity-${item?.label}`}
                        label={item?.label}
                        className="capitalize"
                        onChange={(checked) => handleChangeCheckbox('rarity', item?.value, checked)}
                      />
                    ))}
                </div>
              </div>
            ) : null}
            {type === 'equipment' || showEquipmentRarity ? (
              <div className="px-6 mb-2">
                <p className="text-blue4 mt-1.5">Rarity</p>
                <div className="grid grid-cols-2 gap-y-3 mt-2">
                  {Object.values(RARITY_ITEM).map((item, index) => (
                    <CheckBox
                      key={index}
                      checked={searchItems?.rarity !== undefined && searchItems?.rarity?.includes(item?.value)}
                      id={`item-rarity-${item?.label}`}
                      label={item?.label}
                      className="capitalize"
                      onChange={(checked) => handleChangeCheckbox('rarity', item?.value, checked)}
                    />
                  ))}
                </div>
              </div>
            ) : null}
            {type === 'exp-card' ? (
              <div className="px-6 mb-2">
                <p className="text-blue4 mt-1.5">Exp</p>
                <div className="grid grid-cols-2 gap-x-4 mt-2 gap-y-3">
                  {Object.values(EXPERIENCE_TYPES).map((item, index) => (
                    <CheckBox
                      key={index}
                      checked={searchItems?.exp !== undefined && searchItems?.exp?.includes(item)}
                      id={`item-exp-${item}`}
                      label={item}
                      className="capitalize"
                      onChange={(checked) => handleChangeCheckbox('exp', item, checked)}
                    />
                  ))}
                </div>
              </div>
            ) : (
              // hien filter level cho equipment va skill
              !ignoreLevel && (
                <FilterFromTo
                  className="px-6"
                  label="Level"
                  value={searchItems?.level}
                  onChange={(value) => handleChangeFilterNumber('level', value)}
                />
              )
            )}
            {!ignoreRangePrice && searchData?.filterSale?.value !== 'ACTIVE' ? (
              <>
                <hr className="border-blue2" />
                <FilterPrice searchDragons={searchItems} handleChangeFilterNumber={handleChangeFilterNumber} />
              </>
            ) : null}
          </TabPane>
          {/* <TabPane tabId={2}>
            <div>
              {Object.values(DRAGON_STATS_BASE).map((item, index) => (
                <div key={index}>
                  <FilterFromTo
                    className="px-6 capitalize"
                    label={item.toLowerCase()}
                    value={searchItems?.[item.toLowerCase()]}
                    onChange={(value) => handleChangeFilterNumber(item.toLowerCase(), value)}
                  />
                  {index !== Object.values(DRAGON_STATS_BASE).length - 1 ? <hr className="border-blue2" /> : null}
                </div>
              ))}
            </div>
          </TabPane> */}

          <TabPane tabId={2}>
            <div className=" px-6 mb-2">
              {Object.values(DRAGON_BODY_PART)
                .filter((part) =>
                  type === 'skill-card'
                    ? part?.display !== 'Body' && part?.display !== 'Eyes'
                    : part?.display !== 'Eyes' && part?.display !== 'Middle Horn' && part?.display !== 'Horns',
                )
                .map((item, index) => {
                  return (
                    <CheckBox
                      key={index}
                      checked={
                        searchItems?.part !== undefined &&
                        searchItems?.part?.includes(
                          item?.display === 'Middle Horn' ? 'middlehorns' : item?.display.toLowerCase(),
                        )
                      }
                      id={`item-part-${item?.display}`}
                      label={item?.display}
                      className="capitalize mb-5"
                      onChange={(checked) =>
                        handleChangeCheckbox(
                          'part',
                          item?.display === 'Middle Horn' ? 'middlehorns' : item?.display.toLowerCase(),
                          checked,
                        )
                      }
                    />
                  )
                })}
            </div>
          </TabPane>
        </TabContent>
      </div>
    </div>
  )
}
