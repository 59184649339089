import React from 'react'
import Modal from './ModalV2'
import ModalTitle from './ModalTitleV2'
import ButtonGradient from '../Button/ButtonGradient'
import Value from '../Value/Value'
import { DEFAULT_TOKEN_USED } from 'constants/index'
import tokens from 'constants/tokens'

const ModalEstimateBoosting = ({ open, toggle, dragon, requiredNextLevel, dragonBalance, onSubmit }) => {
  const tokenUsed = requiredNextLevel?.expRequired - (dragon?.xp || 0)

  return (
    <Modal isDark open={open} onClose={toggle}>
      <ModalTitle onClose={toggle}>Boost Level</ModalTitle>
      <div className="p-4">
        <div className="py-5 text-white text-center">
          You have to pay{' '}
          <Value
            className="inline font-bold"
            value={tokenUsed / 100}
            unit={` ${DEFAULT_TOKEN_USED.symbol}`}
            decimals={2}
          />{' '}
          to upgrade the dracora
        </div>
        <div className="py-2 text-white text-center text-sm-md">
          ({`Your balance: `}
          <Value
            className="font-bold inline"
            value={dragonBalance}
            unit={` ${DEFAULT_TOKEN_USED.symbol}`}
            decimals={2}
          />
          )
        </div>
        {typeof dragonBalance === 'number' && dragonBalance < tokenUsed / 100 ? (
          <div className="text-center mb-3">
            <p className="text-orange85 text-center text-sm-md">
              Your {DEFAULT_TOKEN_USED.symbol} balance is not enough
            </p>
            {/* <a
              className="underline text-white text-sm-md"
              href={`https://defily.io/#/swap?outputCurrency=${tokens.dragon.address}`}
              target="_blank"
            >
              Get DRACORA Token
            </a> */}
          </div>
        ) : null}
        <div className="flex justify-center">
          <ButtonGradient
            noneBorder
            disabled={dragonBalance < tokenUsed / 100}
            rounded={false}
            onClick={onSubmit}
            className="mx-1"
          >
            Confirm
          </ButtonGradient>
        </div>
      </div>
    </Modal>
  )
}

export default ModalEstimateBoosting
