import AccountDetails from 'components/AccountDetails/AccountDetails'
import Button from 'components/Button/Button'
import Modal from 'components/Modal/Modal'
import ModalTitle from 'components/Modal/ModalTitle'
import Option from 'components/Modal/ModalWallet/Option'
import PendingView from 'components/Modal/ModalWallet/PendingView'
import ModalWarningInstallKardiachain from 'components/Modal/ModalWarningInstallKardiachain'
import { NETWORK_LABEL } from 'constants/networks'
import { ChainId } from 'defily-v2-sdk'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import usePrevious from 'hooks/usePrevious'
import { useEffect, useState } from 'react'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { DEFAULT_NETWORK, injected, kai, SUPPORTED_NETWORKS } from 'connectors/index'

import { useModalWalletConnect } from 'store/modal/hook'
import { networkLocalStorageKey, SUPPORTED_WALLETS } from 'constants/index'
import { isMobile } from 'react-device-detect'
import ButtonGradient from '../../Button/ButtonGradient'

const WALLET_VIEWS = {
  OPTIONS: 'options',
  OPTIONS_SECONDARY: 'options_secondary',
  ACCOUNT: 'account',
  PENDING: 'pending',
}

export default function WalletModal() {
  // console.log({ ENSName })
  // important that these are destructed from the account-specific web3-react context
  const { active, account, connector, activate, error, deactivate } = useWeb3React()
  const { chainId } = useActiveWeb3React()

  const { isOpen, onToggleConnectModal } = useModalWalletConnect()

  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT)

  const [pendingWallet, setPendingWallet] = useState()

  const [pendingError, setPendingError] = useState()

  const [openWarning, setOpenWarning] = useState(false)

  const toggleWarning = () => setOpenWarning((prevState) => !prevState)

  const previousAccount = usePrevious(account)

  const handleDisconnect = () => {
    deactivate()
    setWalletView(WALLET_VIEWS.OPTIONS)
  }

  const handleChangeNetwork = async (key) => {
    // if (ChainId.KAI === key) {
    //   if (error?.name === 'NoBscProviderError') {
    //     return toggleWarning();
    //   }
    //   return activate(SUPPORTED_WALLETS.Kai.connector);
    // }

    const params = SUPPORTED_NETWORKS[key]

    const provider = window.ethereum
    if (provider) {
      provider?.request({
        method: 'wallet_addEthereumChain',
        params: [params],
      })

      activate(SUPPORTED_WALLETS.INJECTED.connector)
    }
  }

  // close on connection, when logged out before
  useEffect(() => {
    if (account && !previousAccount && isOpen) {
      onToggleConnectModal()
    }
  }, [account, previousAccount, isOpen, onToggleConnectModal])

  // always reset to account view
  useEffect(() => {
    if (isOpen) {
      setPendingError(false)
      setWalletView(WALLET_VIEWS.ACCOUNT)
    }
  }, [isOpen])

  // close modal when a connection is successful
  const activePrevious = usePrevious(active)
  const connectorPrevious = usePrevious(connector)

  useEffect(() => {
    if (isOpen && ((active && !activePrevious) || (connector && connector !== connectorPrevious && !error))) {
      setWalletView(WALLET_VIEWS.ACCOUNT)
    }
  }, [setWalletView, active, error, connector, isOpen, activePrevious, connectorPrevious])

  const tryActivation = async (connector) => {
    let name = ''
    let conn = typeof connector === 'function' ? await connector() : connector

    Object.keys(SUPPORTED_WALLETS).map((key) => {
      if (connector === SUPPORTED_WALLETS[key].connector) {
        return (name = SUPPORTED_WALLETS[key].name)
      }
      return true
    })

    setPendingWallet(conn) // set wallet for pending view
    setWalletView(WALLET_VIEWS.PENDING)

    conn &&
      activate(conn, undefined, true).catch(async (error) => {
        if (error instanceof UnsupportedChainIdError) {
          activate(conn)
        } else {
          setPendingError(true)
        }
      })
  }

  // get wallets user can switch too, depending on device/browser
  function getOptions() {
    const isMetamask = window.ethereum && window.ethereum.isMetaMask
    return Object.keys(SUPPORTED_WALLETS).map((key) => {
      const option = SUPPORTED_WALLETS[key]

      // check for mobile options
      if (isMobile) {
        return (
          <Option
            onClick={() => {
              option.connector !== connector && !option.href && tryActivation(option.connector)
            }}
            id={`connect-${key}`}
            key={key}
            active={option.connector && option.connector === connector}
            color={option.color}
            link={option.href}
            header={option.name}
            subheader={null}
            icon={'/images/wallets/' + option.iconName}
          />
        )
      }

      // overwrite injected when needed
      if (option.connector === injected) {
        // don't show injected if there's no injected provider
        if (!(window.web3 || window.ethereum)) {
          if (option.name === 'MetaMask') {
            return (
              <Option
                id={`connect-${key}`}
                key={key}
                color={'#E8831D'}
                header={'Install Metamask'}
                subheader={null}
                link={'https://metamask.io/'}
                icon="/images/wallets/metamask.png"
              />
            )
          } else {
            return null // dont want to return install twice
          }
        }
        // don't return metamask if injected provider isn't metamask
        else if (option.name === 'MetaMask' && !isMetamask) {
          return null
        }
        // likewise for generic
        else if (option.name === 'Injected' && isMetamask) {
          return null
        }
      }

      // overwrite injected when needed
      if (option.connector === kai) {
        // don't show injected if there's no injected provider
        if (!window.kardiachain) {
          if (option.name === 'Kardiachain') {
            return (
              <Option
                id={`connect-${key}`}
                key={key}
                color={'#E8831D'}
                header={'Install Kardiachain Wallet'}
                subheader={null}
                link={'https://chrome.google.com/webstore/search/kardiachain%20wallet'}
                icon="/images/wallets/kai.png"
              />
            )
          } else {
            return null // dont want to return install twice
          }
        }
      }

      // return rest of options
      return (
        !isMobile &&
        !option.mobileOnly && (
          <Option
            id={`connect-${key}`}
            onClick={() => {
              option.connector === connector
                ? setWalletView(WALLET_VIEWS.ACCOUNT)
                : !option.href && tryActivation(option.connector)
            }}
            key={key}
            active={option.connector === connector}
            color={option.color}
            link={option.href}
            header={option.name}
            subheader={null} // use option.descriptio to bring back multi-line
            icon={'/images/wallets/' + option.iconName}
          />
        )
      )
    })
  }

  function getModalContent() {
    const networkUserSelected = window.localStorage.getItem(networkLocalStorageKey)
      ? +window.localStorage.getItem(networkLocalStorageKey)
      : DEFAULT_NETWORK

    if (error) {
      return (
        <div>
          <ModalTitle onClose={onToggleConnectModal}>
            {error instanceof UnsupportedChainIdError ? 'Wrong Network' : 'Error connecting'}
          </ModalTitle>
          <div>
            {error instanceof UnsupportedChainIdError ? (
              <h5>Please connect to the appropriate {NETWORK_LABEL[networkUserSelected]} network.</h5>
            ) : error?.message === 'Invariant failed: Invalid address undefined' ? (
              'Please login your wallet'
            ) : (
              'Error connecting. Try refreshing the page.'
            )}
            {error instanceof UnsupportedChainIdError && (
              <ButtonGradient onClick={() => handleChangeNetwork(networkUserSelected)} className="mx-auto my-1">
                Switch {NETWORK_LABEL[networkUserSelected]} network
              </ButtonGradient>
            )}
            <ButtonGradient className="mx-auto" onClick={handleDisconnect}>
              Disconnect
            </ButtonGradient>
          </div>
        </div>
      )
    }

    if (account && walletView === WALLET_VIEWS.ACCOUNT) {
      return (
        <AccountDetails
          toggleWalletModal={onToggleConnectModal}
          openOptions={() => setWalletView(WALLET_VIEWS.OPTIONS)}
        />
      )
    }

    return (
      <>
        <ModalTitle onClose={onToggleConnectModal}>Select a Wallet</ModalTitle>
        <div className="flex flex-col space-y-6">
          {walletView === WALLET_VIEWS.PENDING ? (
            <PendingView
              connector={pendingWallet}
              error={pendingError}
              setPendingError={setPendingError}
              tryActivation={tryActivation}
            />
          ) : (
            <div className="flex flex-col space-y-5 overflow-y-auto">{getOptions()}</div>
          )}
          {/*{walletView !== WALLET_VIEWS.PENDING && (*/}
          {/*    <div className="flex flex-col text-center">*/}
          {/*      <div className="text-gray-500">New to Ethereum?</div>*/}
          {/*      <a*/}
          {/*          className="text-primary"*/}
          {/*          href="https://ethereum.org/wallets/">*/}
          {/*        Learn more about wallets*/}
          {/*      </a>*/}
          {/*    </div>*/}
          {/*)}*/}
        </div>
      </>
    )
  }

  return (
    <>
      <ModalWarningInstallKardiachain open={openWarning} toggleModal={toggleWarning} />
      <Modal
        size={account && walletView === WALLET_VIEWS.ACCOUNT ? '' : 'sm'}
        open={isOpen}
        onClose={onToggleConnectModal}
      >
        {getModalContent()}
      </Modal>
    </>
  )
}
