import { createSlice } from '@reduxjs/toolkit'
import dragonsApi from '../../api/dragonsApi'

const initialState = {
  dragons: {
    class: undefined,
    type: undefined,
    stats: undefined,
    generation: {
      from: '',
      to: '',
    },
    potential: {
      from: '',
      to: '',
    },
    attack: {
      from: '',
      to: '',
    },
    mana: {
      from: '',
      to: '',
    },
    defend: {
      from: '',
      to: '',
    },
    health: {
      from: '',
      to: '',
    },
    morale: {
      from: '',
      to: '',
    },
    speed: {
      from: '',
      to: '',
    },
  },
  items: {
    class: undefined,
    stats: undefined,
    exp: undefined,
    rarity: undefined,
    part: undefined,
    level: {
      from: '',
      to: '',
    },
    attack: {
      from: '',
      to: '',
    },
    mana: {
      from: '',
      to: '',
    },
    defend: {
      from: '',
      to: '',
    },
    health: {
      from: '',
      to: '',
    },
    morale: {
      from: '',
      to: '',
    },
    speed: {
      from: '',
      to: '',
    },
  },
  data: {
    sort: undefined,
    filterSale: undefined,
    textSearch: undefined,
  },
  pageQuery: 1,
  query: {},
  bodyParts: {},
  bodyPartsData: undefined,
  canUpdateQuery: false,
  pathName: undefined,
}

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    updateSearchDragons: (state, action) => {
      state.dragons = {
        ...state.dragons,
        ...action.payload,
        hasFilter: true,
      }
    },
    updateSearchItems: (state, action) => {
      state.items = {
        ...state.items,
        ...action.payload,
      }
    },
    resetSearchDragons: (state) => {
      state.dragons = {
        class: undefined,
        type: undefined,
        stats: undefined,
      }
      state.bodyParts = {}
    },
    resetSearchItems: (state) => {
      state.items = {
        stats: undefined,
        exp: undefined,
        part: undefined,
        rarity: undefined,
        class: undefined,
      }
    },
    updateSearchData: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      }
    },
    setQuery: (state, action) => {
      state.query = action.payload
    },
    updateQuery: (state, action) => {
      state.query = {
        ...state.query,
        ...action.payload,
      }
    },
    setPageQuery: (state, action) => {
      state.pageQuery = action.payload
    },
    setCanUpdateQuery: (state, action) => {
      state.canUpdateQuery = action.payload
    },
    updateBodyParts: (state, action) => {
      state.bodyParts = {
        ...state.bodyParts,
        ...action.payload,
      }
    },
    setBodyPartsData: (state, action) => {
      state.bodyPartsData = action.payload
    },
    setPathName: (state, action) => {
      state.pathName = action.payload
    },
    resetSearchData: (state) => ({ ...initialState, bodyPartsData: state.bodyPartsData }),
  },
})

export const fetchBodyPartsDataAsync = () => async (dispatch) => {
  try {
    const response = await dragonsApi.getBodyParts()
    dispatch(setBodyPartsData(response))
  } catch (error) {}
}

export const {
  updateSearchDragons,
  resetSearchDragons,
  updateSearchData,
  setQuery,
  resetSearchData,
  updateQuery,
  setPageQuery,
  setCanUpdateQuery,
  setBodyPartsData,
  updateBodyParts,
  setPathName,
  updateSearchItems,
  resetSearchItems,
} = searchSlice.actions

export default searchSlice.reducer
