import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import Card from 'components/Card/Card'
import ItemHistories from '../ItemHistories'
import Value from 'components/Value/Value'
import ItemAction from '../ItemAction'
import { DRAGON_BODY_PART } from 'constants/index'
import { SKILL_EFFECT, RARITY_SKILL } from 'constants/index'
import { useHistory } from 'react-router-dom'

export default function SkillDetailRight({ item }) {
  const bodyPart = item?.skill?.part?.toLowerCase()
  const effectKey = item?.skill?.effect?.key
  const history = useHistory()
  return (
    <div className="pt-16 md:mx-0 mx-auto px-4">
      <ItemAction item={item} />
      {item?.status !== 'BURNED' ? (
        <>
          <h1 className="mt-8 text-2xl font-bold text-white mb-4">About</h1>
          <Card className="px-4 md:px-8 py-4 md:py-8 text-white sm:max-w-lg">
            <p className="font-bold text-lg text-white50">Owner</p>
            <p className="text-sm sm:text-sm-md text-white50 my-2 break-words">(Kardia: {item?.owner})</p>
            {item?.dragon && (
              <div
                className="font-bold text-blue4 cursor-pointer"
                onClick={() => history.push(`/dragon/${item?.dragon}`)}
              >
                Dragon: #{item?.dragon}
              </div>
            )}
          </Card>
        </>
      ) : null}

      <h1 className="mt-8 text-2xl font-bold text-white mb-4">Description</h1>
      <Card className="px-4 md:px-8 py-4 md:py-8 text-white sm:max-w-lg">
        <div className="flex items-center mb-5">
          <div className="w-10 h-10 flex justify-center items-center">
            <img src={DRAGON_BODY_PART[bodyPart]?.image} className="max-w-full max-h-full" />
          </div>
          <p className="ml-4">
            <span className="font-bold text-lg mr-3">{DRAGON_BODY_PART[bodyPart]?.display}</span>
            <span className="text-white50">(Body Part Uses)</span>
          </p>
        </div>
        <div className="flex items-center">
          <img src={SKILL_EFFECT?.[effectKey]?.icon} height={38} width={38} />
          <p className="ml-2">{item?.skill?.effect?.description}</p>
        </div>
        <div className="flex flex-start mt-4 flex-wrap">
          <div className="flex flex-col mr-8 sm:mb-0 mb-5">
            <p className="text-white50 font-bold">Attack</p>
            <div className="flex items-center mt-2">
              <img src="/icon/attack.svg" alt="icon" width={20} height={20} />
              <div className="text-xl ml-2 flex">
                {typeof item?.skill?.attack === 'number' ? <Value value={item?.skill?.attack} decimals={0} /> : '--'}%
              </div>
            </div>
          </div>
          <div className="flex flex-col mr-8 sm:mb-0 mb-5">
            <p className="text-white50 font-bold">Defend</p>
            <div className="flex items-center mt-2">
              <img src="/icon/defend.svg" alt="icon" width={20} height={20} />
              <div className="text-xl ml-2 flex">
                {typeof item?.skill?.defend === 'number' ? <Value value={item?.skill?.defend} decimals={0} /> : '--'}%
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <p className="text-white50 font-bold">Rarity</p>
            <p
              style={{
                color: RARITY_SKILL?.[item?.skill?.rarity]?.textColor,
              }}
              className="text-xl mt-2 font-bold"
            >
              {RARITY_SKILL?.[item?.skill?.rarity]?.label}
            </p>
          </div>
        </div>
      </Card>

      {item?.histories && <ItemHistories histories={item?.histories?.data} />}
    </div>
  )
}
