import React, { useState } from 'react'
import { useFetchNotifications, useNotifications, useNumberNotify } from 'store/notification/hook'
import { Bell } from 'react-feather'
import classnames from 'classnames'
import Popover from '../Popover'
import ListNotification from './ListNotification'
import { useDispatch } from 'react-redux'
import { fetchNotificationAsync, fetchNumberNotifyAsync } from 'store/notification'
import { useHistory } from 'react-router-dom'
import notificationApi from 'api/notificationApi'
import { DEFAULT_LIMIT } from '../../constants'

export const NFT_PATH = {
  DRAGON: 'dragon',
  SKILL_CARD: 'items/skill-card',
  EQUIPMENT: 'items/equipment',
  EXP_CARD: 'items/exp-card',
}

const Notification = () => {
  useFetchNotifications()
  const notifications = useNotifications()
  const numberNotify = useNumberNotify()
  const dispatch = useDispatch()
  const { push } = useHistory()
  const [isBusy, setBusy] = useState(false)
  const [preventLoadMore, setPreventLoadMore] = useState(false)
  const [forceClose, setForceClose] = useState(undefined)
  const handleRefreshNumber = async () => {
    try {
      if (!numberNotify) return
      await notificationApi.resetNumberNotify()
      dispatch(fetchNumberNotifyAsync())
    } catch (error) {
      console.log(error)
    }
  }

  const IconNotify = (
    <div className="text-white mx-2.5 relative cursor-pointer" onClick={handleRefreshNumber}>
      <Bell />
      {numberNotify && typeof numberNotify === 'number' ? (
        <span
          className={classnames(
            'absolute -top-2.5 -right-1  text-xs px-1 bg-blue4',
            {
              'px-1.5 rounded-md': numberNotify < 10,
            },
            {
              'px-1 rounded-md': numberNotify >= 10,
            },
          )}
        >
          {numberNotify < 10 ? numberNotify : '9+'}
        </span>
      ) : null}
    </div>
  )

  const handleLoadMore = async () => {
    try {
      if (isBusy || preventLoadMore || !Array.isArray(notifications)) return
      setBusy(true)
      const response = await dispatch(
        fetchNotificationAsync({ lastId: notifications?.[notifications?.length - 1]?._id }),
      )
      if (!response?.length || response?.length < DEFAULT_LIMIT) {
        setPreventLoadMore(true)
      }
      setBusy(false)
    } catch (error) {
      setBusy(false)
      console.log(error)
    }
  }

  const onClickNotify = (notify) => {
    setForceClose(Date.now())
    push(`/${NFT_PATH[notify?.nftType] || NFT_PATH.DRAGON}/${notify?.id}`)
  }

  return (
    <>
      <Popover
        bsprefixmenu="p-0 text-sm-md sm:text-base mr-1 sm:mr-4"
        classNameMenuItem="bg-primary text-white right-0 w-80 custom-shadow-box"
        buttonChild={IconNotify}
        forceClose={forceClose}
      >
        <ListNotification
          data={notifications}
          isBusy={isBusy}
          onClickNotify={onClickNotify}
          handleLoadMore={handleLoadMore}
        />
      </Popover>
    </>
  )
}

export default Notification
