import Card from 'components/Card/Card'
import ItemHistories from '../ItemHistories'
import Value from 'components/Value/Value'
import ItemAction from '../ItemAction'

export default function ExpDetailRight({ item }) {
  return (
    <div className="pt-16 md:mx-0 mx-auto px-4">
      <ItemAction item={item} />

      {item?.owner && item?.status !== 'USED' && (
        <>
          <h1 className="mt-8 text-2xl font-bold text-white mb-4">About</h1>
          <Card className="px-4 md:px-8 py-4 md:py-8 text-white sm:max-w-lg">
            <p className="font-bold text-lg text-white50">Owner</p>
            <p className="text-sm sm:text-sm-md text-white50 mt-2 break-words">(Kardia: {item?.owner})</p>
          </Card>
        </>
      )}

      <h1 className="mt-8 text-2xl font-bold text-white mb-4">Description</h1>
      <Card className="px-4 md:px-8 py-4 md:py-8 text-white sm:max-w-lg">
        <p className="text-md">Increases your dracora experience</p>
        <div className="flex items-center mt-4">
          <p className="text-blue4 font-bold text-xl">EXP</p>
          <div className="text-xl ml-2 flex items-center">
            <span>+</span>

            {typeof item?.exp === 'number' ? <Value className="" value={item?.exp} decimals={0} /> : '--'}
          </div>
        </div>
      </Card>

      {item?.histories && <ItemHistories histories={item?.histories?.data} />}
    </div>
  )
}
