import { useCallback } from 'react'
import useActiveWeb3React from './useActiveWeb3React'
import { useTrainingContract, useTrainingExtendContract, useWearableItemContract } from './useContract'
import {
  bootingDragon,
  trainingDragon,
  buffExpDragon,
  dragonUseItem,
  dragonCancelEquipment,
} from '../utils/calls/train'

export const useTrain = (trainingAddress) => {
  const { account } = useActiveWeb3React()
  const contract = useTrainingContract(trainingAddress)
  const handleTrain = useCallback(
    async (idToken) => {
      const txHash = await trainingDragon(contract, idToken)
      return txHash
    },
    [trainingAddress, account],
  )

  return { onTrain: handleTrain }
}

export const useBootDragon = (trainingAddress) => {
  const { account } = useActiveWeb3React()
  const contract = useTrainingContract(trainingAddress)
  const handleBooting = useCallback(
    async (idToken) => {
      const txHash = await bootingDragon(contract, idToken)
      return txHash
    },
    [trainingAddress, account],
  )

  return { onBoot: handleBooting }
}

export const useBuffExpDragon = (trainingAddress) => {
  const { account } = useActiveWeb3React()
  const contract = useTrainingExtendContract(trainingAddress)

  const handleBuffExp = useCallback(
    async (dragonId, itemId) => {
      const txHash = await buffExpDragon(contract, dragonId, itemId)
      return txHash
    },
    [trainingAddress, account],
  )

  return { onUseExpItem: handleBuffExp }
}

export const useItemDragon = (wearableAddress) => {
  const { account } = useActiveWeb3React()
  const contract = useWearableItemContract(wearableAddress)

  const handleDragonUseItem = useCallback(
    async (itemId, dragonId) => {
      const txHash = await dragonUseItem(contract, itemId, dragonId)
      return txHash
    },
    [wearableAddress, account],
  )

  return { onUseItem: handleDragonUseItem }
}

export const useCancelUseEquipmentDragon = (wearableAddress) => {
  const { account } = useActiveWeb3React()
  const contract = useWearableItemContract(wearableAddress)

  const handleDragonCancelUseEquipment = useCallback(
    async (itemPosition, dragonId) => {
      const txHash = await dragonCancelEquipment(contract, itemPosition, dragonId)
      return txHash
    },
    [wearableAddress, account],
  )

  return { onCancelUseEquipment: handleDragonCancelUseEquipment }
}
