import React from 'react'
import Badge from 'components/Badge/Badge'
import { ITEM_STATUS_BG_COLOR, ITEM_STATUS } from 'constants/index'
import ButtonRefresh from '../ButtonRefresh'

export default function ViewExp({ item, updateData }) {
  const backgroundExp =
    item?.exp < 50
      ? '/images/bg_exp/exp_white.png'
      : item?.exp > 75
      ? '/images/bg_exp/exp_purple.png'
      : '/images/bg_exp/exp_blue.png'

  return (
    <div className="px-2">
      <div className="flex mb-3">
        <div>
          <Badge className="text-white px-4 text-sm-md mr-4 bg-orange50">#{item?.nftId}</Badge>
        </div>
        {item?.status !== 'ACTIVE' ? (
          <div>
            <Badge
              style={{
                backgroundImage: `linear-gradient(to right, ${
                  ITEM_STATUS_BG_COLOR[item?.status?.toUpperCase()]?.from
                },${ITEM_STATUS_BG_COLOR[item?.status?.toUpperCase()]?.to})`,
              }}
              className="text-white px-4 text-sm-md mr-4"
            >
              {ITEM_STATUS?.[item?.status?.toUpperCase()]}
            </Badge>
          </div>
        ) : null}
      </div>
      <p className="text-lg font-bold opacity-50">Level {item?.level + 1}</p>
      <p className="flex items-center text-2xl mt-4 mb-2">
        <span className="font-bold mr-2">EXP</span>
        <span className="font-normal">#{item?.nftId}</span>
      </p>
      <ButtonRefresh item={item} updateData={updateData} />
      <div className="w-full md:w-80 mt-28 md:ml-10 flex md:justify-end justify-center">
        <div className="h-40 object-contain relative">
          <img src={backgroundExp} alt="item" className="relative sm:max-w-full max-w-1/2 h-full z-10" />
          <img src="/images/floor-shadow-1.png" alt="background" className="absolute top-24 z-0" />
          <p className="whitespace-nowrap absolute text-xl opacity-50 text-white bottom-7 left-1/2 transform -translate-x-1/2 z-20">
            +{item?.exp} exp
          </p>
        </div>
      </div>
    </div>
  )
}
