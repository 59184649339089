import React, { useState } from 'react'
import Modal from './ModalV2'
import ModalTitle from './ModalTitleV2'
import { useDragon } from 'store/dragons/hook'
import { DEFAULT_EGG_CLASS, FEE_SELL_AND_SIRE, TOKEN_USED_MARKETPLACE } from 'constants/index'
import Input from '../Input/Input'
import Badge from '../Badge/Badge'
import classnames from 'classnames'
import ButtonGradient from '../Button/ButtonGradient'
import { showToastError } from '../CustomToast/CustomToast'
import { useKaiPrice } from '../../hooks/usePrice'
import { formatNumber } from '../../utils/formatBalance'
import BigNumber from 'bignumber.js'

const ModalSellAndSire = ({ open, toggle, isSire, onSubmit }) => {
  const dragon = useDragon()
  const kaiPrice = useKaiPrice()
  const { id, image } = dragon
  const [value, setValue] = useState('')
  const [pendingTx, setPendingTx] = useState(false)
  const isEgg = dragon?.type === 'EGG'
  const priceUsd = value
    ? formatNumber((new BigNumber(value).times(kaiPrice).toNumber() * (100 - FEE_SELL_AND_SIRE)) / 100)
    : 0

  const onConfirm = async () => {
    try {
      setPendingTx(true)
      await onSubmit(value)
      setPendingTx(false)
    } catch (error) {
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
      setPendingTx(false)
    }
  }

  return (
    <Modal isDark open={open} onClose={toggle}>
      <ModalTitle isCenter onClose={toggle}>
        {isSire ? 'Sire' : 'Sell'}
      </ModalTitle>
      <div className="p-4">
        <div className="grid sm:grid-cols-2">
          <div className="mx-5 flex justify-center">
            <div className="rounded-lg border-2 overflow-hidden h-full border-blue2 bg-blue1 p-3 pb-6 text-white w-60 sm:w-full">
              <div className="flex flex-row justify-between">
                <Badge
                  className={classnames(`bg-${dragon?.class?.toLowerCase()}`, 'text-white px-4 text-sm pt-0.5 pb-0.5')}
                >
                  #{id}
                </Badge>
              </div>
              <div className="flex items-center mt-2">
                <Badge className={classnames(`bg-${dragon?.class?.toLowerCase()}`, 'px-1 py-1')} />
                <span className="pl-2 capitalize text-sm-md">{dragon?.class?.toLowerCase()}</span>
              </div>
              <p className="opacity-50 text-sm-md">Level: {dragon?.level || 1}</p>
              <div
                className={classnames('flex justify-center h-36 py-3', {
                  'py-6': isEgg,
                })}
              >
                <img src={isEgg ? DEFAULT_EGG_CLASS[dragon?.class] : image?.root} className="h-full" />
              </div>
            </div>
          </div>
          <div className="text-white mt-3 px-5">
            <h3>Price</h3>
            <div className="flex items-center">
              <div className="mt-1">
                <Input
                  className="bg-transparent text-blue4 text-right custom-input-number custom-input-number-2 w-32"
                  type="number"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
              <p className="text-blue4 ml-3">{TOKEN_USED_MARKETPLACE.symbol}</p>
            </div>
            <p className="opacity-50 text-sm mt-1">
              ~${priceUsd} - after subtracting a {FEE_SELL_AND_SIRE}% fee
            </p>
            <p className="text-sm mt-5">
              {!isSire
                ? 'Your dragon will be listed on the marketplace with this price. You can cancel the sale anytime.'
                : 'This is the price you will accept for someone to breed with your dragon. You can cancel anytime.'}
            </p>
            <ButtonGradient
              className="mt-3"
              disabled={pendingTx || Number(value) <= 0}
              isLoading={pendingTx}
              onClick={onConfirm}
            >
              {isSire ? 'Sire' : 'Sell'} Now
            </ButtonGradient>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default ModalSellAndSire
