import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { formatQueryDragons, formatQueryItems } from './helpers'
import {
  fetchBodyPartsDataAsync,
  resetSearchData,
  setCanUpdateQuery,
  setPageQuery,
  setPathName,
  setQuery,
  updateBodyParts,
  updateSearchData,
  updateSearchDragons,
  updateSearchItems,
} from './index'
import { useQuery } from 'utils'
import {
  BODY_PART_TYPE,
  cooldownSlelect,
  FILTER_DRAGON_FOR_SALE,
  FILTER_ITEM_STATUS,
  MAX_NUMBER_QUERY,
  SORT_DRAGON_FIELD,
  SORT_ITEM_FIELD,
} from 'constants/index'
import useDebounce from 'hooks/useDebounce'
import useRefresh from '../../hooks/useRefresh'
import { useHistory } from 'react-router-dom'

export const useSearchDragon = () => useSelector((state) => state.search.dragons)

export const useSearchData = () => useSelector((state) => state.search.data)

export const useSearch = () => useSelector((state) => state.search)

export const useQuerySearch = () => useSelector((state) => state.search.query)

export const useCanUpdateQuery = () => useSelector((state) => state.search.canUpdateQuery)

export const useBodyPartsData = () => useSelector((state) => state.search.bodyPartsData)

export const useBodyParts = () => useSelector((state) => state.search.bodyParts)

export const usePathStoreQuery = () => useSelector((state) => state.search.pathName)

export const useSearchItem = () => useSelector((state) => state.search.items)

export const usePageQuery = () => useSelector((state) => state.search.pageQuery)

export const useUpdateQuery = () => {
  const dispatch = useDispatch()
  const searchData = useSearch()
  const searchStr = JSON.stringify(searchData)
  const canUpdateQuery = useCanUpdateQuery()
  const { location } = useHistory()
  useEffect(() => {
    if (canUpdateQuery) {
      const parseQuery = formatQueryDragons(searchStr)
      dispatch(setQuery(parseQuery))
    }
  }, [searchStr, canUpdateQuery])
  useEffect(() => {
    setTimeout(() => {
      dispatch(setPathName(location.pathname))
    }, 1000)
    return () => {
      dispatch(resetSearchData())
    }
  }, [])
}

export const useUpdateQueryItems = () => {
  const dispatch = useDispatch()
  const searchData = useSearch()
  const searchStr = JSON.stringify(searchData)
  const canUpdateQuery = useCanUpdateQuery()
  const { location } = useHistory()
  useEffect(() => {
    if (canUpdateQuery) {
      const parseQuery = formatQueryItems(searchStr)
      dispatch(setQuery(parseQuery))
    }
  }, [searchStr, canUpdateQuery])
  useEffect(() => {
    setTimeout(() => {
      dispatch(setPathName(location.pathname))
    }, 1000)
    return () => {
      dispatch(resetSearchData())
    }
  }, [])
}

export const useUpdateQueryWithoutURL = () => {
  const dispatch = useDispatch()
  const searchData = useSearch()
  const searchStr = JSON.stringify(searchData)
  const debouncedQuery = useDebounce(searchStr, 500)
  useEffect(() => {
    const parseQuery = formatQueryDragons(debouncedQuery)
    dispatch(setQuery(parseQuery))
  }, [debouncedQuery])

  useEffect(() => {
    return () => {
      dispatch(resetSearchData({}))
    }
  }, [])
}

const formatValueFromTo = (string, numberMax = null) => {
  if (!string) return null
  const _value = string.split(',')
  if (_value?.length !== 2) return null
  return {
    from: Number(_value[0]) === MAX_NUMBER_QUERY ? '' : Number(_value[0]),
    to: Number(_value[1]) === (numberMax || MAX_NUMBER_QUERY) ? '' : Number(_value[1]),
  }
}

export const formatDataSearch = (search, query) => {
  try {
    let data = Object.assign({}, search?.data) || {}
    let dragons = Object.assign({}, search?.dragons) || {}
    let bodyParts = Object.assign({}, search?.bodyParts) || []
    let page = 1
    if (query?.textSearch) {
      data.textSearch = query.textSearch
    }
    if (query?.order && query?.orderBy) {
      const orderValue = Object.values(SORT_DRAGON_FIELD).find(
        (item) => item.field === query.order && item.type === query.orderBy,
      )
      if (orderValue) {
        data.sort = orderValue
      }
    }
    if (query?.sale && FILTER_DRAGON_FOR_SALE[query?.sale]) {
      data.filterSale = FILTER_DRAGON_FOR_SALE[query?.sale]
    }
    if (query?.classDragon) {
      dragons.class = query.classDragon.split(',')
    }
    if (query?.type) {
      dragons.type = query.type.split(',')
    }
    // if (query?.mutant) {
    //   dragons.type = dragons?.type?.length ? [...dragons.type, DRAGON_TYPE.MUTATION] : [DRAGON_TYPE.MUTATION]
    // }
    if (query?.page) {
      page = Number(query.page)
    }
    if (query?.cooldown) {
      let cooldown = formatValueFromTo(query.cooldown)
      if (cooldown) {
        const _cooldown = cooldownSlelect.find((item) => item.from === cooldown.from && item.to === cooldown.to)
        if (_cooldown) {
          dragons.cooldown = _cooldown
        }
      }
    }
    const arrayKey = ['generation', 'potential', 'mana', 'health', 'attack', 'defend', 'speed', 'morale']
    arrayKey.map((key) => {
      if (query?.[key]) {
        let value = formatValueFromTo(query[key])
        if (value) {
          dragons[key] = value
        }
      }
    })

    if (query?.price) {
      let price = formatValueFromTo(query.price)
      if (price) {
        dragons.price = {
          from: price.from,
          to: price.to,
        }
      }
    }

    if (query?.price2) {
      let price = formatValueFromTo(query.price2, 100 * 10 ** 6)
      if (price) {
        dragons.price2 = {
          from: price.from,
          to: price.to,
        }
      }
    }

    Object.keys(BODY_PART_TYPE).forEach((key) => {
      if (query?.[key]) {
        bodyParts[key] = query[key]
      }
    })

    return {
      data,
      dragons,
      bodyParts,
      page,
    }
  } catch (error) {
    console.log(error)
  }
}

export const formatDataSearchItems = (search, query) => {
  try {
    let data = Object.assign({}, search?.data) || {}
    let items = Object.assign({}, search?.items) || {}
    let page = 1
    if (query?.textSearch) {
      data.textSearch = query.textSearch
    }
    if (query?.order && query?.orderBy) {
      const orderValue = Object.values(SORT_ITEM_FIELD).find(
        (item) => item.field === query.order && item.type === query.orderBy,
      )
      if (orderValue) {
        data.sort = orderValue
      }
    }
    if (query?.status && FILTER_ITEM_STATUS[query?.status]) {
      data.filterSale = FILTER_ITEM_STATUS[query?.status]
    }
    if (query?.page) {
      page = Number(query.page)
    }

    if (query?.exp) {
      items.exp = query.exp.split(',')?.map((item) => Number(item))
    }

    if (query?.part) {
      items.part = query.part.split(',')
    }
    if (query?.rarity) {
      items.rarity = query.rarity.split(',')
    }

    const arrayKey = ['level']
    arrayKey.map((key) => {
      if (query?.[key]) {
        let value = formatValueFromTo(query[key])
        if (value) {
          items[key] = value
        }
      }
    })

    if (query?.price) {
      let price = formatValueFromTo(query.price)
      if (price) {
        items.price = {
          from: price.from,
          to: price.to,
        }
      }
    }

    if (query?.price2) {
      let price = formatValueFromTo(query.price2, 100 * 10 ** 6)
      if (price) {
        items.price2 = {
          from: price.from,
          to: price.to,
        }
      }
    }
    return {
      data,
      items,
      page,
    }
  } catch (error) {
    console.log(error)
  }
}

export const useUpdateSearch = () => {
  const dispatch = useDispatch()
  const search = useSearch()
  const query = useQuery()

  useEffect(() => {
    if (JSON.stringify(query)?.length > 3) {
      const newData = formatDataSearch(search, query)
      dispatch(updateSearchData(newData?.data))
      dispatch(updateSearchDragons(newData?.dragons))
      dispatch(updateBodyParts(newData?.bodyParts))
      dispatch(setPageQuery(newData?.page))
    }
    dispatch(setCanUpdateQuery(true))
  }, [JSON.stringify(query)])
  useEffect(() => {
    return () => {
      dispatch(setCanUpdateQuery(false))
    }
  }, [])
}

export const useFetchBodyPartsData = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchBodyPartsDataAsync())
  }, [dispatch, slowRefresh])
}

export const useUpdateSearchItem = () => {
  const dispatch = useDispatch()
  const query = useQuery()
  const search = useSearch()

  useEffect(() => {
    if (JSON.stringify(query)?.length > 3) {
      const newData = formatDataSearchItems(search, query)
      dispatch(updateSearchData(newData?.data))
      dispatch(updateSearchItems(newData?.items))
      dispatch(setPageQuery(newData?.page))
    }
    dispatch(setCanUpdateQuery(true))
  }, [JSON.stringify(query)])

  useEffect(() => {
    return () => {
      dispatch(setCanUpdateQuery(false))
    }
  }, [])
}

export const useUpdateQueryFilterItemsWithoutURL = () => {
  const dispatch = useDispatch()
  const searchData = useSearch()
  const searchStr = JSON.stringify(searchData)
  const debouncedQuery = useDebounce(searchStr, 500)
  useEffect(() => {
    const parseQuery = formatQueryItems(debouncedQuery)
    dispatch(setQuery(parseQuery))
  }, [debouncedQuery])

  useEffect(() => {
    return () => {
      dispatch(resetSearchData({}))
    }
  }, [])
}
