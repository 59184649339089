import React, { useState } from 'react'
import Button from '../../../components/Button/Button'
import ModalClaimHistory from '../../Monster/components/modals/ModalClaimHistory'

const ButtonClaimHistory = ({ className, type }) => {
  const [openModal, setOpenModal] = useState(false)

  return (
    <>
      <Button outline className={className} onClick={() => setOpenModal(true)}>
        Claim history
      </Button>
      {openModal ? <ModalClaimHistory open={openModal} type={type} toggle={() => setOpenModal(false)} /> : null}
    </>
  )
}

export default ButtonClaimHistory
