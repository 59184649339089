import React from 'react'
import Badge from '../Badge/Badge'
import PropTypes from 'prop-types'
import Box from '../Box/Box'
import classnames from 'classnames'
import Value from 'components/Value/Value'
import { formatNumber, getBalanceAmount } from '../../utils/formatBalance'
import BigNumber from 'bignumber.js'
import { useKaiPrice } from 'hooks/usePrice'
import { TOKEN_USED_MARKETPLACE, ITEM_STATUS_BG_COLOR, ITEM_STATUS } from 'constants/index'
export default function ExpCard({ item, className, showPrice = true, ...rest }) {
  const price = getBalanceAmount(item?.price, 0)
  const tokenPrice = useKaiPrice()
  const priceUsd = price ? new BigNumber(price).times(tokenPrice).toNumber() : 0
  const backgroundExp =
    item?.exp < 50
      ? '/images/bg_exp/exp_white.png'
      : item?.exp > 75
      ? '/images/bg_exp/exp_purple.png'
      : '/images/bg_exp/exp_blue.png'
  return (
    <Box {...rest} className={classnames('rounded-xl border-2 border-blue2 bg-blue1 shadow-lg py-4', className)}>
      <div className="flex flex-col items-center py-1">
        <div className="mb-5 w-full pl-5 flex items-center justify-between">
          <div>
            <Badge className="bg-orange50 py-0.5 px-4 text-sm-md mb-5 text-white">#{item?.nftId}</Badge>
          </div>
          {showPrice && item?.status !== 'ACTIVE' ? (
            <p
              style={{
                backgroundImage: `linear-gradient(to right, ${
                  ITEM_STATUS_BG_COLOR[item?.status?.toUpperCase()]?.from
                },${ITEM_STATUS_BG_COLOR[item?.status?.toUpperCase()]?.to})`,
              }}
              className="whitespace-nowrap flex items-center rounded-l-xl text-sm sm:text-sm-md sm:pr-6 sm:pl-3 px-2 text-white"
            >
              {ITEM_STATUS?.[item?.status?.toUpperCase()]}
            </p>
          ) : null}
        </div>
        <div className="px-5 relative">
          <img src={backgroundExp} alt="item" width={200} />
          <p className="whitespace-nowrap absolute text-xl text-white50 sm:bottom-5 bottom-3.5 left-1/2 transform -translate-x-1/2 exp-card-value">
            +{item?.exp} exp
          </p>
        </div>

        {showPrice && (
          <div className="sm:h-4 h-8 mt-4 text-center">
            {item?.status === 'SELLING' ? (
              <>
                <div className="flex sm:flex-row flex-col text-center items-center text-sm-md">
                  <div className="uppercase mr-1 font-medium flex items-center">
                    {typeof item?.price === 'number' ? <Value className="" value={item?.price} decimals={0} /> : '--'}
                    <span className="ml-1">{TOKEN_USED_MARKETPLACE.symbol}</span>
                  </div>
                  <p className="text-white50">~{formatNumber(priceUsd, 2, 3)}$</p>
                </div>
              </>
            ) : null}
          </div>
        )}
      </div>
    </Box>
  )
}

ExpCard.propTypes = {
  item: PropTypes.object,
}
