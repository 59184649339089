import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const Container = ({ className, children, ...rest }) => {
  return (
    <div {...rest} className={classnames('container max-w-screen-xl m-auto', className)}>
      {children}
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.any.isRequired,
}

export default Container
