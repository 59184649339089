import React, { useEffect, useState } from 'react'
import { formatNumber } from 'utils/formatBalance'
import Progress from 'components/Progress'
import {
  DEFAULT_TOKEN_USED,
  EVENT_MONSTER_TYPES,
  FIGHT_MONSTER_RESULT,
  MONSTER_NAME,
  MONSTER_TYPES,
  MONSTER_TYPES_QUERY,
  RATIO_KILL_MONSTER,
} from 'constants/index'
import Value from 'components/Value/Value'
import ButtonHistory from './ButtonHistory'
import ButtonFight from './ButtonFight'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { fetchMonsterAsync } from 'store/monster'
import { useCooldownFightMonster, useIdAvailableMonsters } from 'store/monster/hook'
import { AlertCircle } from 'react-feather'
import Tooltip from 'components/Tooltip/Tooltip'
import { formatNumberToTime } from 'utils/formatDateTime'
import MonsterCard from '../../Events/components/MonsterCard'

const DetailMonster = ({ monster }) => {
  const { id } = useParams()
  const { location } = useHistory()
  const dispatch = useDispatch()
  const idAvailableMonsters = useIdAvailableMonsters()
  const cooldown = useCooldownFightMonster()
  const pathname = location?.pathname?.split('/')?.[1]
  const type =
    pathname.toLowerCase() === EVENT_MONSTER_TYPES[MONSTER_TYPES.XX_MONSTER].url
      ? MONSTER_TYPES.XX_MONSTER
      : MONSTER_TYPES.YY_MONSTER
  const [openModal, setOpenModal] = useState(undefined)
  const [subStateModal, setSubStateModal] = useState(undefined)

  const detailXX = [
    {
      label: 'Remaining reward',
      value: typeof monster?.totalReward === 'number' ? formatNumber(monster?.totalReward, 0, 0) : null,
      unit: <span className="text-sm-md pl-0.5">{DEFAULT_TOKEN_USED.symbol}</span>,
      tooltip: 'The current amount of tokens that players can earn',
    },
    {
      label: 'Reward',
      value: typeof monster?.rewardAmount === 'number' ? formatNumber(monster?.rewardAmount, 0, 0) : null,
      unit: <span className="text-sm-md pl-0.5">{DEFAULT_TOKEN_USED.symbol}</span>,
      tooltip: 'The amount of tokens you can earn after winning',
    },
    {
      label: 'Required level',
      value: monster?.requireLevel,
      tooltip: 'Your dracora level must be greater than or equal to this level to fight the monster',
    },
    {
      label: 'Chance increase',
      value:
        typeof monster?.chanceIncrease === 'number'
          ? formatNumber((monster?.chanceIncrease / RATIO_KILL_MONSTER) * 100, 0, 2)
          : null,
      unit: '%',
      tooltip:
        'You can attempt to fight the monster many times until you win. After a failure, your win rate will accumulate by this percentage',
    },
    {
      label: 'Interval between fights',
      value: typeof cooldown?.[monster?.type] === 'number' ? formatNumberToTime(cooldown?.[monster?.type]) : '--',
    },
    {
      label: 'Unlock reward in',
      value: formatNumberToTime(monster?.lockTime),
      tooltip: 'When you have been defeated monster. Reward will be unlocked after this time',
    },
  ]

  const detailYY = [
    {
      label: 'Total reward',
      value: typeof monster?.totalReward === 'number' ? formatNumber(monster?.totalReward, 0, 0) : null,
      unit: <span className="text-sm-md pl-0.5">{DEFAULT_TOKEN_USED.symbol}</span>,
      tooltip: 'The amount of tokens will be distributed to all participants (after substract the last-hitting reward)',
    },
    {
      label: 'Last-hitting reward',
      value: monster?.rewardPercentage,
      unit: '%',
      tooltip: 'A percentage of total reward will be given to who get the final hit that kill the monster',
    },
    {
      label: 'Health',
      value:
        typeof monster?.currentHp === 'number' ? (
          <Value
            value={monster?.currentHp || 0}
            decimals={0}
            unit={`/${typeof monster?.totalHp === 'number' ? formatNumber(monster?.totalHp, 0, 0) : '--'}`}
          />
        ) : null,
      tooltip:
        "Health is the level of endurance that determines the damage the monster can take before dying. The HP loss depends on your dracora's attack stat",
    },
    {
      label: 'Interval between fights',
      value: typeof cooldown?.[monster?.type] === 'number' ? formatNumberToTime(cooldown?.[monster?.type]) : '--',
    },
  ]

  const handleSetOpenModal = async (value) => {
    if (!value?.open && value?.type === 'reset' && value?.turn && openModal?.turn !== value?.turn) return
    if (
      [FIGHT_MONSTER_RESULT.DEFEAT, FIGHT_MONSTER_RESULT.VICTORY].includes(value?.result) &&
      value?.turn &&
      openModal?.turn !== value?.turn
    )
      return
    await setOpenModal(value)
  }

  const detailData = monster?.type === MONSTER_TYPES.XX_MONSTER ? detailXX : detailYY

  const updateData = async () => {
    try {
      await dispatch(fetchMonsterAsync(type, id))
    } catch (error) {}
  }

  useEffect(() => {
    handleSetOpenModal(subStateModal)
  }, [subStateModal])

  return (
    <div className="flex flex-col items-center mx-2.5 sm:mx-5">
      <h2 className="text-xl sm:text-2xl text-center mb-5">
        {MONSTER_NAME[monster.type]} {monster?.id}
      </h2>
      <div className="max-w-md w-full">
        <div className="border-1 border-blue2 bg-blue1 rounded-lg py-6 px-6 md:px-12">
          <div className="mb-5">
            {detailData.map((row, index) => (
              <div key={index} className="flex justify-between md:grid grid-cols-5 py-1.5">
                <div className="col-span-3">
                  {row?.tooltip ? (
                    <Tooltip
                      className="flex items-center cursor-pointer"
                      classNameToolTip="w-72 sm:w-80 bg-black text-sm-md border-2 border-blue2"
                      tooltip={row.tooltip}
                    >
                      {row.label}
                      <AlertCircle className="mx-1" size={16} />
                    </Tooltip>
                  ) : (
                    <span>{row.label}</span>
                  )}
                </div>
                <p className="col-span-2 text-blue4">
                  {row.value}
                  {row?.unit}
                </p>
              </div>
            ))}
          </div>
          <ButtonFight
            className="w-full"
            textSize="md"
            monster={monster}
            openModal={openModal}
            setOpenModal={setSubStateModal}
            available={Array.isArray(idAvailableMonsters) && idAvailableMonsters.includes(monster?.id)}
            updateData={updateData}
          />
        </div>

        {monster?.type === MONSTER_TYPES.YY_MONSTER ? (
          <div className="flex items-end mt-5">
            <div className="mr-4">Health:</div>
            <div className="flex-1">
              <div>
                {typeof monster?.currentHp === 'number' ? (
                  <Value
                    value={monster?.currentHp || 0}
                    className="text-sm sm:text-sm-md"
                    decimals={0}
                    unit={`/${typeof monster?.totalHp === 'number' ? formatNumber(monster?.totalHp, 0, 0) : '--'}`}
                  />
                ) : (
                  <p>--</p>
                )}
              </div>
              <Progress
                className="flex-1"
                percent={typeof monster?.currentHp === 'number' ? (monster?.currentHp / monster?.totalHp) * 100 : 100}
              />
            </div>
          </div>
        ) : null}

        <ButtonHistory
          monsterId={monster?.id}
          type={monster?.type === MONSTER_TYPES.XX_MONSTER ? MONSTER_TYPES_QUERY.XX : MONSTER_TYPES_QUERY.YY}
          className="w-full mt-5 text-sm-md sm:text-base"
        />
      </div>
    </div>
  )
}

export default DetailMonster
