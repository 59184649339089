import React, { useState, useEffect } from 'react'
import Value from 'components/Value/Value'
import ItemsFilterAction from '../ItemsFilterAction'
import classnames from 'classnames'
import SkillCardV2 from 'components/Card/SkillCardV2'
import { useFetchItems, useMyItems } from 'store/items/hook'
import { setItems } from 'store/items'
import { useDispatch } from 'react-redux'
import LoadingPage from 'components/Loader/LoadingPage'
import { useUpdateQueryItems, usePageQuery } from 'store/search/hook'
import ListView from 'components/ListView'
import { setPageQuery } from 'store/search'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { ITEM_TYPES } from 'constants/index'
import { showToastError } from 'components/CustomToast/CustomToast'

export default function SkillTab() {
  const { account } = useActiveWeb3React()
  useUpdateQueryItems()
  useFetchItems({ type: ITEM_TYPES.SKILL_CARD, owner: account })
  const pageQuery = usePageQuery()
  const items = useMyItems()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setPageQuery(1))
    return () => {
      dispatch(setItems(undefined))
    }
  }, [dispatch])

  const onChangePage = async (page) => {
    try {
      dispatch(setPageQuery(page + 1))
    } catch (error) {
      showToastError('Error', 'Some error occurred, please try again!')
    }
  }

  if (items === undefined) {
    return <LoadingPage />
  }

  return (
    <>
      <ItemsFilterAction ignoreBurn={true} ignoreBasicSkill={true} />
      <div className="max-w-screen-lg m-auto mt-10">
        <div className="m-auto max-w-screen-lg sm:mx-5 mx-2.5">
          <div className="text-2xl font-bold flex mb-5">
            <p className="mr-2">Total: </p>
            {typeof items?.totalItems === 'number' ? (
              <Value className="" value={items?.totalItems} decimals={0} />
            ) : (
              '--'
            )}
          </div>
        </div>
        <ListView
          className="min-height-list-save max-w-screen-lg m-auto xl:px-0 mt-8 mb-5"
          pageCount={items?.totalPages}
          totalItems={items?.totalItems}
          currentPage={pageQuery}
          fetchApi={onChangePage}
        >
          <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-y-5 sm:gap-x-5 gap-x-2 px-2.5 sm:px-5">
            {items?.data?.map((item) => {
              return (
                <SkillCardV2
                  key={item?.nftId}
                  item={item}
                  isLink={item?.nftId !== undefined}
                  to={`/items/skill-card/${item?.nftId}`}
                />
              )
            })}
          </div>
        </ListView>
      </div>
    </>
  )
}
