import { BIG_TEN } from 'utils/bigNumber'
import tokens from '../constants/tokens'
import { ITEM_TYPES } from '../constants'

export const UINT256_MAX = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'

export const KAI_BLOCK_TIME = 5
export const KAI_BLOCK_PER_YEAR = 6311520
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
// export const RPC_ENDPOINT = process.env.REACT_APP_RPC_ENDPOINT
export const RPC_ENDPOINT = 'https://api.kroma.network'
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
export const DEVELOP_ENVIRONMENT = !process.env.REACT_APP_DEVELOP_ENVIRONMENT
export const GAS_LIMIT_DEFAULT = 5000000

export const AMOUNT_EGG_SALE = 999

// Production
export const SALE_CLOCK_AUCTION_ADDRESS_PROD = '0x9Be6B52BD16662C6DB181dF0db788cA42Dc7F667'
export const SALE_CLOCK_AUCTION_HOME_ADDRESS_PROD = '0x9Be6B52BD16662C6DB181dF0db788cA42Dc7F667'
export const SALE_CLOCK_AUCTION_ADDRESS_OLD_PROD = '0x9Be6B52BD16662C6DB181dF0db788cA42Dc7F667'
export const CORE_ADDRESS_PROD = '0xedb33Bc25e5d2A3A23834992B7851f2f6e435330'
export const TRAINING_ADDRESS_PROD = '0x0bd4CB6aA5fCB8C54B8BDB3AdB5669c04095652d'
export const TRAINING_CAVE_ADDRESS_PROD = '0x2911BFe36841c21b3A8fb7F8FaF8c7d5CC41BbaB'
export const BIRTH_ADDRESS_PROD = '0xfdaB6d562Badb80aDdBF03C5E5cED0Af9944E711'
export const SIRING_ADDRESS_PROD = '0xfD4317d8d074B87867eaE62A2866b79D7185Fc79'
export const EVENT_MONSTER_ADDRESS_PROD = '0x92040aC0c0562621Fd02658321a8ECAc73a1b0FE'
export const AMOUNT_DRAGON_STAKE_PROD = 999
export const PERIOD_SALE_PROD = 0
export const EXPERIENCE_ADDRESS_PROD = '0x432A668A5C7b0C45318243f17c14310DfeDC8d4D'
export const MARKETPLACE_NFT_ADDRESS_PROD = '0x6068a563E6fde80Ff503dfccD9EF2db8192A8732'
export const EQUIPMENT_ADDRESS_PROD = '0xe3a228921FF6BF27026155233032060751cCf2Fa'
export const SKILL_ADDRESS_PROD = '0x073d2563243703f279c18204D647BaEbA210c835'
export const WEARABLE_EQUIPMENT_ADDRESS_PROD = '0x9900587840CD346d0Af75e6591C6da577CA12537'
export const WEARABLE_SKILL_ADDRESS_PROD = '0x3Ff5A872cbde30d1DF4fE8125A03D6fEa6a62E00'
//Test
export const SALE_CLOCK_AUCTION_ADDRESS_DEV = '0x2ED227d3967b7Aa2603fCc5af7A143f0Ce12572F'
export const SALE_CLOCK_AUCTION_HOME_ADDRESS_DEV = '0x48310f344EA32f096bfcD42A79B1CE4B3F228d84'
export const SALE_CLOCK_AUCTION_ADDRESS_OLD_DEV = '0x33456220BA618bc6F7D6E845BaAc08ABC7F57B13'
export const CORE_ADDRESS_DEV = '0xC238f2514AFf49080154C980EB919789190F372E'
export const TRAINING_ADDRESS_DEV = '0x37eaA47aD7034c598edD6182Cf843560ce8a63f0'
export const BIRTH_ADDRESS_DEV = '0xB2b5803c5a0304CF3E82c4b2bbBcc0D2B8762a6b'
export const SIRING_ADDRESS_DEV = '0x37EfD82e44c8d38a176E2a21fB263F1a46301502'
export const EVENT_MONSTER_ADDRESS_DEV = '0x780914b7150A0521230b2930B2316bBD93D4b87e'
export const AMOUNT_DRAGON_STAKE_DEV = 0.1
export const PERIOD_SALE_DEV = 0
export const EXPERIENCE_ADDRESS_DEV = '0x1ee584FD8d0Bc939D8D308d93232a2650bbbbBEf'
export const MARKETPLACE_NFT_ADDRESS_DEV = '0xd981a514A7072140bb7174f74D68B1f5d4038252'
export const EQUIPMENT_ADDRESS_DEV = '0x06260486006a37E1F9AD0103B2C469A24e765e49'
export const SKILL_ADDRESS_DEV = '0x65A2D495c94eE65A52AFB030B9EC437CA2816Dc9'
export const WEARABLE_EQUIPMENT_ADDRESS_DEV = '0xc44a8EbA583B6BB45537149409a3e8FF8f40eD86'
export const WEARABLE_SKILL_ADDRESS_DEV = '0x0E11EF11580C9F580ca099d2A00c662467b7297D'

export const SALE_CLOCK_AUCTION_ADDRESS = DEVELOP_ENVIRONMENT
  ? SALE_CLOCK_AUCTION_ADDRESS_DEV
  : SALE_CLOCK_AUCTION_ADDRESS_PROD
export const SALE_CLOCK_AUCTION_HOME_ADDRESS = DEVELOP_ENVIRONMENT
  ? SALE_CLOCK_AUCTION_HOME_ADDRESS_DEV
  : SALE_CLOCK_AUCTION_HOME_ADDRESS_PROD
export const SALE_CLOCK_AUCTION_ADDRESS_OLD = DEVELOP_ENVIRONMENT
  ? SALE_CLOCK_AUCTION_ADDRESS_OLD_DEV
  : SALE_CLOCK_AUCTION_ADDRESS_OLD_PROD
export const CORE_ADDRESS = DEVELOP_ENVIRONMENT ? CORE_ADDRESS_DEV : CORE_ADDRESS_PROD
export const AMOUNT_DRAGON_STAKE = DEVELOP_ENVIRONMENT ? AMOUNT_DRAGON_STAKE_DEV : AMOUNT_DRAGON_STAKE_PROD
export const PERIOD_SALE = DEVELOP_ENVIRONMENT ? PERIOD_SALE_DEV : PERIOD_SALE_PROD
export const TRAINING_ADDRESS = DEVELOP_ENVIRONMENT ? TRAINING_ADDRESS_DEV : TRAINING_ADDRESS_PROD
export const BIRTH_ADDRESS = DEVELOP_ENVIRONMENT ? BIRTH_ADDRESS_DEV : BIRTH_ADDRESS_PROD
export const SIRING_ADDRESS = DEVELOP_ENVIRONMENT ? SIRING_ADDRESS_DEV : SIRING_ADDRESS_PROD
export const EVENT_MONSTER_ADDRESS = DEVELOP_ENVIRONMENT ? EVENT_MONSTER_ADDRESS_DEV : EVENT_MONSTER_ADDRESS_PROD
export const EXPERIENCE_ADDRESS = DEVELOP_ENVIRONMENT ? EXPERIENCE_ADDRESS_DEV : EXPERIENCE_ADDRESS_PROD
export const EQUIPMENT_ADDRESS = DEVELOP_ENVIRONMENT ? EQUIPMENT_ADDRESS_DEV : EQUIPMENT_ADDRESS_PROD
export const SKILL_ADDRESS = DEVELOP_ENVIRONMENT ? SKILL_ADDRESS_DEV : SKILL_ADDRESS_PROD
export const MARKETPLACE_NFT_ADDRESS = DEVELOP_ENVIRONMENT ? MARKETPLACE_NFT_ADDRESS_DEV : MARKETPLACE_NFT_ADDRESS_PROD
export const WEARABLE_SKILL_ADDRESS = DEVELOP_ENVIRONMENT ? WEARABLE_SKILL_ADDRESS_DEV : WEARABLE_SKILL_ADDRESS_PROD
export const SIRING_CLOCK_AUCTION_ADDRESS = '0x53B9Ee618162ebF1F2ffFCAcBd4D88eAF87f91fD'

export const BIDDING_CLOCK_AUCTION_ADDRESS = '0x8EBDd6d011abc965f0a0218B8aBc291AAE56A4A8'

export const DRAGON_CAVE_ADDRESS = {
  address: '0x18243Ede0EB6479239e2A3799E51585e38A46D79',
  decimals: 18,
}

export const WEARABLE_ITEM_ADDRESS = DEVELOP_ENVIRONMENT
  ? WEARABLE_EQUIPMENT_ADDRESS_DEV
  : WEARABLE_EQUIPMENT_ADDRESS_PROD

export const ITEM_ADDRESS = {
  [ITEM_TYPES.EXP_CARD]: EXPERIENCE_ADDRESS,
  [ITEM_TYPES.EQUIPMENT]: EQUIPMENT_ADDRESS,
  [ITEM_TYPES.SKILL_CARD]: SKILL_ADDRESS,
}

export const IS_MAINTENANCE = false
