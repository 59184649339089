import { useCallback } from 'react'
import useActiveWeb3React from './useActiveWeb3React'
import { useCoreContract, useTokenContract, useItemContract } from './useContract'
import { approveDragonCave, approveTokenDragon, getApproveItem, approveItem } from 'utils/calls/approve'

export const useApprove = (poolAddress, tokenAddress) => {
  const { account } = useActiveWeb3React()
  const contract = useTokenContract(tokenAddress)
  const handleApprove = useCallback(async () => {
    const txHash = await approveTokenDragon(contract, poolAddress)
    return txHash
  }, [account, poolAddress, tokenAddress, contract])

  return { onApprove: handleApprove }
}

export const useApproveWrapDragon = (poolAddress, dragonCaveAddress) => {
  const { account } = useActiveWeb3React()
  const contract = useCoreContract(poolAddress)

  const handleApprove = useCallback(
    async (idToken) => {
      const txHash = await approveDragonCave(contract, dragonCaveAddress, idToken)
      return txHash
    },
    [account, poolAddress, dragonCaveAddress],
  )

  return { onApproveWrapDragon: handleApprove }
}

export const useApproveItem = (type, poolAddress) => {
  const { account } = useActiveWeb3React()
  const contract = useItemContract(type, poolAddress)
  const handleApprove = useCallback(
    async (addressTo, idToken) => {
      const txHash = await approveItem(contract, addressTo, idToken)
      return txHash
    },
    [account, poolAddress, type],
  )

  return { onApproveItem: handleApprove }
}

export const useGetApproveItem = (type, poolAddress) => {
  const { account } = useActiveWeb3React()
  const contract = useItemContract(type, poolAddress)

  const handleGetApprove = useCallback(
    async (idToken) => {
      const txHash = await getApproveItem(contract, idToken)
      return txHash
    },
    [account, poolAddress, type],
  )

  return { onGetApproveItem: handleGetApprove }
}
