import React from 'react'
import Modal from './ModalV2'
import ModalTitle from './ModalTitleV2'
import { TRAINING_DESCRIPTION } from 'constants/training'

const ModalTrainingDescription = ({ open, toggle }) => {
  return (
    <Modal isDark size="xl" open={open} onClose={toggle}>
      <ModalTitle onClose={toggle} className="mb-0-important">
        Adventure details
      </ModalTitle>
      <div className="overflow-auto custom-scrollbar-scroll">
        <table
          className="custom-table-training w-full"
          style={{
            minWidth: 780,
          }}
        >
          <thead>
            <tr>
              <th className="text-left text-white sm:text-center">Level</th>
              <th className="text-left text-white sm:text-center">XP Required (XP)</th>
              <th className="text-left text-white sm:text-center">Buy XP (DRACORA)</th>
              <th className="text-left text-white sm:text-center">Adventure Time (hour)</th>
              <th className="text-left text-white sm:text-center custom-border-left-cell">Adventure</th>
            </tr>
          </thead>
          <tbody className="text-white">
            {TRAINING_DESCRIPTION.map((row, index) => (
              <tr key={index}>
                <td className="text-center">{row?.level}</td>
                <td className="text-center">{row?.expRequired}</td>
                <td className="text-center">{row?.buyExp}</td>
                <td className="text-center">{row?.adventureTimes}</td>
                {index === 0 ? (
                  <td
                    rowSpan={9999}
                    className="custom-border-left-cell"
                    style={{
                      maxWidth: '12rem',
                    }}
                  >
                    <div className="h-full">
                      <p>25 XP per time</p>
                      <p>Require time of 1 hour from the last adventure</p>
                    </div>
                  </td>
                ) : null}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  )
}

export default ModalTrainingDescription
