import React, { useMemo } from 'react'
import Modal from './ModalV2'
import ModalTitle from './ModalTitleV2'
import { AUCTION_TYPE, DEFAULT_TOKEN_USED, MAX_DEFINE_COOLDOWN, TOKEN_USED_MARKETPLACE } from 'constants/index'
import ButtonGradient from '../Button/ButtonGradient'
import Value from '../Value/Value'
import { useModalWalletConnect } from 'hooks/modal'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'
import addTwoFinney from 'utils/addFinneyHelpers'

const ModalEstimateBreedCost = ({ open, toggle, matron, sire, breedCosts, cooldown, balance, onSubmit }) => {
  const { account } = useActiveWeb3React()
  const { onToggleConnectModal } = useModalWalletConnect()

  const isSiring = sire?.sale === AUCTION_TYPE.SIRING

  const breedCost = useMemo(() => {
    if (typeof matron?.cooldownIndex === 'number' && typeof sire?.cooldownIndex === 'number') {
      if (matron?.cooldownIndex > sire?.cooldownIndex)
        return breedCosts?.[matron?.cooldownIndex > MAX_DEFINE_COOLDOWN ? MAX_DEFINE_COOLDOWN : matron?.cooldownIndex]
      return breedCosts?.[sire?.cooldownIndex > MAX_DEFINE_COOLDOWN ? MAX_DEFINE_COOLDOWN : sire?.cooldownIndex]
    }
    return null
  }, [])

  const sirePriceWithTwoFinney = addTwoFinney(sire?.price, 18)

  return (
    <Modal isDark open={open} onClose={toggle}>
      <ModalTitle onClose={toggle}>Breed</ModalTitle>
      <div className="p-4">
        <div className="text-white text-center text-lg py-4">
          {isSiring ? (
            <div className="flex justify-center text-blue4">
              Mating cost:
              <Value value={sirePriceWithTwoFinney} unit={` ETH`} decimals={6} className="pl-2 font-bold" />
            </div>
          ) : null}
          <div className="flex justify-center">
            Estimate breed cost:
            {breedCost ? <Value value={breedCost} unit={` ETH`} decimals={0} className="pl-2 font-bold" /> : '???'}
          </div>
          <p>
            Pregnancy time:{' '}
            <strong>
              {cooldown?.[matron?.cooldownIndex > MAX_DEFINE_COOLDOWN ? MAX_DEFINE_COOLDOWN : matron?.cooldownIndex] ||
                '???'}
            </strong>
          </p>
          <p>
            Resting time:{' '}
            <strong>
              {cooldown?.[sire?.cooldownIndex > MAX_DEFINE_COOLDOWN ? MAX_DEFINE_COOLDOWN : sire?.cooldownIndex] ||
                '???'}
            </strong>
          </p>
          <p className="text-sm-md mt-4">Your ETH tokens will be burned when the mother "Give Birth"</p>
          {isSiring ? (
            <div className="mt-2">
              <div className="py-2 text-white text-center text-sm-md">
                ({`Your balance: `}
                <Value className="font-bold inline" value={balance} unit={` ETH`} decimals={6} />)
              </div>
              {/* {typeof balance === 'number' && balance < sire?.price ? (
                <div className="text-center mb-3">
                  <p className="text-orange85 text-center text-sm-md">
                    Your {TOKEN_USED_MARKETPLACE.symbol} balance is not enough
                  </p>
                  <a
                    className="underline text-white text-sm-md"
                    href={`https://defily.io/#/swap?outputCurrency=${TOKEN_USED_MARKETPLACE.symbol}`}
                    target="_blank"
                  >
                    Get DRACORA Token
                  </a>
                </div>
              ) : null} */}
            </div>
          ) : null}
        </div>
        <div className="flex justify-center mt-2">
          {account ? (
            <>
              <ButtonGradient disabled={isSiring && balance < sire?.price} onClick={onSubmit}>
                Confirm
              </ButtonGradient>
            </>
          ) : (
            <ButtonGradient className="px-5" onClick={onToggleConnectModal}>
              Connect wallet
            </ButtonGradient>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default ModalEstimateBreedCost
