import { GIFT_TYPE } from 'constants/index'

export default function GiftCard({ type, total, onClick }) {
  return (
    <>
      <button
        className="rounded-xl border-2 border-blue2 bg-blue1 shadow-lg px-4 md:px-8 py-4 md:py-8 text-white sm:max-w-lg flex flex-col items-center justify-center cursor-pointer"
        onClick={onClick}
      >
        <div className="flex items-center justify-between sm:mb-3 mb-1 w-full">
          <div></div>
          <p className="transform md:translate-x-8 translate-x-4 sm:pr-6 sm:pl-3 px-2 flex items-center rounded-l-3xl bg-metal sm:text-2xl text-xl font-bold font-monospace">
            X{total}
          </p>
        </div>
        <div className="sm:h-72 h-40 flex flex-col items-center justify-center">
          <img src={GIFT_TYPE?.[type].image} alt="gift-card" className="max-h-full" />
        </div>
      </button>
    </>
  )
}
