import useRefresh from '../../hooks/useRefresh'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchChartRankingAsync,
  fetchDragonsSellingAsync,
  fetchDragonsSiredAsync,
  fetchDragonsSiringAsync,
  fetchDragonsSoldAsync,
  fetchMarketplaceVolume,
  fetchReportBattlesAsync,
  fetchTopDragonsAsync,
  fetchTopOthersAsync,
  fetchEquipmentSellingAsync,
  fetchSkillSellingAsync,
  fetchExpSellingAsync,
  fetchEquipmentSoldAsync,
  fetchSkillSoldAsync,
  fetchExpSoldAsync,
  fetchEquipmentBurnedAsync,
  fetchSkillBurnedAsync,
  fetchExpBurnedAsync,
  setTabListed,
  setTabSold,
  setTabBurned,
  setTypeFilter,
  fetchMarketplaceVolumeItem,
} from './index'
import { TYPE_FILTER_RANKING, ITEM_TYPES } from '../../constants'

export const useChartRanking = () => useSelector((state) => state.report.chart)

export const useTopDragons = () => useSelector((state) => state.report.topDragons)

export const useTopOthers = () => useSelector((state) => state.report.topOthers)

export const useTypeFilter = () => useSelector((state) => state.report.typeFilter)

export const useMarketplaceVolume = () => useSelector((state) => state.report.marketplaceVolume)

export const useDragonsSelling = () => useSelector((state) => state.report.dragonsSelling)

export const useDragonsSiring = () => useSelector((state) => state.report.dragonsSiring)

export const useDragonsSold = () => useSelector((state) => state.report.dragonsSold)

export const useDragonsSired = () => useSelector((state) => state.report.dragonsSired)

export const useTabListed = () => useSelector((state) => state.report.tabListed)

export const useTabSold = () => useSelector((state) => state.report.tabSold)

export const useReportBattles = () => useSelector((state) => state.report.battles)

export const useEquipmentSelling = () => useSelector((state) => state.report.equipmentSelling)

export const useSkillSelling = () => useSelector((state) => state.report.skillSelling)

export const useExpSelling = () => useSelector((state) => state.report.expSelling)

export const useEquipmentSold = () => useSelector((state) => state.report.equipmentSold)

export const useSkillSold = () => useSelector((state) => state.report.skillSold)

export const useExpSold = () => useSelector((state) => state.report.expSold)

export const useEquipmentBurned = () => useSelector((state) => state.report.equipmentBurned)

export const useSkillBurned = () => useSelector((state) => state.report.skillBurned)

export const useMarketplaceVolumeItem = () => useSelector((state) => state.report.marketplaceVolumeItem)

export const useTabBurned = () => useSelector((state) => state.report.tabBurned)

export const useFetchChartRanking = () => {
  const { slowRefresh } = useRefresh()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchChartRankingAsync())
  }, [slowRefresh, dispatch])
}

export const useFetchTopDragons = () => {
  const { slowRefresh } = useRefresh()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTopDragonsAsync())
  }, [slowRefresh, dispatch])
}

export const useFetchTopOthers = () => {
  const { slowRefresh } = useRefresh()
  const dispatch = useDispatch()
  const typeFilter = useTypeFilter()

  useEffect(() => {
    dispatch(fetchTopOthersAsync(typeFilter?.value || TYPE_FILTER_RANKING[0].value))
  }, [slowRefresh, dispatch, typeFilter])

  useEffect(() => {
    return () => {
      dispatch(setTypeFilter(undefined))
    }
  }, [])
}

export const useFetchMarketplaceVolume = () => {
  const { slowRefresh } = useRefresh()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchMarketplaceVolume())
  }, [dispatch, slowRefresh])
}

export const useFetchDragonsListed = () => {
  const tabListed = useTabListed()
  const dispatch = useDispatch()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    if (tabListed === 0) {
      dispatch(fetchDragonsSellingAsync())
    } else {
      dispatch(fetchDragonsSiringAsync())
    }
  }, [tabListed, dispatch, fastRefresh])
  useEffect(() => {
    return () => {
      dispatch(setTabListed(0))
    }
  }, [])
}

export const useFetchDragonsSold = () => {
  const tabSold = useTabSold()
  const dispatch = useDispatch()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    if (tabSold === 0) {
      dispatch(fetchDragonsSoldAsync())
    } else {
      dispatch(fetchDragonsSiredAsync())
    }
  }, [tabSold, dispatch, fastRefresh])
  useEffect(() => {
    return () => {
      dispatch(setTabSold(0))
    }
  }, [])
}

export const useFetchReportBattles = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    dispatch(fetchReportBattlesAsync())
  }, [slowRefresh, dispatch])
}

export const useFetchItemsListed = () => {
  const tabListed = useTabListed()
  const dispatch = useDispatch()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    if (tabListed === 0) {
      dispatch(fetchEquipmentSellingAsync())
    } else if (tabListed === 1) {
      dispatch(fetchSkillSellingAsync())
    } else {
      dispatch(fetchExpSellingAsync())
    }
  }, [tabListed, dispatch, fastRefresh])

  useEffect(() => {
    return () => {
      dispatch(setTabListed(0))
    }
  }, [])
}

export const useFetchItemsSold = () => {
  const tabSold = useTabSold()
  const dispatch = useDispatch()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    if (tabSold === 0) {
      dispatch(fetchEquipmentSoldAsync())
    } else if (tabSold === 1) {
      dispatch(fetchSkillSoldAsync())
    } else {
      dispatch(fetchExpSoldAsync())
    }
  }, [tabSold, dispatch, fastRefresh])

  useEffect(() => {
    return () => {
      dispatch(setTabSold(0))
    }
  }, [])
}

export const useFetchItemsBurned = () => {
  const tabBurned = useTabBurned()
  const dispatch = useDispatch()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    if (tabBurned === 0) {
      dispatch(fetchEquipmentBurnedAsync())
    } else if (tabBurned === 1) {
      dispatch(fetchSkillBurnedAsync())
    }
  }, [tabBurned, dispatch, fastRefresh])

  useEffect(() => {
    return () => {
      dispatch(setTabBurned(0))
    }
  }, [])
}

export const useFetchMarketplaceVolumeItem = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    dispatch(fetchMarketplaceVolumeItem())
  }, [dispatch, slowRefresh])
}
