import { KROMA_CHAIN_ID, KROMA_SEPOLIA_CHAIN_ID } from 'constants/index'
import { ChainId } from 'defily-v2-sdk'

const Bsc = '/images/networks/bsc-network.jpg'
const Kai = '/images/wallets/kai.png'
const harmony = '/images/networks/harmonyone-network.png'

export const NETWORK_ICON = {
  [ChainId.BSC]: Bsc,
  [ChainId.BSC_TESTNET]: Bsc,
  [ChainId.HARMONY]: harmony,
  [ChainId.HARMONY_TESTNET]: harmony,
  [KROMA_CHAIN_ID]: Kai, //change this
  [KROMA_SEPOLIA_CHAIN_ID]: Kai, //change this
}

export const NETWORK_LABEL = {
  [ChainId.BSC]: 'BSC',
  [ChainId.BSC_TESTNET]: 'BSC Testnet',
  [KROMA_CHAIN_ID]: 'Kroma',
  [KROMA_SEPOLIA_CHAIN_ID]: 'Kroma Sepolia',
  [ChainId.HARMONY]: 'Harmony',
  [ChainId.HARMONY_TESTNET]: 'Harmony Testnet',
}
