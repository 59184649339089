import { ethers } from 'ethers'

import { simpleRpcProvider } from '../providers'
import erc20Abi from 'config/abi/erc20.json'
import auctionAbi from 'config/abi/SCA_ABI.json'
import trainingAbi from 'config/abi/trainingAbi.json'
import birthAbi from 'config/abi/birthAbi.json'
import coreAbi from 'config/abi/coreABI.json'
import dragonCaveAbi from 'config/abi/DragonCave_ABI.json'
import saleClockAuctionAbi from 'config/abi/SCA_ABI.json'
import siringAbi from 'config/abi/siringAbi.json'
import monsterAbi from 'config/abi/eventMonsterAbi.json'
import experienceAbi from 'config/abi/expAbi.json'
import marketPlaceNFTAbi from 'config/abi/marketPlaceNFTAbi.json'
import trainingExtendAbi from 'config/abi/trainingExtendAbi.json'
import equipmentAbi from 'config/abi/equipmentAbi.json'
import wearableItemAbi from 'config/abi/wearableItemAbi.json'
import skillAbi from 'config/abi/skillAbi.json'
import { ITEM_TYPES } from '../../constants'

const getContract = (abi, address, signer, chainId = 24) => {
  if (!address) return null

  const signerOrProvider = signer ?? simpleRpcProvider[chainId]
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getERC20Contract = (address, chainId, signer) => {
  if (!address) return null
  return getContract(erc20Abi, address, signer, chainId)
}

export const getAuctionContract = (address, chainId, signer) => {
  if (!address) return null
  return getContract(auctionAbi, address, signer, chainId)
}

export const getTrainingContract = (address, chainId, signer) => {
  if (!address) return null
  return getContract(trainingAbi, address, signer, chainId)
}

export const getBirthContract = (address, chainId, signer) => {
  if (!address) return null
  return getContract(birthAbi, address, signer, chainId)
}

export const getCoreContract = (address, chainId, signer) => {
  if (!address) return null
  return getContract(coreAbi, address, signer, chainId)
}

export const getDragonCaveContract = (address, chainId, signer) => {
  if (!address) return null
  return getContract(dragonCaveAbi, address, signer, chainId)
}

export const getSaleClockAuctionContract = (address, chainId, signer) => {
  if (!address) return null
  return getContract(saleClockAuctionAbi, address, signer, chainId)
}

export const getSireContract = (address, chainId, signer) => {
  if (!address) return null
  return getContract(siringAbi, address, signer, chainId)
}
export const getEventMonsterContract = (address, chainId, signer) => {
  if (!address) return null
  return getContract(monsterAbi, address, signer, chainId)
}

export const getItemContract = (address, type, chainId, signer) => {
  if (!address) return null
  let abi
  switch (type) {
    case ITEM_TYPES.EXP_CARD:
      abi = experienceAbi
      break
    case ITEM_TYPES.EQUIPMENT:
      abi = equipmentAbi
      break
    case ITEM_TYPES.SKILL_CARD:
      abi = skillAbi
      break
    default:
      break
  }
  if (!abi) return null

  return getContract(abi, address, signer, chainId)
}

export const getMarketPlaceNFTContract = (address, chainId, signer) => {
  if (!address) return null
  return getContract(marketPlaceNFTAbi, address, signer, chainId)
}

export const getTrainingExtendContract = (address, chainId, signer) => {
  if (!address) return null
  return getContract(trainingExtendAbi, address, signer, chainId)
}

export const getWearableItemContract = (address, chainId, signer) => {
  if (!address) return null
  return getContract(wearableItemAbi, address, signer, chainId)
}
