import { useCallback } from 'react'
import useActiveWeb3React from './useActiveWeb3React'
import { useDragonCaveContract } from './useContract'
import { depositDragonCave } from '../utils/calls/deposit'

export const useDepositDragonCave = (poolAddress) => {
  const { account } = useActiveWeb3React()
  const contract = useDragonCaveContract(poolAddress)
  const handleDeposit = useCallback(
    async (idToken) => {
      const txHash = await depositDragonCave(contract, idToken)
      return txHash
    },
    [account, poolAddress],
  )

  return { onDeposit: handleDeposit }
}
