import ContainerPage from 'components/Container/ContainerPage'
import { useHistory } from 'react-router-dom'
import WrapperBackground from '../components/Wrapper/WrapperBackground'
import ButtonGradient from '../components/Button/ButtonGradient'

const NotFound = () => {
  const history = useHistory()

  return (
    <ContainerPage>
      <WrapperBackground className="min-h-screen bg-top">
        <div className="text-center mb-8 relative z-20 pt-16">
          <img className="py-10 mx-auto" alt="logo" src={'/images/big-logo.png'} width="230" height="230" />
          <p className="text-white text-4xl z-20">Page Not Found...!</p>
        </div>
        <ButtonGradient className="mx-auto" onClick={() => history.push('/')}>
          Back Home
        </ButtonGradient>
      </WrapperBackground>
    </ContainerPage>
  )
}

export default NotFound
