import { useCallback } from 'react'
import useActiveWeb3React from './useActiveWeb3React'
import { useCoreContract } from './useContract'
import { breedDragon, breedDragonWithSiring } from '../utils/calls/auction'

export const useBreed = (coreAddress) => {
  const { account } = useActiveWeb3React()
  const contract = useCoreContract(coreAddress)
  const handleBreed = useCallback(
    async (matron, sire) => {
      const txHash = await breedDragon(contract, matron, sire)
      return txHash
    },
    [coreAddress, account],
  )

  return { onBreed: handleBreed }
}

export const useBreedWithSiring = (coreAddress) => {
  const { account } = useActiveWeb3React()
  const contract = useCoreContract(coreAddress)

  const handleBreed = useCallback(
    async (sire, matron, price, decimals) => {
      const txHash = await breedDragonWithSiring(contract, sire, matron, price, decimals)
      return txHash
    },
    [coreAddress, account],
  )

  return { onBreedWithSiring: handleBreed }
}
