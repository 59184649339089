import axiosClient from './axiosClient'

const reportApi = {
  getChart: () => {
    const url = '/reports/chart'
    return axiosClient.get(url)
  },
  getReports: (params) => {
    const url = '/reports'
    return axiosClient.get(url, { params })
  },
  getReportMarketplace: () => {
    const url = '/reports/report-marketplace'
    return axiosClient.get(url)
  },
  getDragonsListed: (params) => {
    const url = '/reports/report-marketplace/listed'
    return axiosClient.get(url, { params })
  },
  getDragonsSold: (params) => {
    const url = '/reports/report-marketplace/sold'
    return axiosClient.get(url, { params })
  },
  getReportEvent: () => {
    const url = '/reports/report-event'
    return axiosClient.get(url)
  },
  getReportBattles: () => {
    const url = '/reports/battles'
    return axiosClient.get(url)
  },
  getReportMarketplaceItems: (params) => {
    const url = '/reports/report-marketplace-items'
    return axiosClient.get(url, { params })
  },
  getCountMarketplaceItems: () => {
    const url = '/reports/count-marketplace-items'
    return axiosClient.get(url)
  },
}

export default reportApi
