import React, { useMemo, useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import {
  AUCTION_TYPE,
  cooldownLabel,
  DEFAULT_EGG_CLASS,
  DRAGON_STATUS,
  DRAGON_TYPE,
  HISTORY_MONSTER_TYPE,
  IMAGE_SIZE,
  MAX_DEFINE_COOLDOWN,
  MONSTER_TYPES,
  RATIO_KILL_MONSTER,
} from 'constants/index'
import Countdown, { zeroPad } from 'react-countdown'
import useInterval from '../../../hooks/useInterval'
import Box from '../../../components/Box/Box'
import Badge from '../../../components/Badge/Badge'
import Value from '../../../components/Value/Value'
import { getImageURL } from '../../../utils'
import { formatNumber } from '../../../utils/formatBalance'

const DragonCard = ({ dragon, className, disabled, monster, showPrice = true, onSelect, ...rest }) => {
  const { id, image } = dragon
  const isEgg = dragon?.type === 'EGG'
  const [canBirth, setCanBirth] = useState(false)
  const [canFight, setCanFight] = useState(true)
  const level = dragon?.level || 1
  const cooldownIndex = dragon?.cooldownIndex < MAX_DEFINE_COOLDOWN ? dragon?.cooldownIndex : MAX_DEFINE_COOLDOWN

  const dataStats = [
    {
      icon: '/icon/health.svg',
      label: 'Mana',
      value: typeof dragon?.stats?.mana === 'number' ? dragon?.stats?.mana : '???',
    },
    {
      icon: '/icon/generation.svg',
      label: 'Health',
      value: typeof dragon?.stats?.health === 'number' ? dragon?.stats?.health : '???',
    },
    {
      icon: '/icon/attack.svg',
      label: 'Attack',
      value: typeof dragon?.stats?.attack === 'number' ? dragon?.stats?.attack : '???',
    },
    {
      icon: '/icon/defend.svg',
      label: 'Defend',
      value: typeof dragon?.stats?.defend === 'number' ? dragon?.stats?.defend : '???',
    },
    {
      icon: '/icon/speed.svg',
      label: 'Speed',
      value: typeof dragon?.stats?.speed === 'number' ? dragon?.stats?.speed : '???',
    },
    {
      icon: '/icon/morale.svg',
      label: 'Morale',
      value: typeof dragon?.stats?.morale === 'number' ? dragon?.stats?.morale : '???',
    },
  ]

  const winRate = ((dragon?.stats?.attack + (dragon?.potential + 1) * (level - 1)) / RATIO_KILL_MONSTER) * 100

  const incrementPercent = (dragon?.totalHis || 0) * (monster?.chanceIncrease / RATIO_KILL_MONSTER) * 100

  const totalStats = useMemo(() => {
    if (!dragon?.stats) return null
    return dataStats.reduce(
      (previousValue, currentValue) => previousValue + (currentValue.value + (dragon?.potential + 1) * (level - 1)),
      0,
    )
  }, [dataStats])

  const dragonStatus = useMemo(() => {
    if (dragon?.type === DRAGON_TYPE.DRAGON) {
      if (dragon?.isGestating) return DRAGON_STATUS.PREGNANT
      if (!dragon?.isReady) {
        return DRAGON_STATUS.RESTING
      }
    }
    return null
  }, [dragon])

  const onSelectDragon = () => {
    if (canFight) {
      onSelect(dragon?.id)
    }
  }

  useInterval(() => {
    if (dragon?.isGestating && dragon?.timeLock < Date.now() && !canBirth) {
      setCanBirth(true)
    }
    if (
      !canFight &&
      (!dragon?.timeLock || dragon?.timeLock <= Date.now()) &&
      dragon?.win !== HISTORY_MONSTER_TYPE.FIGHTWIN
    ) {
      setCanFight(true)
    }
    if (canFight && (dragon?.timeLock > Date.now() || dragon?.win === HISTORY_MONSTER_TYPE.FIGHTWIN)) {
      setCanFight(false)
    }
  }, 1000)

  const rendererIncubation = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) return null
    return (
      <div className="text-white mt-0.5">
        <p className="font-bold text-xs sm:text-sm">
          {zeroPad(days)}d {zeroPad(hours)}h {zeroPad(minutes)}m {zeroPad(seconds)}s
        </p>
      </div>
    )
  }

  const rendererCountdownFight = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) return null
    return (
      <div className="text-white mt-0.5">
        <p className="font-bold text-xs sm:text-sm text-center">
          <span className="block sm:inline pr-0 sm:pr-1">Available in</span>
          {zeroPad(days)}d {zeroPad(hours)}h {zeroPad(minutes)}m {zeroPad(seconds)}s
        </p>
      </div>
    )
  }

  return (
    <Box
      {...rest}
      className={classnames(
        'border-2 overflow-hidden h-full border-blue2 bg-blue1 p-1.5 sm:p-2.5 mx-2 text-white relative',
        {
          'pb-6': !showPrice,
        },
        className,
      )}
      onClick={onSelectDragon}
    >
      <div className="flex justify-between">
        <div className="flex justify-between">
          <div className="flex items-center">
            <Badge
              className={classnames(
                `bg-${dragon?.class?.toLowerCase()}`,
                'text-white px-2 sm:px-3 text-xs sm:text-sm pt-0.5 pb-0.5',
              )}
            >
              #{id}
            </Badge>
            <Badge className={classnames('text-white px-2 sm:px-3 text-xs sm:text-sm pt-0.5 pb-0.5 bg-blue2 ml-1')}>
              Gen {dragon?.generation}
            </Badge>
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-2">
        <div className="flex flex-col">
          <Badge className="text-white px-2 sm:px-3 text-xs sm:text-sm pt-0.5 pb-0.5 bg-blue2">
            <span className="inline sm:hidden">Pot</span>
            <span className="hidden sm:inline">Potential</span> {dragon?.potential + 1}
          </Badge>
          <div className="flex items-center">
            <Badge className={classnames(`bg-${dragon?.class?.toLowerCase()}`, 'px-1 py-1')} />
            <span className="pl-1 sm:pl-2 capitalize text-xs sm:text-sm-md">{dragon?.class?.toLowerCase()}</span>
          </div>
          <p className="opacity-50 text-xs sm:text-sm-md">Level: {level || 1}</p>
        </div>
        <div className="flex flex-col items-end h-12">
          {dragonStatus ? (
            <Badge
              className={classnames(
                dragonStatus === DRAGON_STATUS.PREGNANT && canBirth ? 'bg-green1' : 'bg-skin',
                'text-white px-1.5 sm:px-2.5 text-xs sm:text-sm pt-0.5 pb-0.5 ml-1',
              )}
            >
              {dragonStatus === DRAGON_STATUS.PREGNANT && canBirth ? 'Give birth' : dragonStatus}
            </Badge>
          ) : null}
          {!dragonStatus ? (
            <Badge className="bg-blue2 text-white px-2 text-xs sm:text-sm pt-0.5 pb-0.5 ml-1">
              {typeof dragon?.cooldownIndex === 'number'
                ? `${cooldownLabel[cooldownIndex]?.label} (${cooldownLabel[cooldownIndex]?.duration})`
                : '???'}
            </Badge>
          ) : null}
          {dragon?.timeLock ? (
            <Countdown zeroPadTime={2} date={dragon?.timeLock} renderer={rendererIncubation} />
          ) : null}
          {dragon?.hatched ? <Countdown zeroPadTime={2} date={dragon?.hatched} renderer={rendererIncubation} /> : null}
          {[AUCTION_TYPE.AUCTION, AUCTION_TYPE.SIRING].includes(dragon?.sale) ? (
            <p
              style={{
                backgroundImage: `linear-gradient(to right, #48CAE4,#0068B4)`,
              }}
              className="rounded-l-xl text-sm sm:text-sm-md px-6 pl-3 text-white mt-1.5 transform translate-x-2.5"
            >
              {dragon?.sale === AUCTION_TYPE.AUCTION ? 'For sale' : 'Siring'}
            </p>
          ) : null}
        </div>
      </div>
      <div
        className={classnames('flex justify-center h-32 sm:h-36 py-3 relative', {
          'py-6': isEgg,
        })}
      >
        {dragon?.mutant ? <Badge className="bg-mutation px-1.5 py-1.5 mr-1 absolute top-3 left-0" /> : null}
        <img
          src={isEgg ? DEFAULT_EGG_CLASS[dragon?.class] : getImageURL(image, IMAGE_SIZE['250'])}
          className="h-full"
        />
      </div>
      <div className="grid grid-cols-3 mt-2">
        {dataStats.map((item, index) => (
          <div className="flex justify-center items-center" key={index}>
            <img
              src={item.icon}
              style={{
                maxHeight: 12,
                maxWidth: 12,
                width: 12,
              }}
            />
            <div className="text-white ml-2">
              {typeof item?.value === 'number' ? (
                <Value className="text-sm-md" value={item.value + (dragon?.potential + 1) * (level - 1)} decimals={0} />
              ) : (
                item?.value
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-2 my-1">
        <p className="text-center text-sm-md">
          <span className="text-xs text-white50 block sm:inline">XP:</span> {!isEgg ? dragon?.xp || 0 : '--'}
        </p>
        <p className="text-center text-sm-md">
          <span className="text-xs text-white50 block sm:inline">Stats:</span>{' '}
          {typeof totalStats === 'number' ? totalStats : '--'}
        </p>
      </div>

      {dragon?.win !== HISTORY_MONSTER_TYPE.FIGHTWIN && monster?.type === MONSTER_TYPES.XX_MONSTER ? (
        <div>
          <div className="flex justify-between items-center">
            <p className="text-xs sm:text-sm-md">Win rate:</p>
            <p>{formatNumber(winRate || 0, 0, 2)}%</p>
          </div>
          <div className="flex justify-between items-end">
            <p className="text-xs sm:text-sm-md">Incremental percentage:</p>
            <p>
              <p>{formatNumber(incrementPercent < 100 ? incrementPercent : 100, 0, 2)}%</p>
            </p>
          </div>
        </div>
      ) : null}
      <div className="flex justify-center">
        {dragon?.win === HISTORY_MONSTER_TYPE.FIGHTWIN ? (
          <div className="mt-2">
            <Badge className="px-3 bg-green-500 text-xs sm:text-sm-md pt-0.5 pb-0.5">Defeated monster</Badge>
          </div>
        ) : (
          <div>
            {dragon?.timeLock ? (
              <Countdown zeroPadTime={2} date={dragon?.timeLock} renderer={rendererCountdownFight} />
            ) : null}
          </div>
        )}
      </div>
      {!canFight ? <div className="absolute bg-disabled top-0 right-0 w-full h-full" /> : null}
    </Box>
  )
}

DragonCard.propTypes = {
  dragon: PropTypes.object.isRequired,
}

export default DragonCard
