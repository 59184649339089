import React from 'react'
import Modal from './ModalV2'
import ModalTitle from './ModalTitleV2'

const ModalSuccess = ({ open, toggle, children }) => {
  return (
    <Modal isDark open={open} onClose={toggle}>
      <ModalTitle onClose={toggle}>Success</ModalTitle>
      <div className="p-4">
        <div className="flex justify-center my-5">
          <img src="/icon/checked.svg" className="h-16" />
        </div>
        {children}
      </div>
    </Modal>
  )
}

export default ModalSuccess
