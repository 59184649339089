import { UINT256_MAX } from '../../config'

export const approveTokenDragon = async (contract, poolAddress) => {
  const tx = await contract.approve(poolAddress, UINT256_MAX)
  await tx.wait()
  return tx.transactionHash
}

export const approveDragonCave = async (contract, dragonCaveAddress, idToken) => {
  const tx = await contract.approve(dragonCaveAddress, idToken)
  await tx.wait()
  return tx.transactionHash
}

export const getApproveItem = async (contract, idToken) => {
  const tx = await contract.getApproved(idToken)
  return tx
}

export const approveItem = async (contract, addressTo, idToken) => {
  const tx = await contract.approve(addressTo, idToken)
  await tx.wait()
  return tx.transactionHash
}
