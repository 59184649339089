import React, { useState } from 'react'
import Web3 from 'web3'
import { formatNumber, getBalanceAmount, getBalanceNumber } from 'utils/formatBalance'
import { AUCTION_TYPE, DRAGON_TYPE, TOKEN_USED_MARKETPLACE, DEFAULT_TOKEN_USED } from 'constants/index'
import { useKaiPrice } from 'hooks/usePrice'
import BigNumber from 'bignumber.js'
import Value from 'components/Value/Value'
import Badge from 'components/Badge/Badge'
import Button from 'components/Button/Button'
import { Gift, Gitlab, RefreshCcw, ShoppingBag, ShoppingCart, Star } from 'react-feather'
import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast'
import { useTransfer } from 'hooks/useTransfer'
import { CORE_ADDRESS, DRAGON_CAVE_ADDRESS, SALE_CLOCK_AUCTION_ADDRESS, SIRING_ADDRESS } from 'config'
import { useApproveWrapDragon, useApprove } from 'hooks/useApprove'
import { useDepositDragonCave } from 'hooks/useDeposit'
import ModalSendEgg from 'components/Modal/ModalSendEgg'
import ModalWrapDragon from 'components/Modal/ModalWrapDragon'
import ModalSellAndSire from 'components/Modal/ModalSellAndSire'
import { useCancelSell, useSell } from 'hooks/useSell'
import { useCancelSiring, useSire } from 'hooks/useSire'
import ModalConfirm from 'components/Modal/ModalConfirm'
import { useBuyDragonMarket } from 'hooks/useBuyEgg'
import { useHistory } from 'react-router-dom'
import { fetchDetailDragon } from 'store/dragons'
import dragonsApi from 'api/dragonsApi'
import { useDispatch } from 'react-redux'
import { useBalanceMainCoin } from 'hooks/useTokenBalance'
import useTokenBalance from 'hooks/useTokenBalance'
import Modal from 'components/Modal/ModalV2'
import ModalTitle from 'components/Modal/ModalTitleV2'
import useActiveWeb3React from '../../../hooks/useActiveWeb3React'
import address from 'constants/contracts'
import { useUserDataDragonHome, useFetchDataUserDragonHome } from 'store/dragons/hook'
import { fetchUserDataDragonHome } from 'store/dragons'

const ModalWarning = ({ open, toggle, price, balance }) => {
  return (
    <Modal isDark open={open} onClose={toggle}>
      <ModalTitle onClose={toggle}>Warning</ModalTitle>
      <div className="p-4">
        <div className="text-white text-center text-lg py-4">
          <h3 className="text-lg sm:text-xl">Your {TOKEN_USED_MARKETPLACE.symbol} balance is not enough</h3>
          <div className="mt-2">
            <div className="pt-2 text-white text-center text-md">
              {`Dracora price: `}
              <Value
                className="font-bold inline"
                value={price}
                unit={` ${TOKEN_USED_MARKETPLACE.symbol}`}
                decimals={2}
              />
            </div>
            <div className="pt-2 text-white text-center text-sm-md">
              ({`Your balance: `}
              <Value
                className="font-bold inline"
                value={balance}
                unit={` ${TOKEN_USED_MARKETPLACE.symbol}`}
                decimals={2}
              />
              )
            </div>
            {/* {typeof balance === 'number' && balance < price ? (
              <div className="text-center mb-3">
                <a
                  className="underline text-white text-sm-md"
                  href={`https://defily.io/#/swap?outputCurrency=${TOKEN_USED_MARKETPLACE.symbol}`}
                  target="_blank"
                >
                  Get {TOKEN_USED_MARKETPLACE.symbol} Token
                </a>
              </div>
            ) : null} */}
          </div>
        </div>
      </div>
    </Modal>
  )
}

const DragonActions = ({ dragon }) => {
  useFetchDataUserDragonHome()
  const { push } = useHistory()
  const dispatch = useDispatch()
  const { account } = useActiveWeb3React()
  const userData = useUserDataDragonHome()
  const { onApprove } = useApprove(SALE_CLOCK_AUCTION_ADDRESS, DEFAULT_TOKEN_USED.address)
  const { onTransfer } = useTransfer(CORE_ADDRESS)
  const { onApproveWrapDragon } = useApproveWrapDragon(CORE_ADDRESS, DRAGON_CAVE_ADDRESS.address)
  const { onDeposit } = useDepositDragonCave(DRAGON_CAVE_ADDRESS.address)
  const { onSell } = useSell(CORE_ADDRESS)
  const { onSire } = useSire(CORE_ADDRESS)
  const { onCancelSell } = useCancelSell(SALE_CLOCK_AUCTION_ADDRESS)
  const { onCancelSiring } = useCancelSiring(SIRING_ADDRESS)
  const { onBuyDragon } = useBuyDragonMarket(SALE_CLOCK_AUCTION_ADDRESS)
  const balance = getBalanceNumber(useTokenBalance(address.dragon))

  const [openModalSend, setOpenModalSend] = useState(false)
  const [openModalWrap, setOpenModalWrap] = useState(false)
  const [openMarket, setOpenMarket] = useState({})
  const [openModalCancel, setOpenModalCancel] = useState(false)
  const [pendingBuyToken, setPendingBuyToken] = useState(false)
  const [openModalWarning, setOpenModalWarning] = useState(false)
  const [pendingTx, setPendingTx] = useState(false)

  const isOwner =
    account && dragon?.owner && Web3.utils.toChecksumAddress(account) === Web3.utils.toChecksumAddress(dragon?.owner)

  const canBreed = dragon?.isReady && !dragon?.isGestating
  const price = getBalanceAmount(dragon?.price, 0)
  const tokenPrice = useKaiPrice()
  const priceUsd = price ? new BigNumber(price).times(tokenPrice).toNumber() : 0
  const allowance = new BigNumber(userData?.allowance) || BIG_ZERO
  const userDataLoaded = userData?.userDataLoaded
  const isApproved = account && allowance && allowance?.isGreaterThan(new BigNumber(dragon?.price))

  const updateData = async () => {
    await dispatch(fetchUserDataDragonHome(account))
  }

  const toggleModalMarket = () => setOpenMarket({})

  const handleApprove = async () => {
    try {
      setPendingTx(true)
      await onApprove()
      await updateData()
      showToastSuccess('Contract Enabled', 'Approve contract successfully!')
      setPendingTx(false)
    } catch (error) {
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
      setPendingTx(false)
    }
  }

  const updateDragon = async () => {
    try {
      await dragonsApi.migrateData(dragon?.id)
      await dispatch(fetchDetailDragon(account, dragon?.id))
    } catch (error) {}
  }

  const onSendEgg = async (address) => {
    try {
      const addressSend = Web3.utils.toChecksumAddress(address)
      await onTransfer(addressSend, dragon?.id)
      await updateDragon()
      showToastSuccess('Success', `Send ${dragon?.type === 'EGG' ? 'Egg' : 'Dracora'} successfully!`)
    } catch (error) {
      throw error
    }
  }

  const onWrapDragon = async () => {
    try {
      await onDeposit(dragon?.id)
      showToastSuccess('Success', `Wrap your ${dragon?.type === 'EGG' ? 'Egg' : 'Dracora'} to WDRACORA   successfully!`)
    } catch (error) {
      throw error
    }
  }

  const onApproveWrap = async () => {
    try {
      await onApproveWrapDragon(dragon?.id)
      showToastSuccess('Contract Enabled', `You can now wrap ${dragon?.type === 'EGG' ? 'Egg' : 'Dracora'}!`)
    } catch (error) {
      throw error
    }
  }

  const actionMarketPlace = async (amount) => {
    try {
      if (openMarket?.type === 'SELL') {
        await onSell(dragon?.id, amount, 18)
      } else {
        await onSire(dragon?.id, amount, 18)
      }
      await updateDragon()
      toggleModalMarket()
      showToastSuccess(
        'Success',
        `${openMarket?.type === 'SELL' ? 'Selling' : 'Siring'} ${
          dragon?.type === 'EGG' ? 'Egg' : 'Dracora'
        } successfully!`,
      )
    } catch (error) {
      throw error
    }
  }

  const handleBuyDragon = async () => {
    try {
      if (balance < dragon?.price) {
        setOpenModalWarning(true)
        return
      }
      setPendingBuyToken(true)
      await onBuyDragon(dragon?.id, dragon?.price, 18)
      await updateDragon()
      setPendingBuyToken(false)
      showToastSuccess('Success', `Congratulation! You have bought ${dragon?.type.toLowerCase()} successfully!`)
    } catch (error) {
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
      setPendingBuyToken(false)
    }
  }

  const handleCancel = async () => {
    try {
      const type = dragon?.sale
      if (dragon?.sale === AUCTION_TYPE.AUCTION) {
        await onCancelSell(dragon?.id)
      } else {
        await onCancelSiring(dragon?.id)
      }
      await updateDragon()
      setOpenModalCancel(false)
      showToastSuccess('Success', `Canceled dracora ${type === AUCTION_TYPE.SIRING ? 'sire' : 'sale'} successfully`)
    } catch (error) {
      throw error
    }
  }

  const messageButton = (type = 'BREED') => {
    switch (type) {
      case 'BREED':
        if (dragon?.isGestating) {
          return 'Pregnant dracora cannot breed'
        }
        if (!dragon?.isReady) {
          return 'Resting dracora cannot breed'
        }
        return null
      case AUCTION_TYPE.AUCTION:
        if (dragon?.isGestating) {
          return 'Pregnant dracora cannot sell'
        }
        return null
      case AUCTION_TYPE.SIRING:
        if (dragon?.isGestating) {
          return 'Pregnant dracora cannot sire'
        }
        if (!dragon?.isReady) {
          return 'Resting dracora cannot sire'
        }
        if (dragon?.type === DRAGON_TYPE.EGG) {
          return 'Egg cannot sire'
        }
        return null
      default:
        return null
    }
  }

  return (
    <div className="sm:max-w-lg">
      {[AUCTION_TYPE.AUCTION, AUCTION_TYPE.SIRING].includes(dragon?.sale) ? (
        <div className="pb-7">
          <div className="flex items-center">
            <div>
              <Value
                value={price}
                decimals={2}
                unit={` ${TOKEN_USED_MARKETPLACE.symbol}`}
                className="text-white font-bold text-lg sm:text-2xl"
              />
              <p className="text-white text-sm opacity-50">~{formatNumber(priceUsd, 2, 3)}$</p>
            </div>

            <Badge rounded className="bg-blue2 rounded-sm ml-4 px-4 text-white text-sm-md sm:text-md mb-3">
              {dragon?.sale === AUCTION_TYPE.AUCTION ? 'For sale' : 'Siring'}
            </Badge>
          </div>
          {account ? (
            <>
              {isOwner ? (
                <Button outline className="mt-2 text-md pt-1.5 pb-1.5" onClick={() => setOpenModalCancel(true)}>
                  {dragon?.sale === AUCTION_TYPE.AUCTION ? 'Cancel selling' : 'Cancel siring'}
                </Button>
              ) : (
                <>
                  {dragon?.sale === AUCTION_TYPE.AUCTION ? (
                    isApproved ? (
                      <Button
                        outline
                        disabled={pendingBuyToken}
                        isLoading={pendingBuyToken}
                        className="mt-2 text-md pt-1.5 pb-1.5 px-7"
                        onClick={handleBuyDragon}
                      >
                        <ShoppingBag className="mr-2" size={17} />
                        Buy
                      </Button>
                    ) : (
                      <Button
                        outline
                        className="mt-2 text-md pt-1.5 pb-1.5 px-7"
                        disabled={pendingTx || !userDataLoaded}
                        isLoading={pendingTx || !userDataLoaded}
                        onClick={handleApprove}
                      >
                        Approve {DEFAULT_TOKEN_USED.symbol}
                      </Button>
                    )
                  ) : (
                    <Button
                      outline
                      className="mt-2 text-md pt-1.5 pb-1.5 px-7"
                      onClick={() => push(`/breed?father=${dragon?.id}&isMating=true`)}
                    >
                      <Gitlab className="mr-2" size={17} />
                      Mating
                    </Button>
                  )}
                </>
              )}
            </>
          ) : null}
        </div>
      ) : null}
      {(!dragon?.sale || dragon?.sale === AUCTION_TYPE.NOT_FOR_SALE) && isOwner ? (
        <div className="pb-7">
          {/* {dragon?.type === DRAGON_TYPE.DRAGON ? (
            <Button
              outline
              disabled={!canBreed}
              msgDisable={messageButton()}
              className="mt-2 text-md pt-1.5 pb-1.5 w-full"
              onClick={() => push(`/breed?mother=${dragon?.id}`)}
            >
              <Gitlab className="mr-2" size={16} />
              Breed
            </Button>
          ) : null} */}
          <div className="grid grid-cols-2 lg:grid-cols-4 -mx-2">
            <Button outline className="mt-2 text-md pt-1.5 pb-1.5 mx-2" onClick={() => setOpenModalWrap(true)}>
              <RefreshCcw className="mr-2" size={16} />
              Wrap
            </Button>
            <Button
              outline
              disabled={dragon?.type === DRAGON_TYPE.EGG || dragon?.isGestating || !dragon?.isReady}
              className="mt-2 text-md pt-1.5 pb-1.5  mx-2"
              classNameToolTip="w-40"
              msgDisable={messageButton(AUCTION_TYPE.SIRING)}
              onClick={() => setOpenMarket({ type: 'SIRE', open: true })}
            >
              <Star className="mr-2" size={16} />
              Sire
            </Button>
            <Button
              outline
              disabled={dragon?.isGestating}
              className="mt-2 text-md pt-1.5 pb-1.5  mx-2"
              msgDisable={messageButton(AUCTION_TYPE.AUCTION)}
              classNameToolTip="w-40"
              onClick={() => setOpenMarket({ type: 'SELL', open: true })}
            >
              <ShoppingCart className="mr-2" size={16} />
              Sell
            </Button>
            <Button
              outline
              className="mt-2 text-md pt-1.5 pb-1.5  mx-2"
              onClick={() => setOpenModalSend(!openModalSend)}
            >
              <Gift className="mr-2" size={16} />
              Send
            </Button>
          </div>
        </div>
      ) : null}

      {/*Modal send*/}
      {openModalSend ? (
        <ModalSendEgg
          open={openModalSend}
          toggle={() => setOpenModalSend(!openModalSend)}
          dragon={dragon}
          onConfirm={onSendEgg}
        />
      ) : null}

      {/*Modal Wrap*/}
      {openModalWrap && isOwner ? (
        <ModalWrapDragon
          open={openModalWrap}
          isApproved={dragon?.allowanceWrap}
          toggle={() => setOpenModalWrap(!openModalWrap)}
          dragon={dragon}
          onConfirm={onWrapDragon}
          onApprove={onApproveWrap}
        />
      ) : null}

      {/*Modal Sell and Sire*/}
      {isOwner && openMarket?.open ? (
        <ModalSellAndSire
          isSire={openMarket?.type === 'SIRE'}
          open={openMarket?.open}
          toggle={toggleModalMarket}
          onSubmit={actionMarketPlace}
        />
      ) : null}

      {openModalCancel ? (
        <ModalConfirm
          open={openModalCancel}
          title="Confirm"
          content="Are you sure you want to cancel?"
          toggle={() => setOpenModalCancel(false)}
          onConfirm={handleCancel}
        />
      ) : null}

      {openModalWarning ? (
        <ModalWarning
          open={openModalWarning}
          toggle={() => setOpenModalWarning(false)}
          balance={balance}
          price={dragon?.price}
        />
      ) : null}
    </div>
  )
}

export default DragonActions
