import React, { useState, useEffect } from 'react'
import Modal from './ModalV2'
import ModalTitle from './ModalTitleV2'
import { useItem } from 'store/items/hook'
import Input from '../Input/Input'
import Badge from '../Badge/Badge'
import ButtonGradient from '../Button/ButtonGradient'
import { showToastError, showToastSuccess } from '../CustomToast/CustomToast'
import { useKaiPrice } from '../../hooks/usePrice'
import { formatNumber } from '../../utils/formatBalance'
import BigNumber from 'bignumber.js'
import { FEE_SELL_AND_SIRE, TOKEN_USED_MARKETPLACE } from 'constants/index'
import { useApproveItem } from 'hooks/useApprove'
import { ITEM_ADDRESS, MARKETPLACE_NFT_ADDRESS } from 'config'
import SkillCard from 'components/Card/SkillCard'
import { useFetchUserDataDetailItem, useItemApproved } from 'store/items/hook'
import { fetchDetailItemApproved } from 'store/items'
import { useDispatch } from 'react-redux'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import ItemCard from 'components/Card/ItemCard'

export default function ModalSellItem({ open, toggle, onSubmit }) {
  useFetchUserDataDetailItem()
  const dispatch = useDispatch()
  const itemApproved = useItemApproved()
  const item = useItem()
  const { account } = useActiveWeb3React()
  const kaiPrice = useKaiPrice()
  const [value, setValue] = useState('')
  const [pendingTx, setPendingTx] = useState(false)
  const { onApproveItem } = useApproveItem(item?.type, ITEM_ADDRESS[item?.type])

  const priceUsd = value
    ? formatNumber((new BigNumber(value).times(kaiPrice).toNumber() * (100 - FEE_SELL_AND_SIRE)) / 100)
    : 0

  const isApproveItem = account && itemApproved?.itemApproved === MARKETPLACE_NFT_ADDRESS

  const onConfirm = async () => {
    try {
      setPendingTx(true)
      await onSubmit(value)
      setPendingTx(false)
    } catch (error) {
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
      setPendingTx(false)
    }
  }

  const handleApproveContract = async () => {
    try {
      setPendingTx(true)
      await onApproveItem(MARKETPLACE_NFT_ADDRESS, item?.nftId)
      dispatch(fetchDetailItemApproved(item?.type, item?.nftId))
      showToastSuccess('Contract Enabled', 'Approve contract successfully!')
      setPendingTx(false)
    } catch {
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
      setPendingTx(false)
    }
  }

  const title =
    item?.type === 'EXP_CARD' ? 'Sell Exp Card' : item?.type === 'SKILL_CARD' ? 'Sell Skill Card' : 'Sell Equipment'
  const backgroundExp =
    item?.exp < 50
      ? '/images/bg_exp/exp_white.png'
      : item?.exp > 75
      ? '/images/bg_exp/exp_purple.png'
      : '/images/bg_exp/exp_blue.png'
  return (
    <Modal isDark open={open} onClose={toggle}>
      <ModalTitle isCenter onClose={toggle}>
        {title}
      </ModalTitle>
      <div className="py-4 px-8">
        <div className="grid sm:grid-cols-2 gap-x-10">
          {item?.type === 'SKILL_CARD' ? (
            <div className="flex justify-center">
              <SkillCard skill={item?.skill} />
            </div>
          ) : item?.type === 'EXP_CARD' ? (
            <div className="flex justify-center">
              <div className="rounded-lg border-2 overflow-hidden h-full border-blue2 bg-blue1 p-3 pb-6 text-white w-60 sm:w-full">
                <Badge className="bg-orange50 px-3 text-sm">#{item?.nftId}</Badge>
                <div className="h-32 py-2 flex justify-center w-full mt-3">
                  <div className="relative">
                    <img src={backgroundExp} className="h-full" />
                    <p className="absolute text-lg text-white50 bottom-4 left-1/2 transform -translate-x-1/2">
                      +{item?.exp} exp
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex justify-center">
              <ItemCard item={item?.equipment} showBadge={false} />
            </div>
          )}

          <div className="text-white">
            <h3>Price</h3>
            <div className="flex items-center">
              <div className="mt-1">
                <Input
                  className="bg-transparent text-blue4 text-right custom-input-number custom-input-number-2 w-32"
                  type="number"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
              <p className="text-blue4 ml-3">{TOKEN_USED_MARKETPLACE.symbol}</p>
            </div>
            <p className="opacity-50 text-sm mt-1">
              ~${priceUsd} - after subtracting a {FEE_SELL_AND_SIRE}% fee
            </p>
            <p className="text-sm my-5">
              Your item will be listed on the marketplace with this price. You can cancel the sale anytime.
            </p>

            {isApproveItem ? (
              <ButtonGradient disabled={pendingTx || Number(value) <= 0} isLoading={pendingTx} onClick={onConfirm}>
                Sell Now
              </ButtonGradient>
            ) : (
              <ButtonGradient
                disabled={pendingTx || !itemApproved?.userDataLoaded}
                isLoading={pendingTx || !itemApproved?.userDataLoaded}
                onClick={handleApproveContract}
                className="whitespace-nowrap"
              >
                Approve Contract
              </ButtonGradient>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}
