import React, { useEffect, useState } from 'react'
import withLayout from 'hoc/withLayout'
import Container from 'components/Container/Container'
import Value from 'components/Value/Value'
import {
  useDragonsAdventure,
  useFetchDragonAdventure,
  useFetchMyDragon,
  useFetchUserDataTraining,
  useMyDragons,
  useUserDataTraining,
} from 'store/dragons/hook'
import LoadingPage from 'components/Loader/LoadingPage'
import ListView from 'components/ListView'
import { useDispatch } from 'react-redux'
import { setMyDragons } from 'store/dragons'
import { DRAGON_TYPE } from 'constants/index'
import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast'
import FilterAction from '../DragonCave/components/FilterAction'
import { useUpdateQuery } from 'store/search/hook'
import DragonCard from './components/DragonCard'
import { getBalanceNumber } from 'utils/formatBalance'
import useTokenBalance from 'hooks/useTokenBalance'
import address from 'constants/contracts'
import Button from 'components/Button/Button'
import { Shield } from 'react-feather'
import ModalAdventureAll from 'components/Modal/ModalAdventureAll'
import { useTrain } from 'hooks/useTrain'
import { TRAINING_ADDRESS } from 'config'
import { setPageQuery } from 'store/search'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'
import Web3Connect from '../../components/Web3Connect/Web3Connect'

const TrainDragons = ({}) => {
  useFetchUserDataTraining()
  useUpdateQuery()
  useFetchMyDragon({ type: DRAGON_TYPE.DRAGON }, true)
  useFetchDragonAdventure()

  const { onTrain } = useTrain(TRAINING_ADDRESS)
  const dragonBalance = getBalanceNumber(useTokenBalance(address.dragon))
  const dragonsAdventure = useDragonsAdventure()
  const userData = useUserDataTraining()
  const { account } = useActiveWeb3React()
  const dispatch = useDispatch()
  const dragons = useMyDragons()

  const [openModalAdventure, setOpenModalAdventure] = useState(false)
  const [pendingTx, setPendingTx] = useState(false)

  const onChangePage = async (page) => {
    try {
      dispatch(setPageQuery(page + 1))
    } catch (error) {
      showToastError('Error', 'Some error occurred, please try again!')
    }
  }

  const handleTraining = async (id) => {
    try {
      await onTrain(id)
      showToastSuccess('Success', 'Congratulations! Your dragon has successfully defeated the enemy in the adventure!')
    } catch (error) {
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
    }
  }

  const handleAdventureAll = async () => {
    try {
      setOpenModalAdventure(false)
      if (dragonsAdventure?.length) {
        setPendingTx(true)
        let i = 0
        while (i < dragonsAdventure?.length) {
          await handleTraining(dragonsAdventure[i])
          i += 1
        }
        setPendingTx(false)
      }
    } catch (error) {
      setPendingTx(false)
    }
  }

  useEffect(() => {
    return () => dispatch(setMyDragons(undefined))
  }, [])

  if (!account) {
    return (
      <div className="mt-12 flex justify-center">
        <Web3Connect />
      </div>
    )
  }

  if (dragons === undefined) {
    return <LoadingPage />
  }
  return (
    <Container className="pt-10 pb-36">
      <div className="text-center text-white">
        <h3 className="text-xl xl:text-2xl">Total training dragons</h3>
        {typeof dragons?.totalItems === 'number' ? (
          <Value className="text-5xl xl:text-6xl" value={dragons?.totalItems} decimals={0} />
        ) : (
          '--'
        )}
      </div>

      <div className="max-w-screen-lg m-auto px-2.5 sm:px-5 xl:px-0">
        {dragonsAdventure?.length ? (
          <div className="flex justify-center mt-3">
            <Button outline disabled={pendingTx} isLoading={pendingTx} onClick={() => setOpenModalAdventure(true)}>
              <Shield className="mr-2 hidden md:inline" size={16} />
              Adventure all
              <Value value={dragonsAdventure?.length} decimals={0} prefix=" (" unit=")" className="inline pl-0.5" />
            </Button>
          </div>
        ) : null}
      </div>

      <FilterAction />
      <ListView
        className="min-height-list-save max-w-screen-lg m-auto px-2.5 sm:px-5 xl:px-0 mt-8"
        pageCount={dragons?.totalPage}
        totalItems={dragons?.totalItems}
        currentPage={dragons?.currentPage}
        fetchApi={onChangePage}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {dragons?.data?.map((item, index) => (
            <DragonCard key={index} dragon={item} userData={userData} dragonBalance={dragonBalance} />
          ))}
        </div>
      </ListView>

      {openModalAdventure ? (
        <ModalAdventureAll
          open={openModalAdventure}
          toggle={() => setOpenModalAdventure(false)}
          listDragons={dragonsAdventure}
          onSubmit={handleAdventureAll}
        />
      ) : null}
    </Container>
  )
}

export default withLayout(TrainDragons)
