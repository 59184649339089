import tokens from './tokens'
import { injected, kai, walletconnect } from '../connectors'

export const NetworkContextName = 'NETWORK'

export const networkLocalStorageKey = 'network'

export const SUPPORTED_WALLETS = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'injected.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'wallet-connect.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true,
  },
}

export const DRAGON_CLASS_NUMBER = {
  0: 'METAL',
  1: 'WOOD',
  2: 'WATER',
  3: 'FIRE',
  4: 'EARTH',
}

export const DRAGON_CLASS = {
  METAL: 'METAL',
  WOOD: 'WOOD',
  WATER: 'WATER',
  FIRE: 'FIRE',
  EARTH: 'EARTH',
  YINYANG: 'YINYANG',
  LEGEND: 'LEGEND',
}

export const DRAGON_STATS_BASE = {
  MANA: 'MANA',
  HEALTH: 'HEALTH',
  ATTACK: 'ATTACK',
  DEFEND: 'DEFEND',
  SPEED: 'SPEED',
  MORALE: 'MORALE',
}

export const DRAGON_STATS = {
  mana: {
    display: 'Mana',
    icon: '/icon/health.svg',
  },
  attack: {
    display: 'Attack',
    icon: '/icon/attack.svg',
  },
  health: {
    display: 'Health',
    icon: '/icon/generation.svg',
  },
  defend: {
    display: 'Defend',
    icon: '/icon/defend.svg',
  },
  speed: {
    display: 'Speed',
    icon: '/icon/speed.svg',
  },
  morale: {
    display: 'Morale',
    icon: '/icon/morale.svg',
  },
}

export const ITEM_TYPES = {
  SKILL_CARD: 'SKILL_CARD',
  EXP_CARD: 'EXP_CARD',
  EQUIPMENT: 'EQUIPMENT',
}

export const ITEM_TYPES_URL = {
  'skill-card': 'SKILL_CARD',
  'exp-card': 'EXP_CARD',
  equipment: 'EQUIPMENT',
}

export const ORDER_TYPE = {
  ASC: 'asc',
  DESC: 'desc',
}

export const TIME_DEBOUNCE = 600

export const SORT_DRAGON_FIELD = {
  LOWEST_ID: {
    label: 'Lowest ID',
    value: 'LOWEST_ID',
    field: 'id',
    type: ORDER_TYPE.ASC,
  },
  HIGHEST_ID: {
    label: 'Highest ID',
    value: 'HIGHEST_ID',
    field: 'id',
    type: ORDER_TYPE.DESC,
  },
  DATELISTED: {
    label: 'Recently listed',
    value: 'DATELISTED',
    field: 'dateListed',
    type: ORDER_TYPE.DESC,
  },
  LOWEST_GENERATION: {
    label: 'Lowest generation',
    value: 'LOWEST_GENERATION',
    field: 'generation',
    type: ORDER_TYPE.ASC,
  },
  HIGHEST_GENERATION: {
    label: 'Highest generation',
    value: 'HIGHEST_GENERATION',
    field: 'generation',
    type: ORDER_TYPE.DESC,
  },
  LOWEST_POTENTIAL: {
    label: 'Lowest potential',
    value: 'LOWEST_POTENTIAL',
    field: 'potential',
    type: ORDER_TYPE.ASC,
  },
  HIGHEST_POTENTIAL: {
    label: 'Highest potential',
    value: 'HIGHEST_POTENTIAL',
    field: 'potential',
    type: ORDER_TYPE.DESC,
  },
  LOWEST_STATS: {
    label: 'Lowest stats',
    value: 'LOWEST_STATS',
    field: 'totalStats.total',
    type: ORDER_TYPE.ASC,
  },
  HIGHEST_STATS: {
    label: 'Highest stats',
    value: 'HIGHEST_STATS',
    field: 'totalStats.total',
    type: ORDER_TYPE.DESC,
  },
  LOWEST_PRICE: {
    label: 'Lowest price',
    value: 'LOWEST_PRICE',
    field: 'price',
    type: ORDER_TYPE.ASC,
  },
  HIGHEST_PRICE: {
    label: 'Highest price',
    value: 'HIGHEST_PRICE',
    field: 'price',
    type: ORDER_TYPE.DESC,
  },
  EARLIEST_COOLDOWN: {
    label: 'Earliest cooldown',
    value: 'EARLIEST_COOLDOWN',
    field: 'cooldownIndex',
    type: ORDER_TYPE.ASC,
  },
  LATEST_COOLDOWN: {
    label: 'Latest cooldown',
    value: 'LATEST_COOLDOWN',
    field: 'cooldownIndex',
    type: ORDER_TYPE.DESC,
  },
}

export const TYPE_FILTER_RANKING = [
  {
    label: 'Potential',
    value: 'potential',
    key: 'potential',
  },
  {
    label: 'Generation',
    value: 'generation',
    key: 'generation',
  },
  {
    label: 'Cooldown',
    value: 'cooldownIndex',
    key: 'cooldownIndex',
  },
  {
    label: 'XP',
    value: 'xp',
    key: 'xp',
  },
  {
    label: 'Mana',
    value: 'totalStats.mana',
    isStats: true,
    key: 'mana',
  },
  {
    label: 'Speed',
    value: 'totalStats.speed',
    isStats: true,
    key: 'speed',
  },
  {
    label: 'Morale',
    value: 'totalStats.morale',
    isStats: true,
    key: 'morale',
  },
  {
    label: 'Attack',
    value: 'totalStats.attack',
    isStats: true,
    key: 'attack',
  },
  {
    label: 'Health',
    value: 'totalStats.health',
    isStats: true,
    key: 'health',
  },
  {
    label: 'Defend',
    value: 'totalStats.defend',
    isStats: true,
    key: 'defend',
  },
]

export const FILTER_DRAGON_FOR_SALE = {
  ALL: {
    label: 'All',
    value: null,
  },
  AUCTION: {
    label: 'For sale',
    value: 'AUCTION',
  },
  NOT_FOR_SALE: {
    label: 'Not for sale',
    value: 'NOT_FOR_SALE',
  },
  SIRING: {
    label: 'Siring',
    value: 'SIRING',
  },
  FAVORITES: {
    label: 'Favorites',
    value: 'FAVORITES',
  },
}

export const DRAGON_TYPE = {
  DRAGON: 'DRAGON',
  EGG: 'EGG',
  MUTATION: 'MUTATION',
}

export const connectorLocalStorageKey = 'active'

export const DEFAULT_EGG_CLASS = {
  WOOD: '/images/eggs/wood-egg.png',
  WATER: '/images/eggs/water-egg.png',
  FIRE: '/images/eggs/fire-egg.png',
  EARTH: '/images/eggs/earth-egg.png',
  METAL: '/images/eggs/metal-egg.png',
  YINYANG: '/images/eggs/yinyang-egg.png',
  LEGEND: '/images/eggs/legend-egg.png',
}

export const MODAL_TYPE = {
  UPCOMING_ALERT: 'UPCOMING_ALERT',
}

export const TOKEN_PER_EGG = 20

export const DEFAULT_TOKEN_USED = tokens.dragon

export const TOKEN_USED_MARKETPLACE = tokens.dragon

export const EXTERNAL_LINK = {
  telegram: 'https://t.me/DracoraChannel',
  twitter: 'https://x.com/Dracora_xyz',
  gitbook: 'https://dracoras-organization.gitbook.io/dracora',
}

export const DEFAULT_LIMIT = 12

export const FEE_SELL_AND_SIRE = 5

export const MAX_NUMBER_QUERY = 9999

export const MAX_DEFINE_COOLDOWN = 12
export const cooldownLabel = [
  {
    label: 'Rapid',
    duration: '1m',
  },
  {
    label: 'Fast',
    duration: '5m',
  },
  {
    label: 'Fast',
    duration: '30m',
  },
  {
    label: 'Swift',
    duration: '1h',
  },
  {
    label: 'Swift',
    duration: '2h',
  },
  {
    label: 'Snappy',
    duration: '4h',
  },
  {
    label: 'Snappy',
    duration: '8h',
  },
  {
    label: 'Plodding',
    duration: '1d',
  },
  {
    label: 'Plodding',
    duration: '2d',
  },
  {
    label: 'Slow',
    duration: '3d',
  },
  {
    label: 'Slow',
    duration: '4d',
  },
  {
    label: 'Languid',
    duration: '6d',
  },
  {
    label: 'Languid',
    duration: '7d',
  },
]

export const cooldownLabelTooltip = [
  {
    label: 'Rapid',
    duration: '1m',
    value: [0],
  },
  {
    label: 'Fast',
    duration: '5m - 30m',
    value: [1, 2],
  },
  {
    label: 'Swift',
    duration: '1h - 2h',
    value: [3, 4],
  },
  {
    label: 'Snappy',
    duration: '4h - 8h',
    value: [5, 6],
  },
  {
    label: 'Plodding',
    duration: '1d - 2d',
    value: [7, 8],
  },
  {
    label: 'Slow',
    duration: '3d - 4d',
    value: [9, 10],
  },
  {
    label: 'Languid',
    duration: '6d - 7d',
    value: [11, 12],
  },
]

export const cooldownSlelect = [
  {
    label: 'All',
    value: null,
  },
  {
    label: 'Rapid (1m)',
    value: 1,
    from: 0,
    to: 0,
  },
  {
    label: 'Fast (5m - 30m)',
    value: 2,
    from: 1,
    to: 2,
  },
  {
    label: 'Swift (1h - 2h)',
    value: 3,
    from: 3,
    to: 4,
  },
  {
    label: 'Snappy (4h - 8h)',
    value: 4,
    from: 5,
    to: 6,
  },
  {
    label: 'Plodding (1d - 2d)',
    value: 5,
    from: 7,
    to: 8,
  },
  {
    label: 'Slow (3d - 4d)',
    value: 6,
    from: 9,
    to: 10,
  },
  {
    label: 'Languid (6d - 7d)',
    value: 7,
    from: 11,
  },
]

export const SIRING_TYPE = {
  MATRON: 'MATRON',
  SIRE: 'SIRE',
}

export const AUCTION_TYPE = {
  AUCTION: 'AUCTION',
  SIRING: 'SIRING',
  NOT_FOR_SALE: 'NOT_FOR_SALE',
  FAVORITES: 'FAVORITES',
}

export const DRAGON_STATUS = {
  RESTING: 'Resting',
  PREGNANT: 'Pregnant',
}

export const IMAGE_SIZE = {
  250: 250,
  500: 500,
  768: 768,
  root: 'root',
}

export const HISTORY_TYPE_BG_COLOR = {
  'Give birth': '#49beff',
  Transfer: '#30379e',
  Selling: '#7ba928',
  Sold: '#4ca8a6',
  'Cancel selling': '#d9b23c',
  Siring: '#7333a1',
  'Sold siring': '#49beff',
  'Cancel siring': '#d9b23c',
  Pregnant: '#00ab00',
  Mating: '#a15299',
  Training: '#49beff',
  Boots: '#49beff',
}
export const ITEM_STATUS = {
  UPGRADED: 'Upgraded',
  TRANSFER: 'Transfer',
  ACTIVE: 'Active',
  SELLING: 'For sale',
  USED: 'Used',
  BURNED: 'Burned',
  CANCEL_SELL: 'Cancel sell',
  UNWEAR: 'Unwear',
  SOLD: 'Sold',
  UPGRADE_FAILED: 'Upgrade failed',
}

export const ITEM_STATUS_BG_COLOR = {
  USED: {
    from: '#f27210d9',
    to: '#ff6666',
  },
  SELLING: {
    from: '#7ba928',
    to: '#99cc00',
  },
  ACTIVE: {
    from: '#49beff',
    to: '#33ccff',
  },
  BURNED: {
    from: '#7333a1',
    to: '#9966ff',
  },
  TRANSFER: {
    from: '#30379e',
    to: '#6666ff',
  },
  SOLD: {
    from: '#4ca8a6',
    to: '#66ffff',
  },
  CANCEL_SELL: {
    from: '#d9b23c',
    to: '#ff9900',
  },
  UNWEAR: {
    from: '#00ab00',
    to: '#33cc33',
  },
  UPGRADED: {
    from: '#20bbf9',
    to: '#6666ff',
  },
}
export const ITEM_HISTORY_BG_COLOR = {
  USED: '#f27210d9',
  SELLING: '#7ba928',
  ACTIVE: '#49beff',
  BURNED: '#7333a1',
  TRANSFER: '#30379e',
  SOLD: '#4ca8a6',
  CANCEL_SELL: '#d9b23c',
  UNWEAR: '#00ab00',
  UPGRADED: '#20bbf9',
  UPGRADE_FAILED: '#ee0707',
}

export const BODY_PART_TYPE = {
  horns: 'Horn',
  head: 'Head',
  middlehorns: 'Middle Horn',
  eyes: 'Eyes',
  tail: 'Tail',
  wings: 'Wings',
  chest: 'Armor',
  backcales: 'Fins',
}

export const DRAGON_BODY_PART = {
  horns: {
    display: 'Horns',
    image: '/images/dragons/horns.png',
  },
  head: {
    display: 'Head',
    image: '/images/dragons/head.png',
  },
  middlehorns: {
    display: 'Middle Horn',
    image: '/images/dragons/middle-horn.png',
  },
  eyes: {
    display: 'Eyes',
    image: '/images/dragons/eyes.png',
  },
  tail: {
    display: 'Tail',
    image: '/images/dragons/tail.png',
  },
  wings: {
    display: 'Wings',
    image: '/images/dragons/wings.png',
  },
  armor: {
    display: 'Armor',
    image: '/images/dragons/armor.png',
  },
  fins: {
    display: 'Fins',
    image: '/images/dragons/back-scale.png',
  },
  body: {
    display: 'Body',
    image: '/images/dragons/body.png',
  },
}

export const MARKETPLACE_REPORT_TYPE = {
  AUCTION: 'AUCTION',
  SIRING: 'SIRING',
  SUCCESSAUCTION: 'SUCCESSAUCTION',
  SUCCESSSIRING: 'SUCCESSSIRING',
}

export const ITEMS_MARKETPLACE_REPORT_TYPE = {
  SELLING: 'SELLING',
  SOLD: 'SOLD',
  BURNED: 'BURNED',
}

export const MONSTER_TYPES = {
  XX_MONSTER: 'xx-monster',
  YY_MONSTER: 'yy-monster',
}

export const EVENT_TYPES = {
  'xx-monster': {
    label: 'XX Monster',
    value: 'xx-monster',
  },
  'yy-monster': {
    label: 'YY Monster',
    value: 'yy-monster',
  },
}

export const FIGHT_MONSTER_RESULT = {
  VICTORY: 'VICTORY',
  DEFEAT: 'DEFEAT',
}

export const MONSTER_TYPES_QUERY = {
  XX: 'XX',
  YY: 'YY',
}

export const HISTORY_MONSTER_TYPE = {
  FIGHTBOSS: 'Fight',
  FIGHTLOST: 'Lost',
  FIGHTWIN: 'Win',
  KILLBOSS: 'Kill',
  Claim: 'Claim',
}

export const MONSTER_NAME = {
  [MONSTER_TYPES.XX_MONSTER]: 'Arach Spear Monster',
  [MONSTER_TYPES.YY_MONSTER]: 'Lithoid Wolf Immortal',
}

export const EVENT_MONSTER_TYPES = {
  [MONSTER_TYPES.XX_MONSTER]: {
    label: 'Challenge Monsters',
    urls: 'challenge-monsters',
    url: 'challenge-monster',
  },
  [MONSTER_TYPES.YY_MONSTER]: {
    label: 'Monsters Boss',
    urls: 'monsters-boss',
    url: 'monster-boss',
  },
}

export const HISTORY_MONSTER_TYPE_COLOR = {
  Fight: '#b8cce0',
  Lost: '#EE0707',
  Win: '#00ab00',
  Kill: '#49BEFF',
  Claim: '#FF8F39',
}

export const RATIO_KILL_MONSTER = 4500

//TODO
// paste it into menu_list
// {
//   title: 'Marketplace',
//   activeRoute: ['/marketplace', '/dashboard', '/marketplace-items', '/dashboard-items'],
//   child: [
//     {
//       title: 'Dashboard',
//       child: [
//         {
//           title: 'Dragon',
//           route: '/dashboard',
//         },
//         {
//           title: 'Item',
//           route: '/dashboard-items',
//         },
//       ],
//     },
//     {
//       title: 'Dragons',
//       route: '/marketplace?sale=AUCTION&order=dateListed&orderBy=desc',
//     },
//     {
//       title: 'Items',
//       child: [
//         {
//           title: 'Equipment',
//           route: '/marketplace-items/equipment?status=SELLING&order=dateListed&orderBy=desc',
//         },
//         {
//           title: 'Skill',
//           route: '/marketplace-items/skill-card?status=SELLING&order=dateListed&orderBy=desc',
//         },
//         {
//           title: 'Exp',
//           route: '/marketplace-items/exp-card?status=SELLING&order=dateListed&orderBy=desc',
//         },
//       ],
//     },
//   ],
// },
export const LIST_MENU = [
  {
    title: 'Breed',
    route: '/breed',
    activeRoute: ['/breed'],
  },
  // {
  //   title: 'Ranking',
  //   route: '/ranking',
  //   activeRoute: ['/ranking'],
  // },
  {
    title: 'My Dracoras',
    route: '/my-dracoras',
    activeRoute: ['/my-dracoras', '/train', '/dragon-cave', '/my-items', '/gift'],
    child: [
      {
        title: 'My Dracoras',
        route: '/my-dracoras',
      },
      // {
      //   title: 'Items',
      //   child: [
      //     {
      //       title: 'Equipment',
      //       route: '/my-items/equipment',
      //     },
      //     {
      //       title: 'Skill',
      //       route: '/my-items/skill-card',
      //     },
      //     {
      //       title: 'Exp',
      //       route: '/my-items/exp-card',
      //     },
      //   ],
      // },
      // {
      //   title: 'Gift',
      //   route: '/gift',
      // },
      // {
      //   title: 'Train',
      //   route: '/train',
      // },
      // {
      //   title: 'Dragon Cave',
      //   route: '/dragon-cave',
      // },
    ],
  },
  // {
  //   title: 'Events',
  //   activeRoute: [
  //     `/${EVENT_MONSTER_TYPES[MONSTER_TYPES.XX_MONSTER].urls}`,
  //     `/${EVENT_MONSTER_TYPES[MONSTER_TYPES.YY_MONSTER].urls}`,
  //   ],
  //   child: [
  //     {
  //       title: EVENT_MONSTER_TYPES[MONSTER_TYPES.XX_MONSTER].label,
  //       route: `/${EVENT_MONSTER_TYPES[MONSTER_TYPES.XX_MONSTER].urls}`,
  //     },
  //     {
  //       title: EVENT_MONSTER_TYPES[MONSTER_TYPES.YY_MONSTER].label,
  //       route: `/${EVENT_MONSTER_TYPES[MONSTER_TYPES.YY_MONSTER].urls}`,
  //     },
  //   ],
  // },
  {
    title: 'Book',
    activeRoute: ['/book'],
    child: [
      {
        title: 'Skill',
        route: '/book/skill',
      },
      {
        title: 'Effect',
        route: '/book/effect',
      },
      // {
      //   title: 'Equipment',
      //   route: '/book/equipment',
      // },
    ],
  },
  // {
  //   title: 'Get DRAGON',
  //   url: `https://defily.io/#/swap?inputCurrency=0xD675fF2B0ff139E14F86D87b7a6049ca7C66d76e&&outputCurrency=${tokens.dragon.address}`,
  // },
  {
    title: 'Play now!',
    route: '/game',
  },
]

export const SORT_ITEM_FIELD = {
  LOWEST_ID: {
    label: 'Lowest ID',
    value: 'LOWEST_ID',
    field: 'nftId',
    type: ORDER_TYPE.ASC,
  },
  HIGHEST_ID: {
    label: 'Highest ID',
    value: 'HIGHEST_ID',
    field: 'nftId',
    type: ORDER_TYPE.DESC,
  },
  DATELISTED: {
    label: 'Recently listed',
    value: 'DATELISTED',
    field: 'dateListed',
    type: ORDER_TYPE.DESC,
  },
  LOWEST_PRICE: {
    label: 'Lowest price',
    value: 'LOWEST_PRICE',
    field: 'price',
    type: ORDER_TYPE.ASC,
  },
  HIGHEST_PRICE: {
    label: 'Highest price',
    value: 'HIGHEST_PRICE',
    field: 'price',
    type: ORDER_TYPE.DESC,
  },
}

export const FILTER_ITEM_STATUS = {
  ALL: {
    label: 'All',
    value: null,
  },
  SELLING: {
    label: 'For sale',
    value: 'SELLING',
  },
  ACTIVE: {
    label: 'Not for sale',
    value: 'ACTIVE',
  },
  USED: {
    label: 'Used',
    value: 'USED',
  },
  BURNED: {
    label: 'Burned',
    value: 'BURNED',
  },
  NOTUSE: {
    label: 'Not use',
    value: 'NOTUSE',
  },
}

export const EXPERIENCE_TYPES = {
  A: 50,
  B: 150,
  C: 250,
  D: 350,
}

export const NFT_POSITIONS = {
  ARMOR: 1,
  FINS: 2,
  HEAD: 3,
  TAIL: 4,
  BODY: 5,
  WINGS: 6,
  HORNS: 7,
  MIDDLEHORNS: 8,
}

export const SKILL_EFFECT = {
  AttackDown: {
    name: 'Attack Down',
    icon: '/images/skill_effect/AttackDown.png',
  },
  AttackUp: {
    name: 'Attack Up',
    icon: '/images/skill_effect/AttackUp.png',
  },
  Child: {
    name: 'Attack Up',
    icon: '/images/skill_effect/AttackUp.png',
  },
  Cure: {
    name: 'Cure',
    icon: '/images/skill_effect/Cure.png',
  },
  Damage: {
    name: 'Damage',
    icon: '/images/skill_effect/Damage.png',
  },
  DefendDown: {
    name: 'Defend Down',
    icon: '/images/skill_effect/DefendDown.png',
  },
  DefendUp: {
    name: 'Defend Up',
    icon: '/images/skill_effect/DefendUp.png',
  },
  Fear: {
    name: 'Fear',
    icon: '/images/skill_effect/Fear.png',
  },
  Fragile: {
    name: 'Fragile',
    icon: '/images/skill_effect/Fragile.png',
  },
  HealthDown: {
    name: 'Health Down',
    icon: '/images/skill_effect/HealthDown.png',
  },
  HealthUp: {
    name: 'Health Up',
    icon: '/images/skill_effect/HealthUp.png',
  },
  Lethal: {
    name: 'Lethal',
    icon: '/images/skill_effect/Lethal.png',
  },
  ManaDown: {
    name: 'Mana Down',
    icon: '/images/skill_effect/ManaDown.png',
  },
  ManaUp: {
    name: 'Mana Up',
    icon: '/images/skill_effect/ManaUp.png',
  },
  MoraleDown: {
    name: 'Morale Down',
    icon: '/images/skill_effect/MoraleDown.png',
  },
  MoraleUp: {
    name: 'Morale Up',
    icon: '/images/skill_effect/MoraleUp.png',
  },
  Move: {
    name: 'Move',
    icon: '/images/skill_effect/Move.png',
  },
  Rescue: {
    name: 'Rescue',
    icon: '/images/skill_effect/Rescue.png',
  },
  Sleep: {
    name: 'Sleep',
    icon: '/images/skill_effect/Sleep.png',
  },
  SpeedDown: {
    name: 'Speed Down',
    icon: '/images/skill_effect/SpeedDown.png',
  },
  SpeedUp: {
    name: 'Speed Up',
    icon: '/images/skill_effect/SpeedUp.png',
  },
  Stench: {
    name: 'Stench',
    icon: '/images/skill_effect/Stench.png',
  },
  Stun: {
    name: 'Stun',
    icon: '/images/skill_effect/Stun.png',
  },
  Violent: {
    name: 'Violent',
    icon: '/images/skill_effect/Violent.png',
  },
}

export const RARITY_SKILL = {
  Basic: {
    label: 'Basic',
    value: 'Basic',
    image: '/images/skill_card_bg.png',
    textColor: '#F1A614',
  },
  Common: {
    label: 'Common',
    value: 'Common',
    image: '/images/bg_skill/front-common.png',
    textColor: '#fff',
  },
  Uncommon: {
    label: 'Uncommon',
    value: 'Uncommon',
    image: '/images/bg_skill/front-uncommon.png',
    textColor: '#8dd830',
  },
  Rarity: {
    label: 'Rare',
    value: 'Rarity',
    image: '/images/bg_skill/front-rare.png',
    textColor: '#295db4',
  },
  Legendary: {
    //label: 'Legendary',
    label: '',
    value: 'Legendary',
    image: '/images/bg_skill/front-legendary.png',
    textColor: '#d3572e',
  },
  Mythic: {
    //  label: 'Mythic',
    label: '',
    value: 'Mythic',
    image: '/images/bg_skill/front-mythic.png',
    textColor: '#e7a743',
  },
}

export const RARITY_ITEM = {
  A: {
    label: 'Common',
    value: 'A',
    image: '/images/bg_item/item-card-1.png',
    color: '#d9e6ec',
  },
  B: {
    label: 'Uncommon',
    value: 'B',
    image: '/images/bg_item/item-card-3.png',
    color: '#aefaff',
  },
  C: {
    // label: 'Mythic',
    value: 'C',
    image: '/images/bg_item/item-card-6.png',
    color: '#aefaff',
  },
  D: {
    //label: 'Legendary',
    value: 'D',
    image: '/images/bg_item/item-card-8.png',
    color: '#aefaff',
  },
}

export const ICON_EFFECT = {
  a: '/images/skill_effect/1.png',
  s: '/images/skill_effect/2.png',
  ss: '/images/skill_effect/3.png',
}

export const GIFT_TYPE = {
  DAILY_QUEST: {
    label: 'DAILY_QUEST',
    image: '/images/gift/gift_card.png',
  },
  PVE_GOLD: {
    label: 'PVE_GOLD',
    image: '/images/gift/gold_close.png',
  },
  PVE_SILVER: {
    label: 'PVE_SILVER',
    image: '/images/gift/silver_close.png',
  },
  EXP_WHITE: {
    label: 'EXP_WHITE',
    image: '/images/gift/gift_card.png',
  },
  EXP_BLUE: {
    label: 'EXP_BLUE',
    image: '/images/gift/gift_card.png',
  },
  EXP_VIOLET: {
    label: 'EXP_PURPLE',
    image: '/images/gift/gift_card.png',
  },
}

export const KROMA_CHAIN_ID = 0xff
export const KROMA_SEPOLIA_CHAIN_ID = 0x936
