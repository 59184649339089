import { createSlice } from '@reduxjs/toolkit'
import itemsApi from 'api/itemsApi'
import { DEFAULT_LIMIT } from 'constants/index'
import { fetchItemApproved } from './fetchItems'
const initialState = {
  myItems: undefined,
  item: undefined,
  itemType: undefined,
  listSuccessRate: undefined,
  itemApproved: undefined,
  allSkills: undefined,
  allEquipments: undefined,
  allEffects: undefined,
}
const itemsSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    setItems: (state, action) => {
      state.myItems = action.payload
    },
    setItem: (state, action) => {
      state.item = action.payload
    },
    setItemType: (state, action) => {
      state.item = action.payload
    },
    setListSuccessRate: (state, action) => {
      state.listSuccessRate = action.payload
    },
    setItemApproved: (state, action) => {
      state.itemApproved = action.payload
    },
    setAllSkills: (state, action) => {
      state.allSkills = action.payload
    },
    setAllEquipments: (state, action) => {
      state.allEquipments = action.payload
    },
    setAllEffects: (state, action) => {
      state.allEffects = action.payload
    },
  },
})

export const fetchItemsAsync =
  (params = {}) =>
  async (dispatch) => {
    try {
      const response = await itemsApi.getItems({ rowPerPage: DEFAULT_LIMIT, ...params })
      dispatch(setItems(response))
    } catch (error) {
      dispatch(setItems(undefined))
      console.log(error)
    }
  }

export const fetchDetailItem = (type, id) => async (dispatch) => {
  try {
    const promise = [itemsApi.getItem(type, id), itemsApi.getHistoriesItem(type, id)]
    const data = await Promise.all(promise)

    dispatch(
      setItem({
        ...data?.[0],
        histories: data?.[1],
      }),
    )

    return data?.[0]
  } catch (error) {
    dispatch(setItem(null))
    console.log(error)
  }
}

export const fetchListSuccessRate = () => async (dispatch) => {
  try {
    const response = await itemsApi.getUpgradeSuccessRate()
    dispatch(setListSuccessRate(response))
  } catch (error) {}
}

export const fetchDetailItemApproved = (type, id) => async (dispatch) => {
  try {
    const itemApproved = await fetchItemApproved(type, id)
    dispatch(
      setItemApproved({
        itemApproved,
        userDataLoaded: true,
      }),
    )
  } catch (error) {}
}

export const fetchAllDragonSkills =
  (params = {}) =>
  async (dispatch) => {
    try {
      const response = await itemsApi.getAllDragonSkills({ rowPerPage: DEFAULT_LIMIT, ...params })
      dispatch(setAllSkills(response))
    } catch (err) {
      dispatch(setAllSkills(undefined))
      console.log(err)
    }
  }

export const fetchAllEffects = () => async (dispatch) => {
  try {
    const response = await itemsApi.getAllEffects()
    dispatch(setAllEffects(response))
  } catch (err) {
    console.log(err)
  }
}

export const fetchAllEquipments =
  (params = {}) =>
  async (dispatch) => {
    try {
      const response = await itemsApi.getItemsEquipments({ rowPerPage: DEFAULT_LIMIT, ...params })

      dispatch(setAllEquipments(response))
    } catch (err) {
      console.log(err)
    }
  }

export const {
  setItems,
  setItem,
  setItemType,
  setListSuccessRate,
  setItemApproved,
  setAllSkills,
  setAllEquipments,
  setAllEffects,
} = itemsSlice.actions

export default itemsSlice.reducer
