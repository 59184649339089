import React, { useState } from 'react'
import { useMarketplaceVolume } from 'store/report/hook'
import { FEE_SELL_AND_SIRE, TOKEN_USED_MARKETPLACE } from '../../../constants'
import classnames from 'classnames'
import Value from '../../../components/Value/Value'

const TABS = {
  day: {
    label: 'Last 24h',
    value: 'day',
  },
  week: {
    label: '7 Days',
    value: 'week',
  },
  month: {
    label: '30 Days',
    value: 'month',
  },
}
const MarketplaceVolume = () => {
  const data = useMarketplaceVolume()

  const [tab, setTab] = useState(TABS.day)

  const viewCells = [
    {
      label: 'Total sale',
      value: typeof data?.[tab?.value]?.sale === 'number' ? data?.[tab?.value]?.sale : null,
    },
    {
      label: 'Total siring',
      value: typeof data?.[tab?.value]?.sire === 'number' ? data?.[tab?.value]?.sire : null,
    },
    {
      label: `Volume sale (${TOKEN_USED_MARKETPLACE.symbol})`,
      value: typeof data?.[tab?.value]?.totalSale === 'number' ? data?.[tab?.value]?.totalSale : null,
      hasFee: true,
    },
    {
      label: `Volume siring (${TOKEN_USED_MARKETPLACE.symbol})`,
      value: typeof data?.[tab?.value]?.totalSire === 'number' ? data?.[tab?.value]?.totalSire : null,
      hasFee: true,
    },
  ]

  const handleChangeTab = (newTab) => {
    if (newTab.value !== tab?.value) {
      setTab(newTab)
    }
  }

  return (
    <div className="m-4">
      <div className="py-3">
        <div className="flex justify-center mb-2">
          {Object.values(TABS).map((item, index) => (
            <p
              key={index}
              className={classnames(
                'text-white sm:text-lg w-24 sm:w-32 text-center py-2 sm:py-3 cursor-pointer font-bold',
                {
                  'border-b-4 border-white': item.value === tab?.value,
                },
              )}
              onClick={() => handleChangeTab(item)}
            >
              {item?.label}
            </p>
          ))}
        </div>
        <div className="grid grid-cols-2 md:grid-cols-5 rounded-xl border-2 border-blue2 overflow-hidden">
          {viewCells.map((col, index) => (
            <div
              className={classnames(
                'border-blue2 text-white text-center px-2 py-4',
                {
                  'border-l-0': index === 0,
                },
                {
                  'sm:border-l-2': [2].includes(index),
                },
                {
                  'border-l-0 md:border-l-2': [1, 3].includes(index),
                },
                {
                  'border-r-2 md:border-r-0': [0, 2].includes(index),
                },
                {
                  'border-b-2 md:border-b-0': [0, 1].includes(index),
                },
              )}
              key={index}
            >
              <p className="text-sm sm:text-sm-md lg:text-base">{col.label}</p>
              <div className="text-2xl mt-2">
                {typeof col.value === 'number' ? (
                  <>
                    <Value value={Math.round(col.value)} decimals={0} />
                    {col.hasFee ? (
                      <Value
                        className="text-xs sm:text-sm text-white50 block md:hidden"
                        prefix="Fee: "
                        unit={` (${TOKEN_USED_MARKETPLACE.symbol})`}
                        value={Math.round((col.value * FEE_SELL_AND_SIRE) / 100)}
                        decimals={0}
                      />
                    ) : null}
                  </>
                ) : (
                  '--'
                )}
              </div>
            </div>
          ))}
          <div className="border-blue2 text-white text-center px-2 py-4 border-l-2 hidden md:block">
            <p className="text-sm sm:text-sm-md lg:text-base">Volume Fee ({TOKEN_USED_MARKETPLACE.symbol})</p>
            <div className="text-2xl mt-2">
              {typeof data?.[tab?.value]?.totalSale === 'number' &&
              typeof data?.[tab?.value]?.totalSire === 'number' ? (
                <Value
                  value={Math.round(
                    ((data?.[tab?.value]?.totalSale + data?.[tab?.value]?.totalSire) * FEE_SELL_AND_SIRE) / 100,
                  )}
                  decimals={0}
                />
              ) : (
                '--'
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MarketplaceVolume
