import React from 'react'
import withLayout from 'hoc/withLayout'
import { useFetchMarketplaceVolume } from 'store/report/hook'
import Container from '../../components/Container/Container'
import MarketplaceVolume from './components/MarketplaceVolume'
import RecentListed from './components/RecentListed'
import RecentSold from './components/RecentSold'

const Dashboard = () => {
  useFetchMarketplaceVolume()
  return (
    <Container className="pt-10 pb-36">
      <MarketplaceVolume />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <RecentListed />
        <RecentSold />
      </div>
    </Container>
  )
}

export default withLayout(Dashboard)
