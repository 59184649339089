import React, { useState } from 'react'
import Box from '../../../components/Box/Box'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

const BoxTab = ({ title, tabActive, tabs, onChangeTab, children }) => {
  return (
    <div className="mx-4">
      <div className="flex justify-between py-4">
        <h3 className="text-white">{title}</h3>
        <Link className="underline text-blue4 text-sm-md" to="/marketplace?sale=AUCTION">
          View marketplace >
        </Link>
      </div>

      <Box className="border-1 border-blue4 overflow-hidden">
        <div className="grid grid-cols-2 border-b-1 border-blue4 font-bold">
          {tabs.map((label, index) => (
            <div
              key={index}
              className={classnames(
                'text-center text-center py-2.5 sm:py-5 cursor-pointer  text-sm-md sm:text-base',
                {
                  'text-white bg-primary': tabActive === index,
                },
                {
                  'text-white50': tabActive !== index,
                },
              )}
              onClick={() => onChangeTab(index)}
            >
              {label}
            </div>
          ))}
        </div>
        <div className="bg-blue1">{children}</div>
      </Box>
    </div>
  )
}

export default BoxTab
