import { useDispatch, useSelector } from 'react-redux'
import useRefresh from '../../hooks/useRefresh'
import { useEffect } from 'react'
import {
  fetchDetailDragon,
  fetchDragonRemainAsync,
  fetchDragonsAdventureAsync,
  fetchDragonsAsync,
  fetchMyDragonsAsync,
  fetchUserDataDetailDragon,
  fetchUserDataDragonHome,
  fetchUserDataBreading,
  fetchUserDataDragonMarketplace,
  fetchUserDataTraining,
  setDragon,
} from './index'
import { useHistory, useParams } from 'react-router-dom'
import { useCanUpdateQuery, usePathStoreQuery, useQuerySearch } from '../search/hook'
import { useDragonCave } from '../dragonCave/hook'
import { fetchDragonsCaveAsync } from '../dragonCave'
import { DRAGON_CAVE_ADDRESS } from 'config'
import { getUrlQuery, useQuery } from 'utils'
import { DEFAULT_LIMIT, DRAGON_TYPE, FILTER_DRAGON_FOR_SALE, MAX_NUMBER_QUERY } from 'constants/index'
import { useTabSelect } from '../breed/hook'
import { fetchListDragonsBreedAsync, setListDragonsBreed, setTabSelect } from '../breed'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'

export const useMyDragons = () => useSelector((state) => state.dragons.myDragons)

export const useUserDataDragon = () => useSelector((state) => state.dragons.userData)

export const useUserDataDragonHome = () => useSelector((state) => state.dragons.userDataHome)

export const useUserDataBreed = () => useSelector((state) => state.dragons.userDataBreeding)

export const useDragon = () => useSelector((state) => state.dragons.dragon)

export const useDragonRemain = () => useSelector((state) => state.dragons.dragonRemain)

export const useDragons = () => useSelector((state) => state.dragons.dragons)

export const useUserDataDetailDragon = () => useSelector((state) => state.dragons.userDataCore)

export const useUserDataTraining = () => useSelector((state) => state.dragons.userDataCore)

export const useDragonsAdventure = () => useSelector((state) => state.dragons.dragonsAdventure)

export const useFetchUserDataDetailDragon = () => {
  const { id } = useParams()
  const { account } = useActiveWeb3React()
  const dispatch = useDispatch()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    if (account) {
      dispatch(fetchUserDataDetailDragon(account, id))
    }
  }, [account, dispatch, fastRefresh, id])
}

export const useFetchDataUserDragonMarketplace = () => {
  const dispatch = useDispatch()
  const { fastRefresh } = useRefresh()
  const { account } = useActiveWeb3React()

  useEffect(() => {
    if (account) {
      dispatch(fetchUserDataDragonMarketplace(account))
    }
  }, [dispatch, fastRefresh, account])
}

export const useFetchDataUserDragonHome = () => {
  const dispatch = useDispatch()
  const { fastRefresh } = useRefresh()
  const { account } = useActiveWeb3React()

  useEffect(() => {
    if (account) {
      dispatch(fetchUserDataDragonHome(account))
    }
  }, [dispatch, fastRefresh, account])
}

export const useFetchDataUserBreeding = () => {
  const dispatch = useDispatch()
  const { fastRefresh } = useRefresh()
  const { account } = useActiveWeb3React()

  useEffect(() => {
    if (account) {
      dispatch(fetchUserDataBreading(account))
    }
  }, [dispatch, fastRefresh, account])
}

export const useFetchDragonRemain = () => {
  const dispatch = useDispatch()
  const { fastRefresh } = useRefresh()
  const { account } = useActiveWeb3React()
  useEffect(() => {
    if (account) {
      dispatch(fetchDragonRemainAsync(account))
    }
  }, [dispatch, fastRefresh, account])
}

export const useFetchMyDragons = () => {
  const { account } = useActiveWeb3React()
  const dispatch = useDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchMyDragonsAsync(account))
    }
  }, [account])
}

export const formatQueryDragons = (query) => {
  const object = Object.assign({}, query)
  let customValue = {}
  if (object?.potential) {
    const value = object.potential.split(',')
    const potential = {
      from: Number(value[0]) === MAX_NUMBER_QUERY ? MAX_NUMBER_QUERY : Number(value[0]) - 1,
      to: Number(value[1]) === MAX_NUMBER_QUERY ? MAX_NUMBER_QUERY : Number(value[1]) - 1,
    }
    customValue.potential = `${potential.from},${potential.to}`
  }
  if (object?.type) {
    const value = object.type.split(',')
    if (value.includes(DRAGON_TYPE.MUTATION)) {
      const index = value.indexOf(DRAGON_TYPE.MUTATION)
      value.splice(index, 1)
      if (!value?.length) {
        delete object.type
      } else {
        object.type = value.join(',')
      }
      customValue.mutant = true
    }
  }

  if (object?.price) {
    const price = object.price.split(',')
    if (price?.length === 2) {
      if (Number(price?.[0]) === 0 && Number(price?.[1] >= 100)) {
        delete object.price
      } else {
        object.price = `${Number(price?.[0]) * 1000},${price?.[1] === 100 ? 1000 * 1000 : price?.[1] * 1000}`
      }
    }
  }

  if (object?.price2) {
    object.price = object.price2
    delete object.price2
  }

  return {
    ...object,
    ...customValue,
  }
}

export const useFetchDragon = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { account } = useActiveWeb3React()
  const { fastRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchDetailDragon(account, id))
  }, [id, dispatch, fastRefresh, account])

  useEffect(() => {
    return () => {
      dispatch(setDragon(undefined))
    }
  }, [])
}

export const useFetchDragons = () => {
  const dispatch = useDispatch()
  const dragons = useDragons()
  const { account } = useActiveWeb3React()
  const { push, location } = useHistory()
  const { slowRefresh } = useRefresh()
  const searchObj = useQuerySearch()
  const queryUrl = useQuery()
  const canUpdateQuery = useCanUpdateQuery()
  const pathNameQuery = usePathStoreQuery()
  useEffect(() => {
    if (canUpdateQuery && location.pathname === pathNameQuery) {
      push(getUrlQuery(location.pathname, searchObj))
    }
  }, [JSON.stringify(searchObj)])

  useEffect(() => {
    const page = 1
    dispatch(fetchDragonsAsync(formatQueryDragons({ page, rowPerPage: DEFAULT_LIMIT, ...queryUrl })))
  }, [JSON.stringify(queryUrl)])
  useEffect(() => {
    const page = dragons?.currentPage || 1
    dispatch(fetchDragonsAsync(formatQueryDragons({ page, rowPerPage: DEFAULT_LIMIT, ...queryUrl })))
  }, [slowRefresh, account])
}

export const useFetchDragonCave = () => {
  const { account } = useActiveWeb3React()
  const { push, location } = useHistory()
  const dispatch = useDispatch()
  const searchObj = useQuerySearch()
  const queryUrl = useQuery()
  const dragons = useDragonCave()
  const { slowRefresh } = useRefresh()
  const canUpdateQuery = useCanUpdateQuery()
  const pathNameQuery = usePathStoreQuery()
  useEffect(() => {
    if (canUpdateQuery && location.pathname === pathNameQuery) {
      push(getUrlQuery(location.pathname, searchObj))
    }
  }, [JSON.stringify(searchObj)])

  useEffect(() => {
    const page = 1
    dispatch(
      fetchDragonsCaveAsync(
        DRAGON_CAVE_ADDRESS.address,
        formatQueryDragons({ page, rowPerPage: DEFAULT_LIMIT, ...queryUrl }),
      ),
    )
  }, [JSON.stringify(queryUrl)])
  useEffect(() => {
    const page = dragons?.currentPage || 1
    dispatch(
      fetchDragonsCaveAsync(
        DRAGON_CAVE_ADDRESS.address,
        formatQueryDragons({ page, rowPerPage: DEFAULT_LIMIT, ...queryUrl }),
      ),
    )
  }, [slowRefresh, account])
}

export const useFetchMyDragon = (defaultQuery = {}, fast = false) => {
  const { account } = useActiveWeb3React()
  const { push, location } = useHistory()
  const dispatch = useDispatch()
  const searchObj = useQuerySearch()
  const queryUrl = useQuery()
  const dragons = useMyDragons()
  const canUpdateQuery = useCanUpdateQuery()
  const { fastRefresh, slowRefresh } = useRefresh()
  const pathNameQuery = usePathStoreQuery()
  useEffect(() => {
    if (canUpdateQuery && location.pathname === pathNameQuery) {
      push(getUrlQuery(location.pathname, searchObj))
    }
  }, [JSON.stringify(searchObj)])

  useEffect(() => {
    const page = 1
    dispatch(fetchMyDragonsAsync(account, formatQueryDragons({ page, ...queryUrl, ...defaultQuery })))
  }, [JSON.stringify(queryUrl)])
  useEffect(() => {
    const page = dragons?.currentPage || 1
    dispatch(fetchMyDragonsAsync(account, formatQueryDragons({ page, ...queryUrl, ...defaultQuery })))
  }, [fast ? fastRefresh : slowRefresh, account])
}

export const useFetchDragonsBreed = () => {
  const { account } = useActiveWeb3React()
  const dispatch = useDispatch()
  const query = useQuerySearch()
  const dragons = useMyDragons()
  const { slowRefresh } = useRefresh()
  const tab = useTabSelect()
  const defaultQuery = {
    1: {
      type: DRAGON_TYPE.DRAGON,
      sale: FILTER_DRAGON_FOR_SALE.NOT_FOR_SALE.value,
    },
    2: {
      type: DRAGON_TYPE.DRAGON,
      sale: FILTER_DRAGON_FOR_SALE.SIRING.value,
    },
  }
  useEffect(() => {
    if (tab) {
      const page = 1
      dispatch(fetchListDragonsBreedAsync(account, formatQueryDragons({ page, ...query, ...defaultQuery?.[tab] }), tab))
    }
  }, [JSON.stringify(query), tab])
  useEffect(() => {
    if (tab) {
      const page = dragons?.currentPage || 1
      dispatch(fetchListDragonsBreedAsync(account, formatQueryDragons({ page, ...query, ...defaultQuery?.[tab] }), tab))
    }
  }, [slowRefresh, account])
  useEffect(() => {
    return () => {
      dispatch(setListDragonsBreed(undefined))
      dispatch(setTabSelect(undefined))
    }
  }, [])
}

export const useFetchUserDataTraining = () => {
  const { account } = useActiveWeb3React()
  const dispatch = useDispatch()
  const { fastRefresh } = useRefresh()
  useEffect(() => {
    if (account) {
      dispatch(fetchUserDataTraining(account))
    }
  }, [account, dispatch, fastRefresh])
}

export const useFetchDragonAdventure = () => {
  const { account } = useActiveWeb3React()
  const dispatch = useDispatch()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    if (account) {
      dispatch(fetchDragonsAdventureAsync(account))
    }
  }, [account, dispatch, fastRefresh])
}
