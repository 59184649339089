import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  walletConnect: false,
  type: undefined,
  data: undefined,
  network: false,
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    toggleWalletConnect: (state) => {
      state.walletConnect = !state.walletConnect
    },
    toggleModal: (state, action) => {
      state.type = action.payload?.type || undefined
      state.data = action.payload?.type || undefined
    },
    toggleNetwork: (state) => {
      state.network = !state.network
    },
  },
})

export const { toggleWalletConnect, toggleModal, toggleNetwork } = modalSlice.actions

export default modalSlice.reducer
