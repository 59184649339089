import React from 'react'
import Container from '../../components/Container/Container'
import NavBack from '../../components/Navigate/Back'
import withLayout from '../../hoc/withLayout'
import DetailMonster from './components/DetailMonster'
import { EVENT_MONSTER_TYPES, EVENT_TYPES, MONSTER_TYPES } from '../../constants'
import { useFetchCooldown, useFetchIdAvailableMonsters, useFetchMonster, useMonster } from '../../store/monster/hook'
import LoadingPage from '../../components/Loader/LoadingPage'

const Monster = () => {
  useFetchCooldown()
  useFetchMonster()
  useFetchIdAvailableMonsters()
  const monster = useMonster()

  if (monster === undefined) {
    return <LoadingPage />
  }

  return (
    <div className="mx-2.5">
      <NavBack />
      <Container className="mb-16">
        <h2 className="text-white text-2xl text-center">{EVENT_MONSTER_TYPES[monster?.type].label}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 mt-10 gap-4">
          <div className="flex items-center justify-center px-5">
            <img className="max-w-lg w-full" src={`/images/${monster?.type}.png`} />
          </div>
          <DetailMonster monster={monster} />
        </div>
      </Container>
    </div>
  )
}

export default withLayout(Monster, null, '#0A1520')
