import { createSlice } from '@reduxjs/toolkit'
import {
  getCooldown,
  getIdAvailableMonsters,
  getMonster,
  getRewardEvents,
  getXXMonsters,
  getYYMonsters,
} from './fetchMonster'
import { MONSTER_TYPES } from '../../constants'
import eventApi from '../../api/eventApi'

const initialState = {
  monster: undefined,
  monsters: undefined,
  tab: 1,
  dragons: undefined,
  idAvailableMonsters: undefined,
  idDragons: undefined,
  reward: undefined,
  cooldown: undefined,
}

const monstersSlice = createSlice({
  name: 'monster',
  initialState,
  reducers: {
    setMonster: (state, action) => {
      state.monster = action.payload
    },
    setMonsters: (state, action) => {
      state.monsters = action.payload
    },
    setDragons: (state, action) => {
      state.dragons = action.payload
    },
    setTabMonsters: (state, action) => {
      state.tab = action.payload
    },
    setIdAvailableMonsters: (state, action) => {
      state.idAvailableMonsters = action.payload
    },
    setIdDragons: (state, action) => {
      state.idDragons = action.payload
    },
    setReward: (state, action) => {
      state.reward = action.payload
    },
    setCooldown: (state, action) => {
      state.cooldown = action.payload
    },
  },
})

export const fetchMonstersAsync = (type, tab) => async (dispatch) => {
  try {
    let response
    if (type === MONSTER_TYPES.XX_MONSTER) {
      response = await getXXMonsters(tab === 1)
    } else {
      response = await getYYMonsters(tab === 1)
    }
    dispatch(setMonsters(response))
  } catch (error) {}
}

export const fetchXXMonstersAsync = () => async (dispatch) => {
  try {
    const response = await getXXMonsters()
    dispatch(setMonsters(response))
  } catch (error) {}
}

export const fetchYYMonstersAsync = () => async (dispatch) => {
  try {
    const response = await getYYMonsters()
    dispatch(setMonsters(response))
  } catch (error) {}
}

export const fetchMonsterAsync = (type, id) => async (dispatch) => {
  try {
    const response = await getMonster(type, id)
    dispatch(setMonster(response))
  } catch (error) {}
}

export const fetchDragonsCanFightAsync = (monsterId, params, type) => async (dispatch) => {
  try {
    const response = await eventApi.getDragons(monsterId, params)
    dispatch(setDragons(response))
  } catch (error) {}
}

export const fetchIdAvailableMonsters = (type) => async (dispatch) => {
  try {
    const response = await getIdAvailableMonsters(type)
    dispatch(setIdAvailableMonsters(response))
  } catch (error) {}
}

export const fetchIdDragonsAsync = () => async (dispatch) => {
  try {
    const response = await eventApi.getIdDragons()
    dispatch(setIdDragons(response))
  } catch (error) {}
}

export const fetchRewardEvents = (type) => async (dispatch) => {
  try {
    const idDragons = await eventApi.getIdDragons()
    const reward = await getRewardEvents(type, idDragons)
    dispatch(setIdDragons(idDragons))
    dispatch(setReward(reward))
  } catch (error) {}
}

export const fetchCooldownAsync = () => async (dispatch) => {
  try {
    const cooldown = await getCooldown()
    dispatch(setCooldown(cooldown))
  } catch (error) {}
}

export const {
  setMonster,
  setMonsters,
  setDragons,
  setTabMonsters,
  setIdAvailableMonsters,
  setIdDragons,
  setReward,
  setCooldown,
} = monstersSlice.actions

export default monstersSlice.reducer
