import React, { useEffect, useState } from 'react'
import { formatNumber } from '../../utils/formatBalance'
import { TOKEN_USED_MARKETPLACE } from '../../constants'
import { Range } from 'rc-slider'
import useDebounce from '../../hooks/useDebounce'
import { Minus } from 'react-feather'

const Input = ({ ...rest }) => {
  return (
    <div style={{ width: '45%' }} className="border-blue2 border-2 rounded-lg px-2 inline">
      <input
        {...rest}
        style={{ maxWidth: '100%' }}
        className="text-blue4 bg-transparent placeholder-blue2 py-0.5 custom-input-number custom-font-size-placeholder"
      />
    </div>
  )
}

const MAX_PRICE_FILTER = 100
const FilterPrice = ({ searchDragons, handleChangeFilterNumber }) => {
  const price2 = searchDragons?.price2

  const [price, setPrice] = useState({ from: 0, to: MAX_PRICE_FILTER })
  const [fromInput, setFromInput] = useState('')
  const [toInput, setToInput] = useState('')
  const priceString = useDebounce(JSON.stringify(price), 1000)
  const fromInputDebounce = useDebounce(fromInput, 1000)
  const toInputDebounce = useDebounce(toInput, 1000)
  const handleChangePrice = (value) => {
    setPrice({ from: value?.[0], to: value?.[1] })
    handleChangeFilterNumber('price2', undefined)
  }

  const handleChangeInput = (value, isFrom = true) => {
    if (isFrom) {
      setFromInput(value === '' ? '' : Number(value))
    } else {
      setToInput(value === '' ? '' : Number(value))
    }
    handleChangeFilterNumber('price', undefined)
  }

  useEffect(() => {
    handleChangeFilterNumber('price', price)
  }, [priceString])

  useEffect(() => {
    if (searchDragons?.price?.from !== price?.from || searchDragons?.price?.to !== price?.to) {
      setPrice(searchDragons?.price)
    }
  }, [searchDragons?.price])

  useEffect(() => {
    if (price2 === undefined || price2.from === undefined) {
      setFromInput('')
    }
    if (price2 === undefined || price2.to === undefined) {
      setToInput('')
    }
    if (price2?.from && fromInput !== price2?.from) {
      setFromInput(Number(price2?.from))
    }
    if (price2?.to && toInput !== price2?.to) {
      setToInput(Number(price2?.to))
    }
  }, [price2])

  useEffect(() => {
    handleChangeFilterNumber('price2', {
      from: fromInput,
      to: toInput,
    })
  }, [fromInputDebounce, toInputDebounce])

  return (
    <div className="px-6">
      <p className="text-blue4 text-sm-md mt-2 mb-3">
        Price:
        {typeof price2?.from !== 'number' && typeof price2?.to !== 'number' ? (
          <>
            <span className="px-1">
              <strong className="text-white mr-1">{price?.from ? formatNumber(price?.from * 1000, 0, 0) : 0}</strong>
              {TOKEN_USED_MARKETPLACE.symbol}
            </span>
            -
            <span className="px-1">
              <strong className="text-white mr-1">
                {formatNumber(
                  (!price?.to || price?.to >= MAX_PRICE_FILTER ? MAX_PRICE_FILTER : price?.to) * 1000,
                  0,
                  0,
                )}
                {!price?.to || price?.to >= MAX_PRICE_FILTER ? '+' : ''}
              </strong>
              {TOKEN_USED_MARKETPLACE.symbol}
            </span>
          </>
        ) : null}
      </p>
      <Range
        allowCross={false}
        pushable
        step={0.2}
        defaultValue={[0, 100]}
        value={[price?.from || 0, price?.to || 100]}
        onChange={(value) => handleChangePrice(value)}
      />
      <p className="text-center">
        -<span className="text-blue4 px-2 text-sm-md">OR</span>-
      </p>
      <div className="flex justify-between items-center">
        <Input type="number" placeholder="From" value={fromInput} onChange={(e) => handleChangeInput(e.target.value)} />
        <Minus size={17} />
        <Input
          type="number"
          placeholder="To"
          value={toInput}
          onChange={(e) => handleChangeInput(e.target.value, false)}
        />
      </div>
    </div>
  )
}

export default FilterPrice
