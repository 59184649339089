import { MONSTER_TYPES } from '../../constants'

export const fightMonster = async (contract, dragonId, monsterId, type) => {
  const method = {
    [MONSTER_TYPES.XX_MONSTER]: 'fightMonsterXX',
    [MONSTER_TYPES.YY_MONSTER]: 'fightMonsterYY',
  }
  const tx = await contract[method[type]](dragonId, monsterId)
  await tx.wait()
  return tx.transactionHash
}
export const claimRewardMonster = async (contract, type, idDragons) => {
  const method = {
    [MONSTER_TYPES.XX_MONSTER]: 'claimXXRewardByOwner',
    [MONSTER_TYPES.YY_MONSTER]: 'claimYYRewardByOwner',
  }
  const tx = await contract[method[type]](idDragons)
  await tx.wait()
  return tx.transactionHash
}
