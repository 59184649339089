import React, { useEffect, useMemo, useState } from 'react'
import Badge from 'components/Badge/Badge'
import classnames from 'classnames'
import Countdown, { zeroPad } from 'react-countdown'
import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast'
import Web3 from 'web3'
import useInterval from 'hooks/useInterval'
import dragonsApi from 'api/dragonsApi'
import { useFetchUserDataDetailDragon, useUserDataDetailDragon } from 'store/dragons/hook'
import Button from 'components/Button/Button'
import { getImageURL } from 'utils'
import ActionsDragonLeft from './ActionsDragonLeft'
import { DRAGON_STATUS, DRAGON_TYPE } from 'constants/index'
import ButtonRefresh from './ButtonRefresh'
import { useHistory } from 'react-router-dom'
import { formatDDMMYYYYHHmm } from '../../../utils/formatDateTime'
import useActiveWeb3React from '../../../hooks/useActiveWeb3React'

const ViewDragon = ({ dragon, onFavorites, updateData }) => {
  useFetchUserDataDetailDragon()
  const { push } = useHistory()
  const { account } = useActiveWeb3React()
  const userData = useUserDataDetailDragon()
  const { id, image, name, favorite } = dragon
  const [canBirth, setCanBirth] = useState(false)
  const [isHatching, setHatching] = useState(false)
  const [canHatch, setCanHatch] = useState(false)
  const [hatchSuccess, setHatchSuccess] = useState(false)

  const isEgg = dragon?.type === 'EGG'
  const isOwner =
    account && dragon?.owner && Web3.utils.toChecksumAddress(account) === Web3.utils.toChecksumAddress(dragon?.owner)

  const dragonStatus = useMemo(() => {
    if (dragon?.type === DRAGON_TYPE.DRAGON) {
      if (dragon?.isGestating) return DRAGON_STATUS.PREGNANT
      if (!dragon?.isReady) {
        return DRAGON_STATUS.RESTING
      }
    }
    return null
  }, [dragon])

  const onHatchDragon = async () => {
    try {
      setHatching(true)
      await dragonsApi.hatchDragon(account, dragon?.id)
      setHatchSuccess(true)
      showToastSuccess('Hatched successfully', 'Grrrrr! Your dracora came out of its egg!')
      setHatching(false)
    } catch (error) {
      setHatching(false)
      console.log(error)
      showToastError('Error', error?.response?.data?.errors?.[0]?.msg || 'Please try again.')
    }
  }

  useInterval(() => {
    if (!canHatch && dragon?.hatched < Date.now()) {
      setCanHatch(true)
    }
    if (dragon?.isGestating && dragon?.timeLock < Date.now() && !canBirth) {
      setCanBirth(true)
    }
  }, 1000)

  useEffect(() => {
    if (canBirth) {
      setCanBirth(false)
    }
  }, [dragon?.id, dragon?.timeLock])

  const rendererIncubation = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) return null
    return (
      <div className="text-white mb-2">
        <p className="text-sm-md">Egg incubation</p>
        <p className="font-bold text-xl">
          {zeroPad(days)}d {zeroPad(hours)}h {zeroPad(minutes)}m {zeroPad(seconds)}s
        </p>
      </div>
    )
  }

  const rendererPregnancy = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) return null
    return (
      <div className="text-white mb-2">
        <p className="text-sm-md">{dragon?.isGestating ? 'Pregnancy time' : 'Resting time'}</p>
        <p className="font-bold text-xl">
          {zeroPad(days)}d {zeroPad(hours)}h {zeroPad(minutes)}m {zeroPad(seconds)}s
        </p>
      </div>
    )
  }

  return (
    <div className="p-4">
      <div className="flex">
        <div>
          <Badge className={classnames(`bg-${dragon?.class?.toLowerCase()}`, 'text-white px-4 text-sm-md')}>
            #{id}
          </Badge>
        </div>
        {dragon?.birth ? (
          <div className="ml-2">
            <Badge className="text-white px-4 text-sm-md cursor-pointer bg-gray-500">
              Birthday: {formatDDMMYYYYHHmm(dragon?.birth)}
            </Badge>
          </div>
        ) : null}
      </div>
      <div className="flex flex-wrap">
        {dragon?.mutant ? (
          <div className="mt-3 mr-2">
            <Badge className="bg-mutation text-white px-4 text-sm-md">Mutation</Badge>
          </div>
        ) : null}
        {dragonStatus ? (
          <div className="mr-2 mt-3">
            <Badge
              className={classnames(
                dragonStatus === DRAGON_STATUS.PREGNANT && canBirth ? 'bg-green1' : 'bg-skin',
                'text-white px-4 text-sm-md',
              )}
            >
              {dragonStatus === DRAGON_STATUS.PREGNANT && canBirth ? 'Give birth' : dragonStatus}
            </Badge>
          </div>
        ) : null}
        {dragon?.isGestating && dragon?.siringWithId ? (
          <div className="mt-3">
            <Badge
              className="text-white px-4 text-sm-md cursor-pointer bg-gray-500"
              onClick={() => push(`/dragon/${dragon.siringWithId}`)}
            >
              Mated with #{dragon.siringWithId}
            </Badge>
          </div>
        ) : null}
      </div>
      <p className="text-white my-2">{name}</p>
      {dragon?.hatched ? <Countdown zeroPadTime={2} date={dragon?.hatched} renderer={rendererIncubation} /> : null}
      {dragon?.timeLock ? <Countdown zeroPadTime={2} date={dragon?.timeLock} renderer={rendererPregnancy} /> : null}
      <div className="flex justify-between items-center">
        <ButtonRefresh dragon={dragon} isOwner={isOwner} updateData={updateData} />
        {account ? (
          <img
            className="w-8 h-8 cursor-pointer mr-4"
            src={favorite ? '/icon/heart-red.png' : '/icon/heart-white.png'}
            onClick={() => onFavorites(dragon?.id)}
          />
        ) : null}
      </div>
      <div>
        <div className={classnames('flex px-4 pt-4', isEgg ? 'justify-center' : 'justify-start')}>
          {!hatchSuccess && isEgg ? (
            <div className="relative w-80 lg:w-96 h-80 lg:h-96">
              <img className="absolute bottom-0" src="/images/floor-shadow-1.png" />
              <div className="absolute top-0 left-0 w-full h-full">
                <div
                  className={classnames('relative w-80 lg:w-96 h-80 lg:h-96 animation-egg', {
                    'egg-can-hatch-animate': isHatching,
                  })}
                >
                  <img
                    className="absolute z-10 top-0 left-0 right-0 bottom-0 h-full"
                    src={`/images/eggs/egg-${dragon?.class?.toLowerCase()}-default.png`}
                  />
                  <img
                    className="absolute top-0 left-0 right-0 bottom-0 h-full animate-egg-light"
                    src={`/images/eggs/egg-${dragon?.class?.toLowerCase()}-light.png`}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="relative w-60 sm:w-72 lg:w-96 h-60 sm:h-80 mt-10">
              {image?.root ? (
                <>
                  <img
                    className={classnames('absolute bottom-6 -right-8 opacity-50 z-0', {
                      'dragon-shadow-animate': hatchSuccess,
                    })}
                    src="/images/shadow-egg.png"
                  />
                  <div
                    className={classnames('absolute top-0 left-0 w-full h-full dragon-view-animate', {
                      'dragon-show-hatched': hatchSuccess,
                    })}
                  >
                    <img
                      src="/images/blur-background.png"
                      className="h-full absolute right-0 transform translate-x-16 sm:translate-x-24"
                    />
                    <div className="relative flex justify-end">
                      <img src={getImageURL(image)} className={classnames('custom-image-dragon z-20')} />
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          )}
        </div>
        <div>
          {!hatchSuccess && isEgg && canHatch && isOwner ? (
            <div className="flex justify-center">
              <Button outline className="z-10" disabled={isHatching} isLoading={isHatching} onClick={onHatchDragon}>
                Hatch
              </Button>
            </div>
          ) : null}
          <ActionsDragonLeft dragon={dragon} userData={userData} />
        </div>
      </div>
    </div>
  )
}

export default ViewDragon
