import { createSlice } from '@reduxjs/toolkit'
import notificationApi from 'api/notificationApi'
import { DEFAULT_LIMIT } from 'constants/index'

const initialState = {
  notifications: undefined,
  numberNotify: undefined,
}

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload
    },
    updateNotifications: (state, action) => {
      if (Array.isArray(state.notifications)) {
        let notifications = [...state.notifications, ...action.payload]
        notifications = notifications
          .filter((item, pos) => notifications.findIndex((x) => x._id === item?._id) === pos)
          ?.sort((a, b) => (a?.timestamp > b?.timestamp ? -1 : 1))
        state.notifications = notifications
      } else {
        state.notifications = action.payload?.sort((a, b) => (a?.timestamp < b?.timestamp ? -1 : 1)) || []
      }
    },
    setNumberNotify: (state, action) => {
      state.numberNotify = action.payload
    },
  },
})

export const fetchNotificationAsync = (params) => async (dispatch) => {
  try {
    const response = await notificationApi.getNotifications({ rowPerPage: DEFAULT_LIMIT, ...params })
    if (Array.isArray(response)) {
      const notifications = response.map((item) => ({ ...item, timestamp: new Date(item?.date).getTime() }))
      dispatch(updateNotifications(notifications))
      return notifications
    }
  } catch (error) {}
}

export const fetchNumberNotifyAsync = () => async (dispatch) => {
  try {
    const response = await notificationApi.getNumberNotify()
    dispatch(setNumberNotify(response))
  } catch (error) {}
}

export const { setNumberNotify, setNotifications, updateNotifications } = notificationSlice.actions

export default notificationSlice.reducer
