import { useCallback } from 'react'
import { useBirthContract } from './useContract'
import useActiveWeb3React from './useActiveWeb3React'
import { giveBirth } from '../utils/calls/birth'

export const useBirth = (coreAddress) => {
  const { account } = useActiveWeb3React()
  const contract = useBirthContract(coreAddress)
  const handleBirth = useCallback(
    async (idToken) => {
      const txHash = await giveBirth(contract, idToken)
      return txHash
    },
    [account, coreAddress],
  )

  return { onBirth: handleBirth }
}
