import { useCallback } from 'react'
import { useCoreContract, useSaleClockAuctionContract, useMarketPlaceNFTContract } from './useContract'
import useActiveWeb3React from './useActiveWeb3React'
import { cancelSellDragon, sellDragon, sellItem, cancelSellItem } from '../utils/calls/auction'

export const useSell = (coreAddress) => {
  const { account } = useActiveWeb3React()
  const contract = useCoreContract(coreAddress)
  const handleSell = useCallback(
    async (idToken, price, decimals) => {
      const txHash = await sellDragon(contract, idToken, price, decimals)
      return txHash
    },
    [coreAddress, account],
  )

  return { onSell: handleSell }
}

export const useCancelSell = (saleAddress) => {
  const { account } = useActiveWeb3React()
  const contract = useSaleClockAuctionContract(saleAddress)
  const handleCancel = useCallback(
    async (id) => {
      const txHash = await cancelSellDragon(contract, id)
      return txHash
    },
    [saleAddress, account],
  )

  return { onCancelSell: handleCancel }
}

export const useSellItem = (marketPlaceAddress) => {
  const { account } = useActiveWeb3React()
  const contract = useMarketPlaceNFTContract(marketPlaceAddress)
  const handleSell = useCallback(
    async (addressToken, idToken, price, decimals) => {
      const txHash = await sellItem(contract, addressToken, idToken, price, decimals)
      return txHash
    },
    [marketPlaceAddress, account],
  )

  return { onSell: handleSell }
}

export const useCancelSellItem = (marketPlaceAddress) => {
  const { account } = useActiveWeb3React()
  const contract = useMarketPlaceNFTContract(marketPlaceAddress)
  const handleCancelSell = useCallback(
    async (listingId) => {
      const txHash = await cancelSellItem(contract, listingId)
      return txHash
    },
    [marketPlaceAddress, account],
  )
  return { onCancelSell: handleCancelSell }
}
