import React, { useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Box from '../Box/Box'
import Value from 'components/Value/Value'
import Badge from '../Badge/Badge'
import { SKILL_EFFECT, RARITY_SKILL, ICON_EFFECT } from 'constants/index'
import { RefreshCw } from 'react-feather'
import Tooltip from 'components/Tooltip/Tooltip'
import dragonsApi from 'api/dragonsApi'
import { useDragon } from 'store/dragons/hook'
import { useDispatch } from 'react-redux'
import { fetchDetailDragon } from 'store/dragons'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import Web3 from 'web3'

const ButtonSwitchSkill = ({ skill }) => {
  const dragon = useDragon()
  const dispatch = useDispatch()
  const { account } = useActiveWeb3React()
  const [isPendingUseDefault, setIsPendingUseDefault] = useState(false)

  const handleSwitchToDefaultSkill = async () => {
    setIsPendingUseDefault(true)
    try {
      await dragonsApi.useDefaultSkill({
        dragon: dragon?._id,
        skill: skill?.skillDefault || skill?._id,
        useDefault: skill?.nftId !== undefined,
      })
      await dragonsApi.migrateData(dragon?.id)
      dispatch(fetchDetailDragon(account, dragon?.id))
      setIsPendingUseDefault(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Tooltip
      classNameToolTip="w-48 bg-black border-2 border-blue2 right-0 text-center"
      tooltip={<span className="text-sm-md">{skill?.nftId ? 'Use default skill' : 'Use NFT skill'}</span>}
    >
      <button onClick={handleSwitchToDefaultSkill} className="text-sm-md p-1 rounded-full bg-metal">
        <div
          className={classnames({
            'icon-refresh-animate': isPendingUseDefault,
          })}
        >
          <RefreshCw size={16} />
        </div>
      </button>
    </Tooltip>
  )
}
export default function SkillCard({
  dragonOwner,
  skill,
  className,
  showButtonSwitch = false,
  ignoreLevel = false,
  ...rest
}) {
  const { account } = useActiveWeb3React()
  const description = skill?.effect?.description
  const effectKey = skill?.effect?.key
  const bgSkill = skill?.rarity ? RARITY_SKILL?.[skill?.rarity]?.image : '/images/skill_card_bg.png'
  const textColor = skill?.rarity ? RARITY_SKILL?.[skill?.rarity]?.textColor : '#F1A614'
  return (
    <div className="relative flex items-center">
      {(skill?.nftId !== undefined || skill?.skillNft !== undefined) &&
      showButtonSwitch &&
      account &&
      Web3.utils.toChecksumAddress(account) === Web3.utils.toChecksumAddress(dragonOwner) ? (
        <div className="absolute top-1 right-0 transform -translate-y-1/2 z-40">
          <ButtonSwitchSkill skill={skill} />
        </div>
      ) : null}
      <Box
        {...rest}
        style={{
          backgroundImage: `url(${bgSkill})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
        className={classnames('flex flex-col items-center relative background-skill-card', className)}
      >
        {skill?.nftId !== undefined ? (
          <>
            <div className="absolute -top-2.5 -left-2">
              <Badge className="bg-metal py-0.5 px-4 text-sm-md badge-nftid-skill">#{skill?.nftId}</Badge>
            </div>
          </>
        ) : null}
        <div className="absolute left-0 top-10 rounded-r-full bg-black70 z-20 skill-rarity text-sm">
          <p
            style={{
              color: `${textColor}`,
            }}
            className="p-1 font-bold"
          >
            {RARITY_SKILL?.[skill?.rarity]?.label}
          </p>
        </div>

        <div className="absolute left-0 top-16 z-20 w-10 stat-skill-attack">
          <img src="/icon/attack_skillcard.png" alt="attack_icon" className="max-w-full max-h-full" />
          <div className="absolute bottom-0 text-metal font-bold pl-2 -right-2 flex items-center sm:text-sm-md text-sm">
            {typeof skill?.attack === 'number' ? (
              <Value className="skill-stat" value={skill?.attack} decimals={0} />
            ) : (
              '--'
            )}
            %
          </div>
        </div>
        <div className="absolute left-0 top-28 z-20 w-10 stat-skill-defend">
          <img src="/icon/defend_skillcard.png" alt="attack_icon" />
          <div className="absolute bottom-0 text-metal font-bold pl-2 -right-2 flex items-center sm:text-sm-md text-sm">
            {typeof skill?.defend === 'number' ? (
              <Value className="skill-stat" value={skill?.defend} decimals={0} />
            ) : (
              '--'
            )}
            %
          </div>
        </div>
        <div className="absolute top-48 z-20 right-1/2 transform translate-x-1/2 flex-flex-col items-center skill-effect-icon">
          <img src={SKILL_EFFECT?.[effectKey]?.icon} alt="icon" width={30} />
          {skill?.effect?.level && !ignoreLevel ? (
            <img
              src={ICON_EFFECT?.[skill?.effect?.level?.toLowerCase()]}
              alt="icon"
              width={30}
              className="transform translate-x-1 -translate-y-2"
            />
          ) : null}
        </div>
        <div className="w-full h-9 flex items-center justify-center">
          <h1
            style={{
              color: `${textColor}`,
            }}
            className="font-bold capitalize mt-2 skill-name"
          >
            {skill?.name}
          </h1>
        </div>
        <div className="skill-image">
          <img src={skill?.image} alt="skill" className="max-w-full" />
        </div>
        <div className="w-full h-20 flex items-center justify-center">
          <div
            className={classnames(
              'px-3 max-h-12 overflow-hidden break-words text-ellipsis skill-description-container',
            )}
          >
            <p
              style={{
                color: `${textColor}`,
              }}
              className="text-center text-sm-md leading-4 skill-description"
            >
              {description?.length > 71 ? `${description.slice(0, 71)}...` : description}
            </p>
          </div>
        </div>
      </Box>
    </div>
  )
}

SkillCard.propTypes = {
  skill: PropTypes.object,
  className: PropTypes.string,
}
