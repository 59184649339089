import React, { useMemo, useState } from 'react'
import classnames from 'classnames'
import Box from '../Box/Box'
import Badge from '../Badge/Badge'
import PropTypes from 'prop-types'
import {
  AUCTION_TYPE,
  cooldownLabel,
  DEFAULT_EGG_CLASS,
  TOKEN_USED_MARKETPLACE,
  DRAGON_STATUS,
  DRAGON_TYPE,
  IMAGE_SIZE,
  MAX_DEFINE_COOLDOWN,
} from 'constants/index'
import Countdown, { zeroPad } from 'react-countdown'
import ButtonGradient from '../Button/ButtonGradient'
import { DRAGON_CAVE_ADDRESS } from '../../config'
import { useClaimDragonCave } from '../../hooks/useClaim'
import { showToastError, showToastSuccess } from '../CustomToast/CustomToast'
import ModalDragonCave from '../Modal/ModalClaimDragonCave'
import { useHistory } from 'react-router-dom'
import { getImageURL } from '../../utils'
import useInterval from '../../hooks/useInterval'
import Value from '../Value/Value'
import { formatNumber, getBalanceAmount } from '../../utils/formatBalance'
import BigNumber from 'bignumber.js'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'

const DragonCard = ({
  dragon,
  isClaim,
  wDragonBalance,
  updateData,
  className,
  tokenPrice,
  disabled,
  showPrice = true,
  handleFavorites,
  ...rest
}) => {
  const { id, image, favorite } = dragon
  const { account } = useActiveWeb3React()
  const { onClaim } = useClaimDragonCave(DRAGON_CAVE_ADDRESS.address)
  const history = useHistory()
  const isEgg = dragon?.type === 'EGG'
  const [openClaim, setOpenClaim] = useState(false)
  const [canBirth, setCanBirth] = useState(false)
  const price = getBalanceAmount(dragon?.price, 0)
  const priceUsd = price ? new BigNumber(price).times(tokenPrice).toNumber() : 0
  const level = dragon?.level || 1
  const cooldownIndex = dragon?.cooldownIndex < MAX_DEFINE_COOLDOWN ? dragon?.cooldownIndex : MAX_DEFINE_COOLDOWN

  const dataStats = [
    {
      icon: '/icon/health.svg',
      label: 'Mana',
      value: isEgg
        ? typeof dragon?.stats?.mana === 'number'
          ? dragon?.stats?.mana
          : '???'
        : typeof dragon?.currentStats?.mana === 'number'
        ? dragon?.currentStats?.mana
        : '???',
    },
    {
      icon: '/icon/generation.svg',
      label: 'Health',
      value: isEgg
        ? typeof dragon?.stats?.health === 'number'
          ? dragon?.stats?.health
          : '???'
        : typeof dragon?.currentStats?.health === 'number'
        ? dragon?.currentStats?.health
        : '???',
    },
    {
      icon: '/icon/attack.svg',
      label: 'Attack',
      value: isEgg
        ? typeof dragon?.stats?.attack === 'number'
          ? dragon?.stats?.attack
          : '???'
        : typeof dragon?.currentStats?.attack === 'number'
        ? dragon?.currentStats?.attack
        : '???',
    },
    {
      icon: '/icon/defend.svg',
      label: 'Defend',
      value: isEgg
        ? typeof dragon?.stats?.defend === 'number'
          ? dragon?.stats?.defend
          : '???'
        : typeof dragon?.currentStats?.defend === 'number'
        ? dragon?.currentStats?.defend
        : '???',
    },
    {
      icon: '/icon/speed.svg',
      label: 'Speed',
      value: isEgg
        ? typeof dragon?.stats?.speed === 'number'
          ? dragon?.stats?.speed
          : '???'
        : typeof dragon?.currentStats?.speed === 'number'
        ? dragon?.currentStats?.speed
        : '???',
    },
    {
      icon: '/icon/morale.svg',
      label: 'Morale',
      value: isEgg
        ? typeof dragon?.stats?.morale === 'number'
          ? dragon?.stats?.morale
          : '???'
        : typeof dragon?.currentStats?.morale === 'number'
        ? dragon?.currentStats?.morale
        : '???',
    },
  ]

  const totalStats = useMemo(() => {
    if (!dragon?.stats || !dragon?.currentStats) return null
    return dataStats.reduce(
      (previousValue, currentValue) =>
        isEgg
          ? previousValue + (currentValue.value + (dragon?.potential + 1) * (level - 1))
          : previousValue + currentValue.value,
      0,
    )
  }, [dataStats])

  const dragonStatus = useMemo(() => {
    if (dragon?.type === DRAGON_TYPE.DRAGON) {
      if (dragon?.isGestating) return DRAGON_STATUS.PREGNANT
      if (!dragon?.isReady) {
        return DRAGON_STATUS.RESTING
      }
    }
    return null
  }, [dragon])

  const handleClaim = async () => {
    try {
      if (wDragonBalance && account) {
        await onClaim(dragon?.id)
        await updateData()
        showToastSuccess('Success', `Claim ${dragon?.type === 'EGG' ? 'Egg' : 'Dracora'} successfully!`)
      }
    } catch (error) {
      throw error
    }
  }

  const onFavorites = (e) => {
    handleFavorites(id)
    e.stopPropagation()
    e.preventDefault()
  }

  const handleOpenModal = () => {
    if (wDragonBalance) {
      setOpenClaim(true)
    } else {
      const btn = (
        <div className="flex justify-end mt-2">
          <ButtonGradient
            size="sm"
            onClick={() => history.push('/my-dragons')}
            className="text-sm sm:pl-3 sm:pr-3 sm:pt-1.5 sm:pb-1.5"
          >
            Wrap Egg
          </ButtonGradient>
        </div>
      )
      showToastError('', 'You do not have any WDRACORA token!', btn)
    }
  }

  useInterval(() => {
    if (dragon?.isGestating && dragon?.timeLock < Date.now() && !canBirth) {
      setCanBirth(true)
    }
  }, 1000)

  const rendererIncubation = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) return null
    return (
      <div className="text-white mt-0.5">
        <p className="font-bold text-xs sm:text-sm">
          {zeroPad(days)}d {zeroPad(hours)}h {zeroPad(minutes)}m {zeroPad(seconds)}s
        </p>
      </div>
    )
  }
  return (
    <Box
      {...rest}
      className={classnames(
        'border-2 overflow-hidden h-full border-blue2 bg-blue1 p-1.5 sm:p-2.5 mx-2 text-white relative',
        {
          'pb-6': !showPrice,
        },
        className,
      )}
    >
      <div className="flex justify-between">
        <div className="flex justify-between">
          <div className="flex items-center">
            <Badge
              className={classnames(
                `bg-${dragon?.class?.toLowerCase()}`,
                'text-white px-2 sm:px-3 text-xs sm:text-sm pt-0.5 pb-0.5',
              )}
            >
              #{id}
            </Badge>
            <Badge className={classnames('text-white px-2 sm:px-3 text-xs sm:text-sm pt-0.5 pb-0.5 bg-blue2 ml-1')}>
              Gen {dragon?.generation}
            </Badge>
          </div>
        </div>
        {account ? (
          <img
            className="w-4 sm:w-6 h-4 sm:h-6"
            src={favorite ? '/icon/heart-red.png' : '/icon/heart-white.png'}
            onClick={onFavorites}
          />
        ) : null}
      </div>
      <div className="flex justify-between mt-2">
        <div className="flex flex-col">
          <Badge className="text-white px-2 sm:px-3 text-xs sm:text-sm pt-0.5 pb-0.5 bg-blue2">
            <span className="inline sm:hidden">Pot</span>
            <span className="hidden sm:inline">Potential</span> {dragon?.potential + 1}
          </Badge>
          <div className="flex items-center">
            <Badge className={classnames(`bg-${dragon?.class?.toLowerCase()}`, 'px-1 py-1')} />
            <span className="pl-1 sm:pl-2 capitalize text-xs sm:text-sm-md">{dragon?.class?.toLowerCase()}</span>
          </div>
          <p className="opacity-50 text-xs sm:text-sm-md">Level: {level || 1}</p>
        </div>
        <div className="flex flex-col items-end h-12">
          {dragonStatus ? (
            <Badge
              className={classnames(
                dragonStatus === DRAGON_STATUS.PREGNANT && canBirth ? 'bg-green1' : 'bg-skin',
                'text-white px-1.5 sm:px-2.5 text-xs sm:text-sm pt-0.5 pb-0.5 ml-1',
              )}
            >
              {dragonStatus === DRAGON_STATUS.PREGNANT && canBirth ? 'Give birth' : dragonStatus}
            </Badge>
          ) : null}
          {!dragonStatus ? (
            <Badge className="bg-blue2 text-white px-2 text-xs sm:text-sm pt-0.5 pb-0.5 ml-1">
              {typeof dragon?.cooldownIndex === 'number'
                ? `${cooldownLabel[cooldownIndex]?.label} (${cooldownLabel[cooldownIndex]?.duration})`
                : '???'}
            </Badge>
          ) : null}
          {dragon?.timeLock ? (
            <Countdown zeroPadTime={2} date={dragon?.timeLock} renderer={rendererIncubation} />
          ) : null}
          {dragon?.hatched ? <Countdown zeroPadTime={2} date={dragon?.hatched} renderer={rendererIncubation} /> : null}
          {[AUCTION_TYPE.AUCTION, AUCTION_TYPE.SIRING].includes(dragon?.sale) ? (
            <p
              style={{
                backgroundImage: `linear-gradient(to right, #48CAE4,#0068B4)`,
              }}
              className="rounded-l-xl text-sm sm:text-sm-md px-6 pl-3 text-white mt-1.5 transform translate-x-2.5"
            >
              {dragon?.sale === AUCTION_TYPE.AUCTION ? 'For sale' : 'Siring'}
            </p>
          ) : null}
        </div>
      </div>
      <div
        className={classnames('flex justify-center h-32 sm:h-36 py-3 relative', {
          'py-6': isEgg,
        })}
      >
        {dragon?.mutant ? <Badge className="bg-mutation px-1.5 py-1.5 mr-1 absolute top-3 left-0" /> : null}
        <img
          src={isEgg ? DEFAULT_EGG_CLASS[dragon?.class] : getImageURL(image, IMAGE_SIZE['250'])}
          className="h-full"
        />
      </div>
      <div className="grid grid-cols-3 mt-2">
        {dataStats.map((item, index) => (
          <div className="flex justify-center items-center" key={index}>
            <img
              src={item.icon}
              style={{
                maxHeight: 12,
                maxWidth: 12,
                width: 12,
              }}
            />
            <div className="text-white ml-2">
              {typeof item?.value === 'number' ? (
                <Value
                  className="text-sm-md"
                  value={isEgg ? item?.value + (dragon?.potential + 1) * (level - 1) : item?.value}
                  decimals={0}
                />
              ) : (
                item?.value
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-2 my-1">
        <p className="text-center text-sm-md">
          <span className="text-xs text-white50 block sm:inline">XP:</span> {!isEgg ? dragon?.xp || 0 : '--'}
        </p>
        <p className="text-center text-sm-md">
          <span className="text-xs text-white50 block sm:inline">Stats:</span>{' '}
          {typeof totalStats === 'number' ? totalStats : '--'}
        </p>
      </div>
      {isClaim ? (
        <div className="flex justify-center mt-3">
          <ButtonGradient
            size="sm"
            className="text-sm-md sm:pl-4 sm:pr-4 sm:pt-1.5 sm:pb-1.5"
            onClick={(e) => {
              handleOpenModal()
              e.preventDefault()
            }}
          >
            Claim
          </ButtonGradient>
        </div>
      ) : null}

      {showPrice ? (
        <div className="h-9 mt-2.5">
          {dragon?.price ? (
            <>
              <p className="text-white text-center font-bold text-sm-md sm:text-md">
                {formatNumber(dragon?.price, 0, 8)} {TOKEN_USED_MARKETPLACE.symbol}
              </p>
              <p className="text-white text-center text-xs opacity-50">~{formatNumber(priceUsd, 0, 3)}$</p>
            </>
          ) : null}
        </div>
      ) : null}

      {openClaim ? (
        <ModalDragonCave
          open={openClaim}
          toggle={() => setOpenClaim(!openClaim)}
          onSubmit={handleClaim}
          dragon={dragon}
        />
      ) : null}

      {disabled ? <div className="absolute bg-disabled top-0 right-0 w-full h-full" /> : null}
    </Box>
  )
}

DragonCard.propTypes = {
  dragon: PropTypes.object.isRequired,
}

export default DragonCard
