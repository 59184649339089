import useRefresh from '../../hooks/useRefresh'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchCooldownAsync,
  fetchDragonsCanFightAsync,
  fetchIdAvailableMonsters,
  fetchMonsterAsync,
  fetchMonstersAsync,
  fetchRewardEvents,
  setDragons,
  setIdAvailableMonsters,
  setMonster,
  setMonsters,
  setReward,
  setTabMonsters,
} from './index'
import { useHistory, useParams } from 'react-router-dom'
import { EVENT_MONSTER_TYPES, EVENT_TYPES, MONSTER_TYPES, MONSTER_TYPES_QUERY } from 'constants/index'
import { useQuerySearch } from '../search/hook'
import { formatQueryDragons } from '../dragons/hook'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'

export const useMonsters = () => useSelector((state) => state.monsters.monsters)

export const useMonster = () => useSelector((state) => state.monsters.monster)

export const useDragonsCanFight = () => useSelector((state) => state.monsters.dragons)

export const useTabMonsters = () => useSelector((state) => state.monsters.tab)

export const useIdAvailableMonsters = () => useSelector((state) => state.monsters.idAvailableMonsters)

export const useReward = () => useSelector((state) => state.monsters.reward)

export const useIdDragons = () => useSelector((state) => state.monsters.idDragons)

export const useCooldownFightMonster = () => useSelector((state) => state.monsters.cooldown)

export const useFetchMonsters = () => {
  const { fastRefresh } = useRefresh()
  const { location } = useHistory()
  const dispatch = useDispatch()
  const tab = useTabMonsters()
  const pathname = location?.pathname?.split('/')?.[1]
  const type =
    pathname.toLowerCase() === EVENT_MONSTER_TYPES[MONSTER_TYPES.XX_MONSTER].urls
      ? MONSTER_TYPES.XX_MONSTER
      : MONSTER_TYPES.YY_MONSTER
  useEffect(() => {
    dispatch(fetchMonstersAsync(type, tab))
  }, [dispatch, fastRefresh])

  useEffect(() => {
    const updateData = async () => {
      await dispatch(setMonsters(undefined))
      await dispatch(fetchMonstersAsync(type, tab))
    }
    updateData()
  }, [type])

  useEffect(() => {
    const updateData = async () => {
      await dispatch(setMonsters(undefined))
      await dispatch(fetchMonstersAsync(type, tab))
    }
    updateData()
  }, [tab])

  useEffect(() => {
    return () => {
      dispatch(setMonsters(undefined))
      dispatch(setTabMonsters(1))
    }
  }, [])
}

export const useFetchMonster = () => {
  const { id } = useParams()
  const { fastRefresh } = useRefresh()
  const dispatch = useDispatch()
  const { location } = useHistory()
  const pathname = location?.pathname?.split('/')?.[1]
  const type =
    pathname.toLowerCase() === EVENT_MONSTER_TYPES[MONSTER_TYPES.XX_MONSTER].url
      ? MONSTER_TYPES.XX_MONSTER
      : MONSTER_TYPES.YY_MONSTER

  useEffect(() => {
    if (EVENT_TYPES[type]) {
      dispatch(fetchMonsterAsync(type, id))
    }
  }, [dispatch, fastRefresh, id, type])

  useEffect(() => {
    return () => {
      dispatch(setMonster(undefined))
    }
  }, [])
}

export const useFetchDragonsFightMonster = (monster) => {
  const { account } = useActiveWeb3React()
  const dispatch = useDispatch()
  const query = useQuerySearch()
  const dragons = useDragonsCanFight()
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    const page = 1
    dispatch(
      fetchDragonsCanFightAsync(
        monster?.id,
        formatQueryDragons({
          page,
          monster: monster?.type === MONSTER_TYPES.XX_MONSTER ? MONSTER_TYPES_QUERY.XX : MONSTER_TYPES_QUERY.YY,
          level: monster?.requireLevel,
          ...query,
        }),
        monster?.type,
      ),
    )
  }, [JSON.stringify(query), monster?.id])
  useEffect(() => {
    const page = dragons?.currentPage || 1
    dispatch(
      fetchDragonsCanFightAsync(
        monster?.id,
        formatQueryDragons({
          page,
          monster: monster?.type === MONSTER_TYPES.XX_MONSTER ? MONSTER_TYPES_QUERY.XX : MONSTER_TYPES_QUERY.YY,
          level: monster?.requireLevel,
          ...query,
        }),
        monster?.type,
      ),
    )
  }, [slowRefresh, account])
  useEffect(() => {
    return () => {
      dispatch(setDragons(undefined))
    }
  }, [])
}

export const useFetchIdAvailableMonsters = () => {
  const { fastRefresh } = useRefresh()
  const dispatch = useDispatch()
  const { location } = useHistory()
  const pathname = location?.pathname?.split('/')?.[1]
  const type =
    pathname.toLowerCase() === EVENT_MONSTER_TYPES[MONSTER_TYPES.XX_MONSTER].url
      ? MONSTER_TYPES.XX_MONSTER
      : MONSTER_TYPES.YY_MONSTER

  useEffect(() => {
    dispatch(fetchIdAvailableMonsters(type))
  }, [type, fastRefresh, dispatch])

  useEffect(() => {
    return () => {
      dispatch(setIdAvailableMonsters(undefined))
    }
  }, [])
}

export const useFetchRewardEvents = () => {
  const { fastRefresh } = useRefresh()
  const { account } = useActiveWeb3React()
  const dispatch = useDispatch()
  const { location } = useHistory()
  const pathname = location?.pathname?.split('/')?.[1]
  const type =
    pathname.toLowerCase() === EVENT_MONSTER_TYPES[MONSTER_TYPES.XX_MONSTER].urls
      ? MONSTER_TYPES.XX_MONSTER
      : MONSTER_TYPES.YY_MONSTER

  useEffect(() => {
    if (account) {
      dispatch(fetchRewardEvents(type))
    }
  }, [type, fastRefresh, account, dispatch])
  useEffect(() => {
    if (account) {
      dispatch(setReward(undefined))
    }
  }, [type])
}

export const useFetchCooldown = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCooldownAsync())
  }, [])
}
