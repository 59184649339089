import React, { useEffect, useState } from 'react'
import withLayout from 'hoc/withLayout'
import Container from 'components/Container/Container'
import {
  useFetchMonsters,
  useFetchRewardEvents,
  useMonsters,
  useTabMonsters,
  useReward,
  useIdDragons,
  useFetchCooldown,
  useCooldownFightMonster,
} from 'store/monster/hook'
import { useHistory } from 'react-router-dom'
import {
  MONSTER_TYPES,
  MONSTER_TYPES_QUERY,
  DEFAULT_TOKEN_USED,
  FIGHT_MONSTER_RESULT,
  EVENT_MONSTER_TYPES,
} from 'constants/index'
import MonsterCard from './components/MonsterCard'
import LoadingPage from 'components/Loader/LoadingPage'
import classnames from 'classnames'
import { useDispatch } from 'react-redux'
import { fetchMonstersAsync, fetchRewardEvents, setTabMonsters } from 'store/monster'
import ButtonHistory from '../Monster/components/ButtonHistory'
import Value from 'components/Value/Value'
import ButtonGradient from 'components/Button/ButtonGradient'
import { useClaimReward } from 'hooks/useClaimRewardMonster'
import { EVENT_MONSTER_ADDRESS } from 'config'
import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast'
import { getBalanceNumber } from 'utils/formatBalance'
import ButtonClaimHistory from './components/ButtonClaimHistory'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'

const TABS = [
  {
    label: 'Living',
    value: 1,
  },
  {
    label: 'Finished',
    value: 2,
  },
]

const Events = () => {
  useFetchCooldown()
  useFetchMonsters()
  useFetchRewardEvents()
  const { account } = useActiveWeb3React()
  const { onClaimReward } = useClaimReward(EVENT_MONSTER_ADDRESS)
  const dispatch = useDispatch()
  const { location } = useHistory()
  const idDragons = useIdDragons()
  const monsters = useMonsters()
  const tab = useTabMonsters()
  const pathname = location?.pathname?.split('/')?.[1]
  const reward = useReward()
  const cooldown = useCooldownFightMonster()
  const type =
    pathname.toLowerCase() === EVENT_MONSTER_TYPES[MONSTER_TYPES.XX_MONSTER].urls
      ? MONSTER_TYPES.XX_MONSTER
      : MONSTER_TYPES.YY_MONSTER

  const [pendingClaim, setPendingClaim] = useState(false)
  const [openModal, setOpenModal] = useState(undefined)
  const [subStateModal, setSubStateModal] = useState(undefined)

  const handleChangeTab = (_tab) => {
    if (_tab?.value !== tab) {
      dispatch(setTabMonsters(_tab?.value))
    }
  }

  const handleSetOpenModal = async (value) => {
    if (!value?.open && value?.type === 'reset' && value?.turn && openModal?.turn !== value?.turn) return
    if (
      [FIGHT_MONSTER_RESULT.DEFEAT, FIGHT_MONSTER_RESULT.VICTORY].includes(value?.result) &&
      value?.turn &&
      openModal?.turn !== value?.turn
    )
      return
    await setOpenModal(value)
  }

  const handleClaim = async () => {
    try {
      setPendingClaim(true)
      await onClaimReward(type, idDragons)
      await dispatch(fetchRewardEvents(type))
      showToastSuccess('Success', 'Claim reward successfully!')
      setPendingClaim(false)
    } catch (error) {
      setPendingClaim(false)
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
    }
  }

  const updateData = async () => {
    try {
      await dispatch(fetchMonstersAsync(type, tab))
    } catch (error) {}
  }

  useEffect(() => {
    handleSetOpenModal(subStateModal)
  }, [subStateModal])

  useEffect(() => {
    setPendingClaim(false)
  }, [type])

  return (
    <Container className="pt-10 pb-36">
      <div>
        <h2 className="text-white text-center text-2xl">{EVENT_MONSTER_TYPES[type].label}</h2>

        {account ? (
          <div className="flex justify-center mt-5">
            <div className="px-6 md:px-8">
              <h2 className="text-sm-md sm:text-lg text-center">Total reward</h2>
              <div className="text-center mt-1.5 text-lg sm:text-xl md:text-3xl font-bold">
                {typeof reward?.totalReward === 'number' ? (
                  type === MONSTER_TYPES.XX_MONSTER ? (
                    <Value
                      value={reward?.totalReward}
                      decimals={2}
                      unit={
                        <span className="pl-1 text-sm-md sm:text-base md:text-lg font-normal">
                          {DEFAULT_TOKEN_USED.symbol}
                        </span>
                      }
                    />
                  ) : (
                    <Value
                      value={getBalanceNumber(reward?.totalReward, DEFAULT_TOKEN_USED.decimals)}
                      decimals={2}
                      unit={
                        <span className="pl-1 text-sm-md sm:text-base md:text-lg font-normal">
                          {DEFAULT_TOKEN_USED.symbol}
                        </span>
                      }
                    />
                  )
                ) : (
                  '--'
                )}
              </div>
            </div>
            {type === MONSTER_TYPES.XX_MONSTER ? (
              <div className="px-6 md:px-8">
                <h2 className="text-sm-md sm:text-lg text-center">Unlocked reward</h2>
                <div className="text-center mt-1.5 text-lg sm:text-xl md:text-3xl font-bold">
                  {typeof reward?.claimableReward === 'number' ? (
                    <Value
                      value={reward?.claimableReward}
                      decimals={2}
                      unit={
                        <span className="pl-1 text-sm-md sm:text-base md:text-lg font-normal">
                          {DEFAULT_TOKEN_USED.symbol}
                        </span>
                      }
                    />
                  ) : (
                    '--'
                  )}
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
        {account ? (
          <>
            <div className="flex justify-center mb-4">
              {(type === MONSTER_TYPES.XX_MONSTER && reward?.claimableReward) ||
              (type === MONSTER_TYPES.YY_MONSTER && reward?.totalReward) ? (
                <ButtonGradient
                  noneBorder={true}
                  rounded={false}
                  className="mt-3 text-sm-md md:text-base px-4 sm:px-8 mx-2 md:mx-4"
                  disabled={pendingClaim}
                  isLoading={pendingClaim}
                  onClick={handleClaim}
                >
                  Claim reward
                </ButtonGradient>
              ) : null}
            </div>
            <div className="flex justify-center">
              <ButtonHistory
                myOwner={true}
                type={type === MONSTER_TYPES.XX_MONSTER ? MONSTER_TYPES_QUERY.XX : MONSTER_TYPES_QUERY.YY}
                className="mt-3 text-sm-md md:text-base px-4 sm:px-8 mx-2 md:mx-4"
              />
              <ButtonClaimHistory
                className="mt-3 text-sm-md md:text-base px-4 sm:px-8 mx-2 md:mx-4"
                type={type === MONSTER_TYPES.XX_MONSTER ? MONSTER_TYPES_QUERY.XX : MONSTER_TYPES_QUERY.YY}
              />
            </div>
          </>
        ) : null}
      </div>

      <div className="my-4">
        <p className="text-center mx-4 md:text-lg text-blue4">
          {type === MONSTER_TYPES.XX_MONSTER
            ? 'Come to fight them until you win (Once you win, you can not fight anymore)'
            : 'Come to strike the Monster Boss until it is dead (You can fight multiple times)'}
        </p>
      </div>

      <div className="flex justify-center mt-2">
        {Object.values(TABS).map((item, index) => (
          <p
            key={index}
            className={classnames(
              'text-white sm:text-lg w-24 sm:w-32 text-center py-2 sm:py-3 cursor-pointer font-bold',
              {
                'border-b-4 border-white': item.value === tab,
              },
            )}
            onClick={() => handleChangeTab(item)}
          >
            {item?.label}
          </p>
        ))}
      </div>

      <div className="max-w-screen-lg m-auto px-2.5 mt-8">
        {monsters !== undefined ? (
          monsters?.length ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {monsters?.map((item) => (
                <MonsterCard
                  key={item?.id}
                  monster={item}
                  openModal={openModal}
                  cooldown={cooldown?.[type]}
                  setOpenModal={setSubStateModal}
                  updateData={updateData}
                />
              ))}
            </div>
          ) : (
            <p className="text-center text-sm-md sm:text-lg my-20">There are no monsters here.</p>
          )
        ) : (
          <LoadingPage />
        )}
      </div>
    </Container>
  )
}

export default withLayout(Events, null, '#0A1520')
