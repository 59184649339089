import Banner from './components/Banner'
import OverviewInfo from './components/OverviewInfo'
import withLayout from 'hoc/withLayout'
import { IS_MAINTENANCE } from 'config'
import ModalMaintenance from 'components/Modal/ModalMaintenance'
import React from 'react'

const Home = () => {
  return (
    <>
      <Banner />
      <OverviewInfo />
      {IS_MAINTENANCE ? <ModalMaintenance /> : null}
    </>
  )
}

export default withLayout(Home)
