const whitelist = [
  '0x01ACF2578c251FD460B286c8A6Bb197E9879f86f',
  '0x31E0D34066379B73aCd3f3D0834fbdCdE87023C5',
  '0xB50Ce635EAfA47e90C849E9D5c1A5B36cC293D9C',
  '0x26470A6c8737CA5b44E77aFA103F62Ac0BA8B1f9',
  '0x02ED237bdf3aADbF1210A9cec74bd259fD79C290',
  '0x6b1437ad30DdB8F6a105a248a0eB2081c769ba9E',
  '0x9B30933a0Eb3351E09A6970761D173fe6D8DCf0A',
  '0xc5df50667F80427F0157102A635A46d6Ef16910C',
  '0x96243d45761Ee9C5B34C20696394d2c2A1a3439B',
  '0x2AC5bE7D11A7916b5bC935f39885fee6fEA8630A',
  '0x389ce6Bd36F9d1673519f0D1adb2F253D40486Bb',
]

export default whitelist
