import axiosClient from './axiosClient'

const notificationApi = {
  getNotifications: (params) => {
    const url = '/dragons/notifications'
    return axiosClient.get(url, { params })
  },
  getNumberNotify: () => {
    const url = '/dragons/total-notifications'
    return axiosClient.get(url)
  },
  resetNumberNotify: () => {
    const url = '/dragons/reset-notifications'
    return axiosClient.post(url)
  },
}

export default notificationApi
