import React from 'react'
import Container from '../Container/Container'
import Back from '../Navigate/Back'
import ButtonGradient from '../Button/ButtonGradient'
import { useHistory } from 'react-router-dom'

const NotFoundData = ({ text }) => {
  const history = useHistory()
  return (
    <>
      <Back />
      <Container className="flex flex-col items-center justify-center mt-24">
        <h3 className="text-white font-bold text-xl md:text-2xl">{text}</h3>
        <ButtonGradient size="sm" className="mt-4" onClick={() => history.goBack()}>
          Go Back
        </ButtonGradient>
      </Container>
    </>
  )
}

export default NotFoundData
