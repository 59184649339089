import React, { useEffect } from 'react'
import withLayout from 'hoc/withLayout'
import LoadingPage from 'components/Loader/LoadingPage'
import Container from 'components/Container/Container'
import Value from 'components/Value/Value'
import SkillCard from 'components/Card/SkillCard'
import { useFetchAllDragonSkills, useAllDragonSkills } from 'store/items/hook'
import ListView from 'components/ListView'
import { showToastError } from 'components/CustomToast/CustomToast'
import { setPageQuery } from 'store/search'
import { useDispatch } from 'react-redux'
import { useUpdateQueryItems, usePageQuery } from 'store/search/hook'
import { setAllSkills } from 'store/items/index'
import ItemsFilterAction from '../Items/components/ItemsFilterAction'

function DocSkill() {
  useUpdateQueryItems()
  useFetchAllDragonSkills()
  const allSkills = useAllDragonSkills()
  const pageQuery = usePageQuery()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setPageQuery(1))
    return () => {
      dispatch(setAllSkills(undefined))
    }
  }, [dispatch])

  const onChangePage = async (page) => {
    try {
      dispatch(setPageQuery(page + 1))
    } catch (error) {
      showToastError('Error', 'Some error occurred, please try again!')
    }
  }

  if (allSkills === undefined) {
    return <LoadingPage />
  }

  return (
    <Container className="py-10">
      <div className="text-center text-white">
        <h3 className="text-xl xl:text-2xl">Total Skill Card</h3>
        {typeof allSkills?.totalItems === 'number' ? (
          <Value className="text-5xl xl:text-6xl" value={allSkills?.totalItems} decimals={0} />
        ) : (
          '--'
        )}
      </div>
      <ItemsFilterAction
        className="mx-2.5 sm:mx-10"
        ignoreBurn={true}
        showSkillRarity={true}
        ignoreFilterStatus={true}
        ignoreSort={true}
        ignoreLevel={true}
        ignoreRangePrice={true}
        showDragonClass={true}
      />
      <ListView
        className="min-height-list-save max-w-screen-lg m-auto xl:px-0 mt-8 mb-5"
        pageCount={allSkills?.totalPages}
        totalItems={allSkills?.totalItems}
        currentPage={pageQuery}
        fetchApi={onChangePage}
      >
        <div className="grid sm:grid-cols-3 md:grid-cols-4 grid-cols-2 gap-y-5 sm:gap-x-5 gap-x-2 px-2.5 sm:px-5">
          {allSkills?.data?.map((skill, index) => {
            return (
              <div className="flex justify-center">
                <SkillCard skill={skill} key={index} ignoreLevel={true} />
              </div>
            )
          })}
        </div>
      </ListView>
    </Container>
  )
}

export default withLayout(DocSkill)
