import React, { useState } from 'react'
import Button from '../../../components/Button/Button'
import { RefreshCw } from 'react-feather'
import dragonsApi from '../../../api/dragonsApi'
import classnames from 'classnames'
import { showToastSuccess } from '../../../components/CustomToast/CustomToast'

const ButtonRefresh = ({ dragon, updateData }) => {
  const [pendingRequest, setPendingRequest] = useState(false)

  const handleRefresh = async () => {
    try {
      setPendingRequest(true)
      await dragonsApi.migrateData(dragon?.id)
      await updateData()
      showToastSuccess('Success', 'Update data successfully!')
      setPendingRequest(false)
    } catch (error) {
      setPendingRequest(false)
    }
  }

  return (
    <>
      <Button
        className="text-sm-md"
        outline
        classNameToolTip="w-64 left-0"
        disabled={pendingRequest}
        msgHover="Refresh dragon information from Kroma"
        onClick={handleRefresh}
      >
        <div
          className={classnames({
            'icon-refresh-animate': pendingRequest,
          })}
        >
          <RefreshCw size={16} />
        </div>

        <span className="pl-2">Refresh</span>
      </Button>
    </>
  )
}

export default ButtonRefresh
