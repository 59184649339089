import React, { useState } from 'react'
import Modal from './ModalV2'
import ModalFooter from './ModalFooter'
import ButtonGradient from '../Button/ButtonGradient'
import { showToastError } from '../CustomToast/CustomToast'
import ModalTitle from './ModalTitleV2'
import { getImageURL } from '../../utils'
import { IMAGE_SIZE } from '../../constants'

const ModalWrapDragon = ({ dragon, open, toggle, isApproved, onApprove, onConfirm }) => {
  const name = dragon?.type === 'EGG' ? 'Egg' : 'Dracora'
  const isEgg = dragon?.type === 'EGG'
  const [pendingTx, setPendingTx] = useState(false)

  const handleSubmit = async () => {
    try {
      setPendingTx(true)
      if (!isApproved) {
        await onApprove()
      } else {
        await onConfirm()
        await toggle()
      }
      setPendingTx(false)
    } catch (error) {
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
      setPendingTx(false)
    }
  }

  return (
    <Modal isDark open={open} onClose={toggle}>
      <ModalTitle onClose={toggle}>Wrap {name}</ModalTitle>
      <div className="p-4 text-white">
        <div>
          <p className="text-sm-md">
            This action will put your {name} into Dracora Cave. You will get WDRACORA token in exchange for that. With
            WDRACORA token, you can get back any {name} inside the Dracora Cave (your original {name} or any {name}s
            from other people in Cave).
          </p>
          <div className="w-full flex justify-center my-5">
            <img
              style={{
                maxHeight: 180,
              }}
              src={
                isEgg
                  ? `/images/eggs/egg-${dragon?.class?.toLowerCase()}-highlight.png`
                  : getImageURL(dragon?.image, IMAGE_SIZE['250'])
              }
            />
          </div>
          <p className="text-sm-md text-red-600">
            Make sure you understand the risk of Wrapping {name}, because other people can get your {name} and you can
            claim back another {name} that is different with your original {name}.
          </p>
        </div>
        <ModalFooter>
          <ButtonGradient disabled={pendingTx} isLoading={pendingTx} onClick={handleSubmit}>
            {!isApproved ? 'Approve Contract' : 'Wrap'}
          </ButtonGradient>
        </ModalFooter>
      </div>
    </Modal>
  )
}

export default ModalWrapDragon
