export const TRAINING_DESCRIPTION = [
  {
    level: 1,
    expRequired: 0,
    buyExp: 0,
    adventureTimes: 0,
  },
  {
    level: 2,
    expRequired: 100,
    buyExp: 100,
    adventureTimes: 4,
  },
  {
    level: 3,
    expRequired: 200,
    buyExp: 200,
    adventureTimes: 8,
  },
  {
    level: 4,
    expRequired: 300,
    buyExp: 300,
    adventureTimes: 12,
  },
  {
    level: 5,
    expRequired: 500,
    buyExp: 500,
    adventureTimes: 20,
  },
  {
    level: 6,
    expRequired: 800,
    buyExp: 800,
    adventureTimes: 32,
  },
  {
    level: 7,
    expRequired: 1300,
    buyExp: 1300,
    adventureTimes: 52,
  },
  {
    level: 8,
    expRequired: 2100,
    buyExp: 2100,
    adventureTimes: 84,
  },
  {
    level: 9,
    expRequired: 3400,
    buyExp: 3400,
    adventureTimes: 136,
  },
  {
    level: 10,
    expRequired: 5500,
    buyExp: 5500,
    adventureTimes: 220,
  },
  {
    level: 11,
    expRequired: 8900,
    buyExp: 8900,
    adventureTimes: 356,
  },
  {
    level: 12,
    expRequired: 14400,
    buyExp: 14400,
    adventureTimes: 576,
  },
  {
    level: 13,
    expRequired: 23300,
    buyExp: 23300,
    adventureTimes: 932,
  },
  {
    level: 14,
    expRequired: 37700,
    buyExp: 37700,
    adventureTimes: 1508,
  },
  {
    level: 15,
    expRequired: 61000,
    buyExp: 61000,
    adventureTimes: 2440,
  },
  {
    level: 16,
    expRequired: 98700,
    buyExp: 98700,
    adventureTimes: 3948,
  },
  {
    level: 17,
    expRequired: 159700,
    buyExp: 159700,
    adventureTimes: 6388,
  },
  {
    level: 18,
    expRequired: 258400,
    buyExp: 258400,
    adventureTimes: 10336,
  },
  {
    level: 19,
    expRequired: 418100,
    buyExp: 418100,
    adventureTimes: 16724,
  },
  {
    level: 20,
    expRequired: 676500,
    buyExp: 676500,
    adventureTimes: 27060,
  },
  {
    level: 21,
    expRequired: 1094600,
    buyExp: 1094600,
    adventureTimes: 43784,
  },
  {
    level: 22,
    expRequired: 1771100,
    buyExp: 1771100,
    adventureTimes: 70844,
  },
  {
    level: 23,
    expRequired: 2865700,
    buyExp: 2865700,
    adventureTimes: 114628,
  },
  {
    level: 24,
    expRequired: 4636800,
    buyExp: 4636800,
    adventureTimes: 185472,
  },
  {
    level: 25,
    expRequired: 7502500,
    buyExp: 7502500,
    adventureTimes: 300100,
  },
  {
    level: 26,
    expRequired: 12139300,
    buyExp: 12139300,
    adventureTimes: 485572,
  },
  {
    level: 27,
    expRequired: 19641800,
    buyExp: 19641800,
    adventureTimes: 785672,
  },
  {
    level: 28,
    expRequired: 31781100,
    buyExp: 31781100,
    adventureTimes: 1271244,
  },
  {
    level: 29,
    expRequired: 51422900,
    buyExp: 51422900,
    adventureTimes: 2056916,
  },
  {
    level: 30,
    expRequired: 83204000,
    buyExp: 83204000,
    adventureTimes: 3328160,
  },
  {
    level: 31,
    expRequired: 134626900,
    buyExp: 134626900,
    adventureTimes: 5385076,
  },
  {
    level: 32,
    expRequired: 217830900,
    buyExp: 217830900,
    adventureTimes: 8713236,
  },
  {
    level: 33,
    expRequired: 352457800,
    buyExp: 352457800,
    adventureTimes: 14098312,
  },
  {
    level: 34,
    expRequired: 570288700,
    buyExp: 570288700,
    adventureTimes: 22811548,
  },
  {
    level: 35,
    expRequired: 922746500,
    buyExp: 922746500,
    adventureTimes: 36909860,
  },
  {
    level: 36,
    expRequired: 1493035200,
    buyExp: 1493035200,
    adventureTimes: 59721408,
  },
  {
    level: 37,
    expRequired: 2415781700,
    buyExp: 2415781700,
    adventureTimes: 96631268,
  },
  {
    level: 38,
    expRequired: 3908816900,
    buyExp: 3908816900,
    adventureTimes: 156352676,
  },
  {
    level: 39,
    expRequired: 6324598600,
    buyExp: 6324598600,
    adventureTimes: 252983944,
  },
  {
    level: 40,
    expRequired: 10233415500,
    buyExp: 10233415500,
    adventureTimes: 409336620,
  },
]
