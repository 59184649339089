import { useDispatch, useSelector } from 'react-redux'
import { toggleWalletConnect, toggleNetwork } from './index'

export const useModal = () => useSelector((state) => state.modal)

export const useModalWalletConnect = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector((store) => store.modal.walletConnect)

  const toggleModal = () => {
    dispatch(toggleWalletConnect())
  }

  return { isOpen, onToggleConnectModal: toggleModal }
}

export const useModalNetwork = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector((store) => store.modal.network)

  const toggleModal = () => {
    dispatch(toggleNetwork())
  }

  return { isOpen, onToggleNetworkModal: toggleModal }
}
