import React from 'react'
import SkillCard from './SkillCard'
import Badge from '../Badge/Badge'
import PropTypes from 'prop-types'
import Box from '../Box/Box'
import classnames from 'classnames'
import Value from 'components/Value/Value'
import { TOKEN_USED_MARKETPLACE, ITEM_STATUS_BG_COLOR, ITEM_STATUS } from 'constants/index'
import { formatNumber, getBalanceAmount } from 'utils/formatBalance'
import { useKaiPrice } from 'hooks/usePrice'
import BigNumber from 'bignumber.js'
export default function SkillCardV2({ item, className, showPrice = true, ...rest }) {
  const price = getBalanceAmount(item?.price, 0)
  const tokenPrice = useKaiPrice()
  const priceUsd = price ? new BigNumber(price).times(tokenPrice).toNumber() : 0

  return (
    <Box {...rest} className={classnames('rounded-xl border-2 border-blue2 bg-blue1 shadow-lg py-4', className)}>
      <div className="flex flex-col items-center">
        <div className="flex items-start justify-between mb-3 w-full px-4 text-white">
          <div className="flex flex-col items-end h-12">
            <Badge className="bg-orange50 pt-0.5 pb-0.5 sm:px-4 px-2 sm:text-sm-md text-sm">#{item?.nftId}</Badge>
            {item?.status === 'USED' ? (
              <div className="flex items-center mt-2">
                <img src="/icon/dragon_icon.png" className="w-6 h-6" alt="icon" />
                <span className="ml-1 sm:text-md text-sm">{item?.dragon}</span>
              </div>
            ) : null}
          </div>
          <div className="flex flex-col items-end h-12">
            <p className="sm:text-sm-md text-sm text-white50 sm:mb-2 mb-1">Level: {item?.level + 1}</p>
            {showPrice && item?.status !== 'ACTIVE' ? (
              <p
                style={{
                  backgroundImage: `linear-gradient(to right, ${
                    ITEM_STATUS_BG_COLOR[item?.status?.toUpperCase()]?.from
                  },${ITEM_STATUS_BG_COLOR[item?.status?.toUpperCase()]?.to})`,
                }}
                className="whitespace-nowrap flex items-center rounded-l-xl text-sm sm:text-sm-md sm:pr-6 sm:pl-3 px-2 text-white transform translate-x-4"
              >
                {ITEM_STATUS?.[item?.status?.toUpperCase()]}
              </p>
            ) : null}
          </div>
        </div>

        <SkillCard skill={item?.skill} />

        <div className="flex sm:flex-row flex-col text-center items-center text-sm-md mt-3 sm:h-5 h-8">
          {item?.status === 'SELLING' ? (
            <>
              <div className="uppercase mr-1 font-medium flex items-center text-white">
                {typeof item?.price === 'number' ? <Value className="" value={item?.price} decimals={0} /> : '--'}
                <span className="ml-1">{TOKEN_USED_MARKETPLACE.symbol}</span>
              </div>
              <p className="text-white50">~{formatNumber(priceUsd, 2, 3)}$</p>
            </>
          ) : null}
        </div>
      </div>
    </Box>
  )
}

SkillCardV2.propTypes = {
  skill: PropTypes.object,
}
