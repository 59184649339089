import Copy from 'components/AccountDetails/Copy'
import ExternalLink from 'components/ExternalLink/ExternalLink'
import ModalTitle from 'components/Modal/ModalTitle'
import { injected } from 'connectors/index'
import { SUPPORTED_WALLETS } from 'constants/index'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { formatAddress } from 'utils/addressHelpers'
import { getExplorerLink } from 'utils/explorer'
import { ExternalLink as LinkIcon } from 'react-feather'
import ButtonGradient from '../Button/ButtonGradient'

const AccountDetails = ({ toggleWalletModal, openOptions }) => {
  const { chainId, account, connector } = useActiveWeb3React()

  function formatConnectorName() {
    const { ethereum } = window
    const isMetaMask = !!(ethereum && ethereum.isMetaMask)
    const name = Object.keys(SUPPORTED_WALLETS)
      .filter(
        (k) =>
          SUPPORTED_WALLETS[k].connector === connector && (connector !== injected || isMetaMask === (k === 'METAMASK')),
      )
      .map((k) => SUPPORTED_WALLETS[k].name)[0]
    return <div className="font-medium text-baseline text-secondary">Connected with {name}</div>
  }

  return (
    <div className="space-y-3">
      <div className="space-y-3">
        <ModalTitle onClose={toggleWalletModal}>Your wallet</ModalTitle>
        <div className="space-y-3">
          <div className="flex items-center justify-between">
            {formatConnectorName()}
            <div className="flex space-x-3">
              <ButtonGradient
                size="sm"
                onClick={() => {
                  openOptions()
                }}
              >
                Change
              </ButtonGradient>
            </div>
          </div>
          <div id="web3-account-identifier-row" className="flex flex-col justify-center space-y-3">
            <div className="text-center">
              <p className="text-gray-500 break-words hidden sm:block">{account}</p>
              <p className="text-gray-500 break-words block sm:hidden">{account ? formatAddress(account) : ''}</p>
            </div>
            <div className="flex items-center justify-center space-x-3 gap-2">
              {typeof chainId === 'number' && account && (
                <ExternalLink
                  color="blue"
                  startIcon={<LinkIcon size={16} />}
                  href={typeof chainId === 'number' && getExplorerLink(chainId, account, 'address')}
                >
                  <p>View on explorer</p>
                </ExternalLink>
              )}
              {account && (
                <Copy toCopy={account}>
                  <p>Copy Address</p>
                </Copy>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountDetails
