import React, { useEffect, useState } from 'react'
import { AUCTION_TYPE, DEFAULT_TOKEN_USED, DRAGON_TYPE } from 'constants/index'
import Tooltip from 'components/Tooltip/Tooltip'
import Progress from 'components/Progress'
import { AlertCircle, Archive, ChevronsUp, Shield } from 'react-feather'
import Button from 'components/Button/Button'
import { useDispatch } from 'react-redux'
import { useBirth } from 'hooks/useBirth'
import { BIRTH_ADDRESS, TRAINING_ADDRESS } from 'config'
import { useApprove } from 'hooks/useApprove'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import Web3 from 'web3'
import { fetchUserDataDetailDragon } from 'store/dragons'
import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast'
import useInterval from 'hooks/useInterval'
import ModalTrainingDescription from 'components/Modal/ModalTrainingDescription'
import { useBootDragon, useTrain } from 'hooks/useTrain'
import ModalSuccess from 'components/Modal/ModalSuccess'
import ButtonGradient from 'components/Button/ButtonGradient'
import dragonsApi from 'api/dragonsApi'
import { useHistory } from 'react-router-dom'
import Countdown, { zeroPad } from 'react-countdown'
import ModalEstimateBoosting from 'components/Modal/ModalEstimateBoosting'
import { getBalanceNumber } from 'utils/formatBalance'
import useTokenBalance from 'hooks/useTokenBalance'
import address from 'constants/contracts'
import classnames from 'classnames'
import ModalEstimateBirthCost from 'components/Modal/ModalEstimateBirthCost'
import { fetchLatestChildOfMother } from 'store/dragons/fetchDragons'
import { sleep } from 'utils'
import { TRAINING_DESCRIPTION } from 'constants/training'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

const ActionsDragonLeft = ({ dragon, userData }) => {
  const { push } = useHistory()
  const dispatch = useDispatch()
  const dragonBalance = getBalanceNumber(useTokenBalance(address.dragon))
  const { account } = useActiveWeb3React()
  const { onBirth } = useBirth(BIRTH_ADDRESS)
  const { onApprove } = useApprove(BIRTH_ADDRESS, DEFAULT_TOKEN_USED.address)
  const approveTraining = useApprove(TRAINING_ADDRESS, DEFAULT_TOKEN_USED.address)
  const { onTrain } = useTrain(TRAINING_ADDRESS)
  const { onBoot } = useBootDragon(TRAINING_ADDRESS)

  const [canBirth, setCanBirth] = useState(false)
  const [requestingBirth, setRequestingBirth] = useState(false)
  const [pendingApprove, setPendingApprove] = useState(false)
  const [pendingApproveTraining, setPendingApproveTraining] = useState(false)
  const [pendingTraining, setPendingTraining] = useState(false)
  const [pendingBooting, setPendingBooting] = useState(false)
  const [openModalTraining, setOpenModalTraining] = useState(false)
  const [openModalSuccess, setOpenModalSuccess] = useState(false)
  const [dragonBorn, setDragonBorn] = useState(undefined)
  const [openModalBoosting, setOpenModalBoosting] = useState(false)
  const [canAdventure, setCanAdventure] = useState(false)
  const [openModalBirthCost, setOpenModalBirthCost] = useState(false)
  const [showCountDown, setShowCountDown] = useState(false)

  const trainingAvailable = ![AUCTION_TYPE.SIRING, AUCTION_TYPE.AUCTION].includes(dragon?.sale)
  const allowanceBirth = new BigNumber(userData?.allowance?.birth) || BIG_ZERO
  const isApprovedBirth = account && allowanceBirth && allowanceBirth?.isGreaterThan(0)
  const allowanceTraining = new BigNumber(userData?.allowance?.training) || BIG_ZERO
  const isApprovedTraining = account && allowanceTraining && allowanceTraining?.isGreaterThan(0)
  const requiredLevel = TRAINING_DESCRIPTION[dragon?.level ? dragon?.level - 1 : 0]
  const requiredNextLevel = TRAINING_DESCRIPTION[dragon?.level ? dragon?.level : 1]
  const percent =
    (((dragon?.xp ? dragon?.xp : 0) - (requiredLevel.expRequired || 0)) /
      (requiredNextLevel.expRequired - (requiredLevel.expRequired || 0))) *
    100

  const isOwner =
    account && dragon?.owner && Web3.utils.toChecksumAddress(account) === Web3.utils.toChecksumAddress(dragon?.owner)

  const handleApprove = async () => {
    try {
      setPendingApprove(true)
      await onApprove()
      await dispatch(fetchUserDataDetailDragon(account, dragon?.id))
      setPendingApprove(false)
      showToastSuccess('Contract Enabled', 'Approve contract successfully!')
    } catch (error) {
      console.log(error)
      setPendingApprove(false)
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
    }
  }

  const handleApproveTraining = async () => {
    try {
      setPendingApproveTraining(true)
      await approveTraining.onApprove()
      await dispatch(fetchUserDataDetailDragon(account, dragon?.id))
      setPendingApproveTraining(false)
      showToastSuccess('Contract Enabled', 'Approve contract successfully!')
    } catch (error) {
      console.log(error)
      setPendingApproveTraining(false)
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
    }
  }

  const handleRefresh = async () => {
    try {
      await dragonsApi.migrateData(dragon?.id)
      return true
    } catch (error) {
      console.log(error)
    }
  }

  const onBirthDragon = async () => {
    try {
      setOpenModalBirthCost(false)
      setRequestingBirth(true)
      setDragonBorn(undefined)
      await onBirth(dragon?.id)
      await handleRefresh()
      const lastChild = await fetchLatestChildOfMother(dragon?.id)
      if (lastChild) {
        setDragonBorn(lastChild)
      }
      setOpenModalSuccess(true)
      setRequestingBirth(false)
      showToastSuccess('Success', 'Give birth successfully!')
    } catch (error) {
      setRequestingBirth(false)
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
    }
  }

  const handleTraining = async () => {
    try {
      setPendingTraining(true)
      await onTrain(dragon?.id)
      await handleRefresh()
      showToastSuccess('Success', 'Congratulations! Your dragon has successfully defeated the enemy in the adventure!')
      setPendingTraining(false)
    } catch (error) {
      setPendingTraining(false)
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
    }
  }

  const handleBooting = async () => {
    try {
      setOpenModalBoosting(false)
      setPendingBooting(true)
      await onBoot(dragon?.id)
      await handleRefresh()
      showToastSuccess('Success', 'Upgrade dragon successfully!')
      setPendingBooting(false)
    } catch (error) {
      setPendingBooting(false)
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
    }
  }

  useInterval(() => {
    if (dragon?.isGestating && dragon?.timeLock < Date.now() && !canBirth) {
      setCanBirth(true)
    }
    if (!canAdventure && dragon && (dragon?.startLock < Date.now() || !dragon?.startLock)) {
      setCanAdventure(true)
    }
  }, 1000)

  const TooltipProgressLevel = (
    <div className="text-white">
      <p className="text-center">
        {dragon?.xp ? dragon?.xp : 0}/{requiredNextLevel.expRequired}
      </p>
    </div>
  )

  const renderCooldown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed || !showCountDown) return null
    return (
      <span className="pl-1 text-sm">
        {hours > 0 ? `${zeroPad(hours)}h` : ''}
        {zeroPad(minutes)}m{zeroPad(seconds)}s
      </span>
    )
  }

  const navToDragon = (child) => {
    window.scrollTo({ behavior: 'smooth', top: 0, left: 0 })
    push(`/dragon/${child?.id}`)
  }

  useEffect(() => {
    setOpenModalSuccess(false)
  }, [dragon?.id])

  const handleShowCountdown = async () => {
    await setShowCountDown(false)
    await sleep(1500)
    await setShowCountDown(true)
  }

  useEffect(() => {
    setCanAdventure(false)
    handleShowCountdown()
  }, [dragon?.startLock])

  return (
    <>
      {dragon?.type === DRAGON_TYPE.DRAGON ? (
        <div className="flex justify-center items-center">
          <p
            className="text-white50"
            style={{
              minWidth: '4rem',
            }}
          >
            Level {dragon?.level || 1}
            <span className="text-center text-sm pl-1">
              ({dragon?.xp ? dragon?.xp : 0}/{requiredNextLevel.expRequired})
            </span>
          </p>
          <Tooltip
            style={{
              maxWidth: '12.5rem',
            }}
            tooltipStyle={{
              top: '-170%',
            }}
            className="cursor-pointer w-full mx-2"
            classNameToolTip="w-32 sm:w-32 bg-black text-sm-md border-2 border-blue2"
            tooltip={TooltipProgressLevel}
          >
            <Progress percent={percent || 0} />
          </Tooltip>
          <AlertCircle className="cursor-pointer text-blue2" size={20} onClick={() => setOpenModalTraining(true)} />
        </div>
      ) : null}
      <div className="flex justify-center mt-4">
        {dragon?.type === DRAGON_TYPE.DRAGON && isOwner ? (
          <div>
            <p
              className={classnames('text-white text-center', {
                'opacity-0': isApprovedTraining,
              })}
            >
              Training
            </p>
            <div className="flex justify-center mx-1">
              {isApprovedTraining ? (
                <>
                  <Button
                    outline
                    className="z-10 mx-1 text-sm lg:text-md"
                    disabled={pendingBooting || pendingTraining || !trainingAvailable}
                    classNameToolTip="w-44"
                    msgDisable={
                      !trainingAvailable
                        ? `Dragon for ${dragon?.sale === AUCTION_TYPE.AUCTION ? 'sell' : 'sire'} cannot boot level`
                        : null
                    }
                    isLoading={pendingBooting}
                    onClick={() => setOpenModalBoosting(true)}
                  >
                    <ChevronsUp className="mr-2 hidden md:inline" size={16} />
                    Boost Level
                  </Button>
                  {!showCountDown ? (
                    <Button
                      outline
                      disabled={pendingBooting || pendingTraining || !canAdventure || !trainingAvailable}
                      isLoading={pendingTraining}
                      classNameToolTip="w-48"
                      className="z-10 mx-1 text-sm lg:text-md"
                      msgDisable={
                        !trainingAvailable
                          ? `Dragon for ${dragon?.sale === AUCTION_TYPE.AUCTION ? 'sell' : 'sire'} cannot adventure`
                          : null
                      }
                      onClick={handleTraining}
                    >
                      <Shield className="mr-2 hidden md:inline" size={16} />
                      Adventure
                      {trainingAvailable ? (
                        <>
                          {dragon?.startLock && showCountDown ? (
                            <Countdown zeroPadTime={2} date={dragon?.startLock} renderer={renderCooldown} />
                          ) : null}
                        </>
                      ) : null}
                    </Button>
                  ) : (
                    <Button
                      outline
                      disabled={pendingBooting || pendingTraining || !canAdventure || !trainingAvailable}
                      isLoading={pendingTraining}
                      classNameToolTip="w-48"
                      className="z-10 mx-1 text-sm lg:text-md"
                      msgDisable={
                        !trainingAvailable
                          ? `Dragon for ${dragon?.sale === AUCTION_TYPE.AUCTION ? 'sell' : 'sire'} cannot adventure`
                          : null
                      }
                      onClick={handleTraining}
                    >
                      <Shield className="mr-2 hidden md:inline" size={16} />
                      Adventure
                      {trainingAvailable ? (
                        <>
                          {dragon?.startLock && showCountDown ? (
                            <Countdown zeroPadTime={2} date={dragon?.startLock} renderer={renderCooldown} />
                          ) : null}
                        </>
                      ) : null}
                    </Button>
                  )}
                </>
              ) : (
                <div>
                  <Button
                    outline
                    className="z-10 text-sm lg:text-md"
                    disabled={pendingApproveTraining || !userData?.userDataLoaded}
                    isLoading={pendingApproveTraining || !userData?.userDataLoaded}
                    onClick={handleApproveTraining}
                  >
                    Approve Contract
                  </Button>
                </div>
              )}
            </div>
          </div>
        ) : null}
        {isOwner && dragon?.isGestating && canBirth ? (
          <div>
            <p
              className={classnames('text-white text-center', {
                'opacity-0': isApprovedBirth,
              })}
            >
              Give birth
            </p>
            <div className="flex justify-center mx-1">
              {isApprovedBirth ? (
                <Button
                  outline
                  className="z-10 text-sm lg:text-md"
                  disabled={requestingBirth}
                  isLoading={requestingBirth}
                  onClick={() => setOpenModalBirthCost(true)}
                >
                  <Archive className="mr-2 hidden md:inline" size={16} />
                  Give birth
                </Button>
              ) : (
                <div>
                  <Button
                    outline
                    className="z-10 text-sm lg:text-md"
                    disabled={pendingApprove || !userData?.userDataLoaded}
                    isLoading={pendingApprove || !userData?.userDataLoaded}
                    onClick={handleApprove}
                  >
                    Approve Contract
                  </Button>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
      {/*Modal training description*/}
      {openModalTraining ? (
        <ModalTrainingDescription open={openModalTraining} toggle={() => setOpenModalTraining(false)} />
      ) : null}

      {openModalSuccess ? (
        <ModalSuccess open={true} toggle={() => setOpenModalSuccess(false)}>
          <div className="text-white text-center">
            <h2 className="text-2xl">Born</h2>
            <p>Give birth successfully!</p>
          </div>
          <div className="flex justify-center mt-3 py-3">
            {dragonBorn ? (
              <>
                <Button outline className="mx-1 flex-1" onClick={() => push('/my-dragons')}>
                  View all dracoras
                </Button>
                <ButtonGradient
                  noneBorder
                  rounded={false}
                  onClick={() => {
                    setDragonBorn(false)
                    navToDragon({ id: dragonBorn })
                  }}
                  className="mx-1 flex-1"
                >
                  View new dracora
                </ButtonGradient>
              </>
            ) : (
              <ButtonGradient noneBorder rounded={false} onClick={() => push('/my-dragons')} className="mx-1">
                View all dracoras
              </ButtonGradient>
            )}
          </div>
        </ModalSuccess>
      ) : null}

      {openModalBoosting ? (
        <ModalEstimateBoosting
          open={openModalBoosting}
          dragon={dragon}
          dragonBalance={dragonBalance}
          requiredNextLevel={requiredNextLevel}
          toggle={() => setOpenModalBoosting(false)}
          onSubmit={handleBooting}
        />
      ) : null}

      {openModalBirthCost ? (
        <ModalEstimateBirthCost
          open={openModalBirthCost}
          dragon={dragon}
          tokenUsed={userData?.birthCost}
          dragonBalance={dragonBalance}
          toggle={() => setOpenModalBirthCost(false)}
          onSubmit={onBirthDragon}
        />
      ) : null}
    </>
  )
}

export default ActionsDragonLeft
