import React from 'react'
import { EXTERNAL_LINK } from 'constants/index'
import { ChevronLeft } from 'react-feather'
import Container from '../Container/Container'

const Footer = ({}) => {
  const linkFooter = [
    {
      icon: '',
      title: 'Gitbook',
      link: EXTERNAL_LINK.gitbook,
    },
    {
      icon: '/icon/twitter.png',
      title: 'Twitter',
      link: EXTERNAL_LINK.twitter,
    },
    {
      icon: '/icon/telegram.png',
      title: 'Telegram',
      link: EXTERNAL_LINK.telegram,
    },
  ]
  return (
    <footer className="px-3 relative z-20 bg-primary">
      <Container>
        <div className="flex justify-between items-center">
          {/* <div className="h-full flex items-center">
            <a href="https://defily.io" className="flex items-center text-white">
              <ChevronLeft color="white" />
              Back to Defily
            </a>
          </div> */}
          <div className="flex justify-center flex-wrap py-5">
            {linkFooter.map((item, index) => (
              <a
                href={item.link}
                key={index}
                target={!item?.inside ? '_blank' : ''}
                className="text-white px-2.5 md:px-4 py-2"
              >
                {item?.title}
              </a>
            ))}
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
