import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { fetchGiftsAsync } from './index'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
export const useMyGifts = () => useSelector((state) => state.gift.gifts)

export const useFetchMyGifts = () => {
  const { account } = useActiveWeb3React()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchGiftsAsync())
  }, [dispatch, account])
}
