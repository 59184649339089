import React, { useState } from 'react'
import classnames from 'classnames'
import { ChevronRight } from 'react-feather'

const Collapse = ({
  prefix,
  append,
  title,
  children,
  headerClassName,
  className,
  defaultExtend,
  widthArrow,
  showDot,
}) => {
  const [toggle, setToggle] = useState(!!defaultExtend)
  const onToggle = () => setToggle(!toggle)
  return (
    <div className={className}>
      <div className={classnames('flex items-center', headerClassName, { 'mb-3': toggle })}>
        {showDot && <span className="w-1 h-1 rounded-full bg-white mr-2" />}
        <div className={classnames('flex flex-row justify-between cursor-pointer w-full')} onClick={onToggle}>
          <div className={classnames('flex flex-row text-white')}>
            {prefix}
            <span>{title}</span>
            {append}
          </div>
          <div>
            <ChevronRight
              color="white"
              className={classnames('transition duration-100 transform rotate-0', {
                'transition duration-100 transform rotate-90': toggle,
              })}
              width={widthArrow}
            />
          </div>
        </div>
      </div>
      <div
        className={classnames('transition duration-700 h-0 overflow-y-hidden', {
          'transition duration-700 h-auto': toggle,
        })}
      >
        {children}
      </div>
    </div>
  )
}

export default Collapse
