import React, { useState, useEffect } from 'react'
import withLayout from 'hoc/withLayout'
import classnames from 'classnames'
import Equipment from './components/Equipment'
import Skill from './components/Skill'
import Exp from './components/Exp'
import ItemAttribute from 'components/Filter/ItemAttribute'
import { Link, useParams } from 'react-router-dom'
function Items() {
  const { type } = useParams()

  return (
    <div className="max-w-screen-2xl m-auto relative flex items-start mb-10">
      <div className="hidden lg:block">
        <ItemAttribute className="w-72 bg-primary mt-20 pb-5 text-white" ignoreBasicSkill={true} />
      </div>
      <div className="container max-w-screen-xl mx-auto h-full">
        <div className="flex items-center justify-center mt-8 md:mb-0 mb-5">
          <Link
            to={`/marketplace-items/equipment?status=SELLING&order=dateListed&orderBy=desc`}
            className={classnames('sm:px-12 px-5 sm:text-xl text-lg pb-2 cursor-pointer', {
              'border-b-4 border-blue4': type === 'equipment',
            })}
          >
            Equipment
          </Link>
          <Link
            to={`/marketplace-items/skill-card?status=SELLING&order=dateListed&orderBy=desc`}
            className={classnames('sm:px-12 px-5 sm:text-xl text-lg pb-2 cursor-pointer', {
              'border-b-4 border-blue4': type === 'skill-card',
            })}
          >
            Skill
          </Link>
          <Link
            to={`/marketplace-items/exp-card?status=SELLING&order=dateListed&orderBy=desc`}
            className={classnames('sm:px-12 px-5 sm:text-xl text-lg pb-2 cursor-pointer', {
              'border-b-4 border-blue4': type === 'exp-card',
            })}
          >
            EXP
          </Link>
        </div>

        {type === 'skill-card' && <Skill />}
        {type === 'equipment' && <Equipment />}
        {type === 'exp-card' && <Exp />}
      </div>
    </div>
  )
}

export default withLayout(Items)
