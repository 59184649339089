import Button from 'components/Button/Button'
import Modal from 'components/Modal/Modal'
import ModalTitle from 'components/Modal/ModalTitle'
import PropTypes from 'prop-types'

const ModalWarningInstallKardiachain = ({ open, toggleModal }) => {
  return (
    <Modal open={open} onClose={toggleModal}>
      <ModalTitle onClose={toggleModal}>Install KardiaChain Wallet</ModalTitle>
      <div className="mt-2 flex justify-center">
        <p>
          Please install the{' '}
          <a
            className="text-primary underline"
            target="_blank"
            href={`https://chrome.google.com/webstore/search/kardiachain%20wallet`}
          >
            Kardia Extension Wallet
          </a>{' '}
          to access
        </p>
      </div>
      <Button className="mx-auto mt-4" onClick={() => toggleModal()}>
        Close
      </Button>
    </Modal>
  )
}

ModalWarningInstallKardiachain.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
}

export default ModalWarningInstallKardiachain
