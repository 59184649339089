import React from 'react'
import ButtonGradient from 'components/Button/ButtonGradient'
import Container from 'components/Container/Container'
import { EXTERNAL_LINK } from 'constants/index'
import { useHistory } from 'react-router-dom'

const Banner = ({}) => {
  const { push } = useHistory()

  return (
    <div
      style={{
        backgroundImage: 'url(/images/bg-home-banner.png)',
        height: 'calc(100vh - 80px)',
        maxHeight: 700,
      }}
      className="bg-center bg-no-repeat bg-cover flex items-center"
    >
      <Container>
        <div className="flex flex-col items-center md:items-start px-2.5 sm:px-5 md:px-10">
          <div className="text-white">
            <h3 className="text-4xl md:text-5xl font-bold text-center md:text-left">
              DRACORA <br />
              Let's Play
            </h3>
            <p className="max-w-lg text-center md:text-left">
              Dracora - Buy eggs to hatch dracoras, raise them then join battleground to earn rewards!
            </p>
          </div>
          <div className="flex flex-col sm:flex-row justify-center mt-10">
            {/** TODO: Uncomment when deploy prod */}
            {/* <ButtonGradient
              className="mr-3 ml-3 md:ml-0 mt-2 text-lg"
              colors={['#FFA800', '#FF6F00']}
              onClick={() => push('/marketplace?sale=AUCTION')}
            >
              Get Dracora
            </ButtonGradient> */}
            <ButtonGradient
              className="mx-3 mt-2 text-lg"
              colors={['#FFA800', '#FF6F00']}
              onClick={() => window.open(EXTERNAL_LINK.telegram, '_blank')}
            >
              Join our community
            </ButtonGradient>
          </div>
        </div>
      </Container>
    </div>
  )
}
export default Banner
