import React from 'react'
import { ethers } from 'ethers'
import BigNumber from 'bignumber.js'

const addTwoFinney = (price, decimals) => {
  const priceInWei = new BigNumber(price).times(new BigNumber(10).pow(decimals))
  const twoFinneyInWei = ethers.utils.parseUnits('2', 'finney')

  const totalValue = priceInWei.plus(twoFinneyInWei.toString())
  return totalValue.div(new BigNumber(10).pow(decimals)).toString()
}

export default addTwoFinney
