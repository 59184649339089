import { useCallback } from 'react'
import { useDragonCaveContract } from './useContract'
import useActiveWeb3React from './useActiveWeb3React'
import { claimDragonCave } from '../utils/calls/auction'

export const useClaimDragonCave = (poolAddress) => {
  const { account } = useActiveWeb3React()
  const contract = useDragonCaveContract(poolAddress)
  const handleClaim = useCallback(async (idToken) => {
    const txHash = await claimDragonCave(contract, idToken, account)
    return txHash
  })

  return { onClaim: handleClaim }
}
