import React, { useState } from 'react'
import Card from 'components/Card/Card'
import Badge from 'components/Badge/Badge'
import classnames from 'classnames'
import Value from 'components/Value/Value'
import Web3 from 'web3'
import { AlertCircle, ChevronDown } from 'react-feather'
import Tooltip from 'components/Tooltip/Tooltip'
import DragonActions from './DragonActions'
import DragonCardV2 from 'components/Card/DragonCardV2'
import SkillCard from 'components/Card/SkillCard'
import ItemCard from 'components/Card/ItemCard'
import { useHistory } from 'react-router-dom'
import { cooldownLabel, cooldownLabelTooltip, MAX_DEFINE_COOLDOWN } from 'constants/index'
import DragonHistories from './DragonHistories'
import Button from 'components/Button/Button'

const AMOUNT_ROWS = 4
const DetailDragon = ({ dragon, onFavorites }) => {
  const { push } = useHistory()
  const ownerAddress = dragon?.owner ? Web3.utils.toChecksumAddress(dragon?.owner) : null
  const isEgg = dragon?.type === 'EGG'
  const level = dragon?.level || 1
  const cooldownIndex = dragon?.cooldownIndex < MAX_DEFINE_COOLDOWN ? dragon?.cooldownIndex : MAX_DEFINE_COOLDOWN
  const dataStats = [
    {
      icon: '/icon/health.svg',
      label: 'Mana',
      value: isEgg
        ? typeof dragon?.stats?.mana === 'number'
          ? dragon?.stats?.mana
          : '???'
        : typeof dragon?.currentStats?.mana === 'number'
        ? dragon?.currentStats?.mana
        : '???',
    },
    {
      icon: '/icon/generation.svg',
      label: 'Health',
      value: isEgg
        ? typeof dragon?.stats?.health === 'number'
          ? dragon?.stats?.health
          : '???'
        : typeof dragon?.currentStats?.health === 'number'
        ? dragon?.currentStats?.health
        : '???',
    },
    {
      icon: '/icon/attack.svg',
      label: 'Attack',
      value: isEgg
        ? typeof dragon?.stats?.attack === 'number'
          ? dragon?.stats?.attack
          : '???'
        : typeof dragon?.currentStats?.attack === 'number'
        ? dragon?.currentStats?.attack
        : '???',
    },
    {
      icon: '/icon/defend.svg',
      label: 'Defend',
      value: isEgg
        ? typeof dragon?.stats?.defend === 'number'
          ? dragon?.stats?.defend
          : '???'
        : typeof dragon?.currentStats?.defend === 'number'
        ? dragon?.currentStats?.defend
        : '???',
    },
    {
      icon: '/icon/speed.svg',
      label: 'Speed',
      value: isEgg
        ? typeof dragon?.stats?.speed === 'number'
          ? dragon?.stats?.speed
          : '???'
        : typeof dragon?.currentStats?.speed === 'number'
        ? dragon?.currentStats?.speed
        : '???',
    },
    {
      icon: '/icon/morale.svg',
      label: 'Morale',
      value: isEgg
        ? typeof dragon?.stats?.morale === 'number'
          ? dragon?.stats?.morale
          : '???'
        : typeof dragon?.currentStats?.morale === 'number'
        ? dragon?.currentStats?.morale
        : '???',
    },
  ]

  const [numberRows, setNumberRows] = useState(AMOUNT_ROWS)

  const nameBodyPart = (name) => {
    if (isEgg) return '???'
    return name || 'None'
  }

  const dataBody = [
    {
      label: 'Horns',
      img: '/images/dragons/horns.png',
      name: nameBodyPart(dragon?.parts?.horns),
    },
    {
      label: 'Head',
      img: '/images/dragons/head.png',
      name: nameBodyPart(dragon?.parts?.head),
    },
    {
      label: 'Middle Horn',
      img: '/images/dragons/middle-horn.png',
      name: nameBodyPart(dragon?.parts?.middlehorns),
    },
    {
      label: 'Eyes',
      img: '/images/dragons/eyes.png',
      name: nameBodyPart(dragon?.parts?.eyes),
    },
    {
      label: 'Tail',
      img: '/images/dragons/tail.png',
      name: nameBodyPart(dragon?.parts?.tail),
    },
    {
      label: 'Wings',
      img: '/images/dragons/wings.png',
      name: nameBodyPart(dragon?.parts?.wings),
    },
    {
      label: 'Armor',
      img: '/images/dragons/armor.png',
      name: nameBodyPart(dragon?.parts?.chest),
    },
    {
      label: 'Fins',
      img: '/images/dragons/back-scale.png',
      name: nameBodyPart(dragon?.parts?.backcales),
    },
  ]

  const navToDragon = (child) => {
    window.scrollTo({ behavior: 'smooth', top: 0, left: 0 })
    push(`/dragon/${child?.id}`)
  }

  const showMore = () => {
    setNumberRows((prevState) => prevState + AMOUNT_ROWS)
  }

  const TooltipStatsContent = (
    <div>
      <h3 className="text-white my-1 text-center">Next level stats</h3>
      <div className="grid grid-cols-2 md:grid-cols-3">
        {dataStats.map((item, index) => (
          <div className="text-center py-3" key={index}>
            <h4 className="text-white50 text-sm-md pb-2">{item.label}</h4>
            <div className="flex justify-center items-center">
              <img src={item.icon} height={16} />
              <div className="text-white ml-2">
                {typeof item?.value === 'number' ? (
                  <Value
                    value={isEgg ? item.value + (dragon?.potential + 1) * level : item.value + dragon?.potential}
                    decimals={0}
                  />
                ) : (
                  item?.value
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  const TooltipContentCooldown = (
    <div>
      <h3 className="text-white my-1 text-center">Cooldown</h3>
      <div>
        {cooldownLabelTooltip.map((item, index) => (
          <div
            className={classnames('flex justify-between py-1', {
              'font-black': item?.value?.includes(cooldownIndex),
            })}
            key={index}
          >
            <span
              className={classnames({
                'text-white50': !item?.value?.includes(cooldownIndex),
              })}
            >
              {item?.label}
            </span>
            <span>{item?.duration}</span>
          </div>
        ))}
      </div>
    </div>
  )

  return (
    <div className="p-4">
      <div className="m-auto md:m-unset">
        <DragonActions dragon={dragon} />
        <h2 className="text-white text-2xl font-bold mb-4">About</h2>
        <Card className="px-4 md:px-8 py-4 md:py-6 text-white sm:max-w-lg">
          <div className="grid grid-cols-2 lg:grid-cols-12">
            <div className="mt-2 lg:col-span-3">
              <h4 className="text-sm-md text-white50 font-bold">Class</h4>
              <div className="flex items-center mt-2">
                <Badge className={classnames(`bg-${dragon?.class?.toLowerCase()}`, 'px-1 py-1')} />
                <span className="pl-2 capitalize">{dragon?.class?.toLowerCase()}</span>
              </div>
            </div>
            <div className="mt-2 lg:col-span-4">
              <Tooltip
                className="flex items-center cursor-pointer text-sm-md text-white50"
                classNameToolTip="w-40 sm:w-40 bg-black text-sm-md border-2 border-blue2 tooltip-potential z-20"
                tooltip={TooltipContentCooldown}
              >
                Cooldown
                <AlertCircle className="ml-1" size={16} />
              </Tooltip>
              <div className="flex items-center mt-2">
                <p className="text-md">
                  {typeof dragon?.cooldownIndex === 'number'
                    ? `${cooldownLabel[cooldownIndex]?.label} (${cooldownLabel[cooldownIndex]?.duration})`
                    : '???'}
                </p>
              </div>
            </div>
            <div className="mt-2 lg:col-span-3">
              <h4 className="text-sm-md text-white50 font-bold flex">
                <Tooltip
                  className="flex items-center cursor-pointer text-sm-md"
                  classNameToolTip="w-72 sm:w-80 bg-black text-sm-md border-2 border-blue2 tooltip-potential"
                  tooltip="When your dracora grows a level, its stats will increase. For each level gained, each stat will increase by the potential point (1 - 5)"
                >
                  Potential
                  <AlertCircle className="ml-1" size={16} />
                </Tooltip>
              </h4>
              <div className="flex items-center mt-2">
                <p className="flex items-center">{dragon?.potential + 1}</p>
              </div>
            </div>
            <div className="mt-2 lg:col-span-2">
              <h4 className="text-sm-md text-white50 font-bold flex">
                <Tooltip
                  tooltipStyle={{
                    transform: 'translateX(-60%)',
                  }}
                  className="flex items-center cursor-pointer text-sm-md"
                  classNameToolTip="w-72 sm:w-80 bg-black text-sm-md border-2 border-blue2"
                  tooltip="There are limited to only 9,999 Gen0 Dracoras. Next-gen Dracoras will be born by breeding Gen0 Dracoras."
                >
                  Generation
                  <AlertCircle className="ml-1" size={16} />
                </Tooltip>
              </h4>
              <div className="flex items-center mt-2">
                <p>{dragon?.generation}</p>
              </div>
            </div>
          </div>

          <h4 className="text-white50 font-bold mt-4 mb-2">Owner</h4>
          <p className="text-sm-md">Dracora Trainer</p>
          <p className="text-white50 text-sm break-words">(Kroma: {ownerAddress})</p>
        </Card>
        <h2 className="text-white text-2xl font-bold my-4 mt-8 flex">
          <Tooltip
            className="flex items-center cursor-pointer"
            classNameToolTip="w-72 sm:w-80 bg-black text-sm-md sm:text-md border-2 border-blue2"
            tooltip={TooltipStatsContent}
          >
            Stats
            <AlertCircle className="ml-1.5 mt-1" size={16} />
          </Tooltip>
        </h2>
        <Card className="px-3 py-4 md:py-6 text-white sm:max-w-lg">
          <div className="grid grid-cols-2 md:grid-cols-3">
            {dataStats.map((item, index) => (
              <div className="text-center py-3" key={index}>
                <h4 className="text-white50 text-sm-md pb-2">{item.label}</h4>
                <div className="flex justify-center items-center">
                  <img src={item.icon} height={17} />
                  <div className="text-white ml-2">
                    {typeof item?.value === 'number' ? (
                      <Value
                        value={isEgg ? item.value + (dragon?.potential + 1) * (level - 1) : item.value}
                        decimals={0}
                      />
                    ) : (
                      item?.value
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Card>
        <h2 className="text-white text-2xl font-bold my-4 mt-8">Body Parts</h2>
        <Card className="px-3 py-4 md:py-6 text-white sm:max-w-lg">
          <div className="grid grid-cols-2 mx-2 md:mx-4 lg:mx-10">
            {dataBody.map((item, index) => (
              <div className="py-3" key={index}>
                <div className="flex items-center">
                  <div className="w-10 h-7 flex justify-center items-center">
                    <img src={item.img} className="max-w-full max-h-full" />
                  </div>
                  <div className="ml-3.5">
                    <p className="text-white50">{item.label}</p>
                    <p className="text-white text-sm">{item?.name}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Card>

        {dragon?.skills?.length ? (
          <>
            <h2 className="text-white text-2xl font-bold my-4 mt-8">Skills</h2>
            <Card className="px-3 py-4 md:py-12 text-white sm:max-w-lg">
              <div className="grid md:grid-cols-1 lg:grid-cols-2 sm:grid-cols-2 grid-cols-2 gap-y-6">
                {dragon?.skills?.map((skill, index) => {
                  return (
                    <div className="flex items-center justify-center" key={index}>
                      <SkillCard
                        key={index}
                        skill={skill}
                        isLink={skill?.nftId !== undefined}
                        to={`/items/skill-card/${skill?.nftId}`}
                        showButtonSwitch={true}
                        dragonOwner={dragon?.owner}
                      />
                    </div>
                  )
                })}
              </div>
            </Card>
          </>
        ) : null}

        {dragon?.equipments?.length ? (
          <>
            <h2 className="text-white text-2xl font-bold my-4 mt-8">Equipments</h2>
            <Card className="px-3 py-4 md:py-12 text-white sm:max-w-lg">
              <div className="grid md:grid-cols-1 lg:grid-cols-2 sm:grid-cols-2 grid-cols-2 gap-y-6">
                {dragon?.equipments?.map((item, index) => {
                  return (
                    <div className="flex items-center justify-center" key={index}>
                      <ItemCard
                        id={item?.id}
                        item={item}
                        isLink={item?.nftId !== undefined}
                        to={`/items/equipment/${item?.nftId}`}
                      />
                    </div>
                  )
                })}
              </div>
            </Card>
          </>
        ) : null}

        {dragon?.matron?.id || dragon?.sire?.id ? (
          <div>
            <h2 className="text-white text-2xl font-bold py-2 pt-8">Parents</h2>
            <div className="grid grid-cols-2 text-white sm:max-w-lg -mx-1.5 sm:-mx-3">
              <div className="pt-3">
                <DragonCardV2
                  dragon={dragon?.matron}
                  handleFavorites={onFavorites}
                  className="mx-1.5 sm:mx-3 cursor-pointer mt-0-important"
                  onClick={() => navToDragon(dragon?.matron)}
                />
              </div>
              <div className="pt-3">
                <DragonCardV2
                  dragon={dragon?.sire}
                  handleFavorites={onFavorites}
                  className="mx-1.5 sm:mx-3 cursor-pointer mt-0-important"
                  onClick={() => navToDragon(dragon?.sire)}
                />
              </div>
            </div>
          </div>
        ) : null}
        {dragon?.children?.length ? (
          <div>
            <h2 className="text-white text-2xl font-bold py-2 pt-8">Children ({dragon?.children?.length})</h2>
            <div className="grid grid-cols-2 text-white sm:max-w-lg -mx-1.5 sm:-mx-3">
              {dragon?.children?.map((item, index) => {
                if (index >= numberRows) return null
                return (
                  <div className="pt-3" key={index}>
                    <DragonCardV2
                      key={index}
                      dragon={item}
                      handleFavorites={onFavorites}
                      className="mx-1.5 sm:mx-3 cursor-pointer mt-0-important"
                      onClick={() => navToDragon(item)}
                    />
                  </div>
                )
              })}
              {dragon?.children?.length > numberRows ? (
                <div className="flex justify-center pt-5 col-span-2">
                  <Button outline size="xs" className="px-8 text-sm-md h-8" onClick={showMore}>
                    View more ({dragon?.children?.length - numberRows})
                    <ChevronDown className="ml-2" />
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}

        <DragonHistories histories={dragon?.histories} />
      </div>
    </div>
  )
}

export default DetailDragon
