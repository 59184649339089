import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

function toFixedIfNecessary(value, dp) {
  return +parseFloat(value).toFixed(dp)
}

const Progress = ({ containerClass, percent, barClass, progressClass, ...rest }) => {
  return (
    <div {...rest} className={classnames('relative w-full', containerClass)}>
      <div className={classnames('overflow-hidden h-5 text-xs flex rounded-xl border-2 border-blue2', barClass)}>
        <div
          style={{
            width: `${percent || 0}%`,
            backgroundImage: `linear-gradient(to right, #48CAE4,#0068B4)`,
          }}
          className={classnames(
            'shadow-none flex flex-col whitespace-nowrap rounded-xl justify-center bg-primary',
            progressClass,
          )}
        >
          <p
            className={classnames(
              'text-white text-center overflow-hidden h-full flex justify-center items-center text-sm',
              {
                hidden: percent < 20,
              },
            )}
          >
            {toFixedIfNecessary(percent, 2)}%
          </p>
        </div>
      </div>
    </div>
  )
}
Progress.propTypes = {
  percent: PropTypes.number.isRequired,
  containerClass: PropTypes.string,
  barClass: PropTypes.string,
  progressClass: PropTypes.string,
}

export default Progress
