import { RPC } from 'connectors/index'
import { KROMA_CHAIN_ID, KROMA_SEPOLIA_CHAIN_ID } from 'constants/index'
import { ChainId } from 'defily-v2-sdk'
import { ethers } from 'ethers'

const simpleRpcProvider = {
  [KROMA_CHAIN_ID]: new ethers.providers.JsonRpcProvider(RPC[KROMA_CHAIN_ID]),
  [KROMA_SEPOLIA_CHAIN_ID]: new ethers.providers.JsonRpcProvider(RPC[KROMA_SEPOLIA_CHAIN_ID]),
  [ChainId.KAI]: new ethers.providers.JsonRpcProvider(RPC[ChainId.KAI]),
  [ChainId.BSC]: new ethers.providers.JsonRpcProvider(RPC[ChainId.BSC]),
  [ChainId.BSC_TESTNET]: new ethers.providers.JsonRpcProvider(RPC[ChainId.BSC_TESTNET]),
  [ChainId.HARMONY]: new ethers.providers.JsonRpcProvider(RPC[ChainId.HARMONY]),
  [ChainId.HARMONY_TESTNET]: new ethers.providers.JsonRpcProvider(RPC[ChainId.HARMONY_TESTNET]),
}

export default simpleRpcProvider
