import BigNumber from 'bignumber.js'
import useRefresh from 'hooks/useRefresh'
import { useEffect, useState } from 'react'
import { BIG_ZERO } from 'utils/bigNumber'
import useActiveWeb3React from './useActiveWeb3React'
import { ethers } from 'ethers'
import { RPC } from '../connectors'
import { checkChainId } from '../utils'
import { useTokenContract } from './useContract'

const useTokenBalance = (tokenAddress) => {
  const [balance, setBalance] = useState(BIG_ZERO)
  const { chainId, account } = useActiveWeb3React()
  const { fastRefresh } = useRefresh()
  const contract = useTokenContract(tokenAddress)
  useEffect(() => {
    async function fetchTokenBalance() {
      try {
        const res = await contract.balanceOf(account)
        setBalance(new BigNumber(res?._hex))
      } catch (e) {
        console.log(e)
      }
    }

    if (account && tokenAddress && checkChainId(chainId)) {
      fetchTokenBalance()
    }
  }, [account, fastRefresh, tokenAddress])
  return balance
}

export const useBalanceMainCoin = () => {
  const [balance, setBalance] = useState(BIG_ZERO)
  const { account, chainId } = useActiveWeb3React()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    async function fetchBalanceMainCoin() {
      try {
        const provider = new ethers.providers.JsonRpcProvider(RPC[chainId])
        const balance = await provider.getBalance(account)
        setBalance(new BigNumber(balance?._hex))
      } catch (e) {
        console.log(e)
      }
    }

    if (account) {
      fetchBalanceMainCoin()
    }
  }, [account, fastRefresh])

  return balance
}
export default useTokenBalance
