import React from 'react'
import classnames from 'classnames'
import { NavLink } from 'react-router-dom'

const Box = ({ children, className, isLink, ...rest }) => {
  const TagName = isLink ? NavLink : 'div'
  return (
    <TagName {...rest} className={classnames('rounded-lg', className)}>
      {children}
    </TagName>
  )
}
export default Box
