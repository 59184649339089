import React from 'react'
import { useTabSold } from 'store/report/hook'
import BigNumber from 'bignumber.js'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import Badge from 'components/Badge/Badge'
import { ChainId } from 'defily-v2-sdk'
import { formatAddress } from 'utils/addressHelpers'
import { getExplorerLink } from 'utils/explorer'
import Web3 from 'web3'
import { formatDDMMYYYYHHmm } from 'utils/formatDateTime'
import { formatNumber } from 'utils/formatBalance'
import { TOKEN_USED_MARKETPLACE, RARITY_ITEM, RARITY_SKILL, KROMA_CHAIN_ID } from 'constants/index'
export default function SoldItem({ item, tokenPrice }) {
  const FORMAT_ITEM_TYPE_URL = {
    EXP_CARD: 'exp-card',
    SKILL_CARD: 'skill-card',
    EQUIPMENT: 'equipment',
  }

  const tab = useTabSold()
  const priceUsd = item?.price ? new BigNumber(item.price).times(tokenPrice).toNumber() : 0
  const itemImage =
    tab === 0
      ? item?.item?.equipment?.image
      : tab === 1
      ? item?.item?.skill?.image
      : tab === 2
      ? item?.exp < 50
        ? '/images/bg_exp/exp_white.png'
        : item?.exp > 75
        ? '/images/bg_exp/exp_purple.png'
        : '/images/bg_exp/exp_blue.png'
      : ''
  return (
    <Link to={`/items/${FORMAT_ITEM_TYPE_URL?.[item?.item?.type]}/${item?.item?.nftId}`}>
      <div className="border-b-1 border-blue4 cursor-pointer pt-4 px-2.5 relative">
        <div className="flex justify-between">
          <div className="flex flex-1 items-center">
            <div className="h-14 sm:h-20 w-20 sm:w-24 lg:w-28 xl:w-32 flex items-center justify-center relative">
              <img src={itemImage} className="max-w-full max-h-full" />
              {item?.item?.exp ? (
                <p className="absolute whitespace-nowrap text-sm opacity-50 text-white sm:bottom-3 bottom-2 left-1/2 transform -translate-x-1/2 z-20">
                  +{item?.item?.exp} exp
                </p>
              ) : null}
            </div>
            <div className="flex flex-col items-start ml-3">
              <Badge className={classnames('text-white px-2 sm:px-2.5 text-xs sm:text-sm pt-0.5 pb-0.5 bg-metal mb-1')}>
                #{item?.item?.nftId}
              </Badge>
              {item?.item?.type !== 'EXP_CARD' ? (
                <p className="text-xs sm:text-sm-md">Level: {item?.item?.level || 1}</p>
              ) : null}

              {item?.itemType?.toLowerCase() === 'skill_card' ? (
                <p
                  style={{
                    color: RARITY_SKILL?.[item?.item?.skill?.rarity]?.textColor,
                  }}
                  className="text-xs sm:text-sm-md"
                >
                  {RARITY_SKILL?.[item?.item?.skill?.rarity]?.label}
                </p>
              ) : null}

              {item?.itemType?.toLowerCase() === 'equipment' ? (
                <p
                  style={{
                    color: RARITY_ITEM?.[item?.item?.equipment?.rarity]?.color,
                  }}
                  className="text-xs sm:text-sm-md"
                >
                  {RARITY_ITEM?.[item?.item?.equipment?.rarity]?.label}
                </p>
              ) : null}
            </div>
            <div className="ml-2.5 text-white flex items-center">
              <div>
                <p>
                  <span className="text-white pr-1 text-sm sm:text-sm-md block sm:inline">Seller:</span>
                  {item?.from ? (
                    <a
                      href={getExplorerLink(KROMA_CHAIN_ID, Web3.utils.toChecksumAddress(item?.from), 'address')}
                      target="_blank"
                      className="text-white50 text-sm-md sm:text-base"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {formatAddress(item?.from)}
                    </a>
                  ) : (
                    '???'
                  )}
                </p>
                <p>
                  <span className="text-blue4 pr-1 text-sm sm:text-sm-md block sm:inline">Buyer:</span>
                  {item?.to ? (
                    <a
                      href={getExplorerLink(KROMA_CHAIN_ID, Web3.utils.toChecksumAddress(item?.to), 'address')}
                      target="_blank"
                      className="text-white50 text-sm-md sm:text-base"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {formatAddress(item?.to)}
                    </a>
                  ) : (
                    '???'
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="hidden sm:flex items-center justify-end text-white mr-1">
            <div>
              <p
                style={{
                  maxWidth: 105,
                }}
                className="xl:text-lg truncate text-right"
              >
                {formatNumber(item?.price || 0, 0, 3)}
                <span className="text-sm-md pl-0.5">{TOKEN_USED_MARKETPLACE.symbol}</span>
              </p>
              <p className="text-xs opacity-50 text-right">~{formatNumber(priceUsd, 0, 3)}$</p>
            </div>
          </div>
        </div>
        <div className="flex justify-between sm:justify-end items-end my-1 px-2.5">
          <div className="flex sm:hidden justify-center text-white">
            <p>
              <span className="pr-1">{formatNumber(item?.price || 0, 0, 5)}</span>
              <span>{TOKEN_USED_MARKETPLACE.symbol}</span>
              <span className="text-xs opacity-50 pl-2">~{formatNumber(priceUsd, 0, 3)}$</span>
            </p>
          </div>
          <p className="text-xs text-white50 text-right">{formatDDMMYYYYHHmm(item?.createdAt)}</p>
        </div>
      </div>
    </Link>
  )
}
