import { BODY_PART_TYPE, MAX_NUMBER_QUERY } from '../../constants'

const getConditionQueryNumber = (obj, key) => {
  if (typeof obj !== 'object') return null
  if (obj?.from?.toString()?.length && obj?.to?.toString()?.length && Number(obj?.from) > Number(obj?.to)) return null
  if (typeof obj?.from === 'number' && typeof obj?.to === 'number') return `${obj?.from},${obj?.to}`
  if (typeof obj?.from === 'number' && typeof obj?.to !== 'number')
    return `${obj?.from},${key === 'price2' ? 100 * 10 ** 6 : MAX_NUMBER_QUERY}`
  if (typeof obj?.from !== 'number' && typeof obj?.to === 'number') return `${0},${obj?.to}`
  return null
}

export const formatQueryDragons = (query) => {
  const _query = typeof query === 'object' ? query : JSON.parse(query)

  let queryObj = {}
  if (_query?.data?.textSearch) {
    queryObj.textSearch = _query.data.textSearch
  }
  if (_query?.data?.filterSale?.value) {
    queryObj.sale = _query.data.filterSale.value
  }
  if (_query?.data?.sort?.value) {
    queryObj.order = _query.data.sort.field
    queryObj.orderBy = _query.data.sort.type
  }
  if (_query?.dragons?.class?.length) {
    queryObj.classDragon = _query?.dragons?.class.join(',')
  }
  if (_query?.dragons?.type?.length) {
    queryObj.type = _query?.dragons?.type.join(',')
  }
  if (_query?.pageQuery) {
    queryObj.page = _query.pageQuery
  }

  Object.keys(_query?.dragons)?.map((key) => {
    const stringQuery = getConditionQueryNumber(_query?.dragons?.[key], key)
    if (stringQuery) {
      queryObj[key] = stringQuery
    }
  })

  if (_query?.dragons?.price) {
    if (_query?.dragons?.price?.from === 0 && _query?.dragons?.price?.to >= 100) {
      delete queryObj.price
    }
  }

  if (_query?.dragons?.price2) {
    if ((!_query?.dragons?.price2?.from || _query?.dragons?.price2?.from === 0) && !_query?.dragons?.price2?.to) {
      delete queryObj.price2
    }
  }

  if (_query?.bodyParts) {
    Object.keys(BODY_PART_TYPE).forEach((key) => {
      if (_query.bodyParts?.[key]) {
        queryObj[key] = _query.bodyParts[key]
      }
    })
  }
  return queryObj
}

export const formatQueryItems = (query) => {
  const _query = typeof query === 'object' ? query : JSON.parse(query)
  let queryObj = {}
  if (_query?.data?.textSearch) {
    queryObj.textSearch = _query.data.textSearch
  }
  if (_query?.data?.filterSale?.value) {
    queryObj.status = _query.data.filterSale.value
  }
  if (_query?.data?.sort?.value) {
    queryObj.order = _query.data.sort.field
    queryObj.orderBy = _query.data.sort.type
  }
  if (_query?.pageQuery) {
    queryObj.page = _query.pageQuery
  }
  if (_query?.items?.exp?.length) {
    queryObj.exp = _query?.items?.exp.join(',')
  }
  if (_query?.items?.rarity?.length) {
    queryObj.rarity = _query?.items?.rarity.join(',')
  }
  if (_query?.items?.part?.length) {
    queryObj.part = _query?.items?.part.join(',')
  }
  if (_query?.items?.class?.length) {
    queryObj.class = _query?.items?.class.join(',')
  }

  if (_query?.items?.price) {
    if (_query?.items?.price?.from === 0 && _query?.items?.price?.to >= 100) {
      delete _query?.items?.price
    }
  }

  if (_query?.items?.price2) {
    if ((!_query?.items?.price2?.from || _query?.items?.price2?.from === 0) && !_query?.items?.price2?.to) {
      delete _query?.items?.price2
    }
  }

  Object.keys(_query?.items)?.map((key) => {
    const stringQuery = getConditionQueryNumber(_query?.items?.[key], key)
    if (stringQuery) {
      queryObj[key] = stringQuery
    }
  })

  return queryObj
}
