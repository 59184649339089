import React from 'react'
import Modal from './ModalV2'
import ModalTitle from './ModalTitleV2'
import ButtonGradient from '../Button/ButtonGradient'
import classnames from 'classnames'
import Badge from '../Badge/Badge'

const ModalAdventureAll = ({ open, toggle, listDragons, onSubmit }) => {
  return (
    <Modal isDark open={open} onClose={toggle} className="bg-blue">
      <ModalTitle onClose={toggle}>Adventure all dracoras</ModalTitle>
      <div className="p-4">
        <h4 className="text-white text-center text-lg mb-2">Adventure</h4>
        <div className="flex justify-center flex-wrap">
          {listDragons?.map((id, index) => (
            <Badge key={index} className={classnames('bg-blue2 text-white px-4 text-sm pt-0.5 pb-0.5 my-1 mx-0.5')}>
              #{id}
            </Badge>
          ))}
        </div>
        <div className="flex justify-center mt-4">
          <ButtonGradient noneBorder rounded={false} onClick={onSubmit}>
            Adventure all
          </ButtonGradient>
        </div>
      </div>
    </Modal>
  )
}

export default ModalAdventureAll
