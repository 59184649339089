import React from 'react'
import classnames from 'classnames'
import Box from 'components/Box/Box'
import {
  cooldownLabel,
  DEFAULT_EGG_CLASS,
  DEFAULT_TOKEN_USED,
  IMAGE_SIZE,
  MAX_DEFINE_COOLDOWN,
  TOKEN_USED_MARKETPLACE,
} from 'constants/index'
import { Plus } from 'react-feather'
import Badge from 'components/Badge/Badge'
import { getImageURL } from 'utils'
import Value from 'components/Value/Value'
import { formatNumber, getBalanceAmount } from 'utils/formatBalance'
import BigNumber from 'bignumber.js'

const DragonCard = ({ type, dragon, className, tokenPrice, ...rest }) => {
  const isEgg = dragon?.type === 'EGG'
  const price = getBalanceAmount(dragon?.price, 0)
  const priceUsd = price ? formatNumber(new BigNumber(price).times(tokenPrice).toNumber()) : 0
  const cooldownIndex = dragon?.cooldownIndex < MAX_DEFINE_COOLDOWN ? dragon?.cooldownIndex : MAX_DEFINE_COOLDOWN

  return (
    <Box
      {...rest}
      className={classnames(
        'cursor-pointer max-w-xs border-2 flex flex-col items-center flex-1 overflow-hidden h-full border-blue2 bg-blue1 p-0 mx-4 md:mx-14  w-64 md:w-64 lg:w-80 xl:w-96 py-8',
        className,
      )}
    >
      <div className="h-80">
        <h2 className="capitalize text-center text-blue4 font-bold mb-4 flex justify-center items-center">
          {type}
          <img
            src={type === 'Mother' ? '/icon/female.svg' : '/icon/male.svg'}
            style={{
              height: 25,
            }}
            className="ml-1.5"
          />
        </h2>
        {dragon ? (
          <div>
            <div className="flex justify-between items-center">
              <Badge
                className={classnames(`bg-${dragon?.class?.toLowerCase()}`, 'text-white px-4 text-sm pt-0.5 pb-0.5')}
              >
                #{dragon?.id}
              </Badge>
              <Badge className={classnames('text-white px-4 text-sm pt-0.5 pb-0.5 bg-blue2 ml-1')}>
                Gen {dragon?.generation}
              </Badge>
            </div>
            <div className="flex justify-between items-center mt-2">
              <div className="flex items-center">
                <Badge className={classnames(`bg-${dragon?.class?.toLowerCase()}`, 'px-1 py-1')} />
                <span className="pl-2 capitalize text-sm-md text-white">{dragon?.class?.toLowerCase()}</span>
              </div>
              <Badge className={classnames('text-white px-4 text-sm pt-0.5 pb-0.5 bg-blue2 ml-5')}>
                {typeof dragon?.cooldownIndex === 'number'
                  ? `${cooldownLabel[cooldownIndex]?.label} (${cooldownLabel[cooldownIndex]?.duration})`
                  : '???'}
              </Badge>
            </div>
            <div
              className={classnames('flex justify-center h-44 py-3 mt-6 relative', {
                'py-6': isEgg,
              })}
            >
              <img
                src={isEgg ? DEFAULT_EGG_CLASS[dragon?.class] : getImageURL(dragon?.image, IMAGE_SIZE['250'])}
                className="h-full"
              />
            </div>
            <div className="h-9">
              {dragon?.price ? (
                <>
                  <Value
                    value={dragon?.price}
                    decimals={6}
                    unit={` ETH`}
                    className="text-white text-center font-bold text-md"
                  />
                  <Value
                    value={priceUsd}
                    decimals={2}
                    prefix="~"
                    unit="$"
                    className="text-white text-center text-xs opacity-50"
                  />
                </>
              ) : null}
            </div>
          </div>
        ) : (
          <div className="w-full h-full flex justify-center items-center">
            <div className="flex flex-col items-center">
              <Plus className="text-white" size={30} />
              <p className="text-white text-center text-sm-md mt-4">Select dracora</p>
            </div>
          </div>
        )}
      </div>
    </Box>
  )
}

export default DragonCard
