import { useCallback } from 'react'
import { useCoreContract, useSireContract } from './useContract'
import { cancelSiring, sireDragon } from '../utils/calls/auction'
import useActiveWeb3React from './useActiveWeb3React'

export const useSire = (coreAddress) => {
  const { account } = useActiveWeb3React()
  const contract = useCoreContract(coreAddress)
  const handleSire = useCallback(
    async (idToken, price, decimals) => {
      const txHash = await sireDragon(contract, idToken, price, decimals)
      return txHash
    },
    [coreAddress, account],
  )

  return { onSire: handleSire }
}

export const useCancelSiring = (siringAddress) => {
  const { account } = useActiveWeb3React()
  const contract = useSireContract(siringAddress)
  const handleCancel = useCallback(async (idToken) => {
    const txHash = await cancelSiring(contract, idToken)
    return txHash
  })

  return { onCancelSiring: handleCancel }
}
