import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useCanUpdateQuery, useQuerySearch, usePathStoreQuery, usePageQuery } from '../search/hook'
import { useHistory, useParams } from 'react-router-dom'
import useRefresh from '../../hooks/useRefresh'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'
import {
  fetchItemsAsync,
  fetchDetailItem,
  setItems,
  fetchDetailItemApproved,
  fetchAllDragonSkills,
  fetchAllEffects,
  fetchAllEquipments,
} from './index'
import { getUrlQuery, useQuery } from 'utils'
import { DEFAULT_LIMIT, ITEM_TYPES_URL } from 'constants/index'

export const useMyItems = () => useSelector((state) => state.items.myItems)

export const useItem = () => useSelector((state) => state.items.item)

export const useListSuccessRate = () => useSelector((state) => state.items.listSuccessRate)

export const useItemApproved = () => useSelector((state) => state.items.itemApproved)

export const useAllDragonSkills = () => useSelector((state) => state.items.allSkills)

export const useAllEffects = () => useSelector((state) => state.items.allEffects)

export const useAllEquipments = () => useSelector((state) => state.items.allEquipments)

export const formatQueryItems = (query) => {
  const object = Object.assign({}, query)

  if (object?.price) {
    const price = object.price.split(',')
    if (price?.length === 2) {
      if (Number(price?.[0]) === 0 && Number(price?.[1] >= 100)) {
        delete object.price
      } else {
        object.price = `${Number(price?.[0]) * 1000},${price?.[1] === 100 ? 1000 * 1000 : price?.[1] * 1000}`
      }
    }
  }

  if (object.level) {
    const level = object.level.split(',')?.map((item) => Number(item))
    if (level?.length === 2) {
      object.level = `${level[0] - 1},${level[1] - 1}`
    }
  }

  if (object?.price2) {
    object.price = object.price2
    delete object.price2
  }

  return {
    ...object,
  }
}

export const useFetchItems = (defaultQuery = {}) => {
  const { account } = useActiveWeb3React()
  const pageQuery = usePageQuery()
  const { push, location } = useHistory()
  const dispatch = useDispatch()
  const searchObj = useQuerySearch()
  const queryUrl = useQuery()
  const canUpdateQuery = useCanUpdateQuery()
  const { slowRefresh } = useRefresh()
  const items = useMyItems()
  const pathNameQuery = usePathStoreQuery()

  useEffect(() => {
    if (canUpdateQuery && location.pathname === pathNameQuery) {
      push(getUrlQuery(location.pathname, searchObj))
    }
  }, [JSON.stringify(searchObj)])

  useEffect(() => {
    const page = 1
    dispatch(fetchItemsAsync(formatQueryItems({ page, ...queryUrl, rowPerPage: DEFAULT_LIMIT, ...defaultQuery })))
  }, [JSON.stringify(queryUrl)])

  useEffect(() => {
    const page = items?.currentPage || 1
    dispatch(fetchItemsAsync(formatQueryItems({ page, ...queryUrl, rowPerPage: DEFAULT_LIMIT, ...defaultQuery })))
  }, [slowRefresh, account, pageQuery])
}

export const useFetchItemsSameType = (defaultQuery = {}) => {
  const { account } = useActiveWeb3React()
  const dispatch = useDispatch()
  const searchObj = useQuerySearch()
  const { slowRefresh } = useRefresh()
  const items = useMyItems()

  useEffect(() => {
    const page = 1
    dispatch(fetchItemsAsync({ page, ...searchObj, ...defaultQuery }))
  }, [JSON.stringify(searchObj)])

  useEffect(() => {
    const page = items?.currentPage || 1
    dispatch(fetchItemsAsync({ page, ...searchObj, ...defaultQuery }))
  }, [slowRefresh, account])

  useEffect(() => {
    return () => {
      dispatch(setItems(undefined))
    }
  }, [])
}

export const useFetchItem = () => {
  const { id, type } = useParams()
  const itemType = ITEM_TYPES_URL?.[type]
  const dispatch = useDispatch()
  const { fastRefresh } = useRefresh()
  const { account } = useActiveWeb3React()
  useEffect(() => {
    dispatch(fetchDetailItem(itemType, id))
  }, [itemType, id, fastRefresh, account, dispatch])
}

export const useFetchUserDataDetailItem = () => {
  const { id, type } = useParams()
  const { account } = useActiveWeb3React()
  const dispatch = useDispatch()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    if (account) {
      dispatch(fetchDetailItemApproved(ITEM_TYPES_URL?.[type], id))
    }
  }, [id, dispatch, fastRefresh, type, account])
}

export const useFetchAllDragonSkills = (defaultQuery = {}) => {
  const { account } = useActiveWeb3React()
  const pageQuery = usePageQuery()
  const { push, location } = useHistory()
  const dispatch = useDispatch()
  const searchObj = useQuerySearch()
  const queryUrl = useQuery()
  const canUpdateQuery = useCanUpdateQuery()
  const { slowRefresh } = useRefresh()
  const allSkills = useAllDragonSkills()
  const pathNameQuery = usePathStoreQuery()

  useEffect(() => {
    if (canUpdateQuery && location.pathname === pathNameQuery) {
      push(getUrlQuery(location.pathname, searchObj))
    }
  }, [JSON.stringify(searchObj)])

  useEffect(() => {
    const page = 1
    dispatch(fetchAllDragonSkills(formatQueryItems({ page, ...queryUrl, rowPerPage: DEFAULT_LIMIT, ...defaultQuery })))
  }, [JSON.stringify(queryUrl)])

  useEffect(() => {
    const page = allSkills?.currentPage || 1
    dispatch(fetchAllDragonSkills(formatQueryItems({ page, ...queryUrl, rowPerPage: DEFAULT_LIMIT, ...defaultQuery })))
  }, [slowRefresh, account, pageQuery])
}

export const useFetchAllEffects = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchAllEffects())
  }, [])
}

export const useFetchAllEquipments = (defaultQuery = {}) => {
  const { account } = useActiveWeb3React()
  const pageQuery = usePageQuery()
  const { push, location } = useHistory()
  const dispatch = useDispatch()
  const searchObj = useQuerySearch()
  const queryUrl = useQuery()
  const canUpdateQuery = useCanUpdateQuery()
  const { slowRefresh } = useRefresh()
  const allEquipments = useAllEquipments()
  const pathNameQuery = usePathStoreQuery()

  useEffect(() => {
    if (canUpdateQuery && location.pathname === pathNameQuery) {
      push(getUrlQuery(location.pathname, searchObj))
    }
  }, [JSON.stringify(searchObj)])

  useEffect(() => {
    const page = 1
    dispatch(fetchAllEquipments(formatQueryItems({ page, ...queryUrl, rowPerPage: DEFAULT_LIMIT, ...defaultQuery })))
  }, [JSON.stringify(queryUrl)])

  useEffect(() => {
    const page = allEquipments?.currentPage || 1
    dispatch(fetchAllEquipments(formatQueryItems({ page, ...queryUrl, rowPerPage: DEFAULT_LIMIT, ...defaultQuery })))
  }, [slowRefresh, account, pageQuery])
}
