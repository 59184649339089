import React, { useState } from 'react'
import Modal from './Modal'
import ModalTitle from './ModalTitle'
import ModalFooter from './ModalFooter'
import ButtonGradient from '../Button/ButtonGradient'
import { showToastError } from '../CustomToast/CustomToast'

const ModalDragonCave = ({ open, toggle, dragon, onSubmit }) => {
  const [pendingTx, setPendingTx] = useState(false)

  const handleClaim = async () => {
    try {
      setPendingTx(true)
      await onSubmit()
      await toggle()
      setPendingTx(false)
    } catch (error) {
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
      setPendingTx(false)
    }
  }

  return (
    <Modal onClose={toggle} open={open}>
      <ModalTitle onClose={toggle}>Claim {dragon?.type === 'EGG' ? 'Egg' : 'Dracora'}</ModalTitle>
      <div className="p-2 sm:p-4">
        <p>
          This action will burn your WDRACORA token and allow you to claim an{' '}
          {dragon?.type === 'EGG' ? 'Egg' : 'Dracora'} from the Dracora Cave
        </p>
      </div>
      <ModalFooter>
        <ButtonGradient size="sm" disabled={pendingTx} isLoading={pendingTx} onClick={handleClaim}>
          Claim
        </ButtonGradient>
      </ModalFooter>
    </Modal>
  )
}

export default ModalDragonCave
