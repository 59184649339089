import { CORE_ADDRESS, SIRING_ADDRESS } from 'config'
import multicall from 'utils/multicall'
import coreAbi from 'config/abi/coreABI.json'
import siringAbi from 'config/abi/siringAbi.json'
import { DEFAULT_TOKEN_USED } from 'constants/index'
import dragonAbi from 'config/abi/dragon.json'
import BigNumber from 'bignumber.js'

export const checkCanBreed = async (matron, sire) => {
  try {
    const calls = [
      {
        address: CORE_ADDRESS,
        name: 'canMateWith',
        params: [matron, sire],
      },
      {
        address: CORE_ADDRESS,
        name: 'isReadyToMate',
        params: [matron],
      },
      {
        address: CORE_ADDRESS,
        name: 'isReadyToMate',
        params: [sire],
      },
    ]
    const response = await multicall(coreAbi, calls)

    return {
      canMate: response?.[0]?.[0],
      matronIsReady: response?.[1]?.[0],
      sireIsReady: response?.[2]?.[0],
      dataLoaded: true,
    }
  } catch (error) {}
}

export const checkCanMating = async (matron, sire) => {
  try {
    const callsCore = [
      {
        address: CORE_ADDRESS,
        name: 'isReadyToMate',
        params: [matron],
      },
    ]
    const callsSiring = [
      {
        address: SIRING_ADDRESS,
        name: 'canMateViaAuction',
        params: [sire, matron],
      },
    ]
    const promise = [multicall(coreAbi, callsCore), multicall(siringAbi, callsSiring)]
    const response = await Promise.all(promise)

    return {
      canMate: response?.[1]?.[0]?.[0],
      matronIsReady: response?.[0]?.[0]?.[0],
      sireIsReady: true,
      dataLoaded: true,
    }
  } catch (error) {}
}

export const fetchAllowanceBreed = async (account) => {
  try {
    const calls = [
      {
        address: DEFAULT_TOKEN_USED.address,
        name: 'allowance',
        params: [account, CORE_ADDRESS],
      },
    ]
    const [allowance] = await multicall(dragonAbi, calls)
    return new BigNumber(allowance).toJSON()
  } catch (error) {}
}
