export const transferEgg = async (contract, address, idDragon) => {
  const tx = await contract.transfer(address, idDragon)
  await tx.wait()
  return tx.transactionHash
}

export const transferItem = async (contract, fromAddress, toAddress, id) => {
  const tx = await contract.transferFrom(fromAddress, toAddress, id)
  await tx.wait()
  return tx.transactionHash
}
