import { useCallback } from 'react'
import { useEventMonsterContract } from './useContract'
import useActiveWeb3React from './useActiveWeb3React'
import { claimRewardMonster } from '../utils/calls/events'

export const useClaimReward = (eventAddress) => {
  const { account } = useActiveWeb3React()
  const contract = useEventMonsterContract(eventAddress)
  const handleClaim = useCallback(
    async (type, idDragons) => {
      const txHash = await claimRewardMonster(contract, type, idDragons)
    },
    [eventAddress, account],
  )

  return { onClaimReward: handleClaim }
}
