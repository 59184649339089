import { configureStore } from '@reduxjs/toolkit'

import pricesReducer from './prices'
import blockReducer from './block'
import modalReducer from './modal'
import dragonsReducer from './dragons'
import dragonCaveReducer from './dragonCave'
import searchReducer from './search'
import breedReducer from './breed'
import reportReducer from './report'
import notificationReducer from './notification'
import monstersReducer from './monster'
import itemsReducer from './items'
import giftReducer from './gift'
const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    block: blockReducer,
    prices: pricesReducer,
    modal: modalReducer,
    dragons: dragonsReducer,
    dragonCave: dragonCaveReducer,
    search: searchReducer,
    breed: breedReducer,
    report: reportReducer,
    notification: notificationReducer,
    monsters: monstersReducer,
    items: itemsReducer,
    gift: giftReducer,
  },
})

export default store
