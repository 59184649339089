import classNames from 'classnames'
import PropTypes from 'prop-types'

const Card = ({ children, className, ...props }) => {
  return (
    <div {...props} className={classNames(className, 'rounded-xl border-2 border-blue2 bg-blue1 shadow-lg')}>
      {children}
    </div>
  )
}

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  minWidth: PropTypes.number,
  minHeight: PropTypes.number,
}

export default Card
