import { EVENT_MONSTER_ADDRESS } from '../../config'
import multicall from '../../utils/multicall'
import eventMonsterAbi from 'config/abi/eventMonsterAbi.json'
import BigNumber from 'bignumber.js'
import { MONSTER_TYPES } from '../../constants'

export const getIdAvailableMonsters = async (type) => {
  try {
    const calls = [
      {
        name: type === MONSTER_TYPES.XX_MONSTER ? 'getAvailableMonsterXX' : 'getAvailableMonsterYY',
        address: EVENT_MONSTER_ADDRESS,
      },
    ]
    const [[arrMonsters]] = await multicall(eventMonsterAbi, calls)
    return arrMonsters?.map((item) => new BigNumber(item?._hex).toNumber()).filter((item) => item)
  } catch (error) {}
}

export const getXXMonsters = async (available = true) => {
  try {
    const calls = [
      {
        name: available ? 'getAvailableMonsterXX' : 'getUnavailableMonsterXX',
        address: EVENT_MONSTER_ADDRESS,
      },
    ]
    const [[arrMonsters]] = await multicall(eventMonsterAbi, calls)
    const monsterId = arrMonsters?.map((item) => new BigNumber(item?._hex).toNumber()).filter((item) => item)
    const callDetails = monsterId?.map((id) => ({
      name: 'getMonsterXX',
      address: EVENT_MONSTER_ADDRESS,
      params: [id],
    }))
    const response = await multicall(eventMonsterAbi, callDetails)
    const monsters = response?.map((monster, index) => {
      return {
        id: monsterId[index],
        lockTime: new BigNumber(monster?._lockTime?._hex).toNumber(),
        requireLevel: new BigNumber(monster?._requireLevel?._hex).toNumber(),
        rewardAmount: new BigNumber(monster?._rewardAmount?._hex).toNumber(),
        startTime: new BigNumber(monster?._startTime?._hex).toNumber() * 1000,
        totalReward: new BigNumber(monster?._totalReward?._hex).toNumber(),
        chanceIncrease: new BigNumber(monster?._chanceIncrease?._hex).toNumber(),
        type: MONSTER_TYPES.XX_MONSTER,
        available,
      }
    })

    return monsters
  } catch (error) {}
}

export const getYYMonsters = async (available = true) => {
  try {
    const calls = [
      {
        name: available ? 'getAvailableMonsterYY' : 'getUnavailableMonsterYY',
        address: EVENT_MONSTER_ADDRESS,
      },
    ]
    const [[arrMonsters]] = await multicall(eventMonsterAbi, calls)
    const monsterId = arrMonsters?.map((item) => new BigNumber(item?._hex).toNumber()).filter((item) => item)
    const callDetails = monsterId?.map((id) => ({
      name: 'getMonsterYY',
      address: EVENT_MONSTER_ADDRESS,
      params: [id],
    }))
    const response = await multicall(eventMonsterAbi, callDetails)
    const monsters = response?.map((monster, index) => {
      return {
        id: monsterId[index],
        totalHp: new BigNumber(monster?._totalHp?._hex).toNumber(),
        currentHp: new BigNumber(monster?._currentHp?._hex).toNumber(),
        rewardPercentage: new BigNumber(monster?._rewardPercentage?._hex).toNumber(),
        startTime: new BigNumber(monster?._startTime?._hex).toNumber() * 1000 || 0,
        totalReward: new BigNumber(monster?._totalReward?._hex).toNumber(),
        type: MONSTER_TYPES.YY_MONSTER,
        available,
      }
    })

    return monsters
  } catch (error) {}
}

export const getMonster = async (type, id) => {
  try {
    const calls = [
      {
        name: type === MONSTER_TYPES.XX_MONSTER ? 'getMonsterXX' : 'getMonsterYY',
        address: EVENT_MONSTER_ADDRESS,
        params: [id],
      },
    ]
    const [monster] = await multicall(eventMonsterAbi, calls)
    if (type === MONSTER_TYPES.XX_MONSTER) {
      return {
        id: Number(id),
        lockTime: new BigNumber(monster?._lockTime?._hex).toNumber(),
        requireLevel: new BigNumber(monster?._requireLevel?._hex).toNumber(),
        rewardAmount: new BigNumber(monster?._rewardAmount?._hex).toNumber(),
        startTime: new BigNumber(monster?._startTime?._hex).toNumber() * 1000,
        totalReward: new BigNumber(monster?._totalReward?._hex).toNumber(),
        chanceIncrease: new BigNumber(monster?._chanceIncrease?._hex).toNumber(),
        type: MONSTER_TYPES.XX_MONSTER,
      }
    }

    return {
      id: Number(id),
      totalHp: new BigNumber(monster?._totalHp?._hex).toNumber(),
      currentHp: new BigNumber(monster?._currentHp?._hex).toNumber(),
      rewardPercentage: new BigNumber(monster?._rewardPercentage?._hex).toNumber(),
      startTime: new BigNumber(monster?._startTime?._hex).toNumber() * 1000 || 0,
      totalReward: new BigNumber(monster?._totalReward?._hex).toNumber(),
      type: MONSTER_TYPES.YY_MONSTER,
    }
  } catch (error) {}
}

export const getRewardEvents = async (type, idDragons) => {
  try {
    const calls = [
      {
        name: type === MONSTER_TYPES.XX_MONSTER ? 'getTotalXXReward' : 'getTotalYYReward',
        address: EVENT_MONSTER_ADDRESS,
        params: [idDragons],
      },
    ]
    if (type === MONSTER_TYPES.XX_MONSTER) {
      calls.push({
        name: 'getClaimableXXReward',
        address: EVENT_MONSTER_ADDRESS,
        params: [idDragons],
      })
    }
    const [totalReward, claimableReward] = await multicall(eventMonsterAbi, calls)

    const data = {
      totalReward: new BigNumber(totalReward).toNumber(),
    }

    if (type === MONSTER_TYPES.XX_MONSTER) {
      data.claimableReward = new BigNumber(claimableReward).toNumber()
    }

    return data
  } catch (error) {}
}

export const getCooldown = async () => {
  try {
    const calls = [
      {
        name: 'getCooldownXX',
        address: EVENT_MONSTER_ADDRESS,
      },
      {
        name: 'getCooldownYY',
        address: EVENT_MONSTER_ADDRESS,
      },
    ]
    const [[cooldownXX], [cooldownYY]] = await multicall(eventMonsterAbi, calls)
    return {
      [MONSTER_TYPES.XX_MONSTER]: new BigNumber(cooldownXX?._hex).toNumber(),
      [MONSTER_TYPES.YY_MONSTER]: new BigNumber(cooldownYY?._hex).toNumber(),
    }
  } catch (error) {}
}
