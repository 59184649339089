import React, { useEffect, useState } from 'react'
import Modal from 'components/Modal/ModalV2'
import ModalTitle from 'components/Modal/ModalTitleV2'
import { formatAddress } from 'utils/addressHelpers'
import { formatDDMMYYYYHHmm } from 'utils/formatDateTime'
import Pagination from 'components/Pagination'
import eventApi from 'api/eventApi'
import { HISTORY_MONSTER_TYPE, HISTORY_MONSTER_TYPE_COLOR, KROMA_CHAIN_ID, MONSTER_TYPES_QUERY } from 'constants/index'
import { getExplorerLink } from 'utils/explorer'
import Web3 from 'web3'
import classnames from 'classnames'
import Dropdown from 'components/Dropdown/Dropdown'
import useActiveWeb3React from '../../../../hooks/useActiveWeb3React'
import { ChainId } from 'defily-v2-sdk'

const ModalFightHistory = ({ open, toggle, monsterId, type, dragonId, myOwner }) => {
  const { account } = useActiveWeb3React()
  const [history, setHistory] = useState(undefined)
  const [status, setStatus] = useState(undefined)
  const dropdownStatus =
    type === MONSTER_TYPES_QUERY.XX
      ? [
          {
            label: 'All',
            value: null,
          },
          {
            label: HISTORY_MONSTER_TYPE.FIGHTWIN,
            value: HISTORY_MONSTER_TYPE.FIGHTWIN,
          },
          {
            label: HISTORY_MONSTER_TYPE.FIGHTLOST,
            value: HISTORY_MONSTER_TYPE.FIGHTLOST,
          },
        ]
      : [
          {
            label: 'All',
            value: null,
          },
          {
            label: HISTORY_MONSTER_TYPE.FIGHTBOSS,
            value: HISTORY_MONSTER_TYPE.FIGHTBOSS,
          },
          {
            label: HISTORY_MONSTER_TYPE.KILLBOSS,
            value: HISTORY_MONSTER_TYPE.KILLBOSS,
          },
        ]

  const getHistory = async (page = 1) => {
    try {
      const params = {
        page,
        pageLimit: 15,
      }
      if (type) {
        params.monster = type
      }
      if (dragonId) {
        params.dragonId = dragonId
      }

      if (status?.value) {
        params.type = status?.value
      }

      if (typeof monsterId === 'number') {
        params.id = monsterId
      }
      let response
      if (myOwner) {
        response = await eventApi.getEventHistoryOwner(params)
      } else {
        response = await eventApi.getHistories(params)
      }
      setHistory(response)
    } catch (error) {}
  }

  const handleChangePage = (page) => {
    getHistory(page + 1)
  }

  useEffect(() => {
    getHistory()
  }, [status?.value, account])

  return (
    <Modal isDark size="xl" open={open} onClose={toggle}>
      <ModalTitle onClose={toggle} className="mb-0-important">
        {myOwner ? 'History' : 'Fight history'}
      </ModalTitle>
      <div>
        <div className="overflow-auto custom-scrollbar-scroll">
          <table
            className="custom-table-training w-full text-white"
            style={{
              minWidth: 780,
              minHeight: 160,
            }}
          >
            <thead>
              <tr>
                <th>Dracora</th>
                {type === MONSTER_TYPES_QUERY.YY ? <th className="text-center">Dracora stats</th> : null}
                <th>{myOwner ? 'Monster' : 'Owner'}</th>
                <th className="text-center">
                  <Dropdown
                    customLabel={`Status${status?.value ? `: ${status?.label}` : ''}`}
                    classNameMenuItem="bg-primary custom-shadow-box w-52 py-2 top-18 left-0"
                    classNameMenu={classnames('h-full text-white font-bold text-md xl:text-base pt-0 pb-0')}
                    classNameLabelMenu="h-full"
                  >
                    {dropdownStatus.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="px-2.5 mx-2 py-1 bg-dropdown-item-hover text-white hover:text-white block  rounded"
                          onClick={() => setStatus(item)}
                        >
                          <div className="group w-full h-full flex flex-row items-center">
                            <div className="flex-1 text-left">{item.label}</div>
                          </div>
                        </div>
                      )
                    })}
                  </Dropdown>
                </th>
                <th>TX hash</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody className="text-white">
              {Array.isArray(history?.data) ? (
                history?.data?.length ? (
                  history?.data?.map((row) => (
                    <tr key={row?.trxHash}>
                      <td>#{row?.data?.dragonId}</td>
                      {type === MONSTER_TYPES_QUERY.YY ? (
                        <td className="text-center">{row?.data?.dragonStats}</td>
                      ) : null}
                      {myOwner ? (
                        <td>Monster {row?.data?.monsterId}</td>
                      ) : (
                        <td>
                          {row?.ownerAddress ? (
                            <a
                              href={getExplorerLink(
                                KROMA_CHAIN_ID,
                                Web3.utils.toChecksumAddress(row?.ownerAddress),
                                'address',
                              )}
                              target="_blank"
                            >
                              {formatAddress(row?.ownerAddress)}
                            </a>
                          ) : null}
                        </td>
                      )}
                      <td style={{ color: HISTORY_MONSTER_TYPE_COLOR[row?.type] }} className="uppercase text-center">
                        {row?.type}
                      </td>
                      <td>
                        {row?.trxHash ? (
                          <a href={getExplorerLink(KROMA_CHAIN_ID, row?.trxHash, 'transaction')} target="_blank">
                            {row?.trxHash ? formatAddress(row?.trxHash) : null}
                          </a>
                        ) : null}
                      </td>
                      <td>{formatDDMMYYYYHHmm(row?.createdAt)}</td>
                    </tr>
                  ))
                ) : (
                  <tr style={{ borderBottomWidth: 0 }}>
                    <td colSpan={999} className="text-center text-lg my-5">
                      No data
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan={999}>
                    <div className="flex justify-center py-5">
                      <img src="/icon/loading.gif" width={35} />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="my-5">
          {history?.totalItems ? (
            <Pagination
              pageCount={history?.totalPage || 0}
              onChangePage={handleChangePage}
              currentPage={history?.currentPage || 1}
            />
          ) : null}
        </div>
      </div>
    </Modal>
  )
}

export default ModalFightHistory
