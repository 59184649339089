import React from 'react'
import { Link } from 'react-router-dom'

const TopDragons = ({ title, type, data }) => {
  return (
    <div className="m-3 rounded-xl border-1 border-blue2 overflow-hidden">
      <table className="custom-table-base custom-table-base__non-border w-full border-0-important bg-blue1">
        <thead>
          <tr>
            <th colSpan={999999999} className="text-white text-center bg-blue3">
              {title}
            </th>
          </tr>
          <tr className="text-white text-center">
            <td>No.</td>
            <td>Dracora</td>
            <td>{type === 'level' ? 'Level' : 'Total stats'}</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody className="text-white text-center text-md sm:text-base">
          {data?.length ? (
            <>
              {data.map((row, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>Dracora #{row?.id}</td>
                    <td>{type === 'level' ? row?.level : row?.totalStats?.total}</td>
                    <td>
                      <Link to={`/dragon/${row?.id}`}>
                        <span className="text-md text-blue4 hover:opacity-80">Detail</span>
                      </Link>
                    </td>
                  </tr>
                )
              })}
            </>
          ) : (
            <tr>
              <td colSpan={9999} className="text-white text-center">
                Loading ...
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default TopDragons
