import React, { useState } from 'react'
import ButtonGradient from '../Button/ButtonGradient'
import PropTypes from 'prop-types'
import { ArrowDown } from 'react-feather'
import Pagination from '../Pagination'
import LoadingPage from '../Loader/LoadingPage'

const ListView = ({
  className,
  children,
  hasMore,
  fetchApi,
  pageCount,
  currentPage,
  totalItems,
  isBusy,
  type = 'pagination',
}) => {
  const [isLoading, setIsLoading] = useState(false)
  // const scrollToTop = () => {
  //   window.scrollTo({ behavior: 'smooth', top: 0, left: 0 })
  // }
  const handleLoadMore = async () => {
    try {
      if (!isLoading) {
        setIsLoading(true)
        await fetchApi()
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleChangePage = async (page) => {
    try {
      await fetchApi(page)
      // scrollToTop()
    } catch (error) {}
  }

  if (isBusy) {
    return (
      <div className="min-height-list-save">
        <LoadingPage className="mt-4" />
      </div>
    )
  }

  return (
    <div className={className}>
      {children}
      {totalItems === 0 ? (
        <p className="mt-12 text-white text-center text-xl">No data</p>
      ) : (
        <>
          {type === 'pagination' ? (
            <div className="mt-8">
              <Pagination pageCount={pageCount} onChangePage={handleChangePage} currentPage={currentPage} />
            </div>
          ) : (
            <>
              {hasMore ? (
                <div className="flex justify-center mt-10">
                  <ButtonGradient
                    onClick={handleLoadMore}
                    disabled={isLoading}
                    isLoading={isLoading}
                    className="text-sm-md sm:pl-4 sm:pr-4 sm:pt-1.5 sm:pb-1.5"
                    size="sm"
                  >
                    View more
                    {!isLoading && <ArrowDown className="ml-1" size={18} />}
                  </ButtonGradient>
                </div>
              ) : null}
            </>
          )}
        </>
      )}
    </div>
  )
}

ListView.prototype = {
  children: PropTypes.any.isRequired,
  fetchApi: PropTypes.func.isRequired,
  hasMore: PropTypes.bool,
}

export default ListView
