import { createSlice } from '@reduxjs/toolkit'
import reportApi from 'api/reportApi'
import { AUCTION_TYPE, MARKETPLACE_REPORT_TYPE, ITEMS_MARKETPLACE_REPORT_TYPE, ITEM_TYPES } from '../../constants'

const initialState = {
  chart: undefined,
  topDragons: undefined,
  topOthers: undefined,
  typeFilter: undefined,
  marketplaceVolume: undefined,
  dragonsSelling: undefined,
  dragonsSiring: undefined,
  dragonsSold: undefined,
  dragonsSired: undefined,
  equipmentSelling: undefined,
  skillSelling: undefined,
  expSelling: undefined,
  equipmentSold: undefined,
  skillSold: undefined,
  expSold: undefined,
  equipmentBurned: undefined,
  skillBurned: undefined,
  tabListed: 0,
  tabSold: 0,
  tabBurned: 0,
  battles: undefined,
  marketplaceVolumeItem: undefined,
}

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setChart: (state, action) => {
      state.chart = action.payload
    },
    setTopDragons: (state, action) => {
      state.topDragons = action.payload
    },
    setTopOthers: (state, action) => {
      state.topOthers = action.payload
    },
    setTypeFilter: (state, action) => {
      state.typeFilter = action.payload
    },
    setMarketplaceVolume: (state, action) => {
      state.marketplaceVolume = action.payload
    },
    setDragonsSelling: (state, action) => {
      state.dragonsSelling = action.payload
    },
    setDragonsSiring: (state, action) => {
      state.dragonsSiring = action.payload
    },
    setDragonsSold: (state, action) => {
      state.dragonsSold = action.payload
    },
    setDragonsSired: (state, action) => {
      state.dragonsSired = action.payload
    },
    setTabListed: (state, action) => {
      state.tabListed = action.payload
    },
    setTabSold: (state, action) => {
      state.tabSold = action.payload
    },
    setTabBurned: (state, action) => {
      state.tabBurned = action.payload
    },
    setReportBattles: (state, action) => {
      state.battles = action.payload
    },
    setEquipmentSelling: (state, action) => {
      state.equipmentSelling = action.payload
    },
    setSkillSelling: (state, action) => {
      state.skillSelling = action.payload
    },
    setExpSelling: (state, action) => {
      state.expSelling = action.payload
    },
    setEquipmentSold: (state, action) => {
      state.equipmentSold = action.payload
    },
    setSkillSold: (state, action) => {
      state.skillSold = action.payload
    },
    setExpSold: (state, action) => {
      state.expSold = action.payload
    },
    setEquipmentBurned: (state, action) => {
      state.equipmentBurned = action.payload
    },
    setSkillBurned: (state, action) => {
      state.skillBurned = action.payload
    },
    setMarketplaceVolumeItem: (state, action) => {
      state.marketplaceVolumeItem = action.payload
    },
  },
})

export const fetchChartRankingAsync = () => async (dispatch) => {
  try {
    const response = await reportApi.getChart()
    dispatch(setChart(response))
  } catch (error) {}
}

export const fetchTopDragonsAsync = () => async (dispatch) => {
  try {
    const topLevel = await reportApi.getReports({ type: 'level', rows: 10 })
    const topStats = await reportApi.getReports({ type: 'totalStats.total', rows: 10 })
    dispatch(
      setTopDragons({
        level: topLevel,
        stats: topStats,
      }),
    )
  } catch (error) {}
}

export const fetchTopOthersAsync = (type) => async (dispatch) => {
  try {
    const response = await reportApi.getReports({ type, rows: 50 })
    dispatch(setTopOthers(response))
  } catch (error) {}
}

export const fetchMarketplaceVolume = () => async (dispatch) => {
  try {
    const response = await reportApi.getReportMarketplace()
    dispatch(setMarketplaceVolume(response))
  } catch (error) {}
}

export const fetchDragonsSellingAsync = (params) => async (dispatch) => {
  try {
    const response = await reportApi.getDragonsListed({ type: MARKETPLACE_REPORT_TYPE.AUCTION, ...params })
    dispatch(setDragonsSelling(response))
  } catch (error) {}
}

export const fetchDragonsSiringAsync = (params) => async (dispatch) => {
  try {
    const response = await reportApi.getDragonsListed({ type: MARKETPLACE_REPORT_TYPE.SIRING, ...params })
    dispatch(setDragonsSiring(response))
  } catch (error) {}
}

export const fetchDragonsSoldAsync = (params) => async (dispatch) => {
  try {
    const response = await reportApi.getDragonsSold({ type: MARKETPLACE_REPORT_TYPE.SUCCESSAUCTION, ...params })
    dispatch(setDragonsSold(response))
  } catch (error) {}
}

export const fetchDragonsSiredAsync = (params) => async (dispatch) => {
  try {
    const response = await reportApi.getDragonsSold({ type: MARKETPLACE_REPORT_TYPE.SUCCESSSIRING, ...params })
    dispatch(setDragonsSired(response))
  } catch (error) {}
}

export const fetchReportBattlesAsync = () => async (dispatch) => {
  try {
    const response = await reportApi.getReportBattles()
    dispatch(setReportBattles(response))
  } catch (error) {}
}

export const fetchEquipmentSellingAsync = () => async (dispatch) => {
  try {
    const response = await reportApi.getReportMarketplaceItems({
      type: ITEMS_MARKETPLACE_REPORT_TYPE.SELLING,
      itemType: ITEM_TYPES.EQUIPMENT,
    })
    dispatch(setEquipmentSelling(response))
  } catch (error) {}
}
export const fetchSkillSellingAsync = () => async (dispatch) => {
  try {
    const response = await reportApi.getReportMarketplaceItems({
      type: ITEMS_MARKETPLACE_REPORT_TYPE.SELLING,
      itemType: ITEM_TYPES.SKILL_CARD,
    })
    dispatch(setSkillSelling(response))
  } catch (error) {}
}
export const fetchExpSellingAsync = () => async (dispatch) => {
  try {
    const response = await reportApi.getReportMarketplaceItems({
      type: ITEMS_MARKETPLACE_REPORT_TYPE.SELLING,
      itemType: ITEM_TYPES.EXP_CARD,
    })
    dispatch(setExpSelling(response))
  } catch (error) {}
}

export const fetchEquipmentSoldAsync = () => async (dispatch) => {
  try {
    const response = await reportApi.getReportMarketplaceItems({
      type: ITEMS_MARKETPLACE_REPORT_TYPE.SOLD,
      itemType: ITEM_TYPES.EQUIPMENT,
    })
    dispatch(setEquipmentSold(response))
  } catch (error) {}
}

export const fetchSkillSoldAsync = () => async (dispatch) => {
  try {
    const response = await reportApi.getReportMarketplaceItems({
      type: ITEMS_MARKETPLACE_REPORT_TYPE.SOLD,
      itemType: ITEM_TYPES.SKILL_CARD,
    })
    dispatch(setSkillSold(response))
  } catch (error) {}
}

export const fetchExpSoldAsync = () => async (dispatch) => {
  try {
    const response = await reportApi.getReportMarketplaceItems({
      type: ITEMS_MARKETPLACE_REPORT_TYPE.SOLD,
      itemType: ITEM_TYPES.EXP_CARD,
    })
    dispatch(setExpSold(response))
  } catch (error) {}
}

export const fetchEquipmentBurnedAsync = () => async (dispatch) => {
  try {
    const response = await reportApi.getReportMarketplaceItems({
      type: ITEMS_MARKETPLACE_REPORT_TYPE.BURNED,
      itemType: ITEM_TYPES.EQUIPMENT,
    })
    dispatch(setEquipmentBurned(response))
  } catch (error) {}
}

export const fetchSkillBurnedAsync = () => async (dispatch) => {
  try {
    const response = await reportApi.getReportMarketplaceItems({
      type: ITEMS_MARKETPLACE_REPORT_TYPE.BURNED,
      itemType: ITEM_TYPES.SKILL_CARD,
    })
    dispatch(setSkillBurned(response))
  } catch (error) {}
}

export const fetchMarketplaceVolumeItem = () => async (dispatch) => {
  try {
    const response = await reportApi.getCountMarketplaceItems()
    dispatch(setMarketplaceVolumeItem(response))
  } catch (error) {}
}

export const {
  setChart,
  setTopDragons,
  setTopOthers,
  setTypeFilter,
  setMarketplaceVolume,
  setDragonsSelling,
  setDragonsSiring,
  setDragonsSold,
  setDragonsSired,
  setTabListed,
  setTabSold,
  setTabBurned,
  setReportBattles,
  setEquipmentSelling,
  setSkillSelling,
  setExpSelling,
  setEquipmentSold,
  setSkillSold,
  setExpSold,
  setEquipmentBurned,
  setSkillBurned,
  setMarketplaceVolumeItem,
} = reportSlice.actions

export default reportSlice.reducer
