import expAbi from 'config/abi/expAbi'
import equipmentAbi from 'config/abi/equipmentAbi'
import skillAbi from 'config/abi/skillAbi'
import { ITEM_ADDRESS } from 'config'
import multicall from '../../utils/multicall'

export const fetchItemApproved = async (type, id) => {
  const ITEM_ABI = {
    EXP_CARD: expAbi,
    EQUIPMENT: equipmentAbi,
    SKILL_CARD: skillAbi,
  }

  try {
    const calls = [
      {
        address: ITEM_ADDRESS?.[type],
        name: 'getApproved',
        params: [id],
      },
    ]
    const [response] = await multicall(ITEM_ABI?.[type], calls)
    return response?.[0]
  } catch (error) {}
}
