import dragonAbi from 'config/abi/dragon.json'
import coreAbi from 'config/abi/coreABI.json'
import saleAbi from 'config/abi/SCA_ABI.json'
import birthAbi from 'config/abi/birthAbi.json'
import { DEFAULT_TOKEN_USED } from '../../constants'
import {
  BIRTH_ADDRESS,
  CORE_ADDRESS,
  SALE_CLOCK_AUCTION_ADDRESS,
  SALE_CLOCK_AUCTION_ADDRESS_OLD,
  SALE_CLOCK_AUCTION_HOME_ADDRESS,
  TRAINING_ADDRESS,
} from '../../config'
import multicall from '../../utils/multicall'
import BigNumber from 'bignumber.js'
import whitelist from 'constants/whitelist'

export const fetchAllowanceBuyEggHome = async (account) => {
  try {
    const calls = [
      {
        address: DEFAULT_TOKEN_USED.address,
        name: 'allowance',
        params: [account, SALE_CLOCK_AUCTION_HOME_ADDRESS],
      },
    ]
    const [allowance] = await multicall(dragonAbi, calls)
    return new BigNumber(allowance).toJSON()
  } catch (error) {}
}

export const fetchAllowancebidOnTokenSiring = async (account) => {
  try {
    const calls = [
      {
        address: DEFAULT_TOKEN_USED.address,
        name: 'allowance',
        params: [account, CORE_ADDRESS],
      },
    ]
    const [allowance] = await multicall(dragonAbi, calls)
    return new BigNumber(allowance).toJSON()
  } catch (error) {}
}

export const fetchAllowanceBuyMarketplace = async (account) => {
  try {
    const calls = [
      {
        address: DEFAULT_TOKEN_USED.address,
        name: 'allowance',
        params: [account, SALE_CLOCK_AUCTION_ADDRESS],
      },
    ]
    const [allowance] = await multicall(dragonAbi, calls)
    return new BigNumber(allowance).toJSON()
  } catch (error) {}
}

export const fetchAllowanceDragon = async (account) => {
  try {
    const calls = [
      {
        address: DEFAULT_TOKEN_USED.address,
        name: 'allowance',
        params: [account, BIRTH_ADDRESS],
      },
      {
        address: DEFAULT_TOKEN_USED.address,
        name: 'allowance',
        params: [account, TRAINING_ADDRESS],
      },
    ]
    const response = await multicall(dragonAbi, calls)
    return {
      birth: new BigNumber(response?.[0]).toJSON(),
      training: new BigNumber(response?.[1]).toJSON(),
    }
  } catch (error) {}
}

export const fetchAllowanceTraining = async (account) => {
  const calls = [
    {
      address: DEFAULT_TOKEN_USED.address,
      name: 'allowance',
      params: [account, TRAINING_ADDRESS],
    },
  ]
  const response = await multicall(dragonAbi, calls)
  return {
    training: new BigNumber(response?.[0]).toJSON(),
  }
}

export const fetchDragonBirthCost = async (id) => {
  try {
    const calls = [
      {
        address: BIRTH_ADDRESS,
        name: 'getBirthCostOf',
        params: [id],
      },
    ]
    const [response] = await multicall(birthAbi, calls)
    return new BigNumber(response).toNumber()
  } catch (error) {}
}

export const fetchLatestChildOfMother = async (mother) => {
  try {
    const calls = [
      {
        address: BIRTH_ADDRESS,
        name: 'getLatestChildOf',
        params: [mother],
      },
    ]
    const [response] = await multicall(birthAbi, calls)
    return new BigNumber(response).toNumber()
  } catch (error) {}
}

export const fetchStakedDragon = async (account) => {
  try {
    const calls = [
      {
        address: SALE_CLOCK_AUCTION_HOME_ADDRESS,
        name: 'getStake',
        params: [account],
      },
      {
        address: SALE_CLOCK_AUCTION_HOME_ADDRESS,
        name: 'endStakeTimeStamp',
      },
    ]
    const [stakedData, unStakeTime] = await multicall(saleAbi, calls)
    return {
      amount: new BigNumber(stakedData?.['amount']?._hex).toNumber(),
      startTime: new BigNumber(stakedData?.startTime?._hex).toNumber(),
      unStakeTime: new BigNumber(unStakeTime).toNumber() * 1000,
    }
  } catch (error) {
    console.log(error)
  }
}

export const fetchOldStakedDragon = async (account) => {
  try {
    const calls = [
      {
        address: SALE_CLOCK_AUCTION_ADDRESS_OLD,
        name: 'getStake',
        params: [account],
      },
      {
        address: SALE_CLOCK_AUCTION_ADDRESS_OLD,
        name: 'endStakeTimeStamp',
      },
    ]
    const [stakedData, unStakeTime] = await multicall(saleAbi, calls)
    return {
      amount: new BigNumber(stakedData?.['amount']?._hex).toNumber(),
      startTime: new BigNumber(stakedData?.startTime?._hex).toNumber(),
      unStakeTime: new BigNumber(unStakeTime).toNumber() * 1000,
    }
  } catch (error) {
    console.log(error)
  }
}

export const fetchDragonRemain = async () => {
  try {
    const calls = [
      {
        address: CORE_ADDRESS,
        name: 'balanceOf',
        params: [SALE_CLOCK_AUCTION_HOME_ADDRESS],
      },
      {
        address: CORE_ADDRESS,
        name: 'totalSupply',
        params: [],
      },
    ]
    const [balance, totalSupply] = await multicall(coreAbi, calls)
    console.log(balance)

    return {
      numberEgg: new BigNumber(balance).toNumber(),
      totalSupply: new BigNumber(totalSupply).toNumber(),
    }
  } catch (error) {}
}

export const fetchDragonCaveApproved = async (idToken) => {
  try {
    const calls = [
      {
        address: CORE_ADDRESS,
        name: 'dragonIndexToApproved',
        params: [idToken],
      },
    ]
    const [address] = await multicall(coreAbi, calls)
    return address?.[0]
  } catch (error) {}
}
