import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import NavBack from 'components/Navigate/Back'
import withLayout from 'hoc/withLayout'
import Container from 'components/Container/Container'
import {
  useBreedCost,
  useBreedData,
  useCooldown,
  useDragonMatron,
  useDragonSire,
  useFetchBreedCost,
  useFetchBreedData,
  useFetchCooldown,
  useFetchDragonsBreed,
} from 'store/breed/hook'
import DragonCard from './components/DragonCard'
import { AUCTION_TYPE, cooldownLabel, DRAGON_TYPE, SIRING_TYPE } from 'constants/index'
import { RefreshCw } from 'react-feather'
import ButtonGradient from 'components/Button/ButtonGradient'
import ModalSelectDragon from 'components/Modal/ModalSelectDragon'
import { formatQuery, useQuery } from 'utils'
import { useBreed, useBreedWithSiring } from 'hooks/useBreed'
import { CORE_ADDRESS } from 'config'
import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast'
import { useDispatch } from 'react-redux'
import { fetchBreedDataAsync } from 'store/breed'
import Web3 from 'web3'
import ModalSuccess from 'components/Modal/ModalSuccess'
import ModalEstimateBreedCost from 'components/Modal/ModalEstimateBreedCost'
import Button from 'components/Button/Button'
import classnames from 'classnames'
import { useKaiPrice } from 'hooks/usePrice'
import { getBalanceNumber } from 'utils/formatBalance'
import { useBalanceMainCoin } from 'hooks/useTokenBalance'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'

const SireDragon = () => {
  useFetchBreedCost()
  useFetchCooldown()
  useFetchBreedData()
  useFetchDragonsBreed()
  const tokenPrice = useKaiPrice()
  const { push, replace } = useHistory()
  const dispatch = useDispatch()
  const { account } = useActiveWeb3React()
  const query = useQuery()
  const history = useHistory()
  const matron = useDragonMatron()
  const sire = useDragonSire()
  const breedData = useBreedData()
  const breedCosts = useBreedCost()
  const cooldown = useCooldown()
  const balance = getBalanceNumber(useBalanceMainCoin())

  const { onBreed } = useBreed(CORE_ADDRESS)
  const { onBreedWithSiring } = useBreedWithSiring(CORE_ADDRESS)

  const [modal, setModal] = useState(undefined)
  const [pendingTx, setPendingTx] = useState(false)
  const [openModalSuccess, setOpenModalSuccess] = useState(false)
  const [openModalEstimate, setOpenModalEstimate] = useState(false)

  const canBreed = breedData?.canMate && breedData?.matronIsReady && breedData?.sireIsReady

  const handleSwapBreedSide = () => {
    if (sire?.sale !== AUCTION_TYPE.SIRING) {
      if (matron?.id && sire?.id) {
        history.replace(`/breed?mother=${sire?.id}&&father=${matron?.id}`)
        return
      }
      if (matron?.id) {
        history.replace(`/breed?father=${matron?.id}`)
        return
      }
      if (sire?.id) {
        history.replace(`/breed?mother=${sire?.id}`)
      }
    }
  }

  const handleSelectDragon = (id) => {
    if (id) {
      const _query = { ...query }
      if (modal.type === SIRING_TYPE.SIRE) {
        if (Number(_query.mother) === Number(id)) {
          delete _query.mother
        }
      }
      if (modal.type === SIRING_TYPE.MATRON) {
        if (Number(_query.father) === Number(id)) {
          delete _query.father
        }
      }
      _query[modal.type === SIRING_TYPE.MATRON ? 'mother' : 'father'] = id
      const queryStr = formatQuery(_query)
      history.replace(`/breed${queryStr ? `?${queryStr}` : ''}`)
    }
    setModal(undefined)
  }

  const handleBreed = async () => {
    try {
      setPendingTx(true)
      setOpenModalEstimate(false)
      if (sire?.sale === AUCTION_TYPE.SIRING) {
        await onBreedWithSiring(sire?.id, matron?.id, sire?.price, 18)
      } else {
        await onBreed(matron?.id, sire?.id)
      }
      await dispatch(fetchBreedDataAsync(matron?.id, sire?.id, sire?.sale === AUCTION_TYPE.SIRING))
      setPendingTx(false)
      setOpenModalSuccess(true)
      showToastSuccess('Success', 'Congratulation! You have bred dragon successfully!')
    } catch (error) {
      console.log(error)
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
      setPendingTx(false)
    }
  }

  useEffect(() => {
    if (
      (sire?.id &&
        account &&
        Web3.utils.toChecksumAddress(sire?.owner) !== Web3.utils.toChecksumAddress(account) &&
        sire?.sale !== AUCTION_TYPE.SIRING) ||
      (sire?.id && sire?.type === DRAGON_TYPE.EGG)
    ) {
      const _query = { ...query }
      delete _query.father
      const queryStr = formatQuery(_query)
      history.replace(`/breed${queryStr ? `?${queryStr}` : ''}`)
    }
    if (
      (matron?.id &&
        account &&
        Web3.utils.toChecksumAddress(matron?.owner) !== Web3.utils.toChecksumAddress(account)) ||
      (matron?.id && matron?.type === DRAGON_TYPE.EGG) ||
      matron?.sale === AUCTION_TYPE.SIRING
    ) {
      const _query = { ...query }
      delete _query.mother
      const queryStr = formatQuery(_query)
      history.replace(`/breed${queryStr ? `?${queryStr}` : ''}`)
    }
  }, [matron, sire, account])

  useEffect(() => {
    setOpenModalEstimate(false)
    setOpenModalSuccess(false)
  }, [query?.father, query?.mother, account])

  useEffect(() => {
    if (query?.isMating && query?.father) {
      const _query = { ...query }
      delete _query.isMating
      const queryStr = formatQuery(_query)
      history.replace(`/breed${queryStr ? `?${queryStr}` : ''}`)
      setModal({ open: true, type: SIRING_TYPE.MATRON })
    }
  }, [])

  return (
    <>
      <NavBack />
      <Container className="mb-36 px-4">
        <div>
          <h3 className="text-2xl text-white text-center">Breed</h3>
          <p className="text-white text-center">Let's make the most powerful baby dracora!</p>
        </div>
        <div className="flex flex-col sm:flex-row justify-center items-center mt-16">
          <DragonCard
            type="Mother"
            dragon={matron}
            tokenPrice={tokenPrice}
            onClick={() => (!pendingTx ? setModal({ open: true, type: SIRING_TYPE.MATRON }) : {})}
          />
          <div className="flex justify-center my-4">
            <RefreshCw
              className={classnames(
                'text-blue4',
                {
                  'cursor-pointer': sire?.sale !== AUCTION_TYPE.SIRING,
                },
                {
                  'text-gray-500': sire?.sale === AUCTION_TYPE.SIRING,
                },
              )}
              onClick={handleSwapBreedSide}
            />
          </div>
          <DragonCard
            type="Father"
            dragon={sire}
            tokenPrice={tokenPrice}
            onClick={() => (!pendingTx ? setModal({ open: true, type: SIRING_TYPE.SIRE }) : {})}
          />
        </div>
        {breedData?.dataLoaded && !canBreed ? (
          <div className="text-center text-orangeStroke mt-10">
            <h2 className="font-bold text-xl">Warning</h2>
            {!breedData?.canMate ? <p className="mt-1">Your dracoras have the same bloodline</p> : null}
            {!breedData?.matronIsReady && breedData?.sireIsReady ? (
              <p className="mt-1">Mother is temporarily not ready to mate</p>
            ) : null}
            {!breedData?.sireIsReady && breedData?.matronIsReady ? (
              <p className="mt-1">Father is temporarily not ready to mate</p>
            ) : null}
            {!breedData?.sireIsReady && !breedData?.matronIsReady ? (
              <p className="mt-1">Mother, Father are temporarily not ready to mate</p>
            ) : null}
          </div>
        ) : null}
        <div className="flex justify-center my-10">
          <ButtonGradient
            disabled={pendingTx || !canBreed}
            isLoading={pendingTx}
            onClick={() => setOpenModalEstimate(true)}
          >
            Breed Now
          </ButtonGradient>
        </div>
        <div>
          <p className="text-white text-center">
            *There's a fee for breeding, but you just have to pay when the Mother is giving birth
          </p>
          {breedCosts ? (
            <div className="flex justify-center">
              <div className="mt-5 overflow-x-auto custom-scrollbar-scroll">
                <table className="custom-table-base">
                  <thead>
                    <tr>
                      <th colSpan={999999999} className="text-left text-white sm:text-center">
                        Breed Fee
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-white">
                    <tr>
                      <td className="w-48">Cooldown</td>
                      {cooldownLabel.map((item, index) => (
                        <td key={index} className="text-center text-md">
                          {`${cooldownLabel[index]?.label} (${cooldownLabel[index]?.duration})`}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="w-48">DRACORA Cost</td>
                      {breedCosts.map((item, index) => (
                        <td key={index} className="text-center">
                          {item}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
                <div className="text-white italic mt-3">
                  <h3 className="font-bold">Note:</h3>
                  <p className="text-sm-md">
                    - <strong>Cooldown</strong>: Cooldown indicates the duration after any successful breeding, called
                    "Pregnancy time" for Mother and "Resting cooldown" for Father
                  </p>
                  <p className="text-sm-md">
                    - <strong>DRACORA Cost</strong>: The breeding fee is calculated as the maximum cost between Father
                    and Mother at the time of give birth.
                  </p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </Container>
      {!!modal?.open ? (
        <ModalSelectDragon
          open={modal?.open}
          type={modal?.type}
          matron={matron}
          sire={sire}
          toggle={() => setModal(undefined)}
          onSelect={handleSelectDragon}
        />
      ) : null}
      {openModalSuccess ? (
        <ModalSuccess open={true} toggle={() => replace(`/breed`)}>
          <div className="text-white text-center">
            <h2 className="text-2xl">Bred</h2>
            <p>Bred dracora successfully!</p>
          </div>
          <div className="flex justify-end mt-3 py-3">
            <Button outline onClick={() => replace(`/breed`)} className="mx-2 flex-1 text-md pl-1.5 pr-1.5">
              Breed more
            </Button>
            <ButtonGradient
              noneBorder
              rounded={false}
              onClick={() => push(`/dragon/${matron?.id}`)}
              className="mx-2 flex-1  text-md pl-1.5 pr-1.5"
            >
              View Dracora Pregnancy
            </ButtonGradient>
          </div>
        </ModalSuccess>
      ) : null}

      {openModalEstimate ? (
        <ModalEstimateBreedCost
          open={openModalEstimate}
          toggle={() => setOpenModalEstimate(false)}
          matron={matron}
          sire={sire}
          balance={balance}
          breedCosts={breedCosts}
          cooldown={cooldown}
          onSubmit={handleBreed}
        />
      ) : null}
    </>
  )
}

export default withLayout(SireDragon)
