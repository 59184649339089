import React from 'react'
import Box from '../Box/Box'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const ARR_EGG_CLASS = [
  '/images/eggs/egg-metal-highlight.png',
  '/images/eggs/egg-wood-highlight.png',
  '/images/eggs/egg-water-highlight.png',
  '/images/eggs/egg-fire-highlight.png',
  '/images/eggs/egg-earth-highlight.png',
]

const DragonCard = ({ img, title, desc, className, wrapImgClass, indexImg, isEgg, ...rest }) => {
  return (
    <Box
      {...rest}
      className={classnames(
        'max-w-xs border-8 flex flex-col items-center overflow-hidden h-full border-blue2 bg-blue1 p-0 mx-4 md:mx-14  w-64 md:w-64 lg:w-80 xl:w-96',
        className,
      )}
    >
      <div className={classnames('flex justify-center mt-8', wrapImgClass)}>
        {isEgg ? (
          <>
            <img className={classnames(indexImg !== 0 && 'hidden', 'bouncing')} src={ARR_EGG_CLASS[0]} />
            <img className={classnames(indexImg !== 1 && 'hidden', 'bouncing')} src={ARR_EGG_CLASS[1]} />
            <img className={classnames(indexImg !== 2 && 'hidden', 'bouncing')} src={ARR_EGG_CLASS[2]} />
            <img className={classnames(indexImg !== 3 && 'hidden', 'bouncing')} src={ARR_EGG_CLASS[3]} />
            <img className={classnames(indexImg !== 4 && 'hidden', 'bouncing')} src={ARR_EGG_CLASS[4]} />
          </>
        ) : (
          <img src={img} />
        )}
      </div>
      <div className="text-white text-center mb-4 md:my-8">
        <h3 className="text-xl md:text-3xl font-bold">{title}</h3>
        <p>{desc}</p>
      </div>
    </Box>
  )
}

DragonCard.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
}

export default DragonCard
