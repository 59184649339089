import React from 'react'
import withLayout from 'hoc/withLayout'
import LoadingPage from 'components/Loader/LoadingPage'
import Container from 'components/Container/Container'
import Value from 'components/Value/Value'
import { useFetchAllEffects, useAllEffects } from 'store/items/hook'
import { SKILL_EFFECT } from 'constants/index'

function DocEffect() {
  useFetchAllEffects()
  const allEffects = useAllEffects()

  if (allEffects === undefined) {
    return <LoadingPage />
  }

  return (
    <Container className="pt-10 pb-36">
      <div className="text-center text-white">
        <h3 className="text-xl xl:text-2xl">Total Effect</h3>
        {typeof allEffects?.length === 'number' ? (
          <Value className="text-5xl xl:text-6xl" value={allEffects?.length} decimals={0} />
        ) : (
          '--'
        )}
      </div>

      <div className="border border-1 border-blue2 mt-16 rounded-xl mx-2 min-w-full min-h-max md:overflow-x-hidden overflow-x-scroll overflow-y-hidden">
        <table className="w-full rounded-xl bg-blue1">
          <thead className="text-xl text-white border-b border-blue2">
            <tr>
              <th className="border-r border-blue2 border-solid md:text-lg sm:text-md text-sm">Image</th>
              <th className="border-r border-blue2 border-solid md:text-lg sm:text-md text-sm">Name</th>
              <th className="border-r border-blue2 border-solid md:text-lg sm:text-md text-sm">Description</th>
              <th className="border-r border-blue2 border-solid md:text-lg sm:text-md text-sm">Type</th>
              <th className="border-r border-blue2 border-solid md:text-lg sm:text-md text-sm">
                <div className="flex items-center justify-center">
                  <span className="mr-1">A</span>
                  <img className="" src="/images/skill_effect/1.png" />
                </div>
              </th>
              <th className="border-r border-blue2 border-solid md:text-lg sm:text-md text-sm">
                <div className="flex items-center justify-center">
                  <span className="mr-1">S</span>
                  <img className="" src="/images/skill_effect/2.png" />
                </div>
              </th>
              <th className="md:text-lg sm:text-md text-sm">
                <div className="flex items-center justify-center">
                  <span className="mr-1">SS</span>
                  <img className="" src="/images/skill_effect/3.png" />
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="table-effect">
            {allEffects?.map((effect, index) => {
              return (
                <tr key={index} className="border-b border-blue2">
                  <td className="border-r border-blue2 lg:p-5 p-3">
                    <div className="w-full h-full flex items-center justify-center">
                      <img
                        className="md:w-10 md:h-10 w-5 h-5"
                        alt="icon-effect"
                        src={SKILL_EFFECT?.[effect?.key]?.icon}
                      />
                    </div>
                  </td>
                  <td className="border-r border-blue2 text-center lg:px-5 md:text-md sm:text-sm text-xs">
                    {effect?.name}
                  </td>
                  <td className="border-r border-blue2 text-left lg:px-5 px-3 md:text-md sm:text-sm text-xs leading-5 py-2">
                    {effect?.description}
                  </td>
                  <td className="border-r border-blue2 text-center lg:px-5 md:text-md sm:text-sm text-xs">
                    {effect?.type}
                  </td>
                  <td className="lg:px-10 py-2 whitespace-nowrap border-r border-blue2 text-center md:text-md sm:text-sm text-xs">
                    {effect?.level?.length !== 0 ? (
                      <span>
                        {effect?.type?.toLowerCase() === 'buff' ? '+' : '-'}
                        {effect?.level?.A ? effect?.level?.A + '%' : null}
                      </span>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td className="lg:px-10 py-2 whitespace-nowrap border-r border-blue2 text-center md:text-md sm:text-sm text-xs">
                    {effect?.level?.length !== 0 ? (
                      <span>
                        {effect?.type?.toLowerCase() === 'buff' ? '+' : '-'}
                        {effect?.level?.S ? effect?.level?.S + '%' : null}
                      </span>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td className="lg:px-10 py-2 whitespace-nowrap text-center md:text-md sm:text-sm text-xs">
                    {effect?.level?.length !== 0 ? (
                      <span>
                        {effect?.type?.toLowerCase() === 'buff' ? '+' : '-'}
                        {effect?.level?.SS ? effect?.level?.SS + '%' : null}
                      </span>
                    ) : (
                      '-'
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </Container>
  )
}

export default withLayout(DocEffect)
