import React, { useCallback, useMemo, useState } from 'react'
import ButtonGradient from 'components/Button/ButtonGradient'
import eventApi from 'api/eventApi'
import { sleep } from 'utils'
import { FIGHT_MONSTER_RESULT, HISTORY_MONSTER_TYPE, MONSTER_TYPES } from 'constants/index'
import { showToastError, showToastInfo, showToastSuccess } from 'components/CustomToast/CustomToast'
import ModalSelectDragon from './modals/ModalSelectDragon'
import ModalFightResult from './modals/ModalFightResult'
import useInterval from 'hooks/useInterval'
import { useFightMonster } from 'hooks/useFightMonster'
import { EVENT_MONSTER_ADDRESS } from 'config'
import { useModalWalletConnect } from 'hooks/modal'
import Countdown, { zeroPad } from 'react-countdown'
import ModalFighting from './modals/ModalFighting'
import classnames from 'classnames'
import useActiveWeb3React from '../../../hooks/useActiveWeb3React'

const ButtonFight = ({ className, monster, available, updateData, textSize = 'sm', openModal, setOpenModal }) => {
  const { account } = useActiveWeb3React()
  const { onToggleConnectModal } = useModalWalletConnect()
  const { onFightMonster } = useFightMonster(monster?.type, EVENT_MONSTER_ADDRESS)

  const [openFight, setOpenFight] = useState(false)
  const [pendingTx, setPendingTx] = useState(false)
  const [turn, setTurn] = useState(undefined)

  const canFight = useMemo(() => {
    if (monster?.type === MONSTER_TYPES.YY_MONSTER && !monster?.currentHp) {
      return false
    }
    if (monster?.type === MONSTER_TYPES.XX_MONSTER && !monster?.totalReward) {
      return false
    }
    return true
  }, [monster])

  const onSelectDragon = (e) => {
    const time = Date.now()
    setTurn(time)
    setOpenModal({
      open: true,
      type: 'select',
      monster: monster?.id,
      turn: time,
    })
    e.preventDefault()
  }

  const getHistory = async (tx) => {
    try {
      return await eventApi.getHistoryByTxHash(tx)
    } catch (error) {}
  }

  const onConnectWallet = (e) => {
    onToggleConnectModal()
    e.preventDefault()
  }

  const handleShowMessage = async (history, currentStateModal) => {
    await setOpenModal({ open: false, type: 'reset', turn })
    switch (history?.type) {
      case HISTORY_MONSTER_TYPE.FIGHTLOST:
        if (currentStateModal?.type === 'select' && turn === currentStateModal?.turn && openModal.turn === turn) {
          await setOpenModal({
            open: true,
            result: FIGHT_MONSTER_RESULT.DEFEAT,
            monster: monster?.id,
            turn,
          })
        }
        showToastInfo('Failed', 'Your dragon has been defeated')
        break
      case HISTORY_MONSTER_TYPE.FIGHTWIN:
        if (currentStateModal?.type === 'select' && turn === currentStateModal?.turn) {
          setOpenModal({
            open: true,
            result: FIGHT_MONSTER_RESULT.VICTORY,
            monster: monster?.id,
            turn,
          })
        }
        showToastSuccess('Success', 'You have successfully fought the monster')
        break
      case HISTORY_MONSTER_TYPE.FIGHTBOSS:
        showToastSuccess('Success', 'You have successfully fought the monster')
        break
      case HISTORY_MONSTER_TYPE.KILLBOSS:
        if (currentStateModal?.type === 'select' && turn === currentStateModal?.turn) {
          await setOpenModal({
            open: true,
            result: FIGHT_MONSTER_RESULT.VICTORY,
            monster: monster?.id,
            turn,
          })
        }
        showToastSuccess('Victory', 'You have successfully defeated the monster')
        break
      default:
        break
    }
  }

  const handleFightMonster = async (dragonId) => {
    try {
      setPendingTx(true)
      const time = Date.now()
      setTurn(time)
      await setOpenModal(undefined)
      await setOpenModal({
        open: true,
        type: 'fighting',
        turn: turn,
        monster: monster?.id,
      })
      const response = await onFightMonster(dragonId, monster?.id)
      if (response?.transactionHash) {
        await sleep(1500)
        const history = await getHistory(response.transactionHash)
        await updateData()
        if (history) {
          const currentStateModal = { ...openModal }
          await handleShowMessage(history, currentStateModal)
        } else {
          showToastSuccess('Success', 'Please view fight history for results')
        }
      } else {
        showToastSuccess('Success', 'Please view fight history for results')
      }
      setPendingTx(false)
    } catch (error) {
      console.log(error)
      setPendingTx(false)
      setOpenModal(undefined)
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
    }
  }

  const renderStartTime = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) return null
    return (
      <div
        className={classnames(
          'pl-1 text-center',
          {
            'text-xs sm:text-sm': textSize === 'sm',
          },
          {
            'text-sm sm:text-sm-md': textSize === 'md',
          },
        )}
      >
        Open in {zeroPad(days)}d {zeroPad(hours)}h {zeroPad(minutes)}m {zeroPad(seconds)}s
      </div>
    )
  }

  useInterval(() => {
    if (!openFight && monster?.startTime <= Date.now()) {
      setOpenFight(true)
    }
  }, [1000])

  return (
    <>
      <div className={classnames({ 'h-5': textSize === 'sm' })}>
        {!openFight ? <Countdown zeroPadTime={2} date={monster?.startTime} renderer={renderStartTime} /> : null}
        {
          <p
            className={classnames(
              'text-center text-orangeStroke',
              {
                'text-xs sm:text-sm': textSize === 'sm',
              },
              {
                'text-sm-md sm:text-base': textSize === 'md',
              },
            )}
          >
            {monster?.type === MONSTER_TYPES.YY_MONSTER && !monster?.currentHp ? 'Monster has been defeated' : null}
          </p>
        }
      </div>

      <div className="flex justify-center px-0 sm:px-8">
        {account ? (
          <ButtonGradient
            className={className}
            noneBorder={true}
            disabled={!openFight || pendingTx || !available || !canFight}
            isLoading={pendingTx}
            onClick={onSelectDragon}
          >
            Fight
          </ButtonGradient>
        ) : (
          <ButtonGradient className={className} noneBorder={true} onClick={onConnectWallet}>
            Connect wallet
          </ButtonGradient>
        )}
      </div>

      {openModal?.open && openModal?.type === 'select' && openModal?.monster === monster?.id ? (
        <ModalSelectDragon
          open={openModal?.open}
          type={monster?.type}
          monster={monster}
          toggle={() => setOpenModal(undefined)}
          onSelect={handleFightMonster}
        />
      ) : null}
      {openModal?.open &&
      openModal?.monster === monster?.id &&
      [FIGHT_MONSTER_RESULT.DEFEAT, FIGHT_MONSTER_RESULT.VICTORY].includes(openModal?.result) ? (
        <ModalFightResult open={openModal?.open} toggle={() => setOpenModal(undefined)} result={openModal?.result} />
      ) : null}

      {openModal?.open && openModal?.monster === monster?.id && openModal.type === 'fighting' ? (
        <ModalFighting open={openModal?.open} toggle={() => setOpenModal(undefined)} />
      ) : null}
    </>
  )
}

export default ButtonFight
