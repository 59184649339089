import classnames from 'classnames'
import { Search } from 'react-feather'

const InputSearch = ({ className, classNameInput, ...rest }) => {
  return (
    <div className={classnames('border-blue2 border-2 rounded-xl px-2 flex items-center', className)}>
      <Search size={20} className="text-blue4 mr-3" />
      <input {...rest} className={classnames('text-blue4 bg-transparent placeholder-blue2', classNameInput)} />
    </div>
  )
}

export default InputSearch
