import React, { useEffect, useState } from 'react'
import ItemCardV2 from 'components/Card/ItemCardV2'
import Value from 'components/Value/Value'
import ItemsFilterAction from '../../Items/components/ItemsFilterAction'
import { useFetchItems, useMyItems } from 'store/items/hook'
import { setItems } from 'store/items'
import { useDispatch } from 'react-redux'
import LoadingPage from 'components/Loader/LoadingPage'
import ListView from 'components/ListView'
import { setPageQuery } from 'store/search'
import { useUpdateQueryItems } from 'store/search/hook'
import { ITEM_TYPES } from 'constants/index'
import { showToastError } from 'components/CustomToast/CustomToast'

export default function Equipment() {
  useUpdateQueryItems()
  useFetchItems({ type: ITEM_TYPES.EQUIPMENT })
  const items = useMyItems()
  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      dispatch(setItems(undefined))
    }
  }, [])

  const onChangePage = async (page) => {
    try {
      dispatch(setPageQuery(page + 1))
    } catch (error) {
      showToastError('Error', 'Some error occurred, please try again!')
    }
  }

  if (items === undefined) {
    return <LoadingPage />
  }

  return (
    <div className="max-w-screen-lg m-auto">
      <div className="flex items-center text-white mx-2.5 sm:mx-5">
        <p className="mr-1 text-md sm:text-lg">Total equipments: </p>
        {typeof items?.totalItems === 'number' ? (
          <Value className="text-2xl" value={items?.totalItems} decimals={0} />
        ) : (
          '--'
        )}
      </div>
      <ItemsFilterAction filterLeft={true} />
      <div className="mt-10">
        <ListView
          className="min-height-list-save max-w-screen-lg m-auto mt-8 px-2.5 sm:px-5"
          pageCount={items?.totalPages}
          totalItems={items?.totalItems}
          currentPage={items?.currentPage}
          fetchApi={onChangePage}
        >
          <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-y-5 gap-x-5">
            {items?.data?.map((item) => {
              return <ItemCardV2 key={item?.nftId} item={item} isLink to={`/items/equipment/${item?.nftId}`} />
            })}
          </div>
        </ListView>
      </div>
    </div>
  )
}
