import axios from 'axios'
import tokens from '../../constants/tokens'
import erc20 from 'config/abi/erc20.json'
import UNIV2PairAbi from 'config/abi/lpToken.json'
import multicall from '../../utils/multicall'
import BigNumber from 'bignumber.js'
import { DEFAULT_TOKEN_DECIMAL } from '../../config'
import { setPricesData } from './index'

// const TOKENS_KAI = [{ id: 'kardiachain', symbol: 'KAI', contract: tokens.wkai.address }]
const TOKENS_KROMA = [{ id: 'kromachain', symbol: 'ETH', contract: tokens.weth.address }]

const MASTERCHEF_ADDRESS = '0x0245a1f57Ee84b55Cf489Eb5F3d27355014e57f8'

const poolDflWkai = {
  pid: 5,
  stakingToken: {
    address: '0x256b8a99f69dbdbb5ac781e97f11080a336f5507',
    decimals: 18,
  },
  lpAddress: 0x256b8a99f69dbdbb5ac781e97f11080a336f5507,
  allocPoint: '10000',
  token0: tokens.wkai,
  token1: tokens.defily,
}

const chunk = (arr, n) => (arr.length ? [arr.slice(0, n), ...chunk(arr.slice(n), n)] : [])

const lookUpPrices = async function (id_array) {
  const prices = {}
  for (const id_chunk of chunk(id_array, 50)) {
    let ids = id_chunk.join('%2C')
    let res = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=' + ids + '&vs_currencies=usd')

    for (const [key, v] of Object.entries(res.data)) {
      if (v.usd) prices[key] = v.usd
    }
  }
  return prices
}

export async function getPrices() {
  const idPrices = await lookUpPrices(TOKENS_KROMA.map((x) => x.id))
  const prices = {}
  for (const bt of TOKENS_KROMA) if (idPrices[bt.id]) prices[bt.contract] = idPrices[bt.id]
  return {
    ...prices,
    [tokens.kusd.address]: 1,
  }
}

export function getParameterCaseInsensitive(object, key) {
  if (!key) return undefined
  if (object instanceof Object && key) {
    return object[Object.keys(object).find((k) => k?.toLowerCase() === key?.toLowerCase())]
  }
  return undefined
}

const getUniPrices = (prices, pool) => {
  var t0 = pool.token0
  var p0 = getParameterCaseInsensitive(prices, pool.token0.address)
  var t1 = pool.token1
  var p1 = getParameterCaseInsensitive(prices, pool.token1.address)

  if (p0 == null && p1 == null) {
    console.log(`Missing prices for tokens ${pool.token0} and ${pool.token1}.`)
    return undefined
  }
  if (t0?.decimals == null) {
    console.log(`Missing information for token ${pool.token0}.`)
    return undefined
  }
  if (t1?.decimals == null) {
    console.log(`Missing information for token ${pool.token1}.`)
    return undefined
  }

  var q0 = pool.q0 / 10 ** t0.decimals
  var q1 = pool.q1 / 10 ** t1.decimals
  if (p0 == null) {
    p0 = (q1 * p1) / q0
    if (p0) {
      prices[pool.token0.address] = p0
    }
  }
  if (p1 == null) {
    p1 = (q0 * p0) / q1
    if (p1) {
      prices[pool.token1.address] = p1
    }
  }
  var tvl = q0 * p0 + q1 * p1
  var price = tvl / pool.totalSupply
  if (price) {
    prices[pool.lpAddress] = price
  }
  var stakedTvl = pool.staked * price

  return {
    t0: t0,
    p0: p0,
    q0: q0,
    t1: t1,
    p1: p1,
    q1: q1,
    price: price,
    tvl: tvl,
    stakedTvl: stakedTvl,
  }
}

export const getPriceDfl = async (prices) => {
  try {
    const callsTotalSupply = [
      {
        address: poolDflWkai.stakingToken.address,
        name: 'totalSupply',
      },
    ]
    const callsBalance = [
      {
        address: poolDflWkai.stakingToken.address,
        name: 'balanceOf',
        params: [MASTERCHEF_ADDRESS],
      },
    ]
    const callsReserves = [
      {
        address: poolDflWkai.stakingToken.address,
        name: 'getReserves',
      },
    ]
    const promise = [
      multicall(erc20, callsTotalSupply),
      multicall(erc20, callsBalance),
      multicall(UNIV2PairAbi, callsReserves),
    ]

    const response = await Promise.all(promise)
    const totalSupply = response?.[0]
    const stakedBalance = response?.[1]
    const totalReserves = response?.[2]
    const poolInfoFetch = {
      ...poolDflWkai,
      q0: totalReserves[0]._reserve0._hex,
      q1: totalReserves[0]._reserve1._hex,
      totalSupply: new BigNumber(totalSupply[0]).div(DEFAULT_TOKEN_DECIMAL).toJSON(),
      staked: new BigNumber(stakedBalance[0]).div(DEFAULT_TOKEN_DECIMAL).toJSON(),
    }
    getUniPrices(prices, poolInfoFetch)
    return prices
  } catch (error) {
    console.log('Error in getPriceDfl')
    console.log(error)
  }
}

export const fetchPricesAsync = () => async (dispatch) => {
  const pricesFetch = await getPrices()
  const prices = await getPriceDfl(pricesFetch)
  if (prices) {
    prices[tokens.dragon.address] = prices?.[tokens.defily.address]
    dispatch(setPricesData(prices))
  }
}
