import { ChainId } from 'defily-v2-sdk'

const KROMA_CHAIN_ID = 0xff
const KROMA_SEPOLIA_CHAIN_ID = 0x936

const address = {
  defily: '0xD675fF2B0ff139E14F86D87b7a6049ca7C66d76e',
  masterChef: '0x0245a1f57Ee84b55Cf489Eb5F3d27355014e57f8',
  masterChefBeco: '0x20e8Ff1e1d9BC429489dA76B1Fc20A9BFbF3ee7e',
  masterChefLtd: '0x1174e5968f5Dbf5d9Be92e628926E318F7735749',
  wKai: '0xAF984E23EAA3E7967F3C5E007fbe397D8566D23d',
  dragon: '0x0Cf7c2A584988871b654Bd79f96899e4cd6C41C0',
  treasury: '0xDd6a2C70f540BbccF53708884B58F7538baa9494',
  multicall: '0xF7FdA38a1d27220a16591D50c0cfcc087340429b',
  weth: '0x4200000000000000000000000000000000000001',
}

export const MULTICALL_ADDRESS = {
  [ChainId.BSC_TESTNET]: '0xae11C5B5f29A6a25e955F0CB8ddCc416f522AF5C',
  [KROMA_CHAIN_ID]: '0xF7FdA38a1d27220a16591D50c0cfcc087340429b', //change this
  [KROMA_SEPOLIA_CHAIN_ID]: '0xc8A1cFb98b538bf196Cd75B391B330B35D11D875',
  [ChainId.BSC]: '0xa9193376D09C7f31283C54e56D013fCF370Cd9D9',
  [ChainId.KAI]: '0x95C7e4AA82381dB726d0303B12DA4A001548f6D4',
  [ChainId.HARMONY]: '0xdDCbf776dF3dE60163066A5ddDF2277cB445E0F3',
}

export default address
