import axiosClient from './axiosClient'

const eventApi = {
  getDragons: (monsterId, params) => {
    const url = `/events/${monsterId}/dragons`
    return axiosClient.get(url, { params })
  },
  getEventHistoryOwner: (params) => {
    const url = '/events/get-event-history-owner'
    return axiosClient.get(url, { params })
  },
  getHistories: (params) => {
    const url = '/events'
    return axiosClient.get(url, { params })
  },
  getHistoryByTxHash: (tx) => {
    const url = `/events/${tx}`
    return axiosClient.get(url)
  },
  getIdDragons: () => {
    const url = '/events/dragons'
    return axiosClient.get(url)
  },
}

export default eventApi
