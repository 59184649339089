import React from 'react'
import FilterAction from 'views/DragonCave/components/FilterAction'
import { useQuerySearch, useUpdateQueryWithoutURL } from 'store/search/hook'
import { formatQueryDragons } from 'store/dragons/hook'
import { MONSTER_TYPES, MONSTER_TYPES_QUERY } from 'constants/index'
import { useDispatch } from 'react-redux'
import Modal from 'components/Modal/ModalV2'
import ModalTitle from 'components/Modal/ModalTitleV2'
import LoadingPage from 'components/Loader/LoadingPage'
import ListView from 'components/ListView'
import { showToastError } from 'components/CustomToast/CustomToast'
import { useDragonsCanFight, useFetchDragonsFightMonster } from 'store/monster/hook'
import { fetchDragonsCanFightAsync } from 'store/monster'
import DragonCard from '../DragonCard'

const ModalSelectDragon = ({ open, toggle, onSelect, monster }) => {
  useUpdateQueryWithoutURL()
  useFetchDragonsFightMonster(monster)
  const dispatch = useDispatch()
  const dragons = useDragonsCanFight()
  const query = useQuerySearch()

  const onChangePage = async (page) => {
    try {
      const params = {
        ...query,
        page: page + 1,
        level: monster?.requireLevel,
        monster: monster?.type === MONSTER_TYPES.XX_MONSTER ? MONSTER_TYPES_QUERY.XX : MONSTER_TYPES_QUERY.YY,
      }
      await dispatch(fetchDragonsCanFightAsync(monster?.id, formatQueryDragons(params)), monster?.type)
    } catch (error) {
      showToastError('Error', 'Some error occurred, please try again!')
    }
  }

  return (
    <Modal open={open} isDark size="xl" onClose={toggle}>
      <ModalTitle onClose={toggle}>Select dracora</ModalTitle>
      <div className="pb-8 px-2 sm:px-4">
        <FilterAction showFilter={false} showDropdownBox={false} showSort={false} />
        {dragons === undefined ? (
          <div className="mb-20">
            <LoadingPage />
          </div>
        ) : (
          <ListView
            className="min-height-list-save-modal max-w-screen-lg m-auto px-2.5 sm:px-5 xl:px-0 mt-8"
            pageCount={dragons?.totalPage}
            totalItems={dragons?.totalItems}
            currentPage={dragons?.currentPage}
            fetchApi={onChangePage}
          >
            <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 -mx-2 gap-y-4">
              {dragons?.data?.map((item) => {
                return <DragonCard key={item?.id} dragon={item} monster={monster} onSelect={onSelect} />
              })}
            </div>
          </ListView>
        )}
      </div>
    </Modal>
  )
}

export default ModalSelectDragon
