import React, { useEffect, useState } from 'react'
import withLayout from 'hoc/withLayout'
import Container from 'components/Container/Container'
import GiftCard from './components/GiftCard'
import { GIFT_TYPE } from 'constants/index'
import { useFetchMyGifts, useMyGifts } from './../../store/gift/hook'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import Web3Connect from '../../components/Web3Connect/Web3Connect'
import LoadingPage from 'components/Loader/LoadingPage'
import ModalOpenGift from './components/ModalOpenGift'

function Gift() {
  useFetchMyGifts()

  const gifts = useMyGifts()
  const [giftObj, setGiftObj] = useState()
  const [openModalOpenGift, setOpenModalOpenGift] = useState(false)
  const [type, setType] = useState()
  const { account } = useActiveWeb3React()

  const arrayToObject = (array, key, value) => {
    if (array?.length) {
      return array.reduce(
        (obj, item) => ({
          ...obj,
          [item[key]]: item[value],
        }),
        {},
      )
    }
  }

  useEffect(() => {
    setGiftObj(arrayToObject(gifts, 'type', 'total'))
  }, [gifts])

  if (!account) {
    return (
      <div className="mt-12 flex justify-center">
        <Web3Connect />
      </div>
    )
  }

  if (gifts === undefined) {
    return <LoadingPage />
  }

  return (
    <Container className="pt-10 pb-36 px-4">
      <div className="max-w-screen-lg m-auto">
        <div className="text-center text-white">
          <h3 className="text-3xl">Gift</h3>
        </div>
        {gifts?.length ? (
          <div className="grid sm:grid-cols-3 grid-cols-2 gap-6 mt-10">
            {Object.values(GIFT_TYPE).map((giftType) => {
              if (!giftObj?.[giftType.label]) return
              return (
                <GiftCard
                  type={giftType.label}
                  total={giftObj?.[giftType.label]}
                  onClick={() => {
                    setType(giftType.label)
                    setOpenModalOpenGift(true)
                  }}
                />
              )
            })}
          </div>
        ) : (
          <p className="mt-20 text-white text-center text-xl">No data</p>
        )}
      </div>

      <ModalOpenGift open={openModalOpenGift} toggle={() => setOpenModalOpenGift(!openModalOpenGift)} type={type} />
    </Container>
  )
}

export default withLayout(Gift)
