import classnames from 'classnames'
import { useState } from 'react'
import { Menu, X } from 'react-feather'
import { NavLink } from 'react-router-dom'
import { LIST_MENU } from '../../constants'
import { resetSearchData } from '../../store/search'
import { useDispatch } from 'react-redux'
import Collapse from '../Collapse'
import { useQuery } from 'utils'
import { useHistory } from 'react-router-dom'
const MenuMobile = () => {
  const dispatch = useDispatch()
  const { location } = useHistory()
  const [open, setOpen] = useState(false)
  const query = useQuery()
  const closeMenu = () => {
    setOpen(false)
    dispatch(resetSearchData())
  }

  return (
    <>
      {/** TODO: Uncomment when deploy prod */}
      {/* <Menu className="text-white cursor-pointer block lg:hidden" size={32} onClick={() => setOpen(!open)} />
      {open && <div className="z-40 opacity-8 fixed top-0 left-0 w-full h-full" onClick={closeMenu} />} */}
      <div
        className={classnames(
          'transition-transform h-full duration-200 shadow-lg absolute left-full z-50 bg-primary w-full h-auto py-4 w-screen menu-slide-mobile',
          open && 'transform -translate-x-full',
          !open && 'transform -translate-x-0',
        )}
        style={{
          top: '0',
          position: 'fixed',
          maxWidth: 520,
          overflowY: 'auto',
        }}
      >
        <div className="flex flex-row justify-end">
          <div className="rounded p-3 bg-dropdown-item-hover mr-4 cursor-pointer" onClick={closeMenu}>
            <X color="white" />
          </div>
        </div>

        <div className="flex justify-center mb-5">
          <img src="/logo.png" />
        </div>
        {LIST_MENU.map((menu, index) => {
          const TagLink = menu?.route ? NavLink : 'a'
          if (menu.child) {
            return (
              <Collapse key={index} className="py-3.5" headerClassName="px-10" defaultExtend title={menu?.title}>
                {menu.child?.map((item, i) => {
                  const TagLink = item?.route ? NavLink : 'a'
                  if (item?.child) {
                    return (
                      <Collapse
                        key={`collapse-${i}`}
                        className="block"
                        headerClassName="pr-10 pl-14"
                        defaultExtend
                        title={item?.title}
                        showDot={true}
                      >
                        {item?.child?.map((child, i) => {
                          const TagLink = child?.route ? NavLink : 'a'
                          return (
                            <TagLink
                              key={`collapse-child-${i}`}
                              className={classnames('block pl-20 bg-dropdown-item-hover', {
                                'bg-dropdown-item': child?.route?.split('?')?.[0] === location.pathname,
                              })}
                              {...(child?.route
                                ? {
                                    to: child.route,
                                  }
                                : {
                                    href: child?.url,
                                    target: !child?.inside ? '_blank' : '',
                                  })}
                              onClick={closeMenu}
                            >
                              <div className="h-full flex items-center px-4 py-2.5 text-white">
                                <span className="w-1 h-1 rounded-full bg-white mr-2" />
                                <div className="flex-1">{child.title}</div>
                              </div>
                            </TagLink>
                          )
                        })}
                      </Collapse>
                    )
                  }
                  return (
                    <TagLink
                      key={`collapse-${i}`}
                      className={classnames('block pl-10 bg-dropdown-item-hover', {
                        'bg-dropdown-item': item?.route?.split('?')?.[0] === location.pathname,
                      })}
                      {...(item?.route
                        ? {
                            to: item.route,
                          }
                        : {
                            href: item?.url,
                            target: !item?.inside ? '_blank' : '',
                          })}
                      onClick={closeMenu}
                    >
                      <div className="h-full flex items-center px-4 py-2.5 text-white">
                        <span className="w-1 h-1 rounded-full bg-white mr-2" />
                        <div className="flex-1">{item.title}</div>
                      </div>
                    </TagLink>
                  )
                })}
              </Collapse>
            )
          }

          return (
            <TagLink
              className="group text-white px-10 py-3.5 block whitespace-nowrap bg-dropdown-item-hover"
              key={index}
              {...(menu?.route
                ? {
                    to: menu.route,
                  }
                : {
                    href: menu?.url,
                  })}
              to={menu.route}
              isActive={(match, location) => location.pathname === menu.route}
              activeClassName="text-white bg-grey-400"
              onClick={closeMenu}
            >
              <div className="w-full h-full flex flex-row items-center">
                <span>{menu.title}</span>
              </div>
            </TagLink>
          )
        })}
      </div>
    </>
  )
}

export default MenuMobile
