import React from 'react'
import Dropdown from '../Dropdown/Dropdown'
import classnames from 'classnames'
import { BODY_PART_TYPE } from '../../constants'
import { useDispatch } from 'react-redux'
import { updateBodyParts } from '../../store/search'

const BodyParts = ({ bodyPartsData, bodyParts }) => {
  const dispatch = useDispatch()
  const handleChangeFilterBodyParts = (type, value) => {
    dispatch(updateBodyParts({ [type]: value }))
  }
  return (
    <div className="px-6 mb-2">
      {bodyPartsData &&
        Object.keys(BODY_PART_TYPE)?.map((key, index) => (
          <div key={index} className="flex justify-between items-center my-2">
            <p className="text-blue4 text-sm-md mt-1.5 mb-1">{BODY_PART_TYPE[key]}</p>
            <Dropdown
              bsprefixmenu="p-0 text-sm-md sm:text-base mr-1 sm:mr-4"
              menu={
                [undefined, null].includes(bodyParts?.[key])
                  ? { value: '', label: 'All' }
                  : { value: bodyParts?.[key], label: bodyPartsData?.[key]?.[bodyParts?.[key]] }
              }
              className="flex-1 sm:flex-none mr-0"
              classNameMenu="border-blue2 border-2 rounded-xl h-10 min-w-8"
              classNameLabel="mr-8 sm:mr-4"
              classNameMenuItem={classnames(
                'bg-primary text-white right-0 w-44 -right-10 max-h-96 overflow-auto custom-scrollbar-scroll custom-scrollbar-scroll-y',
                {
                  'bottom-0': index > 3,
                },
              )}
              classNameLabelMenu="justify-between px-2"
              classNameButtonToggle={'inline text-blue4 px-4 sm:px-2.5 mr-0 cursor-pointer'}
            >
              <p
                className="p-3 text-white cursor-pointer hover:bg-blue2"
                onClick={() => handleChangeFilterBodyParts(key, null)}
              >
                All
              </p>
              {Object.keys(bodyPartsData?.[key])?.map((_key, index) => (
                <p
                  key={index}
                  className="p-3 text-white cursor-pointer hover:bg-blue2"
                  onClick={() => handleChangeFilterBodyParts(key, _key)}
                >
                  {bodyPartsData?.[key]?.[_key]}
                </p>
              ))}
            </Dropdown>
          </div>
        ))}
    </div>
  )
}

export default BodyParts
