import React from 'react'
import Modal from 'components/Modal/ModalV2'

const ModalFightResult = ({ open, toggle, result }) => {
  return (
    <Modal isDark open={open} onClose={toggle} className="bg-transparent-important shadow-none">
      <div className="p-10">
        <img className="custom-effect-show-out" src={`/images/${result.toLowerCase()}.png`} />
      </div>
    </Modal>
  )
}

export default ModalFightResult
