import React from 'react'
import withLayout from 'hoc/withLayout'
import NavBack from 'components/Navigate/Back'
import Container from 'components/Container/Container'
import ViewDragon from './components/ViewDragon'
import DetailDragon from './components/DetailDragon'
import { useDragon, useFetchDataUserDragonMarketplace, useFetchDragon } from '../../store/dragons/hook'
import LoadingPage from '../../components/Loader/LoadingPage'
import NotFoundData from '../../components/NotFoundData'
import { fetchDetailDragon } from '../../store/dragons'
import dragonsApi from '../../api/dragonsApi'
import { useDispatch } from 'react-redux'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'

const Dragon = ({}) => {
  useFetchDragon()
  useFetchDataUserDragonMarketplace()
  const dispatch = useDispatch()
  const { account } = useActiveWeb3React()
  const dragon = useDragon()

  const updateData = async () => {
    try {
      await dispatch(fetchDetailDragon(account, dragon?.id))
    } catch (error) {}
  }

  const onFavorites = async (id) => {
    try {
      await dragonsApi.tickFavorites(id, account)
      await updateData()
    } catch (error) {
      console.log(error)
    }
  }

  if (dragon === undefined) {
    return <LoadingPage className="h-full" />
  }
  if (dragon === null) {
    return <NotFoundData text="Dragon not found!" />
  }

  return (
    <>
      <NavBack />
      <Container className="mb-16">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <ViewDragon dragon={dragon} onFavorites={onFavorites} updateData={updateData} />
          <DetailDragon dragon={dragon} onFavorites={onFavorites} />
        </div>
      </Container>
    </>
  )
}

export default withLayout(Dragon, null, '#0A1520')
