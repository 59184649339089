import React, { useState, useEffect } from 'react'
import withLayout from 'hoc/withLayout'
import NavBack from 'components/Navigate/Back'
import Container from 'components/Container/Container'
import LoadingPage from '../../components/Loader/LoadingPage'
import NotFoundData from '../../components/NotFoundData'

const Gameplay = () => {
  const [loading, setLoading] = useState(true)
  const [gameplayData, setGameplayData] = useState(null)

  useEffect(() => {
    // Simulating an API call or data fetching
    const fetchGameplayData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 500)) // 2 second delay
      setLoading(false)
      setGameplayData([]) // Set to null to simulate "not found" scenario
    }

    fetchGameplayData()
  }, [])

  if (loading) {
    return <LoadingPage className="h-full" />
  }

  if (gameplayData === null) {
    return <NotFoundData text="Gameplay data not found!" />
  }

  return (
    <>
      <NavBack />
      <Container className="mb-16">
        <div className="flex items-center justify-center h-screen">
          <h1 className="text-4xl font-bold text-white">Gameplay Coming Soon!</h1>
        </div>
      </Container>
    </>
  )
}

export default withLayout(Gameplay, null, '#0A1520')
