import React from 'react'
import BoxTab from './BoxTab'
import { useDragonsSelling, useDragonsSiring, useFetchDragonsListed, useTabListed } from '../../../store/report/hook'
import { useDispatch } from 'react-redux'
import { setTabListed } from '../../../store/report'
import ListedDragon from './ListedDragon'
import { useKaiPrice } from '../../../hooks/usePrice'

const RecentListed = () => {
  useFetchDragonsListed()
  const dragonsSelling = useDragonsSelling()
  const dragonsSiring = useDragonsSiring()
  const dispatch = useDispatch()
  const tokenPrice = useKaiPrice()
  const tab = useTabListed()

  const listedDragons = tab === 0 ? dragonsSelling : dragonsSiring

  const onChangeTab = (value) => {
    if (value !== tab) {
      dispatch(setTabListed(value))
    }
  }

  return (
    <BoxTab
      title="Recently listed"
      tabActive={tab}
      tabs={['Dragon selling', 'Dragon siring']}
      onChangeTab={onChangeTab}
    >
      {listedDragons === undefined ? (
        <div className="flex justify-center py-10">
          <img src="/icon/loading.gif" width={45} />
        </div>
      ) : listedDragons?.length ? (
        listedDragons?.map((dragon, index) => <ListedDragon key={index} dragon={dragon} tokenPrice={tokenPrice} />)
      ) : (
        <p className="text-xl text-white py-10 text-center">No data</p>
      )}
    </BoxTab>
  )
}

export default RecentListed
