import React, { useState, useEffect } from 'react'
import withLayout from 'hoc/withLayout'
import classnames from 'classnames'
import EquipmentTab from './components/Equipment/EquipmentTab'
import SkillTab from './components/Skill/SkillTab'
import ExpTab from './components/Exp/ExpTab'
import Container from 'components/Container/Container'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'
import Web3Connect from 'components/Web3Connect/Web3Connect'
import { ITEM_TYPES } from '../../constants'
import { Link, useParams } from 'react-router-dom'
function ItemTabPage() {
  const { account } = useActiveWeb3React()
  const { type } = useParams()

  if (!account) {
    return (
      <div className="mt-12 flex justify-center">
        <Web3Connect />
      </div>
    )
  }

  return (
    <Container>
      <div className="flex items-center justify-center sm:mt-12 mt-8 mb-5">
        <Link
          to={`/my-items/equipment`}
          className={classnames('sm:px-12 px-5 sm:text-xl text-lg pb-2 cursor-pointer', {
            'border-b-4 border-blue4': type === 'equipment',
          })}
        >
          Equipment
        </Link>
        <Link
          to={`/my-items/skill-card`}
          className={classnames('sm:px-12 px-5 sm:text-xl text-lg pb-2 cursor-pointer', {
            'border-b-4 border-blue4': type === 'skill-card',
          })}
        >
          Skill
        </Link>
        <Link
          to={`/my-items/exp-card`}
          className={classnames('sm:px-12 px-5 sm:text-xl text-lg pb-2 cursor-pointer', {
            'border-b-4 border-blue4': type === 'exp-card',
          })}
        >
          EXP
        </Link>
      </div>

      {type === 'skill-card' && <SkillTab />}
      {type === 'equipment' && <EquipmentTab />}
      {type === 'exp-card' && <ExpTab />}
    </Container>
  )
}

export default withLayout(ItemTabPage)
