import React, { useEffect, useState } from 'react'
import Container from 'components/Container/Container'
import DragonCard from 'components/Card/DragonCard'
import { DEFAULT_TOKEN_USED, TOKEN_PER_EGG } from 'constants/index'
import ButtonGradient from 'components/Button/ButtonGradient'
import tokens, { DRAGON } from 'constants/tokens'
import useInterval from 'hooks/useInterval'
import { useDispatch } from 'react-redux'
import {
  useDragonRemain,
  useFetchDataUserDragonHome,
  useFetchDragonRemain,
  useUserDataDragonHome,
} from 'store/dragons/hook'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import { useApprove } from 'hooks/useApprove'
import {
  AMOUNT_DRAGON_STAKE,
  AMOUNT_EGG_SALE,
  PERIOD_SALE,
  SALE_CLOCK_AUCTION_ADDRESS_OLD,
  SALE_CLOCK_AUCTION_HOME_ADDRESS,
} from 'config'
import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast'
import { fetchUserDataDragonHome } from 'store/dragons'
import classnames from 'classnames'
import { useBuyEgg } from 'hooks/useBuyEgg'
import { useModalWalletConnect } from 'hooks/modal'
import Countdown, { zeroPad } from 'react-countdown'
import dragonsApi from 'api/dragonsApi'
import Value from 'components/Value/Value'
import { USER_BOUGHT_EGG } from 'utils/storage'
import { useStakeToBuy } from 'hooks/useStake'
import ModalStake from 'components/Modal/ModalStake'
import { useDflPrice } from 'hooks/usePrice'
import useTokenBalance from 'hooks/useTokenBalance'
import { useUnStakeToBuy } from 'hooks/useUnstake'
import { getBalanceNumber } from 'utils/formatBalance'
import { formatDDMMMHHmm } from 'utils/formatDateTime'
import { AlertCircle } from 'react-feather'
import Tooltip from 'components/Tooltip/Tooltip'
import ModalWarningBuyEgg from 'components/Modal/ModalWarningBuyEgg'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

const TIME_START_SALE = 1629036000000
const COOL_DOWN_DURATION = 30 * 1000
const MAX_EGG_SALE = 9999
const BuyEgg = ({}) => {
  useFetchDragonRemain()
  useFetchDataUserDragonHome()
  const dispatch = useDispatch()
  const userData = useUserDataDragonHome()
  const dragonRemain = useDragonRemain()
  const { account, chainId } = useActiveWeb3React()
  const { onApprove } = useApprove(SALE_CLOCK_AUCTION_HOME_ADDRESS, DEFAULT_TOKEN_USED.address)
  const { onBuyToken } = useBuyEgg(SALE_CLOCK_AUCTION_HOME_ADDRESS)
  // const { onStake } = useStakeToBuy(SALE_CLOCK_AUCTION_HOME_ADDRESS)
  // const { onUnStake } = useUnStakeToBuy(SALE_CLOCK_AUCTION_HOME_ADDRESS, SALE_CLOCK_AUCTION_ADDRESS_OLD)
  const { onToggleConnectModal } = useModalWalletConnect()
  const dflPrice = useDflPrice()
  const dragonBalance = useTokenBalance(DRAGON[chainId]?.address)
  const [openSell, setOpenSell] = useState(false)
  const [displayEgg, setDisplayEgg] = useState(0)
  const [pendingTx, setPendingTx] = useState(false)
  const [pendingUnstake, setPendingUnstake] = useState(false)
  const [cooldown, setCooldown] = useState(undefined)
  const [openStake, setOpenStake] = useState(false)
  const [openWarning, setOpenWarning] = useState(false)

  // const staked = userData?.userDataLoaded
  //   ? getBalanceNumber(userData?.stake?.amount, DEFAULT_TOKEN_USED.decimals)
  //   : undefined

  // const oldStaked = userData?.userDataLoaded
  //   ? getBalanceNumber(userData?.oldStake?.amount, DEFAULT_TOKEN_USED.decimals)
  //   : undefined

  const timeCooldown = USER_BOUGHT_EGG.get()
  const allowance = new BigNumber(userData?.allowance) || BIG_ZERO
  const userDataLoaded = userData?.userDataLoaded
  const isApproved = account && allowance && allowance?.isGreaterThan(new BigNumber('20000000'))
  const timeSale = TIME_START_SALE + PERIOD_SALE * 7 * 24 * 60 * 60 * 1000
  const totalSupply =
    (PERIOD_SALE + 2) * AMOUNT_EGG_SALE > dragonRemain?.totalSupply
      ? dragonRemain?.totalSupply
      : (PERIOD_SALE + 2) * AMOUNT_EGG_SALE === 7700
      ? MAX_EGG_SALE
      : (PERIOD_SALE + 2) * AMOUNT_EGG_SALE
  // const soldEggs = (totalSupply < MAX_EGG_SALE ? totalSupply : MAX_EGG_SALE)
  const soldEggs = (totalSupply < MAX_EGG_SALE ? totalSupply : MAX_EGG_SALE) - dragonRemain?.numberEgg

  const updateTimeBuyEgg = async () => {
    let dataStorage = await USER_BOUGHT_EGG.get()
    dataStorage = JSON.parse(dataStorage)
    const newData = {
      ...dataStorage,
      [account]: Date.now() + COOL_DOWN_DURATION,
    }
    await USER_BOUGHT_EGG.set(JSON.stringify(newData))
  }

  const getEggInfo = async () => {
    try {
      const response = await dragonsApi.getEgg()
      return response
    } catch (error) {
      console.log(error)
    }
  }

  const updateTimeCooldown = async () => {
    let dataStorage = await USER_BOUGHT_EGG.get()
    dataStorage = JSON.parse(dataStorage)
    if (dataStorage?.[account]) {
      if (Date.now() > dataStorage?.[account] && cooldown) {
        setCooldown(undefined)
        return
      }
      if (Date.now() < dataStorage?.[account] && (!cooldown || cooldown !== dataStorage?.[account])) {
        setCooldown(dataStorage?.[account])
        return
      }
    }

    if (!dataStorage?.[account] && cooldown !== undefined) {
      setCooldown(undefined)
      return
    }
  }

  useInterval(() => {
    setDisplayEgg((preState) => (preState + 1) % 5)
    if (!openSell) {
      const time = Date.now()
      if (time > timeSale) {
        setOpenSell(true)
      }
    } else {
      updateTimeCooldown()
    }
  }, 1000)

  useEffect(() => {
    setCooldown(undefined)
  }, [timeCooldown, account])

  const updateData = async () => {
    await dispatch(fetchUserDataDragonHome(account))
  }

  const handleApprove = async () => {
    try {
      setPendingTx(true)
      await onApprove()
      await updateData()
      showToastSuccess('Contract Enabled', 'Approve contract successfully!')
      setPendingTx(false)
    } catch (error) {
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
      setPendingTx(false)
    }
  }

  const randomIntFromInterval = (min, max) => {
    const _max = max !== 7700 ? max : 9999
    return Math.floor(Math.random() * (_max - min + 1) + min)
  }

  const handleBuy = async (forceBuy = false) => {
    try {
      if (forceBuy !== true) {
        if (Date.now() + 150 >= timeSale) {
          // early 150ms
          setPendingTx(true)
          const eggInfo = await getEggInfo()
          if (eggInfo?.id) {
            await onBuyToken(eggInfo?.id, eggInfo?.price, 0)
          } else {
            // Random number
            const startNumber = (PERIOD_SALE + 1) * AMOUNT_EGG_SALE
            const numberEgg = randomIntFromInterval(startNumber + 1, startNumber + 700)
            await onBuyToken(numberEgg, TOKEN_PER_EGG, DEFAULT_TOKEN_USED.decimals)
          }
          await updateTimeBuyEgg()
          showToastSuccess('Success', 'Congratulation! You have bought an egg successfully!')
          setPendingTx(false)
        } else {
          setOpenWarning(true)
        }
      } else {
        setOpenWarning(false)
        setPendingTx(true)
        // Random number
        const startNumber = (PERIOD_SALE + 1) * AMOUNT_EGG_SALE
        const numberEgg = randomIntFromInterval(startNumber + 1, startNumber + 700)
        await onBuyToken(numberEgg, TOKEN_PER_EGG, DEFAULT_TOKEN_USED.decimals)
        await updateTimeBuyEgg()
        showToastSuccess('Success', 'Congratulation! You have bought an egg successfully!')
        setPendingTx(false)
      }
    } catch (error) {
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
      setPendingTx(false)
    }
  }

  // const handleStake = async () => {
  //   try {
  //     await onStake(AMOUNT_DRAGON_STAKE, DEFAULT_TOKEN_USED.decimals)
  //     await updateData()
  //     showToastSuccess('Staked', `Your ${DEFAULT_TOKEN_USED.symbol} funds have been staked!`)
  //   } catch (error) {
  //     throw error
  //   }
  // }

  // const handleUnStake = async (oldContract = false) => {
  //   try {
  //     setPendingUnstake(true)
  //     if (oldContract === true) {
  //       await onUnStake(SALE_CLOCK_AUCTION_ADDRESS_OLD)
  //     } else {
  //       await onUnStake()
  //     }
  //     await updateData()
  //     showToastSuccess('Success', `Unstake ${DEFAULT_TOKEN_USED?.symbol} successfully!`)
  //     setPendingUnstake(false)
  //   } catch (error) {
  //     showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
  //     setPendingUnstake(false)
  //   }
  // }

  const rendererStartIn = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) return null
    return (
      <div className="text-white mb-5 text-center md:text-left">
        <p className="text-sm-md">Countdown for next batch</p>
        <p className="font-bold text-xl">
          {zeroPad(days)}d {zeroPad(hours)}h {zeroPad(minutes)}m {zeroPad(seconds)}s
        </p>
      </div>
    )
  }

  const renderCooldown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) return null
    return <span className="pl-1 text-sm">({zeroPad(seconds)}s)</span>
  }

  return (
    <Container id="box-buy-egg" className="pb-24 mt-6">
      {/* <div className="text-center">
        <Value
          className="text-white text-2xl"
          value={AMOUNT_DRAGON_STAKE}
          decimals={2}
          // TODO: Change TOKEN symbol to DRACORA
          // unit={` ${DEFAULT_TOKEN_USED.symbol} Required`}
          unit={` DRACORA Required`}
        />
        <p className="text-white text-sm">You have to stake 1,000 DRACORA to buy egg</p>
        {typeof userData?.stake?.amount === 'number' ? (
          <div className="flex justify-center items-center">
            <Value className="text-white text-4xl mt-4 mb-2" value={staked} decimals={2} />
            {oldStaked ? (
              <Tooltip
                className="flex items-center cursor-pointer text-sm-md"
                classNameToolTip="w-72 sm:w-80 bg-black text-sm-md border-2 border-blue2 tooltip-potential"
                tooltip="You have to unstake the pre-batch then stake for this batch"
              >
                <AlertCircle color="white" className="ml-1 mt-3" size={16} />
              </Tooltip>
            ) : null}
          </div>
        ) : (
          <p className="text-white text-4xl mt-4 mb-2">0.00</p>
        )}
        <div className="flex justify-center">
          {account ? (
            <div className="flex flex-col sm:flex-row justify-center">
              {isApproved ? (
                <>
                  {userData?.stake?.amount ? (
                    <ButtonGradient
                      className="mt-2"
                      onClick={handleUnStake}
                      disabled={pendingTx || pendingUnstake || userData?.stake?.unStakeTime > Date.now()}
                      isLoading={pendingUnstake}
                    >
                      Unstake
                    </ButtonGradient>
                  ) : (
                    <ButtonGradient className="mt-2" onClick={() => setOpenStake(true)}>
                      Stake
                    </ButtonGradient>
                  )}
                </>
              ) : (
                <ButtonGradient
                  className={classnames('px-5 mx-2 mt-2')}
                  disabled={pendingTx || !userDataLoaded}
                  isLoading={pendingTx || !userDataLoaded}
                  onClick={handleApprove}
                >
                  Approve Contract
                </ButtonGradient>
              )}
              {oldStaked ? (
                <ButtonGradient
                  className="mx-2 mt-2"
                  onClick={() => handleUnStake(true)}
                  disabled={pendingUnstake}
                  isLoading={pendingUnstake}
                >
                  Unstake
                  <span className="pl-1 text-sm">(Pre-batch)</span>
                </ButtonGradient>
              ) : null}
            </div>
          ) : (
            <ButtonGradient className="px-5 mt-2" onClick={onToggleConnectModal}>
              Connect wallet
            </ButtonGradient>
          )}
        </div>
        {userData?.stake?.unStakeTime ? (
          <p className="text-white text-sm mt-1">Unstake in: {formatDDMMMHHmm(userData?.stake?.unStakeTime)} (UTC)</p>
        ) : null}
      </div> */}
      <div className="flex justify-center flex-wrap mt-6">
        <div className="mt-4">
          <DragonCard
            isEgg
            indexImg={displayEgg}
            title="Random Egg"
            // desc={`${TOKEN_PER_EGG} ${DEFAULT_TOKEN_USED.symbol}`}
            // TODO: Change TOKEN symbol to DRACORA
            desc={`${TOKEN_PER_EGG} USDT`}
            className="max-w-xs"
            style={{
              maxWidth: 280,
              backgroundImage: 'url(/images/EggBack.png)',
            }}
          />
        </div>
        <div className="flex items-center mt-4">
          <div>
            <div className="text-white">
              <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl max-w-xs font-bold leading-10">
                Get your own warriors
              </h2>
              <p className="mt-6 text-center md:text-left">
                Everything you need is here. <br />
                Let's build your own squad!
              </p>
            </div>

            <div className="mt-4 md:mt-8">
              <Countdown zeroPadTime={2} date={timeSale} renderer={rendererStartIn} />
              {typeof dragonRemain?.numberEgg === 'number' ? (
                <div className="mb-1.5 text-center md:text-left">
                  <Value
                    prefix="Total sales: "
                    className="text-white"
                    value={!openSell ? (PERIOD_SALE + 1) * AMOUNT_EGG_SALE : soldEggs}
                    unit={`/9,999`}
                    decimals={0}
                  />
                  <p className="text-white text-sm italic">(999 eggs per week)</p>
                </div>
              ) : null}
              <div className="flex justify-center md:justify-start">
                {account ? (
                  <>
                    {isApproved ? (
                      <>
                        <ButtonGradient
                          className={classnames(cooldown > Date.now() ? 'px-6 md:px-10' : 'px-8 md:px-12')}
                          disabled={
                            pendingTx ||
                            (openSell && dragonRemain?.numberEgg === 0) ||
                            cooldown > Date.now() ||
                            // !userData?.stake?.amount ||
                            pendingUnstake
                          }
                          isLoading={pendingTx}
                          onClick={handleBuy}
                        >
                          {openSell ? (dragonRemain?.numberEgg === 0 ? 'Sold out' : 'BUY') : 'BUY'}
                          {cooldown ? <Countdown zeroPadTime={2} date={cooldown} renderer={renderCooldown} /> : null}
                        </ButtonGradient>
                      </>
                    ) : (
                      <ButtonGradient
                        className={classnames('px-5')}
                        disabled={pendingTx || !userDataLoaded}
                        isLoading={pendingTx || !userDataLoaded}
                        onClick={handleApprove}
                      >
                        Approve Contract
                      </ButtonGradient>
                    )}
                  </>
                ) : (
                  <ButtonGradient className="px-5" onClick={onToggleConnectModal}>
                    Connect wallet
                  </ButtonGradient>
                )}
              </div>
            </div>
            {/* <p className="text-white mt-5 text-sm md:text-sm-md text-center md:text-left">
              Don't have enough token?{' '}
              <a
                className="underline"
                href={`https://defily.io/#/swap?outputCurrency=${tokens.dragon.address}`}
                target="_blank"
              >
                Get USDT Token
              </a>
            </p> */}
          </div>
        </div>
      </div>
      {openStake ? (
        <ModalStake
          open={openStake}
          onDismiss={() => setOpenStake(false)}
          max={new BigNumber(dragonBalance)}
          allowance={new BigNumber(userData?.allowance) || BIG_ZERO}
          title={`Stake ${DEFAULT_TOKEN_USED.symbol}`}
          lpTokenName={DEFAULT_TOKEN_USED.symbol}
          unStakeTime={userData?.stake?.unStakeTime}
          priceCurrency={dflPrice}
          fixedValue={AMOUNT_DRAGON_STAKE}
          textNotEnoughBalance={`Your ${DEFAULT_TOKEN_USED.symbol} balance is not enough`}
          // onConfirm={handleStake}
          onApprove={handleApprove}
        />
      ) : null}
      {!openSell && openWarning ? (
        <ModalWarningBuyEgg open={openWarning} toggle={() => setOpenWarning(false)} onSubmit={() => handleBuy(true)} />
      ) : null}
    </Container>
  )
}

export default BuyEgg
