import { createSlice } from '@reduxjs/toolkit'
import dragonsApi from 'api/dragonsApi'
import { checkCanBreed, checkCanMating, fetchAllowanceBreed } from './fetchData'
import { DEFAULT_LIMIT } from 'constants/index'

const initialState = {
  matron: undefined,
  sire: undefined,
  breedData: undefined,
  breedCost: undefined,
  cooldown: undefined,
  dragons: undefined,
  tabSelect: undefined, // 1: My Dragon - 2: Siring
  userData: undefined,
}

const breedSlice = createSlice({
  name: 'breed',
  initialState,
  reducers: {
    setMatron: (state, action) => {
      state.matron = action.payload
    },
    setSire: (state, action) => {
      state.sire = action.payload
    },
    setBreedData: (state, action) => {
      state.breedData = action.payload
    },
    setBreedCost: (state, action) => {
      state.breedCost = action.payload
    },
    setCooldown: (state, action) => {
      state.cooldown = action.payload
    },
    setTabSelect: (state, action) => {
      state.tabSelect = action.payload
    },
    setListDragonsBreed: (state, action) => {
      state.dragons = action.payload
    },
    setUserData: (state, action) => {
      state.userData = action.payload
    },
  },
})

export const fetchDragonMatronAsync = (id) => async (dispatch) => {
  try {
    const response = await dragonsApi.getDragon(id)
    dispatch(setMatron(response))
  } catch (error) {
    dispatch(setMatron(undefined))
    console.log(error)
  }
}

export const fetchDragonSireAsync = (id) => async (dispatch) => {
  try {
    const response = await dragonsApi.getDragon(id)
    dispatch(setSire(response))
  } catch (error) {
    dispatch(setSire(undefined))
    console.log(error)
  }
}

export const fetchBreedDataAsync = (matron, sire, isMating) => async (dispatch) => {
  try {
    let response
    if (isMating) {
      response = await checkCanMating(matron, sire)
    } else {
      response = await checkCanBreed(matron, sire)
    }
    dispatch(setBreedData(response))
  } catch (error) {
    console.log(error)
  }
}

export const fetchBreedCostAsync = () => async (dispatch) => {
  try {
    const response = await dragonsApi.getBirthCost()
    dispatch(setBreedCost(response))
  } catch (error) {
    console.log(error)
  }
}

export const fetchCooldownAsync = () => async (dispatch) => {
  try {
    const response = await dragonsApi.getCooldown()
    dispatch(setCooldown(response))
  } catch (error) {
    console.log(error)
  }
}

export const fetchListDragonsBreedAsync =
  (account, params = {}, tab) =>
  async (dispatch) => {
    try {
      let response
      if (tab === 1) {
        response = await dragonsApi.getMyDragons(account, { rowPerPage: DEFAULT_LIMIT, ...params })
      } else {
        response = await dragonsApi.getDragons({ rowPerPage: DEFAULT_LIMIT, ...params })
      }
      dispatch(setListDragonsBreed(response))
    } catch (error) {
      dispatch(setListDragonsBreed(undefined))
      console.log(error)
    }
  }

export const fetchUserDataAsync = (account) => async (dispatch) => {
  try {
    const allowance = await fetchAllowanceBreed(account)
    dispatch(
      setUserData({
        allowance,
        userDataLoaded: true,
      }),
    )
  } catch (error) {}
}

export const {
  setMatron,
  setSire,
  setBreedData,
  setBreedCost,
  setCooldown,
  setTabSelect,
  setListDragonsBreed,
  setUserData,
} = breedSlice.actions

export default breedSlice.reducer
