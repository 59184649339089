import React, { useEffect } from 'react'
import ContainerPage from '../components/Container/ContainerPage'
import Header from '../components/Header/Header'
import WrapperBackground from '../components/Wrapper/WrapperBackground'
import Footer from '../components/Footer/Footer'
import { useHistory } from 'react-router-dom'
import { IS_MAINTENANCE } from '../config'
import GoTop from '../components/GoTop'

const withLayout =
  (Component, bgImg = '/images/bg-secondary.png', colorOverlay = '#0A152080') =>
  (props) => {
    const history = useHistory()
    useEffect(() => {
      window.scrollTo({ behavior: 'smooth', top: 0, left: 0 })
      if (IS_MAINTENANCE && history?.location?.pathname !== '/') {
        history.replace('/')
      }
    }, [history?.location?.pathname])

    return (
      <ContainerPage>
        <Header />
        <WrapperBackground bgImg={bgImg} colorOverlay={colorOverlay} className="flex-1">
          <Component {...props} />
        </WrapperBackground>
        <Footer />
        <GoTop />
      </ContainerPage>
    )
  }

export default withLayout
