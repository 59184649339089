import React, { useEffect, useState } from 'react'
import reportApi from 'api/reportApi'
import Dropdown from 'components/Dropdown/Dropdown'
import classnames from 'classnames'
import { getExplorerLink } from '../../../utils/explorer'
import Web3 from 'web3'
import { formatAddress } from '../../../utils/addressHelpers'
import { formatNumber } from '../../../utils/formatBalance'
import { DEFAULT_TOKEN_USED, KROMA_CHAIN_ID } from '../../../constants'
import { Link } from 'react-router-dom'
import { ChainId } from 'defily-v2-sdk'

const XX_EVENT_TYPE = [
  {
    label: 'Fight',
    value: 'xx',
  },
  {
    label: 'Win',
    value: 'xxWin',
  },
  {
    label: 'Claim',
    value: 'claimXX',
  },
]

const YY_EVENT_TYPE = [
  {
    label: 'Fight',
    value: 'yy',
  },
  {
    label: 'Kill',
    value: 'yyKill',
  },
  {
    label: 'Claim',
    value: 'claimYY',
  },
]

const TopEvent = () => {
  const [eventHistory, setEventHistory] = useState(undefined)
  const [xxType, setXXType] = useState(XX_EVENT_TYPE[0])
  const [yyType, setYYType] = useState(YY_EVENT_TYPE[0])

  const fetchEventHistory = async () => {
    try {
      const response = await reportApi.getReportEvent()
      setEventHistory(response)
    } catch (error) {
      console.log('Error in fetchEventHistory => ', error)
    }
  }

  useEffect(() => {
    fetchEventHistory()
  }, [])

  return (
    <div id="event-ranking">
      <h2 className="text-center text-2xl mt-10 mb-3">Event ranking</h2>
      <div className="grid gird-cols-1 sm:grid-cols-2 -mx-3">
        <div className="mb-5">
          <div className="m-3 rounded-xl border-1 border-blue2 overflow-hidden">
            <table
              className="custom-table-base custom-table-base__non-border w-full border-0-important bg-blue1"
              style={{
                minHeight: 200,
              }}
            >
              <thead>
                <tr>
                  <th
                    colSpan={999999999}
                    className="text-white text-center bg-blue3"
                    style={{
                      padding: '0 0.5rem',
                    }}
                  >
                    <div className="flex justify-between items-center">
                      <p className="flex-1 text-left ml-2">Challenge Monsters</p>
                      <Dropdown
                        bsprefixmenu="p-0 text-sm-md sm:text-base mr-1 sm:mr-4"
                        menu={xxType || XX_EVENT_TYPE[0]}
                        className="sm:ml-2.5"
                        classNameMenu="border-blue2 border-2 rounded-xl h-10"
                        classNameLabel="mr-8 sm:mr-4"
                        classNameMenuItem={classnames('bg-primary text-white right-0 w-44')}
                        classNameButtonToggle={'inline text-blue4 px-4 sm:px-2.5 mr-0 cursor-pointer'}
                      >
                        {XX_EVENT_TYPE.map((item, index) => (
                          <p
                            key={index}
                            className="p-3 text-white cursor-pointer hover:bg-blue2 text-left"
                            onClick={() => setXXType(item)}
                          >
                            {item.label}
                          </p>
                        ))}
                      </Dropdown>
                    </div>
                  </th>
                </tr>
                <tr className="text-white text-center">
                  <td>No.</td>
                  {xxType?.value !== 'claimXX' ? (
                    <>
                      <td>Dracora</td>
                      <td>Times</td>
                      <td>Action</td>
                    </>
                  ) : (
                    <>
                      <td>Address</td>
                      <td>Amount</td>
                    </>
                  )}
                </tr>
              </thead>
              <tbody className="text-white text-center text-md sm:text-base">
                {typeof eventHistory !== 'undefined' ? (
                  <>
                    {eventHistory?.[xxType?.value]?.length ? (
                      eventHistory?.[xxType?.value]?.map((row, index) => {
                        if (index >= 20) return null
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            {xxType?.value !== 'claimXX' ? (
                              <>
                                <td>Dracora #{row?._id?.dragonId}</td>
                                <td>{formatNumber(row?.count, 0, 0)}</td>
                                <td>
                                  <Link to={`/dragon/${row?._id?.dragonId}`}>
                                    <span className="text-md text-blue4 hover:opacity-80">Detail</span>
                                  </Link>
                                </td>
                              </>
                            ) : (
                              <>
                                <td>
                                  {row?._id?.owner ? (
                                    <a
                                      href={getExplorerLink(
                                        KROMA_CHAIN_ID,
                                        Web3.utils.toChecksumAddress(row?._id?.owner),
                                        'address',
                                      )}
                                      target="_blank"
                                    >
                                      {formatAddress(row?._id?.owner)}
                                    </a>
                                  ) : null}
                                </td>
                                <td>
                                  {formatNumber(row?.count, 0, 2)} {DEFAULT_TOKEN_USED.symbol}
                                </td>
                              </>
                            )}
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={999} className="text-center">
                          No data
                        </td>
                      </tr>
                    )}
                  </>
                ) : (
                  <tr>
                    <td colSpan={9999} className="text-white text-center">
                      Loading ...
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="mb-5">
          <div className="m-3 rounded-xl border-1 border-blue2 overflow-hidden">
            <table
              style={{
                minHeight: 200,
              }}
              className="custom-table-base custom-table-base__non-border w-full border-0-important bg-blue1"
            >
              <thead>
                <tr>
                  <th
                    colSpan={999999999}
                    className="text-white text-center bg-blue3"
                    style={{
                      padding: '0 0.5rem',
                    }}
                  >
                    <div className="flex justify-between items-center">
                      <p className="flex-1 text-left ml-2">Monsters Boss</p>
                      <Dropdown
                        bsprefixmenu="p-0 text-sm-md sm:text-base mr-1 sm:mr-4"
                        menu={yyType || YY_EVENT_TYPE[0]}
                        className="sm:ml-2.5"
                        classNameMenu="border-blue2 border-2 rounded-xl h-10"
                        classNameLabel="mr-8 sm:mr-4"
                        classNameMenuItem={classnames('bg-primary text-white right-0 w-44')}
                        classNameButtonToggle={'inline text-blue4 px-4 sm:px-2.5 mr-0 cursor-pointer'}
                      >
                        {YY_EVENT_TYPE.map((item, index) => (
                          <p
                            key={index}
                            className="p-3 text-white cursor-pointer hover:bg-blue2 text-left"
                            onClick={() => setYYType(item)}
                          >
                            {item.label}
                          </p>
                        ))}
                      </Dropdown>
                    </div>
                  </th>
                </tr>
                <tr className="text-white text-center">
                  <td>No.</td>
                  {yyType?.value !== 'claimYY' ? (
                    <>
                      <td>Dragon</td>
                      <td>Times</td>
                      <td>Action</td>
                    </>
                  ) : (
                    <>
                      <td>Address</td>
                      <td>Amount</td>
                    </>
                  )}
                </tr>
              </thead>
              <tbody className="text-white text-center text-md sm:text-base">
                {typeof eventHistory !== 'undefined' ? (
                  <>
                    {eventHistory?.[yyType?.value]?.length ? (
                      eventHistory?.[yyType?.value]?.map((row, index) => {
                        if (index >= 20) return null
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            {yyType?.value !== 'claimYY' ? (
                              <>
                                <td>Dracora #{row?._id?.dragonId}</td>
                                <td>{formatNumber(row?.count, 0, 0)}</td>
                                <td>
                                  <Link to={`/dragon/${row?._id?.dragonId}`}>
                                    <span className="text-md text-blue4 hover:opacity-80">Detail</span>
                                  </Link>
                                </td>
                              </>
                            ) : (
                              <>
                                <td>
                                  {row?._id?.owner ? (
                                    <a
                                      href={getExplorerLink(
                                        KROMA_CHAIN_ID,
                                        Web3.utils.toChecksumAddress(row?._id?.owner),
                                        'address',
                                      )}
                                      target="_blank"
                                    >
                                      {formatAddress(row?._id?.owner)}
                                    </a>
                                  ) : null}
                                </td>
                                <td>
                                  {formatNumber(row?.count, 0, 2)} {DEFAULT_TOKEN_USED.symbol}
                                </td>
                              </>
                            )}
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={999} className="text-center">
                          No data
                        </td>
                      </tr>
                    )}
                  </>
                ) : (
                  <tr>
                    <td colSpan={9999} className="text-white text-center">
                      Loading ...
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopEvent
