import React from 'react'
import BoxTab from './BoxTab'
import { useDragonsSired, useDragonsSold, useFetchDragonsSold, useTabSold } from '../../../store/report/hook'
import { useDispatch } from 'react-redux'
import { setTabSold } from '../../../store/report'
import ListedDragon from './ListedDragon'
import { useKaiPrice } from '../../../hooks/usePrice'
import SoldDragon from './SoldDragon'

const RecentSold = () => {
  useFetchDragonsSold()
  const dragonsSold = useDragonsSold()
  const dragonsSired = useDragonsSired()
  const dispatch = useDispatch()
  const tokenPrice = useKaiPrice()
  const tab = useTabSold()

  const soldDragons = tab === 0 ? dragonsSold : dragonsSired

  const onChangeTab = (value) => {
    if (value !== tab) {
      dispatch(setTabSold(value))
    }
  }

  return (
    <BoxTab title="Recently sold" tabActive={tab} tabs={['Dragon sold', 'Dragon sired']} onChangeTab={onChangeTab}>
      {soldDragons === undefined ? (
        <div className="flex justify-center py-10">
          <img src="/icon/loading.gif" width={45} />
        </div>
      ) : soldDragons?.length ? (
        soldDragons?.map((dragon, index) => (
          <SoldDragon key={index} dragon={dragon} tokenPrice={tokenPrice} isSired={tab === 1} />
        ))
      ) : (
        <p className="text-xl text-white py-10 text-center">No data</p>
      )}
    </BoxTab>
  )
}

export default RecentSold
