import React, { useEffect, useState } from 'react'
import InputSearch from 'components/Input/InputSearch'
import { FILTER_ITEM_STATUS, SORT_ITEM_FIELD } from 'constants/index'
import classnames from 'classnames'
import Dropdown from 'components/Dropdown/Dropdown'
import { useSearchData, useUpdateSearchItem } from 'store/search/hook'
import { useDispatch } from 'react-redux'
import { updateSearchData, setPageQuery } from 'store/search'
import useDebounce from 'hooks/useDebounce'
import FilterItemAttribute from 'components/Filter/FilterItemAttribute'
export default function ItemsFilterAction({
  filterLeft,
  ignoreBurn = false,
  ignorePrice = false, // show filter highest price or lowest price or not
  ignoreRangePrice = false, // show filter range price or not
  ignoreLevel = false, // show filter level or not
  ignoreFilterStatus = false, // show filter status or not
  showFilterItemAttribute = true, // show item attribute or not
  className,
  showSkillRarity, // show rarity of skill or not
  showEquipmentRarity, // show rarity of equipment or not
  showDragonClass, // show class of dragon or not
  ignoreSort = false, // show sort (tab status) or not
  ignoreBasicSkill, // show basic skill check box or not
}) {
  useUpdateSearchItem()
  const searchData = useSearchData()
  const dispatch = useDispatch()

  const [textSearch, setTextSearch] = useState(searchData?.textSearch)
  const textSearchDebounce = useDebounce(textSearch, 0)

  const updateFieldSearch = (field, value) => {
    dispatch(updateSearchData({ [field]: value }))
    dispatch(setPageQuery(1))
  }

  const onChangeText = (e) => {
    setTextSearch(e.target.value)
    dispatch(setPageQuery(1))
  }

  useEffect(() => {
    if (textSearchDebounce !== undefined) {
      updateFieldSearch('textSearch', textSearchDebounce)
    }
  }, [textSearchDebounce])

  useEffect(() => {
    setTextSearch(searchData?.textSearch)
  }, [])

  return (
    <div className={classnames('m-auto pt-2 sm:pt-4 max-w-screen-lg')}>
      <div className={classnames('sm:flex justify-between items-center mx-2.5 sm:mx-5', className)}>
        <InputSearch
          className="flex-1 sm:max-w-sm h-10 mt-2"
          placeholder="Search #..."
          classNameInput="w-full"
          value={searchData?.textSearch || ''}
          onChange={onChangeText}
        />
        <div className="block sm:flex items-center">
          <div className="flex mt-2">
            {showFilterItemAttribute && (
              <FilterItemAttribute
                className={classnames('flex-1 sm:flex-none mr-2 sm:mr-0 sm:ml-2.5', {
                  'block lg:hidden': filterLeft,
                })}
                classNameWrapperButton="w-full sm:w-auto"
                classNameMenuItem="popover-filter-dragons"
                showSkillRarity={showSkillRarity}
                showEquipmentRarity={showEquipmentRarity}
                ignoreRangePrice={ignoreRangePrice}
                ignoreLevel={ignoreLevel}
                showDragonClass={showDragonClass}
                ignoreBasicSkill={ignoreBasicSkill}
              />
            )}
            {!ignoreFilterStatus && (
              <Dropdown
                bsprefixmenu="p-0 text-sm-md sm:text-base mr-1 sm:mr-4"
                menu={searchData?.filterSale || FILTER_ITEM_STATUS.ALL}
                className="flex-1 sm:flex-none mr-2 sm:mr-0 sm:ml-2.5"
                classNameMenu="border-blue2 border-2 rounded-xl h-10"
                classNameLabel="text-sm-md2 sm:text-sm-md sm:mr-4 mr-8"
                classNameMenuItem={classnames('bg-primary text-white right-0 w-44')}
                classNameButtonToggle={'inline text-blue4 px-4 sm:px-2.5 mr-0 cursor-pointer'}
              >
                {Object.values(FILTER_ITEM_STATUS)
                  .filter((status) => (ignoreBurn ? status.label !== 'Burned' : true))
                  .map((item, index) => (
                    <p
                      key={index}
                      className="p-3 text-white cursor-pointer hover:bg-blue2"
                      onClick={() => updateFieldSearch('filterSale', item)}
                    >
                      {item.label}
                    </p>
                  ))}
              </Dropdown>
            )}
          </div>

          {!ignoreSort ? (
            <div className="flex mt-2">
              <Dropdown
                bsprefixmenu="p-0 text-sm sm:text-base mr-1 sm:mr-4"
                menu={searchData?.sort || SORT_ITEM_FIELD.LOWEST_ID}
                className="flex-1 sm:flex-none mr-2 sm:mr-0 sm:ml-2.5"
                classNameMenu="border-blue2 border-2 rounded-xl h-10"
                classNameLabel="text-sm-md2 sm:text-sm-md sm:mr-4"
                classNameMenuItem={classnames('bg-primary text-white right-0 w-44')}
                classNameButtonToggle={'inline text-blue4 px-4 sm:px-2.5 mr-0 cursor-pointer'}
              >
                {Object.values(SORT_ITEM_FIELD)
                  .filter((field) =>
                    ignorePrice ? field.label !== 'Lowest price' && field.label !== 'Highest price' : true,
                  )
                  .map((item, index) => (
                    <p
                      key={index}
                      className="p-3 text-white cursor-pointer hover:bg-blue2"
                      onClick={() => updateFieldSearch('sort', item)}
                    >
                      {item.label}
                    </p>
                  ))}
              </Dropdown>
              <div className="flex-1 sm:flex-none ml-2 sm:ml-0" />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
