import axiosClient from './axiosClient'

const itemsApi = {
  getItems: (params) => {
    const url = '/items'
    return axiosClient.get(url, { params })
  },
  getItem: (type, id) => {
    const url = `/items/${type}/${id}`
    return axiosClient.get(url)
  },
  getHistoriesItem: (type, id) => {
    const url = `/items/histories/${type}/${id}`
    return axiosClient.get(url)
  },
  migrateData: (type, id) => {
    const url = `/items/migrate/${type}/${id}`
    return axiosClient.get(url)
  },
  getUpgradeSuccessRate: () => {
    const url = '/items/upgrade-success-rate'
    return axiosClient.get(url)
  },
  getAllDragonSkills: (params) => {
    const url = '/dragons/skills'
    return axiosClient.get(url, { params })
  },
  getAllEffects: () => {
    const url = '/dragons/detail-effects'
    return axiosClient.get(url)
  },
  getItemsEquipments: (params) => {
    const url = '/items/equipments'
    return axiosClient.get(url, { params })
  },
}
export default itemsApi
