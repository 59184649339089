import React, { useEffect } from 'react'
import DragonAttribute from 'components/Filter/DragonAttribute'
import Container from 'components/Container/Container'
import Value from 'components/Value/Value'
import FilterAction from '../DragonCave/components/FilterAction'
import { useDragonCave } from 'store/dragonCave/hook'
import { useDispatch } from 'react-redux'
import ListView from 'components/ListView'
import { showToastError } from 'components/CustomToast/CustomToast'
import { useUpdateQuery } from 'store/search/hook'
import { formatQueryDragons, useFetchDragons } from 'store/dragons/hook'
import dragonsApi from 'api/dragonsApi'
import { useDflPrice, useKaiPrice } from 'hooks/usePrice'
import { setPageQuery, updateSearchData } from 'store/search'
import { fetchDragonsAsync } from 'store/dragons'
import { DEFAULT_LIMIT, FILTER_DRAGON_FOR_SALE } from 'constants/index'
import { formatQuery, useQuery } from 'utils'
import DragonCard from 'components/Card/DragonCardV3'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import withLayout from 'hoc/withLayout'

const Shops = ({}) => {
  useUpdateQuery()
  useFetchDragons()

  const { account } = useActiveWeb3React()
  const dispatch = useDispatch()
  const dragons = useDragonCave()
  const dflPrice = useDflPrice()
  const queryUrl = useQuery()
  const tokenPrice = useKaiPrice()

  const handleFavorites = async (id) => {
    try {
      await dragonsApi.tickFavorites(id, account)
      const page = dragons?.currentPage || 1
      dispatch(fetchDragonsAsync(formatQueryDragons({ page, rowPerPage: DEFAULT_LIMIT, ...queryUrl })))
    } catch (error) {
      console.log(error)
    }
  }

  const onChangePage = async (page) => {
    try {
      dispatch(setPageQuery(page + 1))
    } catch (error) {
      showToastError('Error', 'Some error occurred, please try again!')
    }
  }

  useEffect(() => {
    const queryStr = formatQuery(queryUrl)
    if (!queryStr) {
      dispatch(updateSearchData({ filterSale: FILTER_DRAGON_FOR_SALE.AUCTION }))
    }
  }, [])

  return (
    <div className="max-w-screen-2xl m-auto relative flex">
      <div className="hidden lg:block">
        <DragonAttribute className="w-72 bg-primary mt-20 pb-5 text-white" />
      </div>
      <Container className="pt-8 sm:pt-20 lg:pt-16 mt-2 pb-36">
        <div className="max-w-screen-lg m-auto">
          <div className="mx-2.5 sm:mx-5 2xl:mx-0 text-white">
            <div className="flex items-center text-white">
              <p className="mr-1 text-md sm:text-lg">Total dracoras: </p>
              {typeof dragons?.totalItems === 'number' ? (
                <Value className="text-2xl" value={dragons?.totalItems} decimals={0} />
              ) : (
                '--'
              )}
            </div>
          </div>
        </div>
        <FilterAction filterLeft />
        <ListView
          isBusy={dragons === undefined}
          className="min-height-list-save max-w-screen-lg m-auto px-2.5 sm:px-5 xl:px-0 mt-8"
          totalItems={dragons?.totalItems}
          pageCount={dragons?.totalPage}
          currentPage={dragons?.currentPage}
          fetchApi={onChangePage}
        >
          <div className="mx-0 sm:mx-0 xl:mx-5 2xl:mx-0">
            <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 -mx-2 gap-y-4">
              {dragons?.data?.map((item, index) => (
                <DragonCard
                  key={index}
                  dragon={item}
                  handleFavorites={handleFavorites}
                  tokenPrice={tokenPrice}
                  isShop
                  isLink
                  dflPrice={dflPrice}
                  to={`/dragon/${item.id}`}
                />
              ))}
            </div>
          </div>
        </ListView>
      </Container>
    </div>
  )
}

export default withLayout(Shops)
