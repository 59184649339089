import { useCallback } from 'react'
import useActiveWeb3React from './useActiveWeb3React'
import { useCoreContract, useItemContract } from './useContract'
import { transferEgg, transferItem } from '../utils/calls/transfer'

export const useTransfer = (poolAddress) => {
  const { account } = useActiveWeb3React()

  const contract = useCoreContract(poolAddress)

  const handleTransferEgg = useCallback(
    async (address, idDragon) => {
      const txHash = await transferEgg(contract, address, idDragon)
      return txHash
    },
    [poolAddress, account],
  )
  return { onTransfer: handleTransferEgg }
}

export const useTransferItem = (type, address) => {
  const { account } = useActiveWeb3React()
  const contract = useItemContract(type, address)

  const handleTransfer = useCallback(
    async (recipient, itemId) => {
      const txHash = await transferItem(contract, account, recipient, itemId)
      return txHash
    },
    [account, type, address],
  )

  return { onTransfer: handleTransfer }
}
