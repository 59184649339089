import React, { useState, useEffect } from 'react'
import withLayout from 'hoc/withLayout'
import Container from 'components/Container/Container'
import NavBack from 'components/Navigate/Back'
import ViewEquipment from './ViewEquipment'
import EquipmentDetailRight from './EquipmentDetailRight'
import { useFetchItem, useItem } from 'store/items/hook'
import { setItem } from 'store/items'
import { useDispatch } from 'react-redux'
import LoadingPage from 'components/Loader/LoadingPage'
import NotFoundData from 'components/NotFoundData'
import { fetchDetailItem } from 'store/items'
function EquipmentDetail() {
  useFetchItem()

  const dispatch = useDispatch()

  const item = useItem()

  useEffect(() => {
    return () => dispatch(setItem(undefined))
  }, [])

  const updateData = () => {
    try {
      dispatch(fetchDetailItem(item?.type, item?.nftId))
    } catch (err) {
      console.log(err)
    }
  }

  if (item === undefined) {
    return <LoadingPage />
  }

  if (item === null) {
    return <NotFoundData text="Item not found!" />
  }

  return (
    <Container className="md:grid md:grid-cols-2 mb-16">
      <div>
        <NavBack />
        <ViewEquipment item={item} updateData={updateData} />
      </div>
      <EquipmentDetailRight item={item} />
    </Container>
  )
}

export default withLayout(EquipmentDetail, null, '#0A1520')
