import { useDispatch, useSelector } from 'react-redux'
import useRefresh from '../../hooks/useRefresh'
import { useEffect } from 'react'
import {
  fetchBreedCostAsync,
  fetchBreedDataAsync,
  fetchCooldownAsync,
  fetchDragonMatronAsync,
  fetchDragonSireAsync,
  fetchUserDataAsync,
  setBreedData,
  setMatron,
  setSire,
} from './index'
import { useQuery } from 'utils'
import { AUCTION_TYPE } from 'constants/index'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'

export const useDragonSire = () => useSelector((state) => state.breed.sire)

export const useDragonMatron = () => useSelector((state) => state.breed.matron)

export const useBreedData = () => useSelector((state) => state.breed.breedData)

export const useBreedCost = () => useSelector((state) => state.breed.breedCost)

export const useCooldown = () => useSelector((state) => state.breed.cooldown)

export const useTabSelect = () => useSelector((state) => state.breed.tabSelect)

export const useListDragonsBreed = () => useSelector((state) => state.breed.dragons)

export const useUserDataBreed = () => useSelector((state) => state.breed.userData)

export const useFetchDragonsBreed = () => {
  const { father, mother } = useQuery()
  const { fastRefresh } = useRefresh()
  const dispatch = useDispatch()
  useEffect(() => {
    if (mother) {
      dispatch(fetchDragonMatronAsync(mother))
    } else {
      dispatch(setMatron(undefined))
    }
  }, [mother, fastRefresh])

  useEffect(() => {
    if (father) {
      dispatch(fetchDragonSireAsync(father))
    } else {
      dispatch(setSire(undefined))
    }
  }, [father, fastRefresh])

  useEffect(() => {
    return () => {
      dispatch(setMatron(undefined))
      dispatch(setSire(undefined))
    }
  }, [])
}

export const useFetchBreedData = () => {
  const { fastRefresh } = useRefresh()
  const matron = useDragonMatron()
  const sire = useDragonSire()
  const dispatch = useDispatch()

  useEffect(() => {
    if (sire?.id && matron?.id) {
      dispatch(fetchBreedDataAsync(matron?.id, sire?.id, sire?.sale === AUCTION_TYPE.SIRING))
    }
    if (!sire?.id || !matron?.id) {
      dispatch(setBreedData(undefined))
    }
  }, [sire?.id, matron?.id, fastRefresh])
}

export const useFetchBreedCost = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchBreedCostAsync())
  }, [])
}

export const useFetchCooldown = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCooldownAsync())
  }, [])
}

export const useFetchUserDataBreed = () => {
  const { account } = useActiveWeb3React()
  const { fastRefresh } = useRefresh()
  const dispatch = useDispatch()

  useEffect(() => {
    if (account) {
      dispatch(fetchUserDataAsync(account))
    }
  }, [dispatch, account, fastRefresh])
}
