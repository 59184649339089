import React, { useState, useEffect } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import Value from 'components/Value/Value'
import classnames from 'classnames'
import { formatNumber, getBalanceAmount, getBalanceNumber } from 'utils/formatBalance'
import ModalSendItem from 'components/Modal/ModalSendItem'
import ModalSellItem from 'components/Modal/ModalSellItem'
import Web3 from 'web3'
import { useKaiPrice } from 'hooks/usePrice'
import BigNumber from 'bignumber.js'
import { useTransferItem } from 'hooks/useTransfer'
import {
  ITEM_ADDRESS,
  MARKETPLACE_NFT_ADDRESS,
  TRAINING_ADDRESS,
  WEARABLE_ITEM_ADDRESS,
  WEARABLE_SKILL_ADDRESS,
} from 'config'
import { fetchDetailItem, fetchListSuccessRate, fetchDetailItemApproved } from 'store/items'
import { useListSuccessRate, useFetchUserDataDetailItem, useItemApproved } from 'store/items/hook'
import { useDispatch } from 'react-redux'
import { showToastSuccess, showToastError } from '../../../components/CustomToast/CustomToast'
import { useSellItem, useCancelSellItem } from 'hooks/useSell'
import { useBuyItem } from 'hooks/useBuyEgg'
import { ITEM_TYPES, TOKEN_USED_MARKETPLACE, NFT_POSITIONS, AUCTION_TYPE } from 'constants/index'
import { ShoppingCart, Gift, ShoppingBag, Crosshair, FastForward } from 'react-feather'
import Button from 'components/Button/Button'
import { useBalanceMainCoin } from 'hooks/useTokenBalance'
import Modal from 'components/Modal/ModalV2'
import ModalTitle from 'components/Modal/ModalTitleV2'
import ModalSelectDragonUseItem from 'components/Modal/ModalSelectDragonUseItem'
import ModalSelectItem from 'components/Modal/ModalSelectItem'
import { useBuffExpDragon, useItemDragon, useCancelUseEquipmentDragon } from 'hooks/useTrain'
import { useGetApproveItem, useApproveItem } from 'hooks/useApprove'
import ButtonGradient from 'components/Button/ButtonGradient'
import { useUpgradeItem } from 'hooks/useUpgrade'
import ExpCard from 'components/Card/ExpCard'
import ItemCardV2 from 'components/Card/ItemCardV2'
import SkillCardV2 from 'components/Card/SkillCardV2'
import dragonsApi from 'api/dragonsApi'
import { useHistory } from 'react-router-dom'
import itemsApi from 'api/itemsApi'
const ModalWarning = ({ open, toggle, price, balance }) => {
  return (
    <Modal isDark open={open} onClose={toggle}>
      <ModalTitle onClose={toggle}>Warning</ModalTitle>
      <div className="p-4">
        <div className="text-white text-center text-lg py-4">
          <h3 className="text-lg sm:text-xl">Your {TOKEN_USED_MARKETPLACE.symbol} balance is not enough</h3>
          <div className="mt-2">
            <div className="pt-2 text-white text-center text-md">
              {`Item price: `}
              <Value
                className="font-bold inline"
                value={price}
                unit={` ${TOKEN_USED_MARKETPLACE.symbol}`}
                decimals={2}
              />
            </div>
            <div className="pt-2 text-white text-center text-sm-md">
              ({`Your balance: `}
              <Value
                className="font-bold inline"
                value={balance}
                unit={` ${TOKEN_USED_MARKETPLACE.symbol}`}
                decimals={2}
              />
              )
            </div>
            {/* {typeof balance === 'number' && balance < price ? (
              <div className="text-center mb-3">
                <a
                  className="underline text-white text-sm-md"
                  href={`https://defily.io/#/swap?outputCurrency=${TOKEN_USED_MARKETPLACE.symbol}`}
                  target="_blank"
                >
                  Get {TOKEN_USED_MARKETPLACE.symbol} Token
                </a>
              </div>
            ) : null} */}
          </div>
        </div>
      </div>
    </Modal>
  )
}

const ModalWarningSelectDragon = ({ open, toggle, dragon, nftIdSkill, onConfirm, onCancel }) => {
  const history = useHistory()

  const [isOpen, setIsOpen] = useState(true)

  return (
    <Modal isDark open={open || isOpen} onClose={toggle || setIsOpen}>
      <ModalTitle onClose={toggle}>Warning</ModalTitle>
      <div className="px-10 py-5 text-white flex flex-col items-center">
        <p className="text-lg sm:text-xl mb-5">
          Your
          <span
            onClick={() => history.push(`/dragon/${dragon?.id}`)}
            className="cursor-pointer px-1 text-water font-bold"
          >
            dragon #{dragon?.id}
          </span>
          has used
          <span
            onClick={() => {
              history.push(`/items/skill-card/${nftIdSkill}`)
              onCancel()
            }}
            className="cursor-pointer px-1 text-water font-bold"
          >
            skill #{nftIdSkill}.
          </span>
        </p>
        <p className="text-md mb-5">If you use different skill, that skill will be burned.</p>
        <p className="text-md mb-3">Are you sure about that?</p>
        <div className="flex items-center">
          <ButtonGradient className="mx-5" onClick={() => onConfirm(dragon?.id)}>
            Yes
          </ButtonGradient>
          <ButtonGradient className="mx-5" onClick={onCancel}>
            No
          </ButtonGradient>
        </div>
      </div>
    </Modal>
  )
}

const ModalApproveContract = ({ open, toggle, onConfirm, isPending, title, item }) => {
  return (
    <Modal isDark open={open} onClose={toggle}>
      <ModalTitle onClose={toggle}>{title}</ModalTitle>

      <div className="flex justify-center sm:px-0 px-5">
        <div className="py-10 flex flex-col">
          <div className="mb-5">
            {item?.type === 'EXP_CARD' ? <ExpCard item={item} /> : null}
            {item?.type === 'EQUIPMENT' ? <ItemCardV2 className="sm:w-64 w-52" item={item} /> : null}
            {item?.type === 'SKILL_CARD' ? <SkillCardV2 className="sm:w-64 w-52" item={item} /> : null}
          </div>
          <ButtonGradient onClick={onConfirm} disabled={isPending} isLoading={isPending} className="whitespace-nowrap">
            Approve Contract
          </ButtonGradient>
        </div>
      </div>
    </Modal>
  )
}

const ModalWarningUpgrade = ({ open, toggle, onConfirm, onCancel, item }) => {
  const listSuccessRate = useListSuccessRate()
  const type = item?.type === 'SKILL_CARD' ? 'skill' : 'equipment'
  return (
    <Modal isDark open={open} onClose={toggle}>
      <ModalTitle onClose={toggle}>Warning</ModalTitle>
      <div className="px-10 py-5 text-white flex flex-col items-center">
        <p className="text-md sm:text-lg mb-5 text-center">
          Your {type} #{item?.nftId} is level {item?.level + 1}.{' '}
        </p>
        <p className="text-md sm:text-lg mb-5 text-center">
          If you want to upgrade your {type} to level {item?.level + 2}, upgrade success rate is{' '}
          {listSuccessRate?.[item?.level + 1]}%, the {type} you just selected will be burned. If the upgrade fail, level
          of your {type} won't be upgraded. Otherwise, level of your {type} will be upgrade to level {item?.level + 2}.
        </p>
        <p className="text-md mb-3">Do you want to continue ?</p>
        <div className="flex items-center">
          <ButtonGradient
            className="mx-5"
            onClick={() => {
              onConfirm()
              onCancel()
            }}
          >
            Yes
          </ButtonGradient>
          <ButtonGradient className="mx-5" onClick={onCancel}>
            Cancel
          </ButtonGradient>
        </div>
      </div>
    </Modal>
  )
}

const ItemAction = ({ item }) => {
  useFetchUserDataDetailItem()
  const itemApproved = useItemApproved()

  const APPROVE_CONTRACT_ADDRESS = {
    EXP_CARD: TRAINING_ADDRESS,
    EQUIPMENT: WEARABLE_ITEM_ADDRESS,
    SKILL_CARD: WEARABLE_SKILL_ADDRESS,
  }

  const { account } = useActiveWeb3React()
  const [openModalSend, setOpenModalSend] = useState(false)
  const [openModalSell, setOpenModalSell] = useState(false)
  const [pendingBuyItem, setPendingBuyItem] = useState(false)
  const [openModalWarning, setOpenModalWarning] = useState(false)
  const [openModalSelectDragon, setOpenModalSelectDragon] = useState(false)
  const [openModalSelectDragonUseEquipment, setOpenModalSelectDragonUseEquipment] = useState(false)
  const [openModalSelectDragonUseSkill, setOpenModalSelectDragonUseSkill] = useState(false)
  const [openModalSelectItem, setOpenModalSelectItem] = useState(false)
  const [pendingTx, setPendingTx] = useState(false)
  const [pendingUseItem, setPendingUseItem] = useState(false)
  const [pendingUpgradeItem, setPendingUpgradeItem] = useState(false)
  const [pendingCancelSell, setPendingCancelSell] = useState(false)
  const [pendingCancelUse, setPendingCancelUse] = useState(false)
  const [openModalWarningSelectDragon, setOpenModalWarningSelectDragon] = useState(false)
  const [dragonState, setDragonState] = useState()
  const [nftSkillIdDragonUsed, setNftSkillIdDragonUsed] = useState()
  const [openModalWarningUpgrade, setOpenModalWarningUpgrade] = useState(false)
  const [itemUpgradeId, setItemUpgradeId] = useState()

  const isApproveContract = account && itemApproved?.itemApproved === APPROVE_CONTRACT_ADDRESS?.[item?.type]

  const { onTransfer } = useTransferItem(item?.type, ITEM_ADDRESS[item?.type])

  const { onSell } = useSellItem(MARKETPLACE_NFT_ADDRESS)
  const { onCancelSell } = useCancelSellItem(MARKETPLACE_NFT_ADDRESS)
  const { onBuyItem } = useBuyItem(MARKETPLACE_NFT_ADDRESS)

  const { onUseExpItem } = useBuffExpDragon(TRAINING_ADDRESS)

  const { onUseItem } = useItemDragon(APPROVE_CONTRACT_ADDRESS?.[item?.type])
  const { onApproveItem } = useApproveItem(item?.type, ITEM_ADDRESS[item?.type])
  const { onUpgradeItem } = useUpgradeItem(item?.type, ITEM_ADDRESS[item?.type])
  const { onCancelUseEquipment } = useCancelUseEquipmentDragon(WEARABLE_ITEM_ADDRESS)
  const balance = getBalanceNumber(useBalanceMainCoin())
  const typeName = item?.type === 'EXP_CARD' ? 'exp card' : item?.type === 'SKILL_CARD' ? 'skill card' : 'equipment'
  const dispatch = useDispatch()
  const isOwner =
    account && item?.owner && Web3.utils.toChecksumAddress(account) === Web3.utils.toChecksumAddress(item?.owner)

  const updateItem = () => {
    try {
      dispatch(fetchDetailItem(item?.type, item?.nftId))
    } catch (error) {
      console.log(error)
    }
  }

  const onSendItem = async (address) => {
    try {
      const addressSend = Web3.utils.toChecksumAddress(address)
      await onTransfer(addressSend, item?.nftId)
      updateItem()
      showToastSuccess('Success', `Send ${typeName} successfully!`)
    } catch {
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
    }
  }

  const handleApproveContract = async () => {
    try {
      setPendingTx(true)
      await onApproveItem(APPROVE_CONTRACT_ADDRESS[item?.type], item?.nftId)
      dispatch(fetchDetailItemApproved(item?.type, item?.nftId))
      showToastSuccess('Contract Enabled', 'Approve contract successfully!')
      setPendingTx(false)
    } catch {
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
      setPendingTx(false)
    }
  }

  const onSellItem = async (amount) => {
    try {
      await onSell(ITEM_ADDRESS[item?.type], item?.nftId, amount, 18)
      updateItem()
      showToastSuccess('Success', `Sell ${typeName} successfully!`)
      setOpenModalSell(false)
    } catch {
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
    }
  }

  const onCancelSellItem = async (listingId) => {
    setPendingCancelSell(true)
    try {
      await onCancelSell(listingId)
      setPendingCancelSell(false)
      updateItem()
      showToastSuccess('Success', `Cancel sell ${typeName} successfully!`)
    } catch {
      setPendingCancelSell(false)
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
    }
  }

  const handleBuyItem = async () => {
    if (balance < item?.price) {
      setOpenModalWarning(true)
      return
    }
    try {
      setPendingBuyItem(true)
      await onBuyItem(item?.listingId, item?.price, 18)
      updateItem()
      setPendingBuyItem(false)
      showToastSuccess('Success', `Congratulation! You have bought ${typeName} successfully!`)
    } catch {
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
      setPendingBuyItem(false)
    }
  }

  const handleSelectDragonUseExpCard = async (dragonId) => {
    setOpenModalSelectDragon(false)
    setPendingUseItem(true)
    try {
      await onUseExpItem(dragonId, item?.nftId)
      updateItem()
      setPendingUseItem(false)
      showToastSuccess('Success', `Congratulation! Your dracora's exp was upgraded!`)
    } catch {
      setPendingUseItem(false)
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
    }
  }

  const handleSelectDragonUseEquipment = async (dragonId) => {
    setOpenModalSelectDragonUseEquipment(false)
    setPendingUseItem(true)
    try {
      await onUseItem(item?.nftId, dragonId)
      updateItem()
      setPendingUseItem(false)
      showToastSuccess('Success', `Use equipment successfully!`)
    } catch {
      setPendingUseItem(false)
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
    }
  }

  const handleUseSkill = async (dragonId) => {
    setOpenModalSelectDragonUseSkill(false)
    setOpenModalWarningSelectDragon(false)
    setPendingUseItem(true)
    try {
      await onUseItem(item?.nftId, dragonId)
      updateItem()
      setPendingUseItem(false)
      showToastSuccess('Success', `Use skill successfully!`)
    } catch {
      setPendingUseItem(false)
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
    }
  }

  const handleSelectDragonUseSkill = async (dragonId) => {
    const response = await dragonsApi.getDragon(dragonId)
    setDragonState(response)
    let isUsedSkill

    response?.skills?.map((skill) => {
      if ((skill?.skillNft || skill?.skillDefault) && item?.skill?.part === skill?.part) {
        isUsedSkill = true
        setNftSkillIdDragonUsed(skill?.nftId || skill?.skillNft)
      }
    })

    if (isUsedSkill) {
      setOpenModalSelectDragonUseSkill(false)
      setOpenModalWarningSelectDragon(true)
    } else {
      handleUseSkill(dragonId)
    }
  }

  const handleUpgradeItem = async (itemId) => {
    setPendingUpgradeItem(true)
    try {
      await onUpgradeItem(item?.nftId, itemId)
      updateItem()
      setPendingUpgradeItem(false)
      const histories = await itemsApi.getHistoriesItem(item?.type, item?.nftId)
      if (histories?.data?.[0]?.type === 'UPGRADED') {
        showToastSuccess('Success', `Congratulation! Upgrade ${typeName} successfully!`)
      } else if (histories?.data?.[0]?.type === 'UPGRADE_FAILED') {
        showToastError('Failed', `Failed to upgrade ${typeName}`)
      }
    } catch {
      setPendingUpgradeItem(false)
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
    }
  }

  const handleSelectItemUpgrade = (itemId) => {
    setOpenModalSelectItem(false)
    setOpenModalWarningUpgrade(true)
    setItemUpgradeId(itemId)
    dispatch(fetchListSuccessRate())
  }
  const onCancelUseItem = async () => {
    setPendingCancelUse(true)
    try {
      await onCancelUseEquipment(NFT_POSITIONS?.[item?.equipment?.part.toUpperCase()], item?.dragon)
      updateItem()
      setPendingCancelUse(false)
      showToastSuccess('Success', `Cancel use equipment successfully!`)
    } catch {
      setPendingCancelUse(false)
      showToastError('Canceled', 'Please try again, your transaction cannot be completed!')
    }
  }
  const isSelling = item?.status === 'SELLING'
  const isUsing = item?.status === 'USED'
  const isUseSkill = item?.status === 'USED' && item?.type === ITEM_TYPES.SKILL_CARD
  const price = getBalanceAmount(item?.price, 0)
  const tokenPrice = useKaiPrice()
  const priceUsd = price ? new BigNumber(price).times(tokenPrice).toNumber() : 0

  const messageButton = () => {
    if (
      item?.status === 'USED' &&
      (item?.dragonSaleStatus === AUCTION_TYPE.AUCTION || item?.dragonSaleStatus === AUCTION_TYPE.SIRING)
    ) {
      const dragonStatus = item?.dragonSaleStatus === AUCTION_TYPE.AUCTION ? 'Sale' : 'Sire'
      return `The dracora using this equipment is on ${dragonStatus}`
    }
  }

  return (
    <>
      {isSelling ? (
        <>
          <div className="flex items-center md:justify-start justify-center">
            <div className="font-bold text-2xl mr-8 flex items-center">
              {typeof item?.price === 'number' ? <Value className="" value={item?.price} decimals={0} /> : '--'}
              <span className="ml-1">{TOKEN_USED_MARKETPLACE.symbol}</span>
            </div>

            <div className="text-white text-md rounded-xl bg-blue2 px-4 py-2 cursor-pointer">For sale</div>
          </div>

          <p className="opacity-50 text-lg md:text-left text-center md:mr-0 mr-32">~{formatNumber(priceUsd, 2, 3)}$</p>
        </>
      ) : null}

      {!isOwner && isSelling && account && (
        <Button
          outline
          disabled={pendingBuyItem}
          isLoading={pendingBuyItem}
          className="mt-4 text-md py-1.5 px-7"
          onClick={handleBuyItem}
        >
          <ShoppingBag className="mr-2" size={17} />
          Buy
        </Button>
      )}

      {isOwner ? (
        <>
          {item?.type !== ITEM_TYPES.EXP_CARD && !isSelling ? (
            <Button
              outline
              className="w-full sm:max-w-lg mt-8 flex items-center justify-center py-1.5"
              onClick={() => setOpenModalSelectItem(true)}
              isLoading={pendingUpgradeItem}
              disabled={pendingUpgradeItem}
            >
              <FastForward size={17} className="mr-2 -rotate-90" />
              Upgrade
            </Button>
          ) : null}
          <div
            className={classnames(
              'grid lg:grid-cols-3 md:grid-cols-2 grid-cols-2 sm:grid-cols-3 gap-x-2 gap-y-2 mt-2 sm:max-w-lg text-md',
              {
                'mt-10': item?.type === ITEM_TYPES.EXP_CARD,
              },
            )}
          >
            {!isUseSkill ? (
              <>
                {!isSelling ? (
                  <>
                    {item?.type === ITEM_TYPES.EXP_CARD ? (
                      <Button
                        outline
                        className={classnames('text-md py-1.5 whitespace-nowrap')}
                        onClick={() => setOpenModalSelectDragon(true)}
                        disabled={isSelling || pendingUseItem}
                        isLoading={pendingUseItem}
                      >
                        <Crosshair size={17} className="mr-2" />
                        Use
                      </Button>
                    ) : null}

                    {item?.type === ITEM_TYPES.EQUIPMENT ? (
                      <Button
                        outline
                        className={classnames('text-md py-1.5 whitespace-nowrap')}
                        onClick={() => (!isUsing ? setOpenModalSelectDragonUseEquipment(true) : onCancelUseItem())}
                        isLoading={pendingUseItem || pendingCancelUse}
                        disabled={
                          isSelling ||
                          pendingCancelUse ||
                          pendingUseItem ||
                          (item?.status === 'USED' &&
                            (item?.dragonSaleStatus === AUCTION_TYPE.AUCTION ||
                              item?.dragonSaleStatus === AUCTION_TYPE.SIRING))
                        }
                        msgDisable={messageButton()}
                      >
                        {!isUsing && <Crosshair size={17} className="mr-2" />}
                        {isUsing ? 'Cancel using' : 'Use'}
                      </Button>
                    ) : null}
                    {item?.type === ITEM_TYPES.SKILL_CARD ? (
                      <Button
                        outline
                        className={classnames('text-md py-1.5 whitespace-nowrap')}
                        onClick={() => setOpenModalSelectDragonUseSkill(true)}
                        isLoading={pendingUseItem}
                        disabled={isSelling || pendingUseItem}
                      >
                        <Crosshair size={17} className="mr-2" />
                        Use
                      </Button>
                    ) : null}
                  </>
                ) : null}

                <Button
                  outline
                  className={classnames('text-md py-1.5 whitespace-nowrap')}
                  onClick={() => {
                    !isSelling ? setOpenModalSell(!openModalSell) : onCancelSellItem(item?.listingId)
                  }}
                  disabled={pendingCancelSell || isUsing}
                  isLoading={pendingCancelSell}
                >
                  {!isSelling && <ShoppingCart size={17} className="mr-2" />}
                  <span>{isSelling ? 'Cancel selling' : 'Sell'}</span>
                </Button>

                {!isSelling ? (
                  <Button
                    outline
                    className={classnames('text-md py-1.5')}
                    onClick={() => setOpenModalSend(!openModalSend)}
                    disabled={isUsing}
                  >
                    <Gift size={17} className="mr-2" />
                    Send
                  </Button>
                ) : null}
              </>
            ) : null}
            {openModalSend && (
              <ModalSendItem
                open={openModalSend}
                toggle={() => setOpenModalSend(!openModalSend)}
                item={item}
                onConfirm={onSendItem}
              />
            )}

            {openModalSell && (
              <ModalSellItem
                open={openModalSell}
                toggle={() => setOpenModalSell(!openModalSell)}
                onSubmit={onSellItem}
              />
            )}

            {isApproveContract && openModalSelectDragon && (
              <ModalSelectDragonUseItem
                open={openModalSelectDragon}
                toggle={() => setOpenModalSelectDragon(!openModalSelectDragon)}
                onSelect={handleSelectDragonUseExpCard}
              />
            )}

            {isApproveContract && openModalSelectDragonUseEquipment && (
              <ModalSelectDragonUseItem
                open={openModalSelectDragonUseEquipment}
                toggle={() => setOpenModalSelectDragonUseEquipment(!openModalSelectDragonUseEquipment)}
                onSelect={handleSelectDragonUseEquipment}
                item={item}
              />
            )}

            {isApproveContract && openModalSelectDragonUseSkill && (
              <ModalSelectDragonUseItem
                open={openModalSelectDragonUseSkill}
                toggle={() => setOpenModalSelectDragonUseSkill(!openModalSelectDragonUseSkill)}
                onSelect={handleSelectDragonUseSkill}
                item={item}
              />
            )}

            {!isApproveContract && openModalSelectDragon && (
              <ModalApproveContract
                open={openModalSelectDragon}
                toggle={() => setOpenModalSelectDragon(!openModalSelectDragon)}
                onConfirm={handleApproveContract}
                isPending={pendingTx || !itemApproved?.userDataLoaded}
                title="Use Exp"
                item={item}
              />
            )}

            {!isApproveContract && openModalSelectDragonUseEquipment && (
              <ModalApproveContract
                open={openModalSelectDragonUseEquipment}
                toggle={() => setOpenModalSelectDragonUseEquipment(!openModalSelectDragonUseEquipment)}
                onConfirm={handleApproveContract}
                isPending={pendingTx || !itemApproved?.userDataLoaded}
                title="Use Equipment"
                item={item}
              />
            )}

            {!isApproveContract && openModalSelectDragonUseSkill && (
              <ModalApproveContract
                open={openModalSelectDragonUseSkill}
                toggle={() => setOpenModalSelectDragonUseSkill(!openModalSelectDragonUseSkill)}
                onConfirm={handleApproveContract}
                isPending={pendingTx || !itemApproved?.userDataLoaded}
                title="Use Skill"
                item={item}
              />
            )}

            {openModalSelectItem && (
              <ModalSelectItem
                open={openModalSelectItem}
                toggle={() => setOpenModalSelectItem(!openModalSelectItem)}
                onSelect={handleSelectItemUpgrade}
                item={item}
              />
            )}

            {openModalWarningSelectDragon && (
              <ModalWarningSelectDragon
                open={openModalWarningSelectDragon}
                toggle={() => setOpenModalWarningSelectDragon(!openModalWarningSelectDragon)}
                dragon={dragonState}
                nftIdSkill={nftSkillIdDragonUsed}
                onConfirm={handleUseSkill}
                onCancel={() => setOpenModalWarningSelectDragon(false)}
              />
            )}

            {openModalWarningUpgrade && (
              <ModalWarningUpgrade
                open={openModalWarningUpgrade}
                toggle={() => setOpenModalWarningUpgrade(!openModalWarningUpgrade)}
                onConfirm={() => handleUpgradeItem(itemUpgradeId)}
                onCancel={() => setOpenModalWarningUpgrade(false)}
                item={item}
              />
            )}
          </div>
        </>
      ) : null}
      {openModalWarning && (
        <ModalWarning
          open={openModalWarning}
          toggle={() => setOpenModalWarning(!openModalWarning)}
          balance={balance}
          price={item?.price}
        />
      )}
    </>
  )
}

export default ItemAction
