import React, { useState } from 'react'
import Card from 'components/Card/Card'
import { formatAddress } from 'utils/addressHelpers'
import { formatDDMMYYYYHHmm } from 'utils/formatDateTime'
import { formatNumber } from 'utils/formatBalance'
import { ITEM_HISTORY_BG_COLOR, TOKEN_USED_MARKETPLACE, ITEM_STATUS, KROMA_CHAIN_ID } from 'constants/index'
import Button from 'components/Button/Button'
import { ChevronDown } from 'react-feather'
import { getExplorerLink } from 'utils/explorer'
import Web3 from 'web3'
import { ChainId } from 'defily-v2-sdk'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
const AMOUNT_ROWS = 5

export default function ItemHistories({ histories }) {
  const { type } = useParams()
  const history = useHistory()
  const [rows, setRows] = useState(AMOUNT_ROWS)
  const showMore = () => {
    setRows((prevState) => prevState + AMOUNT_ROWS)
  }

  if (!histories?.length) return null

  return (
    <>
      <h1 className="mt-8 text-2xl font-bold text-white mb-4">Histories</h1>
      <Card className="px-4 md:px-8 py-4 md:py-8 text-white sm:max-w-lg">
        <table className="w-full">
          <thead>
            <tr className="text-left">
              <th className="px-1 font-bold text-lg">Address</th>
              <th className="px-1 font-bold text-lg">Type</th>
              <th className="px-1 font-bold text-lg">Detail</th>
            </tr>
          </thead>
          <tbody>
            {histories.map((item, index) => {
              if (index >= rows) return null
              return (
                <tr key={index}>
                  <td className="py-2 px-1">
                    {item?.from && (
                      <div>
                        <span className="text-sm-md block lg:inline text-white mr-1">From:</span>
                        <a
                          className="opacity-50 text-sm-md"
                          href={getExplorerLink(KROMA_CHAIN_ID, Web3.utils.toChecksumAddress(item?.from), 'address')}
                          target="_blank"
                        >
                          {formatAddress(item?.from)}
                        </a>
                      </div>
                    )}

                    {item?.to && (
                      <div>
                        <span className="text-sm-md block lg:inline text-blue4 mr-1">To:</span>
                        <a
                          className="opacity-50 text-sm-md"
                          href={getExplorerLink(KROMA_CHAIN_ID, Web3.utils.toChecksumAddress(item?.to), 'address')}
                          target="_blank"
                        >
                          {formatAddress(item?.to)}
                        </a>
                      </div>
                    )}
                  </td>
                  <td className="py-2 px-1">
                    <div className="flex items-center w-full h-full">
                      <span
                        style={{
                          background: ITEM_HISTORY_BG_COLOR[item?.type?.toUpperCase()],
                        }}
                        className="text-xs px-2.5 py-1 rounded-xl text-white"
                      >
                        {ITEM_STATUS?.[item?.type]}
                      </span>
                    </div>
                  </td>
                  <td className="py-2 px-1">
                    {item?.price ? (
                      <p>
                        <span className="text-sm-md block lg:inline text-white">Price:</span>{' '}
                        <span className="text-orange85">
                          {formatNumber(Number(item.price), 0, 8)}{' '}
                          <span className="text-sm">{TOKEN_USED_MARKETPLACE.symbol}</span>
                        </span>
                      </p>
                    ) : null}
                    {item?.burnedItem ? (
                      <div
                        onClick={() => history.push(`/items/${type}/${item?.burnedItem}`)}
                        className="cursor-pointer"
                      >
                        <span className="text-sm-md block lg:inline text-white">Item Burned:</span>{' '}
                        <span className="text-orange85 ml-1 text-md">{item?.burnedItem}</span>
                      </div>
                    ) : null}
                    {item?.type === 'USED' ? (
                      <div onClick={() => history.push(`/dragon/${item?.dragon}`)} className="cursor-pointer">
                        <span className="text-sm-md block lg:inline text-white">Dragon:</span>{' '}
                        <span className="text-orange85">{item?.dragon}</span>
                      </div>
                    ) : null}
                    <p className="opacity-50 text-sm-md">{formatDDMMYYYYHHmm(item?.createdAt)}</p>
                    {item?.txHash ? (
                      <a
                        className="underline text-sm text-blue4"
                        href={getExplorerLink(KROMA_CHAIN_ID, item?.txHash, 'transaction')}
                        target="_blank"
                      >
                        View Tx hash
                      </a>
                    ) : null}
                  </td>
                </tr>
              )
            })}
            {histories?.length > rows ? (
              <tr>
                <td colSpan={999}>
                  <div className="flex justify-center pt-5">
                    <Button outline size="xs" className="px-8 text-sm-md h-8" onClick={showMore}>
                      View more ({histories?.length - rows})
                      <ChevronDown className="ml-2" />
                    </Button>
                  </div>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </Card>
    </>
  )
}
