import React, { useState } from 'react'
import Button from 'components/Button/Button'
import { RefreshCw } from 'react-feather'
import itemsApi from 'api/itemsApi'
import classnames from 'classnames'
import { showToastSuccess } from 'components/CustomToast/CustomToast'

export default function ButtonRefresh({ item, updateData }) {
  const [pendingRequest, setPendingRequest] = useState(false)

  const handleRefresh = async () => {
    try {
      setPendingRequest(true)
      await itemsApi.migrateData(item?.type, item?.nftId)
      await updateData()
      showToastSuccess('Success', 'Update data successfully!')
      setPendingRequest(false)
    } catch (error) {
      setPendingRequest(false)
    }
  }

  return (
    <>
      <Button
        className="text-sm-md"
        outline
        classNameToolTip="w-64 left-0"
        disabled={pendingRequest}
        msgHover="Refresh item information from Kardiachain"
        onClick={handleRefresh}
      >
        <div
          className={classnames({
            'icon-refresh-animate': pendingRequest,
          })}
        >
          <RefreshCw size={16} />
        </div>

        <span className="pl-2">Refresh</span>
      </Button>
    </>
  )
}
