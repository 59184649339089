import React from 'react'
import { Filter } from 'react-feather'
import Popover from '../Popover'
import classnames from 'classnames'
import ItemAttribute from './ItemAttribute'

const FilterItemAttribute = ({
  classNameMenuItem,
  className,
  classNameButtonToggle,
  classNameWrapperButton,
  showSkillRarity,
  showEquipmentRarity,
  ignoreLevel,
  ignoreRangePrice,
  showDragonClass,
  ignoreBasicSkill,
}) => {
  const buttonToggle = (
    <span className="text-sm-md mx-0.5 flex justify-center items-center">
      Filter
      <Filter className="ml-8 sm:ml-4 inline-block" size={15} />
    </span>
  )
  return (
    <div className={className}>
      <Popover
        className={classNameWrapperButton}
        bsprefixmenu="p-0 text-sm-md sm:text-base mr-1 sm:mr-4"
        classNameMenuItem={classnames('bg-primary text-white right-0 w-72', classNameMenuItem)}
        buttonChild={buttonToggle}
        classNameButtonToggle={classnames(
          'inline text-blue4 border-blue2 border-2 rounded-xl px-4 sm:px-2.5 mr-0 cursor-pointer',
          classNameButtonToggle,
        )}
      >
        <ItemAttribute
          isResetWhenChangeTab={false}
          showSkillRarity={showSkillRarity}
          showEquipmentRarity={showEquipmentRarity}
          ignoreRangePrice={ignoreRangePrice}
          ignoreLevel={ignoreLevel}
          showDragonClass={showDragonClass}
          ignoreBasicSkill={ignoreBasicSkill}
        />
      </Popover>
    </div>
  )
}

export default FilterItemAttribute
