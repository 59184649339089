import React, { useEffect, useState } from 'react'
import Button from 'components/Button/Button'
import ModalFightHistory from './modals/ModalFightHistory'

const ButtonHistory = ({ className, monsterId, owner, myOwner, dragonId, type }) => {
  const [openModalHistory, setOpenModalHistory] = useState(false)

  const onOpenModal = (e) => {
    setOpenModalHistory(true)
    e.preventDefault()
  }

  useEffect(() => {
    setOpenModalHistory(false)
  }, [monsterId, dragonId])

  return (
    <>
      <Button outline className={className} onClick={onOpenModal}>
        Fight history
      </Button>

      {openModalHistory ? (
        <ModalFightHistory
          open={openModalHistory}
          toggle={() => setOpenModalHistory(false)}
          monsterId={monsterId}
          owner={owner}
          myOwner={myOwner}
          dragonId={dragonId}
          type={type}
        />
      ) : null}
    </>
  )
}

export default ButtonHistory
