import Card from 'components/Card/Card'
import ItemHistories from '../ItemHistories'
import Value from 'components/Value/Value'
import ItemAction from '../ItemAction'
import { DRAGON_BODY_PART, DRAGON_STATS, RARITY_ITEM } from 'constants/index'
import { useHistory } from 'react-router-dom'

export default function EquipmentDetailRight({ item }) {
  const bodyPart = item?.equipment?.part
  const stat = item?.equipment?.stat
  const history = useHistory()
  return (
    <div className="pt-16 md:mx-0 mx-auto px-4">
      <ItemAction item={item} />
      {item?.status !== 'BURNED' ? (
        <>
          <h1 className="mt-8 text-2xl font-bold text-white mb-4">About</h1>
          <Card className="px-4 md:px-8 py-4 md:py-8 text-white sm:max-w-lg">
            <p className="font-bold text-lg text-white50">Owner</p>
            <p className="text-sm sm:text-sm-md text-white50 my-2 break-words">(Kardia: {item?.owner})</p>
            {item?.dragon && (
              <div
                className="font-bold text-md text-blue4 cursor-pointer"
                onClick={() => history.push(`/dragon/${item?.dragon}`)}
              >
                Dracora: #{item?.dragon}
              </div>
            )}
          </Card>
        </>
      ) : null}

      <h1 className="mt-8 text-2xl font-bold text-white mb-4">Description</h1>
      <Card className="px-4 md:px-8 py-4 md:py-8 text-white sm:max-w-lg">
        <div className="flex items-center">
          <div className="w-10 h-10 flex justify-center items-center">
            <img src={DRAGON_BODY_PART[bodyPart]?.image} className="max-w-full max-h-full" />
          </div>
          <p className="text-md ml-4">
            <span className="font-bold text-lg mr-3">{DRAGON_BODY_PART[bodyPart]?.display}</span>
            <span className="text-md text-white50">(Body Part Uses)</span>
          </p>
        </div>
        <div className="flex flex-start mt-4 flex-wrap">
          <div className="flex flex-col">
            <p className="text-white50 font-bold text-md">{DRAGON_STATS[stat]?.display}</p>
            <div className="flex items-center mt-2">
              <img src={DRAGON_STATS[stat]?.icon} alt="icon" width={20} height={20} />
              <div className="text-xl ml-2 flex items-center">
                <span>+</span>

                {typeof item?.equipment?.point === 'number' ? (
                  <Value className="" value={item?.equipment?.point} decimals={0} />
                ) : (
                  '--'
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col ml-8">
            <p className="text-white50 font-bold text-md">Rarity</p>
            <p
              style={{
                color: RARITY_ITEM?.[item?.equipment?.rarity]?.color,
              }}
              className="text-xl mt-2 font-bold"
            >
              {RARITY_ITEM?.[item?.equipment?.rarity]?.label}
            </p>
          </div>
        </div>
      </Card>

      {item?.histories && <ItemHistories histories={item?.histories?.data} />}
    </div>
  )
}
