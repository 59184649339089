import React from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'

const InputNumber = ({ value, onChange, disabledIncrease, disabledDecrease, max }) => {
  const handleIncrease = () => {
    if (max && Math.floor(value) + 1 >= max) return

    if (!disabledIncrease) {
      if (!Number(value)) {
        onChange(1)
      } else {
        onChange(Math.floor(value) + 1)
      }
    }
  }

  const handleDecrease = () => {
    if (!disabledDecrease) {
      if (Number(value)) {
        onChange(value - 1 > 0 ? value - 1 : 0)
      }
    }
  }

  return (
    <div className="inline-flex border-2 border-blue2 rounded-xl h-10 overflow-hidden">
      <input
        type="number"
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
        className="bg-transparent custom-input-number text-right text-sm-md"
      />
      <div className="flex flex-col border-l-2 border-blue2">
        <button
          className="flex-1 px-1 border-b-1 border-blue2 cursor-pointer button-change-input-number"
          onClick={handleIncrease}
          disabled={disabledIncrease}
        >
          <ChevronUp size={15} />
        </button>
        <button
          className="flex-1 px-1 border-t-1 border-blue2 cursor-pointer button-change-input-number"
          onClick={handleDecrease}
          disabled={disabledDecrease}
        >
          <ChevronDown size={15} />
        </button>
      </div>
    </div>
  )
}

export default InputNumber
