import React from 'react'
import Modal from './ModalV2'
import ModalTitle from './ModalTitleV2'
import ButtonGradient from '../Button/ButtonGradient'
import Value from '../Value/Value'
import { DEFAULT_TOKEN_USED } from '../../constants'
import tokens from '../../constants/tokens'

const ModalEstimateBirthCost = ({ open, toggle, dragonBalance, tokenUsed, onSubmit }) => {
  return (
    <Modal isDark open={open} onClose={toggle}>
      <ModalTitle onClose={toggle}>Give birth</ModalTitle>
      <div className="p-4">
        <div className="py-5 text-white text-center">
          You have to pay{' '}
          <Value className="inline font-bold" value={tokenUsed} unit={` ${DEFAULT_TOKEN_USED.symbol}`} decimals={2} />{' '}
          to give birth the dracora
        </div>
        <div className="py-2 text-white text-center text-sm-md">
          ({`Your balance: `}
          <Value className="font-bold inline" value={dragonBalance} unit={` ${DEFAULT_TOKEN_USED.symbol}`} />)
        </div>
        {typeof dragonBalance === 'number' && dragonBalance < tokenUsed ? (
          <div className="text-center mb-3">
            <p className="text-orange85 text-center text-sm-md">
              Your {DEFAULT_TOKEN_USED.symbol} balance is not enough
            </p>
            <a
              className="underline text-white text-sm-md"
              href={`https://defily.io/#/swap?outputCurrency=${tokens.dragon.address}`}
              target="_blank"
            >
              Get DRACORA Token
            </a>
          </div>
        ) : null}
        <div className="flex justify-center">
          <ButtonGradient
            noneBorder
            disabled={dragonBalance < tokenUsed}
            rounded={false}
            onClick={onSubmit}
            className="mx-1"
          >
            Confirm
          </ButtonGradient>
        </div>
      </div>
    </Modal>
  )
}

export default ModalEstimateBirthCost
