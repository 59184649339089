// Multichain Explorer
import { KROMA_CHAIN_ID, KROMA_SEPOLIA_CHAIN_ID } from 'constants/index'
import { ChainId } from 'defily-v2-sdk'

const builders = {
  etherscan: (chainName: string, data: string, type: 'transaction' | 'token' | 'address' | 'block') => {
    const prefix = `https://${chainName ? `${chainName}.` : ''}etherscan.io`
    switch (type) {
      case 'transaction':
        return `${prefix}/tx/${data}`
      default:
        return `${prefix}/${type}/${data}`
    }
  },
  bscscan: (chainName: string, data: string, type: 'transaction' | 'token' | 'address' | 'block') => {
    const prefix = `https://${chainName ? `${chainName}.` : ''}bscscan.com`
    switch (type) {
      case 'transaction':
        return `${prefix}/tx/${data}`
      default:
        return `${prefix}/${type}/${data}`
    }
  },

  kaiscan: (chainName: string, data: string, type: 'transaction' | 'token' | 'address' | 'block') => {
    const prefix = `https://explorer.kardiachain.io`
    switch (type) {
      case 'transaction':
        return `${prefix}/tx/${data}`
      default:
        return `${prefix}/${type}/${data}`
    }
  },

  kromascan: (chainName: string, data: string, type: 'transaction' | 'token' | 'address' | 'block') => {
    const prefix = `https://kromascan.com`
    switch (type) {
      case 'transaction':
        return `${prefix}/tx/${data}`
      case 'token':
        return `${prefix}/tokens/${data}`
      case 'address':
        return `${prefix}/address/${data}`
      case 'block':
        return `${prefix}/block/${data}`
      default:
        return `${prefix}/${type}/${data}`
    }
  },

  kromaSepoliascan: (chainName: string, data: string, type: 'transaction' | 'token' | 'address' | 'block') => {
    const prefix = `https://sepolia.kromascan.com/`
    switch (type) {
      case 'transaction':
        return `${prefix}/tx/${data}`
      case 'token':
        return `${prefix}/tokens/${data}`
      case 'address':
        return `${prefix}/address/${data}`
      case 'block':
        return `${prefix}/block/${data}`
      default:
        return `${prefix}/${type}/${data}`
    }
  },

  matic: (chainName: string, data: string, type: 'transaction' | 'token' | 'address' | 'block') => {
    const prefix = 'https://polygonscan.com'
    switch (type) {
      case 'transaction':
        return `${prefix}/tx/${data}`
      case 'token':
        return `${prefix}/tokens/${data}`
      default:
        return `${prefix}/${type}/${data}`
    }
  },

  tomo: (chainName: string, data: string, type: 'transaction' | 'token' | 'address' | 'block') => {
    const prefix = 'https://scan.tomochain.com'
    switch (type) {
      case 'transaction':
        return `${prefix}/tx/${data}`
      case 'token':
        return `${prefix}/tokens/${data}`
      default:
        return `${prefix}/${type}/${data}`
    }
  },
}

const chains = {
  [KROMA_CHAIN_ID]: {
    chainName: '',
    builder: builders.kromascan,
  },
  [KROMA_SEPOLIA_CHAIN_ID]: {
    chainName: '',
    builder: builders.kromaSepoliascan,
  },
  [ChainId.KAI]: {
    chainName: '',
    builder: builders.kaiscan,
  },
  [ChainId.MAINNET]: {
    chainName: '',
    builder: builders.etherscan,
  },
  [ChainId.ROPSTEN]: {
    chainName: 'ropsten',
    builder: builders.etherscan,
  },
  [ChainId.RINKEBY]: {
    chainName: 'rinkeby',
    builder: builders.etherscan,
  },
  [ChainId.GÖRLI]: {
    chainName: 'goerli',
    builder: builders.etherscan,
  },
  [ChainId.KOVAN]: {
    chainName: 'kovan',
    builder: builders.etherscan,
  },
  [ChainId.MATIC]: {
    chainName: 'mainnet',
    builder: builders.matic,
  },
  [ChainId.MATIC_TESTNET]: {
    chainName: 'mumbai',
    builder: builders.matic,
  },
  [ChainId.BSC]: {
    chainName: '',
    builder: builders.bscscan,
  },
  [ChainId.BSC_TESTNET]: {
    chainName: 'testnet',
    builder: builders.bscscan,
  },
  [ChainId.TOMO]: {
    chainName: '',
    builder: builders.tomo,
  },
  [ChainId.TOMO_TESTNET]: {
    chainName: '',
    builder: builders.tomo,
  },
}

export function getExplorerLink(chainId, data, type) {
  console.log(chainId, data, type)
  const chain = chains[chainId]
  return chain.builder(chain.chainName, data, type)
}
