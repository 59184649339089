import React from 'react'
import withLayout from 'hoc/withLayout'
import Container from 'components/Container/Container'
import { useDispatch } from 'react-redux'
import { useDragonCave, useFetchUserDataDragonCave, useUserDataDragonCave } from 'store/dragonCave/hook'
import Value from 'components/Value/Value'
import DragonCard from 'components/Card/DragonCardV3'
import LoadingPage from 'components/Loader/LoadingPage'
import { fetchUserDataDragonCaveAsync, fetchDragonsCaveAsync } from 'store/dragonCave'
import FilterAction from './components/FilterAction'
import { showToastError } from 'components/CustomToast/CustomToast'
import { DRAGON_CAVE_ADDRESS } from 'config'
import ListView from 'components/ListView'
import { useUpdateQuery } from 'store/search/hook'
import { formatQueryDragons, useFetchDragonCave } from 'store/dragons/hook'
import { setPageQuery } from 'store/search'
import dragonsApi from 'api/dragonsApi'
import { useQuery } from 'utils'
import { DEFAULT_LIMIT } from 'constants/index'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'

const DragonCave = ({}) => {
  useUpdateQuery()
  useFetchDragonCave()
  useFetchUserDataDragonCave()
  const { account } = useActiveWeb3React()
  const dispatch = useDispatch()
  const dragons = useDragonCave()
  const userData = useUserDataDragonCave()
  const queryUrl = useQuery()

  const onChangePage = async (page) => {
    try {
      dispatch(setPageQuery(page + 1))
    } catch (error) {
      showToastError('Error', 'Some error occurred, please try again!')
    }
  }

  const updateData = async () => {
    try {
      if (account) {
        const page = dragons?.currentPage || 1
        const promise = [
          dispatch(fetchUserDataDragonCaveAsync(account)),
          dispatch(
            fetchDragonsCaveAsync(
              DRAGON_CAVE_ADDRESS.address,
              formatQueryDragons({ page, rowPerPage: DEFAULT_LIMIT, ...queryUrl }),
            ),
          ),
        ]
        await Promise.all(promise)
      }
    } catch (error) {}
  }

  const handleFavorites = async (id) => {
    try {
      await dragonsApi.tickFavorites(id, account)
      const page = dragons?.currentPage || 1
      dispatch(
        fetchDragonsCaveAsync(
          DRAGON_CAVE_ADDRESS.address,
          formatQueryDragons({ page, rowPerPage: DEFAULT_LIMIT, ...queryUrl }),
        ),
      )
    } catch (error) {
      console.log(error)
    }
  }

  if (dragons === undefined) {
    return <LoadingPage />
  }

  return (
    <Container className="pt-10 pb-36">
      <div className="grid grid-cols-1 sm:grid-cols-2">
        <div className="text-center text-white">
          <h3 className="text-xl xl:text-2xl">Total dragons</h3>
          {typeof dragons?.totalItems === 'number' ? (
            <Value className="text-5xl xl:text-6xl" value={dragons?.totalItems} decimals={0} />
          ) : (
            '--'
          )}
        </div>
        <div className="text-center text-white">
          <h3 className="text-xl xl:text-2xl">Your WDRAGON Balance</h3>
          {typeof userData?.balance === 'number' ? (
            <Value className="text-5xl xl:text-6xl" value={userData?.balance} decimals={0} />
          ) : (
            '--'
          )}
        </div>
      </div>
      <FilterAction />
      <ListView
        className="min-height-list-save max-w-screen-lg m-auto px-2.5 sm:px-5 xl:px-0 mt-8"
        totalItems={dragons?.totalItems}
        pageCount={dragons?.totalPage}
        currentPage={dragons?.currentPage}
        fetchApi={onChangePage}
      >
        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 -mx-2 gap-y-4">
          {dragons?.data?.map((item, index) => (
            <DragonCard
              key={index}
              dragon={item}
              isLink
              showPrice={false}
              handleFavorites={handleFavorites}
              updateData={updateData}
              to={`/dragon/${item.id}`}
              wDragonBalance={userData?.balance}
              isClaim
            />
          ))}
        </div>
      </ListView>
    </Container>
  )
}

export default withLayout(DragonCave)
